import React, {useEffect, useState} from 'react'
import {get} from "../../../../Model/Network/Config/Axios";
import {handleError} from "../../../Common/fToast/ToastCallback";
import {SidePane} from '../../../Common/fSidePane/RightPane'
import {Spinner} from "../../../Common/fLoader";
import Invoice from "./India";
import Others from "./Others";
import { useSelector } from 'react-redux';
import { Modal } from '../../../Common/fModal/Modal';


function ViewInvoice(props) {
	const [loading,setLoading] = useState(true)
	const [details, setDetails] = useState({});
	const [debitNote,setDebitNote] = useState(undefined);
	const [debitNoteDetails,setDebitNoteDetails] = useState(undefined);
	const [count,setCount] = useState(0);
	const [invoiceId,setInvoiceId] = useState(undefined);

	const {config} = useSelector(state => ({config: state.auth.config}))
	const {addLineItemSingleLineInvoice} = config.plugins;
    const [tdsDetails,setTdsDetails] = useState(false);
	
	useEffect(() => {
		fetchInvoice(props.id)
		if(window.FreshworksWidget) window.FreshworksWidget('hide')
		return () => {
			if(window.FreshworksWidget) window.FreshworksWidget('show')
		}
	}, [props.id,props.invoiceId,props.reload]);

	const fetchInvoice = (ID)=>{
		get(`vendor/invoicing/${ID}/details`,(e,r)=>{
			if(r){
				setDetails(r);
				setInvoiceId(r.invoice.id)
				if(config && config.tenant==="JPT" && r && r.tags && r.tags.tds_automation && count===0){
					setTdsDetails(r.tags.tds_automation)
					setCount(1);
				}
				setLoading(false);
				 if(r.debitNote) {
					get(`vendor/invoicing/${props.invoiceId}/debitNote`,(e,res)=>{
						if(res) {
							setDebitNote(true);
							setDebitNoteDetails(res);
							setLoading(false)
						}
					})
				 } else {
					setDebitNote(false);
					setLoading(false)
				 }
			}else if(e){
				handleError(e)
			}
		})
	}

	if(loading){
		return  <SidePane column={<Spinner/>} button={false} onClose={() => props.onClose(false)}>
			<Spinner/>
		</SidePane>
	}

	if(details.renderer==="INDIA"){
		return  (
			<>
				<Invoice utilities={(props && props.utilities)?props.utilities:false} debitNote={debitNote} setCreditId={props.setCreditId} setDebitId={props.setDebitId} submitDebitNote={props.submitDebitNote} submitCreditNote={props.submitCreditNote} enableAddLineItem={addLineItemSingleLineInvoice} reloadInvoice={props.reload} debitNoteDetails={debitNoteDetails} details={details} onReload={()=>{fetchInvoice(props.id)}} onClose={props.onClose} id={props.invoiceId?props.invoiceId:invoiceId}/>
				{
					(config && config.tenant==="JPT" && tdsDetails)?<Modal
						title='TDS Automation'
						des=""
						close={() => {
							setTdsDetails(false)
						}}
						show={tdsDetails}
						hideButton={true}
					>
					<p style={{'fontWeight':'bold',marginBottom:'1rem'}}>TDS Automation Applied On Invoice</p>
					<p style={{'fontWeight':'bold'}}>{tdsDetails}</p>
					</Modal>:null
				}
			</>
		)
	}else {
		return  (
			<Others details={details} onReload={()=>{fetchInvoice(props.id)}} onClose={props.onClose} id={props.invoiceId?props.invoiceId:invoiceId} enableAddLineItem={addLineItemSingleLineInvoice}/>
		)
	}
}

export  {
	ViewInvoice
}
