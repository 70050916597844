import React from 'react';
import Moment from 'moment';
import {connect} from 'react-redux';
import {PDFExport} from "@progress/kendo-react-pdf";
import Terms from './Terms';
import Vendor from './Vendor';
import Shipping from './ShippingAddress';
import Items from './Items';

class Index extends React.Component {

    items = (procured,vendor,billingTo) => {
        return (
            <div>
                <div style={{
                    minHeight: 50,
                    borderBottom: '1px solid var(--border-color)',
                    display: 'flex',
                    alignItems: 'center'
                }}>

                </div>

                <div>
                    <Items items={procured} currency={this.props.data.currency} vendor={vendor} billingTo={billingTo}/>
                </div>
            </div>
        )
    }

    confidentails = () => {
        return (
            <div style={{
                borderBottom: '1px solid var(--border-color)',
            }}>
                <div style={{
                    borderRight: '1px solid var(--border-color)',
                    padding: '10px 20px',

                }}>
                    <p style={{
                        fontWeight: 800,
                        marginBottom: 10,
                        padding:'8px 10px',
                        textTransform: 'uppercase'

                    }}>
                        Confidentiality
                    </p>
                    <p style={{
                        padding: 10
                    }}>
                        All information that is revived or derived or accessed by the vendor and /or its skilled manpower shall remain
                        confidential and shall not be disclosed to any third party whatsover provided further that the vendor may disclose
                        such information in compliance of statutory and regulatory requirements o rin compliance with a judicial or regulatory directive
                        with the prior approval of official to the extent possible and allowed by law. The Vendor shall undertake to ensure that the data collected as part of the
                        service delivery shall be treated in confidentiality and the Vendor shall also undertak that the data will not be shared with any
                        third party and that the data will not be misappropriated, retained beyond the period that is defined for the delivery of
                        services
                    </p>
                </div>
                <div style={{
                    flex: 1
                }}>

                </div>
            </div>
        )
    }

    approve = () => {
        let {details,timelines} =  this.props.data;
        let approver="";
        if(timelines && timelines.length){
            if(timelines && timelines[timelines.length-1].status==="COMPLETED" && timelines[timelines.length-1].name){
                approver=timelines[timelines.length-1].name
            }
        }
        return (
            <>
                <div style={{
                    display: 'flex',
                    flex: 7,
                    height: 80,
                    borderBottom: '1px solid var(--border-color)'
                }}>
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection:'column'
                    }}>
                        <p style={{
                            fontWeight: 600
                        }}>PREPARED BY: </p>
                    </div>
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection:'column',
                        textAlign:'center'
                    }}>
                        <p style={{
                            fontWeight:700,
                        }}>{details.raisedBy.ownerName}</p>
                    </div>  
                </div>
                {(approver)?<div style={{
                    display: 'flex',
                    flex: 7,
                    height: 80,
                    borderBottom: '1px solid var(--border-color)'
                }}>
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection:'column'
                    }}>
                        <p style={{
                            fontWeight: 600
                        }}>AUTHORIZED BY: </p>
                    </div>
                    <div style={{
                        flex: 1,
                        borderRight: '1px solid var(--border-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection:'column',
                        textAlign:'center'
                    }}>
                        <p style={{
                            fontWeight:700,
                        }}>{approver}</p>
                    </div>  
                </div>:null}
                <div style={{
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 10,
                    fontSize: 16
                }}>
                    This is an electronically generated PO, hence does not require signature.
                </div>

            </>
        )
    }

    render() {
        let list = this.props.data;
        let { conditions, vendor, procured,details,billingTo ,forms} = this.props.data;

        return (
            <div>
                <div style={{ textAlign: "right" }} >
                    <p
                        style={{
                            fontWeight: 600,
                            color: "var(--primary-color)",
                            cursor: "pointer",
                        }}
                        onClick={() => { this.pdf.save() }}
                    >
                        Download Now
                    </p>
                </div>
                <PDFExport title="Order Document" padding="40" fileName={`Order-${list.orderId}.pdf`} ref={r => this.pdf = r}>
                    <div style={{
                        width: 850,
                        margin: '10px auto',
                        marginTop: 10,
                        marginBottom: 20,
                        textAlign: 'center'
                    }}>
                        <div style={{'display':'flex'}}>
                            <div style={{marginRight:'200px'}}>
                                <img height="100px" width="100px" src="https://static.eka.io/v2/receipts/2024/12/b9bf0120-7bf4-46fa-98d4-9990f12bfe77.jpeg" alt="SBFC logo"/>
                            </div>
                            <div>
                                <p style={{
                                    fontSize: 32,
                                    fontWeight: 500,
                                    margin:10,
                                }}>SBFC Finance Limited</p>
                                <p style={{
                                    fontSize: 20,
                                    fontWeight: 500,
                                    margin:10,
                                }}>SBFC FINANCE LIMITED</p>
                                <p style={{
                                    fontSize: 18,
                                    fontWeight: 500,
                                    margin:10,
                                }}>-</p>
                            </div>
                        </div>
                        <p style={{
                            fontWeight: 400
                        }}>
                            Unit No. 103, 1st Floor, C and B Square, Sangam Complex, CTS No.95A, 127 Andheri Kurla Road, Village Chakala, Andheri (East),Mumbai - 400059
                        </p>
                    </div>
                    <div style={{
                        border: '1px solid var(--border-color)'
                    }}>
                        <div style={{
                            height: 50,
                            borderBottom: '1px solid var(--border-color)',
                            backgroundColor: 'rgb(244,244,244)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: 600
                        }}>
                            PURCHASE ORDER
                    </div>
                    <div style={{
                            borderBottom: '1px solid var(--border-color)',
                            height: 100,
                            display: "flex",
                        }}>
                            <div style={{
                                borderRight: '1px solid var(--border-color)',
                                display:'flex',
                                flexDirection:'column',
                                justifyContent:'center',
                                height: 100,
                                flex: 1,
                                padding: 10
                            }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <p style={{
                                        width: 150,
                                        fontWeight: 600
                                    }}>Purchase Order No </p>
                                    <p style={{
                                        flex: 1,
                                        marginLeft: 5
                                    }}>: {(list && list.orderId)?list.orderId:""}</p>
                                </div>
                            </div>
                            <div style={{
                                display:'flex',
                                flexDirection:'column',
                                justifyContent:'center',
                                flex: 1,
                                padding: 10
                            }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <p style={{
                                        width: 150,
                                        fontWeight: 600
                                    }}>PO Created On </p>
                                    <p style={{
                                        flex: 1,
                                        marginLeft: 5
                                    }}>: {(details && details.createdAt)?Moment(details.createdAt).format('DD-MM-YYYY'):""}</p>

                                </div>
                            </div>

                        </div>

                        <div style={{
                            borderBottom: '1px solid var(--border-color)',
                            height: 100,
                            display: "flex",
                        }}>

                            <div style={{
                                borderRight: '1px solid var(--border-color)',
                                display:'flex',
                                flexDirection:'column',
                                justifyContent:'center',
                                height: 100,
                                flex: 1,
                                padding: 10
                            }}>

                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <p style={{
                                        width: 140,
                                        fontWeight: 600
                                    }}>Vendor Card Code</p>
                                    <p style={{
                                        flex: 1,
                                        marginLeft: 5

                                    }}>: {vendor.vendorId}</p>

                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <p style={{
                                        width: 140,
                                        fontWeight: 600
                                    }}>Kind Attention </p>
                                    <p style={{
                                        flex: 1,
                                        marginLeft: 5
                                    }}>:  {vendor.name}</p>

                                </div>



                            </div>
                            <div style={{
                                display:'flex',
                                flexDirection:'column',
                                justifyContent:'center',
                                flex: 1,
                                padding: 10
                            }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <p style={{
                                        width: 150,
                                        fontWeight: 600
                                    }}>Purchase Order No </p>
                                    <p style={{
                                        flex: 1,
                                        marginLeft: 5
                                    }}>: {list.orderId}</p>

                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <p style={{
                                        width: 150,
                                        fontWeight: 600
                                    }}>Delivery date </p>
                                    <p style={{
                                        flex: 1,
                                        marginLeft: 5
                                    }}>: {Moment(procured[0].deliveryDate).format('DD-MM-YYYY')}</p>

                                </div>
                            </div>

                        </div>
                        <div style={{
                            borderBottom: '1px solid var(--border-color)',
                            display: 'flex'
                        }}>
                            <div style={{
                                borderRight: '1px solid var(--border-color',
                                display:'flex',
                                flexDirection:'column',
                                justifyContent:'center',
                                flex: 1
                            }}>
                                <Vendor vendor={vendor} />
                            </div>
                            <div style={{
                                flex: 1
                            }}>
                                <Shipping title='Bill To' address={{
                                    registered_name:(billingTo && billingTo.registered_name)? billingTo.registered_name : '-',
                                    address:(billingTo && billingTo.address)? billingTo.address : '-',
                                    city:' ',
                                    gstin:(billingTo&&billingTo.gstin)?billingTo.gstin : '-',
                                    pan:(billingTo&&billingTo.pan)?billingTo.pan : '-',
                                }} />
                                <Shipping address={{
                                    registered_name:(procured && procured.length && procured[0].officeRegisteredName)?procured[0].officeRegisteredName:'-',
                                    address:(procured && procured.length && procured[0].officeAddress)?procured[0].officeAddress:'-',
                                    city:' ',
                                    gstin:(procured && procured.length && procured[0].officeGstin)?procured[0].officeGstin:'-',
                                    pan:(procured && procured.length && procured[0].officePan)?procured[0].officePan:'-',
                                }} />
                            </div>
                        </div>

                        {this.items(procured,vendor,billingTo)}
                        <div style={{
                            minHeight: 10,
                            borderBottom: '1px solid var(--border-color)',
                            padding:10
                        }}>
                            <p style={{
                                fontWeight: 800,
                                marginBottom: 10,
                                padding:'8px 10px'
                            }}>Conditional PO's:</p>
                            {/* need to add Conditional POs once data comes */}
                        </div>
                        <Terms conditions={conditions} />
                        <Terms title='Payment Terms:' conditions={forms.map(item=>item.id==='payment_terms'?{...item,title:item.value}:null)}/>
                        {this.confidentails()}
                        {this.approve()}
                    </div>
                </PDFExport>
            </div>
        )
    }
}

export default connect(state => {
    return {
        config: state.auth.config
    }
}, null)(Index);
