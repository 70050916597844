import React, { useState } from 'react';
import CreateRequest from '../../../Others/Payouts/Eka/RechargeWallet/CreateRequest';
import Moment from 'moment';

export const WalletRechargeRequest = ({ invoiceId, dueOn ,reload,details}) => {
    const [walletRequest, setWalletRequest] = useState(undefined);
    const [payD3, setDay3] = useState(undefined);    
    return (
        <>
            <div style={{ display: 'flex', width: '96%' }}>
                <div onClick={() => {
                    setWalletRequest({
                        attrs: {
                            invoice_id: invoiceId
                        },
                        bill:{
                            amount:(details && details.invoice && details.invoice.payableAmount)||0
                        }
                    })
                }}
                    className='btn btn-primary'
                    style={{ background: "var(--primary-color)", color: '#fff', width: '100%',flex:0.5}}>
                    Initiate Wallet Recharge
                </div>
                {(dueOn && dueOn > 0 ? Moment(dueOn).isAfter(Moment()) && Moment(dueOn).diff(Moment(), 'days') >= 4 : false) &&
                    <div onClick={() => {
                        setWalletRequest({
                            attrs: {
                                invoice_id: invoiceId
                            },
                            bill:{
                                amount:(details && details.invoice && details.invoice.payableAmount)||0
                            }
                        })
                        setDay3(true)
                    }}
                        className='btn btn-primary'
                        style={{ background: "#a7c2eb", color: '#fff', width: '100%', maxWidth: '315px', marginLeft: 10 ,flex:0.5}}>
                        Pay D-3
                    </div>
                }
            </div>
            {walletRequest &&
                <CreateRequest
                    prefill={walletRequest}
                    onVaAcc="Utility Payout"
                    payD3={payD3}
                    onClose={() => {
                        setWalletRequest(undefined)
                        setDay3(undefined)
                    }} reload={reload}
                />}
        </>
    )

}