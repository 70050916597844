import React from 'react';
import Props from 'prop-types';

function StateButton(props){
		return (
			<div style={{ ...props.style }}>
				<div className="lds-ellipsis">
					<div style={{...props.dotStyle}}></div>
					<div style={{...props.dotStyle}}></div>
					<div style={{...props.dotStyle}}></div>
					<div style={{...props.dotStyle}}></div>
				</div>
			</div>
		);
}

StateButton.defaultProps = {
	buttonName: 'upload'
}

StateButton.PropsTypes = {
	buttonName: Props.string
}

export { StateButton }
