import React, { useEffect, useState } from 'react'
import { showToast } from '../../../../../Common/fToast/ToastCallback';
import BorderTable from '../../../../../Common/BorderTable/BorderTable';
import { Modal } from '../../../../../Common/fModal/Modal';

const AddUnmatchedLineItems = ({ unmatchedOcrItemData, onClose, onSubmit }) => {
    const [filteredLineItems, setFilteredLineItem] = useState([]);
    const [selectedList,setSelectedList] = useState([])
    const [query, setQuery] = useState("");

    useEffect(() => {
        let tempFiltered = [...unmatchedOcrItemData].filter((item => {
            if (query.length > 0) {
                let val = query.toLowerCase();
                return (JSON.stringify(item).toLowerCase().includes(val));
            }else return true;
        }))
        setFilteredLineItem(tempFiltered);
    }, [query,unmatchedOcrItemData])


    const handleSubmit = () => {
        if(!selectedList.length){
            showToast({type:"error",message:"Please select at least one lineitem"});
            return;
        }
        onSubmit(selectedList);
    }
    return (
        <Modal show={true} modalStyle={{maxWidth:900}} width={800} title='Add Line Item' des='select line items to add' close={onClose} onSave={handleSubmit} buttonName={`Add ${selectedList.length?`(${selectedList.length})`:''}`}>
            {/* <Filter margin="0 -4rem" onSearch={setQuery} /> */}
            <BorderTable onSearch={setQuery} selectionKeys={filteredLineItems.map(el => el.itemId)} onSelect={setSelectedList} rows={filteredLineItems.map(el => [<><p>{el.name}</p>{el.productCode?<p>productCode: {el.productCode}</p>:null}</>,el.price,el.qtty,el.total])}
            headers={["Details","Price","Quantity","Amount(INR)"]}
            />
        </Modal>
    )
}

export default AddUnmatchedLineItems