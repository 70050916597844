import React from 'react'
import './ocr.css'
import KeyValue from './KeyValue'

const HeaderLevelData = ({ details, checks, setChecks }) => {

    const handleClick = (e) => {
        e.stopPropagation()
        let key = e.target.name;
        if(!checks.hasOwnProperty(key)) return;
        setChecks((prev) => ({ ...prev, [key]: e.target.checked }))
    }
    
    return (
        <div className='headerleveldata' onClick={handleClick}>
            <div className='row'>
                <div className='column'>
                    <KeyValue  title="Acknowledgement Date" value={details.acknowledgementDate} />
                </div>
                <div className='column'>
                    <KeyValue  title="Acknowledgement No" value={details.acknowledgementNo ? details.acknowledgementNo : 0}  />
                </div>
                <div className='column'>
                    <KeyValue  title="Status" value={details.status ? details.status : ''}  />
                </div>
            </div>
            <div className="row">
                {details && details.sellerGstin &&
                    <div className="column">
                        <KeyValue
                            title={"Seller Gstin"}
                            value={details.sellerGstin || ''}
                        />
                    </div>}
                {details && details.buyerGstin &&
                    <div className="column">
                        <KeyValue
                            title={"Buyer Gstin"}
                            value={details.buyerGstin || ''}
                        />
                    </div>}
            </div>
            {details.requestDate &&
                <div className='row'>
                    <div className='column'>
                        <KeyValue  title={'Request Date'} value={details.requestDate}/>
                    </div>
            </div>}
            {details.irn &&
                <div className='row'>
                    <div className='column'>
                        <KeyValue  title={'irn'} value={details.irn}/>
                    </div>
            </div>}
            {details.ocrData && details.ocrData.placeOfSupply &&
                <div className='row'>
                    <div className='column'>
                        <KeyValue  title={'Place of Supply'} value={details.ocrData.placeOfSupply}/>
                    </div>
            </div>}
        </div>
    )
}

export default HeaderLevelData