import React, { useEffect, useState } from "react";
import Moment from 'moment';
import { useSelector } from "react-redux";
import { Card } from "../../../../../Common/fCards";
import { AsnView } from "../../../ASN/AsnView";
import { PageLoader } from "../../../../../Common/Components/PageLoader";
import { Title as Header } from "../../../../../Header/TopHeader";


const Asn = (props) =>{

const {vendorAsn} = useSelector(config=> config.auth.config.plugins);
// const [items,setItems] = useState([]);
// const [loading,setLoading] = useState(true);
const [open,setOpen] = useState(false);

useEffect(() =>{
    props.fetchASN()
},[])

// const INIT = () => {
//     let data ={
//         startDate:1672511400000,
//         endDate:Moment().add(30,'days').valueOf(),
//         po_id:props.id
//     };

//     // data.filter=`${props.id}@po_id`;

//     get(`vendor/procurement/asn/getAll`,(e,r)=>{
//         if(r) {
//             setItems(r.asnList);
//             setLoading(false);
//         }
//     },data)
// }

let mapCard = (item,index) => {
    return(
        <Card
        key={`Asn-${index}`}
        flag={{value: item.status, color: item.status === 'COMPLETED'  ? '#198754' : '#6f42c1'}}
        headings={[item.name]}
                   details={[
                    {label: 'ASN Number', value:item.asnId },
                    {label: 'Created Date', value: Moment(item.createdAt).format(" hh:mm a DD MMM YY")},
                    {label: 'Delivery Date', value: item.deliveryDate ? Moment(item.deliveryDate).format(" hh:mm a DD MMM YY"):''},
                    {label: 'PO ID', value: item.poId},
                    {label: 'Category', value: item.category}
                ]}
                   onClick={()=>{
                        setOpen(item.id);
                   }}
                   >
        </Card>
    )
}




if(!vendorAsn)  {
    return <></>
}

if(props.loading) {
    return <PageLoader />
}
   

return(
    <>
        <div style={{padding: "10px 20px",fontSize:22}}>
             <Header container={true} title="All ASN" />
        </div>
        <div style={{marginLeft:20,display:'flex',flexWrap:'wrap'}}>
            {props.items.map(mapCard)}
         </div>
         {
            open ?
            <AsnView id={open} onReload={()=>{props.fetchASN()}}  onClose={()=>{setOpen(false)}}  /> 
            :null
         }
    </>
    
)
}

export default Asn
