import React, { useState } from 'react'
import { ViewAttachment } from '../ViewAttachments';
import round_tick from "./tick.png";
import round_tick_inactive from "./tick_inactive.png";
import { Modal } from '../fModal/Modal';

const Index = ({ details, file, onClose, setOcrDetails, onNext, onDiscard }) => {
    const [cgst, setCgst] = useState(true);
    const [igst, setIgst] = useState(true);
    const [sgst, setSgst] = useState(true);
    const [date, setDate] = useState(true);
    const [vendor, setVendor] = useState(true);
    const [address, setAddress] = useState(true);
    const [invoice, setInvoice] = useState(true);
    const [amount, setAmount] = useState(true);

    let _onNext = () => {
        if(onNext){
            onNext(cgst,igst,sgst,date,vendor,address,invoice,amount);
            return;
        }
        let addresses = details.address ? details.address : undefined;
        let dates = details.date ? details.date : undefined;
        let vendors = details.contact ? details.contact : undefined;
        let amounts = details.amount ? details.amount : undefined;
        let invoices = details.invoice ? details.invoice : undefined;
        let Igst = details.taxation.igst ? details.taxation.igst : 0;
        let Cgst = details.taxation.cgst ? details.taxation.cgst : 0;
        let Sgst = details.taxation.sgst ? details.taxation.sgst : 0;
        let totalTax = details.taxation.gst;
        let originalOcr = details.ocrData ? details.ocrData : {}
        let data = {};
        if (addresses && address) {
            data["address"] = addresses
        }
        if (dates && date) {
            data["date"] = dates;
        }

        if (vendors && vendor) {
            data["vendor"] = vendors;
        }

        if (amount && amounts) {
            data["amount"] = amounts;
        }
        if (invoice && invoices) {
            data["invoice"] = invoices
        }
        if (igst && Igst) {
            data['igst'] = Igst;
        }
        if (cgst && Cgst) {
            data['cgst'] = Cgst
        }
        if (sgst && Sgst) {
            data['sgst'] = Sgst;
        }
        if (totalTax) {
            data['totalTax'] = totalTax;
        }

        setOcrDetails({
            ...data,
            originalOcr

        });
        onClose(true);
    }

    let _onDiscard = () => {
        if(onDiscard){
            onDiscard();
            return;
        }
        setOcrDetails({});
        onClose(true);
    }

    return (
        <Modal
        modalStyle={{
                minWidth: '80%'
            }}
            show={details}
            hideButton={true}
            title="Ocr Fetch Details"
            des="Review the details of ocr to continue"
            close={onClose}
            style={{
                save: {
                    height: 45,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }
            }}
        >
            <div style={{ flex: 1, display: 'flex', flexDirection: 'row-reverse' }}>
                <div style={{ width: '40%', padding: 30, background: '#f2f4f7', borderLeft: '2px dashed var(--border-color)' }}>
                    <p style={{ fontWeight: 600, fontSize: 20 }}>Parased Details</p>
                    <p style={{ fontWeight: 600, marginBottom: 30, fontSize: 14, color: 'var(--inactive-color)' }}>All information founded in this invoice are shown below :</p>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 20,

                    }}>
                        <img src={amount ? round_tick : round_tick_inactive} style={{
                            height: 25,
                            width: 25,
                            objectFit: 'contain',
                            cursor: 'pointer'
                        }} onClick={() => {
                            setAmount(!amount)
                        }} alt="" />

                        <div style={{
                            flex: 1,
                            marginLeft: 30
                        }}>
                            <div style={{
                                fontSize: 12,
                                color: 'var(--inactive-color)'
                            }}>Amount:</div>
                            <div style={{
                                fontSize: 15,
                                color: 'var(--sidebar-active-color)',
                                fontWeight: 600,
                                marginTop: 5
                            }}>{details.amount ? details.amount : 0}</div>

                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 20
                    }}>
                        <img src={cgst ? round_tick : round_tick_inactive} style={{
                            height: 25,
                            width: 25,
                            objectFit: 'contain',
                            cursor: 'pointer'
                        }} onClick={() => {
                            setCgst(!cgst)
                        }} alt="" />

                        <div style={{
                            flex: 1,
                            marginLeft: 30
                        }}>
                            <div style={{
                                fontSize: 12,
                                color: 'var(--inactive-color)'
                            }}>Cgst:</div>
                            <div style={{
                                fontSize: 15,
                                color: 'var(--sidebar-active-color)',
                                fontWeight: 600,
                                marginTop: 5
                            }}>{details.taxation.cgst ? details.taxation.cgst : 0}</div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 20
                    }}>
                        <img src={sgst ? round_tick : round_tick_inactive} style={{
                            height: 25,
                            width: 25,
                            objectFit: 'contain',
                            cursor: 'pointer'
                        }} onClick={() => {
                            setSgst(!sgst)
                        }} alt="" />

                        <div style={{
                            flex: 1,
                            marginLeft: 30
                        }}>
                            <div style={{
                                fontSize: 12,
                                color: 'var(--inactive-color)'
                            }}>Sgst:</div>
                            <div style={{
                                fontSize: 15,
                                color: 'var(--sidebar-active-color)',
                                fontWeight: 600,
                                marginTop: 5
                            }}>{details.taxation.sgst ? details.taxation.sgst : 0}</div>

                        </div>
                    </div> <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 20
                    }}>
                        <img src={igst ? round_tick : round_tick_inactive} style={{
                            height: 25,
                            width: 25,
                            objectFit: 'contain',
                            cursor: 'pointer'
                        }} onClick={() => {
                            setIgst(!igst)
                        }} alt="" />

                        <div style={{
                            flex: 1,
                            marginLeft: 30
                        }}>
                            <div style={{
                                fontSize: 12,
                                color: 'var(--inactive-color)'
                            }}>Igst:</div>
                            <div style={{
                                fontSize: 15,
                                color: 'var(--sidebar-active-color)',
                                fontWeight: 600,
                                marginTop: 5
                            }}>{details.taxation.igst ? details.taxation.igst : 0}</div>

                        </div>
                    </div>
                    {
                        details.invoice ?
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 20
                            }}>
                                <img src={invoice ? round_tick : round_tick_inactive} style={{
                                    height: 25,
                                    width: 25,
                                    objectFit: 'contain',
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    setInvoice(!invoice)
                                }} alt="" />

                                <div style={{
                                    flex: 1,
                                    marginLeft: 30
                                }}>
                                    <div style={{
                                        fontSize: 12,
                                        color: 'var(--inactive-color)'
                                    }}>Invoice Number:</div>
                                    <div style={{
                                        fontSize: 15,
                                        color: 'var(--sidebar-active-color)',
                                        fontWeight: 600,
                                        marginTop: 5
                                    }}>{details.invoice}</div>
                                </div>
                            </div>
                            : null

                    }
                    {
                        details.date ?

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 20
                            }}>
                                <img src={date ? round_tick : round_tick_inactive} style={{
                                    height: 25,
                                    width: 25,
                                    objectFit: 'contain',
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    setDate(!date)
                                }} alt="" />

                                <div style={{
                                    flex: 1,
                                    marginLeft: 30
                                }}>
                                    <div style={{
                                        fontSize: 12,
                                        color: 'var(--inactive-color)'
                                    }}>Date:</div>
                                    <div style={{
                                        fontSize: 15,
                                        color: 'var(--sidebar-active-color)',
                                        fontWeight: 600,
                                        marginTop: 5
                                    }}>{details.date ? details.date : 0}</div>
                                </div>
                            </div>
                            : null
                    }
                    {
                        details.contact ?

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 20
                            }}>
                                <img src={vendor ? round_tick : round_tick_inactive} style={{
                                    height: 25,
                                    width: 25,
                                    objectFit: 'contain',
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    setVendor(!vendor)
                                }} alt="" />

                                <div style={{
                                    flex: 1,
                                    marginLeft: 30
                                }}>
                                    <div style={{
                                        fontSize: 12,
                                        color: 'var(--inactive-color)'
                                    }}>Vendor Details:</div>
                                    <div style={{
                                        fontSize: 15,
                                        color: 'var(--sidebar-active-color)',
                                        fontWeight: 600,
                                        marginTop: 5

                                    }}>{details.contact ? details.contact.title : 'NA'}</div>
                                    <div style={{
                                        fontSize: 14,
                                        marginTop: 2
                                    }}>
                                        {details.contact.gstin}
                                    </div>
                                    <div style={{
                                        fontSize: 14,
                                        marginTop: 2
                                    }}>
                                        {details.contact.registeredId}
                                    </div>
                                    <div style={{
                                        fontSize: 14,
                                        marginTop: 2
                                    }}>
                                        GSTIN Status :  {details.contact.gstinStatus || 'NA'}
                                    </div>
                                    <div style={{
                                        fontSize: 14,
                                        marginTop: 2
                                    }}>
                                        Tax Payer Type :  {details.contact.taxPayerType || 'NA'}
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                    {
                        details.address ?

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 20
                            }}>
                                <img src={address ? round_tick : round_tick_inactive} style={{
                                    height: 25,
                                    width: 25,
                                    objectFit: 'contain',
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    setAddress(!address)
                                }} alt="" />

                                <div style={{
                                    flex: 1,
                                    marginLeft: 30
                                }}>
                                    <div style={{
                                        fontSize: 12,
                                        color: 'var(--inactive-color)'
                                    }}>Billing Details:</div>
                                    <div style={{
                                        fontSize: 15,
                                        color: 'var(--sidebar-active-color)',
                                        fontWeight: 600,
                                        marginTop: 5
                                    }}>{details.address ? details.address.name : 0}</div>
                                    <div style={{
                                        fontSize: 15,
                                        marginTop: 2
                                    }}>
                                        {details.address.gstin}
                                    </div>
                                </div>
                            </div>
                            : null
                    }

                    {
                        details.sellerGstin ?

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 20
                            }}>

                                <div style={{
                                    flex: 1,
                                }}>
                                    <div style={{
                                        fontSize: 12,
                                        color: 'var(--inactive-color)'
                                    }}>SellerGstin Details:</div>
                                    <div style={{
                                        fontSize: 15,
                                        color: 'var(--sidebar-active-color)',
                                        fontWeight: 600,
                                        marginTop: 5
                                    }}>{details.sellerGstin ? details.sellerGstin : '-'}</div>

                                </div>
                            </div>
                            : null
                    }

                    {
                        details.buyerGstin ?

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 20
                            }}>

                                <div style={{
                                    flex: 1,
                                }}>
                                    <div style={{
                                        fontSize: 12,
                                        color: 'var(--inactive-color)'
                                    }}>Buyer Gsting Details:</div>
                                    <div style={{
                                        fontSize: 15,
                                        color: 'var(--sidebar-active-color)',
                                        fontWeight: 600,
                                        marginTop: 5
                                    }}>{details.buyerGstin ? details.buyerGstin : '-'}</div>
                                </div>
                            </div>
                            : null
                    }

                    <div className="btn btn-portlet" onClick={_onNext} style={{ marginTop: 50, maxWidth: 350 }}>Continue</div>
                    <div className="btn btn-portlet" onClick={_onDiscard} style={{ marginTop: 15, maxWidth: 350, background: '#ff6464' }}>Discard OCR</div>

                </div>
                <div style={{ flex: 2, display: 'flex', justifyContent: 'center' }}>
                    <ViewAttachment items={[file]} />
                </div>
            </div>

        </Modal>
    )
}

export default Index