import React, { useCallback, useState, forwardRef } from 'react'
import { SearchableSelect } from '../Class/Select/SearchableSelec2'
import { get } from '../../../Model/Network/Config/Axios'
import _ from 'lodash'

const CustomFormSearch = forwardRef(({ formType, fieldId, onSelect, disabled, placeholder,clearInput },ref) => {
    const [state, setState] = useState({
        value: undefined,
        items: []
    })

    const customValueSelect = filter => {
        if (onSelect) {
            onSelect(filter)
        }
        if(clearInput){
            setState({value: undefined,items: []})
            return;
        }
        setState(prev => {
            return (
                {
                    ...prev,
                    value: filter
                }
            )
        })
    }

    // useImperativeHandle(ref,()=>{
    //     return{
    //     }
    // })

    const onChange = useCallback(q => {
        if (q.length > 0) {
            get(`/search/customFieldsValue?formType=${formType}&fieldId=${fieldId}`, (e, r) => {
                if (r) {
                    setState(prev => {
                        return (
                            {
                                ...prev,
                                items: r.data.map(item => ({
                                    label: item.map? item.map.fieldValue: "",
                                    value: item.map? item.map.fieldValue: "",
                                }))
                            })
                    })
                }
            }, {
                q
            })
        } else {
            setState({
                items: []
            })
        }
    }, [formType, fieldId])

    const debouncedOnChange = useCallback(_.debounce(onChange, 500), [onChange])
    return (
        <SearchableSelect
            menuStyle={{ top:'-9px' }}
            disabled={disabled}
            items={state.items}
            onChange={debouncedOnChange}
            label={""}
            placeholder={placeholder ? placeholder : "Select Filter Value"}
            select={customValueSelect} value={state.value} />
    )
})

export default CustomFormSearch