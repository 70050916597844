import React from 'react'
import Accordion from '../../../../Common/Accordion';
import { FunctionalKeyValue } from '../../../../Common/fInputs/details';

const OcrDetails = ({ invoiceDeatails }) => {
    const { invoiceLineItem = {}, headerOcr = {} } = invoiceDeatails;

    let editableEwayBillOcrData = {}, editableIrnQrData = {}, editableLrOcrData = {}, editableOcrData = {}, editableLineItemData = {};
    if(invoiceDeatails.editableEwayBillOcrData){
        Object.keys(invoiceDeatails.editableEwayBillOcrData).forEach(item => {
            if(invoiceDeatails.editableEwayBillOcrData[item] && typeof(invoiceDeatails.editableEwayBillOcrData[item]) !== "object"){
                editableEwayBillOcrData[item] = invoiceDeatails.editableEwayBillOcrData[item];
            }
        })
    }

    if(invoiceDeatails.editableIrnQrData){
        Object.keys(invoiceDeatails.editableIrnQrData).forEach(item => {
            if(invoiceDeatails.editableIrnQrData[item] && typeof(invoiceDeatails.editableIrnQrData[item]) !== "object"){
                editableIrnQrData[item] = invoiceDeatails.editableIrnQrData[item];
            }
        })
    }

    if(invoiceDeatails.editableLrOcrData){
        Object.keys(invoiceDeatails.editableLrOcrData).forEach(item => {
            if(invoiceDeatails.editableLrOcrData[item] && typeof(invoiceDeatails.editableLrOcrData[item]) !== "object"){
                editableLrOcrData[item] = invoiceDeatails.editableLrOcrData[item];
            }
        })
    }

    if(invoiceDeatails.editableOcrData){
        Object.keys(invoiceDeatails.editableOcrData).forEach(item => {
            if(invoiceDeatails.editableOcrData[item] && typeof(invoiceDeatails.editableOcrData[item]) !== "object"){
                editableOcrData[item] = invoiceDeatails.editableOcrData[item];
            }
        })
    }

    if(invoiceDeatails.editableOcrData && invoiceDeatails.editableOcrData.invoiceItems){
        invoiceDeatails.editableOcrData.invoiceItems.forEach((item, index) => {
            let key = item.title + item.desc;
            editableLineItemData[key] = {
                ...item.productCode?{"Product Code": item.productCode}:{},
                ...item.hsn?{"HSN": item.hsn}:{},
                ...item.taxAmount && Number(item.taxAmount)?{"Tax Amount": item.taxAmount}:{},
                ...item.price && Number(item.price)?{"Price": item.price}:{},
                ...item.quantity && Number(item.quantity)?{"Quantity": item.quantity}:{},
                ...item.amount && Number(item.amount)?{"Amount": item.amount}:{},
            };
        })
    }


    return (<>
        {/* header mismatch */}
        {!!Object.keys(headerOcr).length && <div style={{ marginBottom: "20px" }}>
            <h3 style={{ marginBottom: "15px", marginTop: '10px' }}>Header Level Mismatch</h3>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                {Object.keys(headerOcr).map((key, index) => {
                    let modifiedKey = key.split("_").join(" ");
                    let { po: poValue, ocr: ocrValue } = headerOcr[key];

                    return (
                        <div key={index}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '6px',
                                alignItems: "flex-start",
                                border: "1px solid #ccc",
                                borderRadius: "8px",
                                padding: "10px",
                                background: "#f9f9f9"
                            }}>
                            <strong>{modifiedKey.toUpperCase()}</strong>
                            <div><strong>Invoice:</strong> {poValue || "N/A"}</div>
                            <div><strong>OCR:</strong> {ocrValue || "N/A"}</div>
                        </div>
                    );
                })}
            </div>
        </div>}

        {/* line item mismatch */}
        {!!invoiceLineItem.length && <div style={{ marginBottom: "20px" }}>
            <h3 style={{ marginBottom: "15px", marginTop: '10px' }}>LineItem Mismatch</h3>
            {
                invoiceLineItem.map((lineItem, index) => {
                    let header = lineItem.title.po + lineItem.desc.po;

                    return (<>
                        <Accordion title={header} uniqueKey={index}>
                            <div style={{ display: "flex", flexDirection: "column", gap: "10px",fontSize:'12px' }}>
                                {Object.keys(lineItem).map((key, index) => {
                                    if (key === "title" || key === "desc") return null;
                                    let modifiedKey = key.split("_").join(" ");
                                    let { po: poValue, ocr: ocrValue } = lineItem[key];
                                        return (
                                            <div key={index}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '6px',
                                                    alignItems: "flex-start",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "8px",
                                                    padding: "10px",
                                                    background: "#f9f9f9"
                                                }}>
                                                <strong>{modifiedKey.toUpperCase()}</strong>
                                                <div><strong>Invoice:</strong> {poValue || "N/A"}</div>
                                                <div><strong>OCR:</strong> {ocrValue || "N/A"}</div>
                                            </div>
                                        );
                                })}
                            </div>
                        </Accordion>
                    </>)
                })
            }
        </div>}

        <>
            <section aria-labelledby="ocr mismatch">
                
            </section>
            <section aria-labelledby="ocr edited data">
                {!!Object.keys(editableEwayBillOcrData).length && <h5 style={{ marginTop: 20 }}>Eway Bill</h5>}
                {
                    
                    !!Object.keys(editableEwayBillOcrData).length && Object.keys(editableEwayBillOcrData).map(item => {
                        return (<>
                            <FunctionalKeyValue title={item} value={editableEwayBillOcrData[item]} />
                        </>)
                    })
                }
                {!!Object.keys(editableIrnQrData).length && <h5 style={{ marginTop: 20 }}>IRN</h5>}
                {
                    !!Object.keys(editableIrnQrData).length && Object.keys(editableIrnQrData).map(item => {
                        return (<>
                            <FunctionalKeyValue title={item} value={editableIrnQrData[item]} />
                        </>)
                    })
                }
                {!!Object.keys(editableLrOcrData).length && <h5 style={{ marginTop: 20 }}>LR-Copy</h5>}
                {
                    !!Object.keys(editableLrOcrData).length && Object.keys(editableLrOcrData).map(item => {
                        return (<>
                            <FunctionalKeyValue title={item} value={editableLrOcrData[item]} />
                        </>)
                    })
                }
                {!!Object.keys(editableOcrData).length && <h5 style={{ marginTop: 20 }}>OCR Invoice</h5>}
                {
                    !!Object.keys(editableOcrData).length && Object.keys(editableOcrData).map(item => {
                        return (<>
                            <FunctionalKeyValue title={item} value={editableOcrData[item]} />
                        </>)
                    })
                }
                {
                    !!Object.keys(editableLineItemData).length && <h5 style={{ marginTop: 20 }}>Line Items</h5>
                }
                {   
                    !!Object.keys(editableLineItemData).length &&
                        Object.keys(editableLineItemData).map((lineItem,index)=>{
                            return (
                                <Accordion title={lineItem} uniqueKey={index} backgroundColor='white'>
                                    {
                                        Object.keys(editableLineItemData[lineItem]).map((key,index)=>{
                                            return (
                                                <FunctionalKeyValue title={key} value={editableLineItemData[lineItem][key]} key={index}/>
                                            )
                                        })
                                    }
                                </Accordion>
                            )
                        })
                    
                }
            </section>
        </>
    </>

    )
}

export default OcrDetails