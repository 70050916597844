import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import Validator from "validator";
import Props from "prop-types";
import moment from "moment";
import { Calendar } from 'react-date-range';

const FunctionalInput = React.forwardRef((props,ref)=>{
	let [className,setClassName] = useState("");
	
	useEffect(()=>{
	   if(props.defaultValue && ref && ref.current) {
		   ref.current.value = props.defaultValue
		} else if(ref && ref.current && !ref.current.value){
			ref.current.value = "";
		}
	},[props])
	
	let onBlur = () => {
		if (props.onBlur)
			props.onBlur()
		let className = "";
		let required = props.required;
		if(!ref.current){
			return;
		}
		let value = ref.current.value;
		switch (props.type) {
			case "email":
				if (!value) {
					if (required)
						className = "invalid";
					break
				}
				if (!Validator.isEmail(value)) {
					className = "invalid";
					break
				} else {
					className = "valid";
					break
				}
			case "password": {
				if (value && value.length > 4) {
					className = "valid";
				} else {
					className = "invalid";
				}
				break
			}
			case "text":
			default:
				if (required) {
					if (value)
						if (props.customValidator) {
							if (props.customValidator(value))
								className = "valid";
							else
								className = "invalid"
						} else {
							className = "valid";
						}
					else
						className = "invalid";
				} else {
					if (value) {
						className = "valid";
					}
				}
				break
		}
		setClassName(className)
	}


 const blueOnScroll = (e) => {
	if(document.activeElement.type === "number"){
        document.activeElement.blur();
    }
  };

	return (
		<div className="full-width" style={props.style}>
			{
				props.title ? <label className="input-label" style={props.labelStyle}>
					{props.title} {props.optionalValue? props.optionalValue : ''}:
				</label> : null
			}
			<div style={props.style} className={`input ${ props.className ? props.className : className}`} onClick={()=>{
				if(props.onClick) {
					props.onClick()
				}
			}}>
			  <input 
				disabled={props.disabled} {...props.inputProps}
			
				style={props.inputStyle} 
				  onBlur={() => {
					if (props.onEdited) {
						props.onEdited()
					}
					onBlur()
				   }}
			    ref={ref} 
				onWheel={blueOnScroll}
				min={props.min}
				autoComplete='off'
			    type={props.type} 
				placeholder={props.placeholder ?props.placeholder : props.title}
			    onChange={e => {
					onBlur()
					if (props.onChange) {
						props.onChange(e)
					}
				}} 
				maxLength={props.maxLength}
			   />
			</div>
			{
				props.blue ?
					<p style={{
						fontSize: 9,
						color: "#EE3F5a",
						margin: 0
					}}>{props.blue}</p> : null
			}
		</div>
	)
})

const FloatInputButton = (props)=> {


	const [getDelta, setDelta] = useState(false);
	let Input = useRef();

	useEffect(()=>{
		if(props.placeholder) {
			Input.current.value = props.placeholder
		}

		if(typeof props.placeholder === 'number' && props.placeholder===0) {
			Input.current.value = props.placeholder
		}
	},[props.placeholder])


	const updateValue = (val)=>{
		if(props.onChange){
			props.onChange(val.target.value);
		}
		if(Input.current.value===''){
			setDelta(false);
		}else{
			setDelta(true);
		}
	}

	const submitValue = ()=>{
		props.onSubmit(Input.current.value);
		Input.current.value='';
		setDelta(false);
	}

		return (
			<div className="popup__field field" style={{ ...props.style }}>
				<div className="field__label" style={{margin: '20px 0px 10px 0px', fontWeight: 'normal'}} >{props.title}</div>
				<div className="field__wrap" style={{
					...props.containerStyle
				}}>
					<div style={{display: 'flex'}} onClick={()=>{
						if(props.onClick) {
							props.onClick()
						}
					}}>
						<input id='FieldInput' ref={ref => {
							Input.current = ref;
							if (props.handleRef) {
								props.handleRef({
									ref,
									setValue: val => ref.value = val,
									value: () => ref.value,
									getValue: () => ref.value
								})
							}
						}} 
					    	min="1" 
					     	autoComplete='off'
							defaultValue={props.default}
							onChange={updateValue}
							disabled={props.disabled}
							className="field__input small_input"
							type={props.type}
							placeholder={props.placeholder}
							style={{fontSize: '12px', height: '40px', width: '100%', padding: '10px'}}
							readOnly={props.disabled}
							
							/>

							{
								getDelta?
								<div onClick={submitValue} style={{height:'40px', width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px', cursor: 'pointer', color: 'var(--white-color)', backgroundColor: 'var(--primary-color)', marginLeft: '-40px'}}>
								<svg class="bi bi-check-lg" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
								<path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
								</svg>
								</div>
								:<></>
							}
						</div>
				</div>
			</div>
		)
	}


const DatePickerCustom = forwardRef(({formate,label,unset,onChange,value}, ref) => {
		const [date, setDate] = useState(undefined);
		const [show, setShow] = useState(false);
	  
		const handleSelectDate = (date) => {
		  onChange && onChange(date);
	
		  setDate(date);
		  setShow(false);
		};
	  
		useImperativeHandle(ref, () => ({
		    value: date,
			setValue:(date)=>{
				setDate(date);
			}
		}));

		return (
		  <div className="full-width">
			<label className="floating-top-label">{label ? label : 'Select Date'}</label>
			<div className={`floating-label`} onClick={() => setShow(!show)}>
			  <p>{date ? moment(date).format(formate) : formate ? formate :  "DD-MM-YYYY"}</p>
			</div>
			{show ? (
			  <div style={{ position:  unset ? "unset" : 'absolute',border: '1px solid var(--border-color',zIndex:11111}} id="no-shadow">
				<Calendar 
					date={date} 
					onChange={handleSelectDate} 
				    style={{border: '1px solid var(--border-color'}}/>
			   </div>
			) : null}
		  </div>
		);
	  });
	  


FunctionalInput.propTypes = {
	title: Props.string,
	type: Props.string.isRequired,
	handleRef: Props.func,
	customValidator: Props.func
}

FunctionalInput.defaultProps = {
	required: true,
	inputProps: {},
	min: 1,
	maxLength: 2000,
}

export {
	FunctionalInput, FloatInputButton,DatePickerCustom
}
