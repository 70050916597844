import React, { useEffect, useRef, useState } from 'react';
import { get } from '../../../../../../Model/Network/Config/Axios';
import { showToast } from '../../../../../Common/fToast/ToastCallback';

const Item = ({item,index,currency,onChange,onConditionChange}) => {

    console.log(item);

    let [condition,setCondition] = useState([]);
    let INPUT = useRef();

    useEffect(()=>{

        get(`vendor/procurement/poCondition/getPoItemConditions`,(e,r)=>{
            if(r) {
                setCondition(r.poConditions)
            }
        },{
            filter:`${item.id}@t0.procured_item_id`
        })

    },[])


    let grnChange= (value)=>{
        if(value && +value>(item.qtty-item.approved)) {
            INPUT.current.value = item.qtty-item.approved;
            showToast({
                type:'error',
                message:'Qtty is not greater than line item qtty'
            })
            return
        } else {
            onChange(value)
        }
    }


    let conditionChange=(value,i)=>{
        if(value && +value>i.qty) {
            INPUT.current.value = i.qty;
            showToast({
                type:'error',
                message:'Qtty is not greater than line item qtty'
            })
            return
        } else {
            onConditionChange(value,i)
        }
    }
   

    let mapCondition = (i,j) => {
        return(
            <div style={{
                border:'1px solid var(--border-color)',
                minHeight:50,
                display:'flex',
                fontSize:14
            }} key={j}>
             <div style={{
                width:50,
                height:50,
                borderRight:'1px solid var(--border-color)',
                fontWeight:'bold'
            }} className='flex flex-v-center flex-h-center'>
               {index+1}.{j+1}
            </div>
            <div style={{
                flex:2,
                minHeight:50,
                borderRight:'1px solid var(--border-color)',
            }} className='flex flex-v-center'>
                <div style={{paddingLeft:10}}>
                    <p style={{fontSize:14}}>{i.description}</p>
                    <p style={{fontSize:12}}>{i.notes}</p>
                </div>
            </div>
            <div style={{
                flex:1,
                minHeight:50,
                borderRight:'1px solid var(--border-color)'
            }} className='flex flex-v-center'>
                <div style={{paddingLeft:10}}>
                   {currency} {i.price}
                </div>
            </div>
            <div style={{
                 flex:1,
                 minHeight:50,
                 borderRight:'1px solid var(--border-color)'
            }} className='flex flex-v-center'>
                <div style={{
                    paddingLeft:10
                }}>
                    {i.qty} / {i.units}
                </div>
            </div>
            <div style={{
                 flex:1,
                 height:50,
                 borderRight:'1px solid var(--border-color)',
            }} className='flex flex-v-center'>
                <div style={{padding:10}}>
                    <input  type="number" style={{
                        width:'100%',
                        height:35,
                        padding:10
                    }} onChange={(e)=>{
                        conditionChange(e.target.value,i)
                    }} />
                </div>
            </div>
            </div>
        )
    }

    return(
        <>
        <div style={{
            border:'1px solid var(--border-color)',
            minHeight:50,
            display:'flex',
            fontSize:14
        }}>
            <div style={{
                width:50,
                height:50,
                borderRight:'1px solid var(--border-color)'
            }} className='flex flex-v-center flex-h-center'>
                {index+1}
            </div>
            <div style={{
                flex:2,
                height:50,
                borderRight:'1px solid var(--border-color)',
            }} className='flex flex-v-center'>
                <div style={{paddingLeft:10}}>
                    <p style={{fontSize:14}}>{item.name}</p>
                    <p style={{fontSize:12}}>{item.description}</p>
                    <p style={{fontSize:12}}>Item Ref no: {item.id}</p>
                </div>
            </div>
            <div style={{
                flex:1,
                height:50,
                borderRight:'1px solid var(--border-color)'
            }} className='flex flex-v-center'>
                <div style={{paddingLeft:10}}>
                   {currency} {item.price}
                </div>
            </div>
            <div style={{
                 flex:1,
                 height:50,
                 borderRight:'1px solid var(--border-color)'
            }} className='flex flex-v-center'>
                <div style={{
                    paddingLeft:10
                }}>
                  {item.qtty-item.approved} / {item.unit}
                </div>
            </div>
            <div style={{
                 flex:1,
                 height:50,
                 borderRight:'1px solid var(--border-color)'
            }} className='flex flex-v-center'>
                <div style={{padding:10}}>
                    <input ref={INPUT} type="number" style={{
                        width:'100%',
                        height:35,
                        padding:10
                    }} onChange={(e)=>{
                        grnChange(e.target.value)
                    }} />
                </div>
            </div>
        </div>
        {
            condition.map(mapCondition)
        }
        </>
    )
}

export default Item;