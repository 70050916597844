import React, {useEffect, useRef, useState} from 'react'
import {SidePane} from "../../../../../Common/fSidePane/RightPane";
import Invoice from "./Invoice";
import {Spinner} from "../../../../../Common/fLoader";
import {DatePickerCustom, FunctionalInput,FloatInputButton} from "../../../../../Common/fInputs";
import {FileUpload} from "../../../../../Common/fInputs/FunctionalFile";
import PhotoTile from "../../../../../Common/fPhoto";
import {handleError, showToast} from "../../../../../Common/fToast/ToastCallback";
import Moment from "moment";
import {SearchOffice2} from "../../../../../Common/fSubmitInvoice/common/SearchOffice";
import {Modal} from "../../../../../Common/fModal/Modal";
import {Select} from "../../../../../Common/Class/Select";
import {FunctionalKeyValue} from "../../../../../Common/fInputs/details";
import {get, post} from '../../../../../../Model/Network/Config/Axios';
import {Form} from '../../../../../Common/Class/Form/index';
import { useSelector } from 'react-redux';
import { useVendorPaymentTerm } from '../../../../../HooksUtility/Vendor/getPaymentTerm';
import { CostAllocation } from '../CostAllocation';
import { LineItemCostAllocation } from '../CostAllocation/LineItemCostAllocation';
import ProductSearch from '../../ProductSearch';
import useDateFormat from '../../../useDateFormat';
import SearchHsn from '../../SearchHsn';
import { StateButton } from '../../../../../Common/fStateButton';
import AdditionalCharges from '../AdditionalCharges';
import { ReadOnly } from '../../../../../Common/fCard/ButtonOutline/ReadOnly';
import useOCRV2 from '../../../../../Hooks/useOCRV2';
import { numberWithCommas } from '../../../../../../Utils/NumberToComma';
import SelectGST from '../../../../../Common/fUtils/SelectGST';
import AddUnmatchedLineItems from './AddUnmatchedLineItems';
import MismatchModal from './MismatchModal';
import { getFormattedEditableData, OCRMismatchBajaj, ocrMismatchStandard } from './OCRMismatch';
import ConfirmationModal from '../../../../../Common/OCRCOnfirmation';
import LineItemConfirmation from "../../../../../Common/OCRCOnfirmationPO"
import { RunOCR } from '../../../../../../Utils/Utils';
import BajajMismatchModal from './BajajMismatchModal'; 
import ToggleSwitch from '../../../../../Common/fSwitch';
import CreateAsn from './CreateAsn';
import IrnInvoiceData from '../../../../../Common/IrnInvoiceData';
import BorderTable from '../../../../../Common/BorderTable/BorderTable';


function SubmitInvoice(props) {
	let {data,contractId,lineItemEditDisable} = props;
  const OCRURL = useOCRV2();

  if(!lineItemEditDisable) {
    lineItemEditDisable = false
  }
	
  if(props.conditionalSumbit) {
    delete data.billingTo;
    delete data.shipTo;
  }
	if(!data){
		data = {}
	}

	let vendor = props.vendor;
	if (props.poId){
		vendor = {
			...data.vendor,
			gstin:data.vendor.address.gstin?data.vendor.address.gstin:data.vendor.gstin
		}
	}

	if(props.contractId) {
		vendor = {
			...data.vendor,
			gstin:data.vendor.gstin
		}
	}
  let asnRequired=((props && props.data && props.data.asnRequired)?props.data.asnRequired:(props && props.data && props.data.category && props.data.category.attrs && props.data.category.attrs['enable_invoice_create_asn'] && props.data.category.attrs['enable_invoice_create_asn']) === 'true')

	let {invoiceOcr,roundOff,restrictDuplicateInvoiceSubmission,costAllocation,vendorAdditionalCharges,budgetingV2,costAllocationOnBaseAmount,invoiceDiscounting,vertexAiInvoiceOcr,searchProductByService,ocrLineItem,vendorAsn} = useSelector(state=>state.auth.config.plugins)
  const [enableAsn,setEnableAsn] = useState(asnRequired);
	let BILLNUMBER=useRef(),BILLDATE=useRef();
	const [loading, setLoading] = useState(true);
	const [btnLoading, setBtnLoading] = useState(false);
	const [edit,setEdit] = useState(false);
  const [invoiceRoundOfAmountLimit,setInvoiceRoundOfAmountLimit] = useState(1);
	let paymentTerms = useVendorPaymentTerm();
  const [contractTypeWiseAmountEdit,setContractTypeWiseAmountEdit]=useState({
    key:"",
    value:""
  });
  let propData=((props && props.data && props.data.contractData && props.data.contractData.type)?props.data.contractData.type:undefined);
  const [contractType,] = useState(propData);

	const [files, setFiles] = useState([]);
	const [gst, setGst] = useState({
		value: 5,
		label: 5
	})
	const [editGst,setEditGst] = useState({
		value:2,
		label:2
	})
	const [discount,setDiscount] = useState(0);
	const [singleService, setSingleService] = useState({});
	const [services, setServices] = useState([]);
	const [inputData, setInputData] = useState({
		vendor: vendor,
		billingTo: data.billingTo,
		shippingTo: data.shipTo,
		lineItems: props.lineItems?props.lineItems: [],
		billDate: undefined,
		due: undefined,
		billNumber: undefined,
		discount: 0,
		predictedAmount:0,
		costAllocationType:undefined,
		costAllocationValue:undefined
	});
	const [duplicate,setDuplicate] = useState(false);
	const [duplicateInvoice,setDuplicateInvoice]= useState([]);
	const [lineItemCostAllocation,setLineItemCostAllocation] = useState(false);
	const [viewItemAllocation,setViewItemAllocation] = useState(false);
	let dateFormate = useDateFormat('MM-DD-YYYY')
	let FORM = useRef(),FORM1=useRef(),COSTALLOCATION=useRef(),DISCOUNTEDITMAMOUNT=useRef(),ADDITIONAL_CHARGES=useRef(),DISCOUNTAMT = useRef()
	const [shippingAdd,setShippingAdd] = useState(false);
	const [modal, setModal] = useState(false)
  const [filterData,setFilterData] = useState({});
  const [ocrDetails,setOcrDetails] = useState({});
  const [ocrRunning,setOcrRunning] = useState(false);
  const [details, setDetails] = useState(undefined);
  const [fetchingLineitems,setFetchingLineItems] = useState(false);
  const [addUmatchedLineItem,setAddUmatchedLineItem] = useState();
  const [unmatchedOcrItemData,setUnmatchedOcrItemData] = useState([]);
  const [matchingOcrItemData,setMatchingOcrItemData] = useState({});
  const [showMismatchModal,setShoWMismatchModal] = useState(false);
  const [showIrnMismatchModal,setShoWIrnMismatchModal] = useState(false);
  const [confirmation, setConfimation] = useState(false);
  const [config,setOCRConfig] = useState({});
  const [ocrV2,setOcrV2] = useState(false);
  const tenant = useSelector(state=>state.auth.config.tenant);
  const [LRCOPYOCR,setLRCOPYOCR] = useState(false);
  const [EwayBillOCR,setEwayBillOCR]=useState(false);
  const [ewayBillDATA,setEwayBillDATA] = useState({});
  const [LRCOPYDATA,setLRCOPYDATA]=useState({});
  const [showBajajMismatchModal,setShowBajajMismatchModal] = useState();
  const [customFormOcr,setCustomFormOcr] = useState({})
  const [asnModal,setAsnModal] = useState(false);
  const [asnForms,setAsnForm] = useState([]);
  const [saveData,setSaveData] = useState(undefined);
  const [showIrnData,setShowIrnData] = useState(undefined);
  

	useEffect(()=>{
		if(!loading) {
			FORM.current.loadForm(`vendor::category::${props.categoryId}`)
		}
	},[loading])

  useEffect(()=>{
    if(vendorAsn && !(props && props.data && props.data.disableAsn) && props.poId && props.categoryId) {
      get(`/forms/vendor::asn::${props.categoryId}`,(e,r)=>{
          if(r && r.forms) {
              setAsnForm(r.forms);
          }
      })
    }
  },[props])
  
  
  useEffect(()=>{
    if(props.irnData && services){
      setShowIrnData(props.irnData)
    }
  },[props.irnData,services])

  let handleShippingAddress = (event) => {

    if(inputData.billingTo && inputData.billingTo.name) {
      setShippingAdd(event.target.checked);
      if(event.target.checked){
        setInputData({
          ...inputData,
          shippingTo: inputData.billingTo
        })
      }else{
        setInputData({
          ...inputData,
          shippingTo: data.shipTo
        })
      }
    } else {
      showToast({
        type:'error',
        message:"Please select the billing address first."
      })

    }
    
  }

  useEffect(() => {
    if (details && !ocrRunning && props.poId && ocrLineItem) {
      let payload = {
        poItems: props.lineItems.map(item => item.itemId),
        ocrDataV2Data: details.ocrData
      }
      setFetchingLineItems(true);
      post(`/vendor/invoicing/poItemInvoiceOcr`, payload, (e, r) => {
        if (r) {
          setMatchingOcrItemData(r.matchingOcrItemData)
        } else {
          handleError(e);
          setMatchingOcrItemData({})
        }
        setFetchingLineItems(false)
      })
    }
  }, [details, ocrRunning, props.lineItems, props.poId, ocrLineItem])

  useEffect(() => {
    if (props.poId && matchingOcrItemData && Object.keys(ocrDetails).length && ocrLineItem) {
      let matchingKeys = Object.keys(matchingOcrItemData);
      let tempLineItem = [...props.lineItems];
      let filteredLineItems = tempLineItem.filter(el => matchingKeys.includes((el.itemId).toString()));
      let unmatchedOcrItemData = tempLineItem.filter(el => !matchingKeys.includes((el.itemId).toString()));
      setUnmatchedOcrItemData(unmatchedOcrItemData);
      setInputData(prev => {
        return ({
          ...prev,
          lineItems: filteredLineItems
        })
      })
    }
  }, [ocrDetails, matchingOcrItemData, props.poId,ocrLineItem, props.lineItems])

  const handleCustomFormOCRBajaj = async (value, item) => {
    if ((tenant !== "bajajelec" && tenant !== "TRDT")) return;
    if (item.fieldId === " lr_copy") {
      setCustomFormOcr({" lr_copy":true});
      if (value.endsWith('.jpg') || value.endsWith('.jpeg') || value.endsWith('.pdf') || value.endsWith('.png')) {
        setLRCOPYOCR(true);
        showToast({type:"success",message:"OCR RUNNING IN BACKGROUND"});
        try {
          let data = await RunOCR(`vendor/invoicing/ocr.lr`, value)
          showToast({type:"success",message:"LR COPY DATA FETCHED SUCESSFULLY"});
          setLRCOPYDATA(data)
        } catch (err) {
          handleError(err);
        } finally {
          setLRCOPYOCR(false);
          setCustomFormOcr({" lr_copy":false});
        }
      } else {
        showToast({type:"error",message:"Only .jpg .Jpeg .pdf and .png file allowed"});
      }
    } else if (item.fieldId === "e-waybill") {
      if (value.endsWith('.jpg') || value.endsWith('.jpeg') || value.endsWith('.pdf') || value.endsWith('.png')) {
        setEwayBillOCR(true);
        setCustomFormOcr((prev)=> ({...prev,"e-waybill":true}));
        showToast({type:"success",message:"OCR RUNNING IN BACKGROUND"});
        try {
          let data = await RunOCR(`vendor/invoicing/ocr.eway`, value)
          showToast({type:"success",message:"E-WAY BILL DATA FETCHED SUCESSFULLY"});
          setEwayBillDATA(data)
        } catch (err) {
          handleError(err);
        } finally {
          setEwayBillOCR(false);
          setCustomFormOcr((prev)=> ({...prev,"e-waybill":false}));
        }
      } else {
        showToast({type:"error",message:"Only .jpg .Jpeg .pdf and .png file allowed"});
      }
    }
  }

  const bajaOCRMismatchHandler = ({data}) => {
    let { headerLevelMismatch, ocrInvoiceQRMismatch, lineitemOCRPOMismatch, ocrInvoiceLrCopyMismatch, ocrInvoiceEwayBillMismatch, ocrLREwayBillMismatch } = OCRMismatchBajaj(props.data, props.lineItems, matchingOcrItemData, ocrDetails, LRCOPYDATA, ewayBillDATA, props.categoryId, tenant)
    let isLineItemMismatch = false;
    Object.keys(lineitemOCRPOMismatch).forEach(el => {
      if (!lineitemOCRPOMismatch[el] || Object.keys(lineitemOCRPOMismatch[el]).length) isLineItemMismatch = true; 
    })
    if (headerLevelMismatch.length === 0 && ocrInvoiceQRMismatch.length === 0 && isLineItemMismatch && ocrInvoiceLrCopyMismatch.length === 0 && ocrInvoiceEwayBillMismatch.length === 0 && ocrLREwayBillMismatch.length === 0) {
      if (vendorAsn && enableAsn) {
        setBtnLoading(false)
        setSaveData({ data, OriginalEditableData:{} })
        setAsnModal(true)
      } else {
        poInvoice(data,{});
      }
      return;
    }
    setShowBajajMismatchModal({ mismatch: { headerLevelMismatch, ocrInvoiceQRMismatch, lineitemOCRPOMismatch, ocrInvoiceLrCopyMismatch, ocrInvoiceEwayBillMismatch, ocrLREwayBillMismatch }, data });
  }

  let checkInvoiceDateInContract = (check)=>{

    if(contractId && inputData.billDate  ) {
      let billDate = Moment(inputData.billDate,'YYYY-MM-DD')
      let {contractData} = props.data
      let contractStartDate = contractData.startDate;
      let contractEndDate = contractData.endDate;
  
      if(!billDate.isBetween(contractStartDate,contractEndDate,'[]')) {
        showToast({
          type:'error',
          message:`Invoice Date is not Between contract date , please select a valid date between ${Moment(contractStartDate).format('DD-MM-YYYY')} - ${Moment(contractEndDate).format('DD-MM-YYYY')} `
        })
        setInputData({
          ...inputData,
          billDate:undefined,
          due:undefined
        })
        BILLDATE.current.setValue(undefined);
        DUEDATE.current.setValue(undefined);
        if(check) {
          return true;
        }
        return;
      }
    }
  
  }


	useEffect(()=>{

		if(contractId && inputData.billDate && props.data.contractData.type === 'RENTAL') {


			let billDate = Moment(inputData.billDate,'YYYY-MM-DD')
			let {contractData} = props.data
			let contractStartDate = contractData.startDate;
			let contractEndDate = contractData.endDate;
      let billNew = Moment(billDate)
      // Not accept in rent free period 
     if(props.contractId && props.rentFreePeriod && billNew.isBetween(props.rentFreePeriod.startDate,props.rentFreePeriod.endDate,null,[])) {
      showToast({
        type:'error',
        message:"Invoice submission is not allowed due date fall in rent free period"
      })
      setInputData({
        ...inputData,
        billDate: undefined
      });
      BILLDATE.current.value = undefined;
    return;
    }
		

			function getMonthsDiff(startDate, endDate) {
        if(contractData.freePeriod) {
            startDate = Moment(startDate).add('days',contractData.freePeriod);
        }
				let startMonth = Moment(startDate).startOf('month');
      
				let endMonth = Moment(endDate).startOf('month');
				let months = endMonth.diff(startMonth, 'months', true);
				return Math.floor(months);
			  }

			let month = getMonthsDiff(Moment(contractStartDate),Moment(inputData.billDate));

			
			let lineItems = [...props.lineItems];

			let getNewAmount = (prorata,price,count,percentage,escalationAmount) => {


				let amount = price;
				let startDate = Moment(contractStartDate);
				const startOfMonth = Moment(startDate).startOf('month');
				const endOfMonth = Moment(startDate).endOf('month');
				const daysInMonth = endOfMonth.diff(startOfMonth, 'days') + 1;

				if(prorata && false) {
					let billDateMonth = Moment(inputData.billDate,'YYYY-MM-DD')
					let contractStartDateMonth = Moment(contractStartDate);
					let contractEndDateMonth = Moment(contractEndDate);

					if(billDateMonth.month()===contractStartDateMonth.month() && billDateMonth.year()===contractStartDateMonth.year()) {
						amount = (price/daysInMonth)*prorata;
					} else if( !percentage && billDateMonth.month()===contractEndDateMonth.month() && billDateMonth.year()===contractEndDateMonth.year()) {
						amount = (price)*(getStartDaysofMonth(contractData.endDate))/Moment(contractData.endDate).daysInMonth();
					}
					else if(percentage) {
						for(let i=0;i<count;i++) {
							amount +=  ((price*prorata*percentage/(daysInMonth*100)))
						}
					}
					
				} else if(props && props.rentFreePeriod && Moment(props.rentFreePeriod.endDateFormat).isSame(inputData.billDate,'month')) {
            let totalDays = Moment(inputData.billDate,'YYYY-MM-DD').daysInMonth();
            let rentFree = props.rentFreePeriod.days;
            let predictedAmount = (amount*(totalDays-rentFree)) / (totalDays);
            amount = predictedAmount
         
          
         
        } else {
					if(month===0 && prorata===0) {
						amount = price
					} else if(month===0) {
						amount = (price/daysInMonth)*prorata;
					} else if(escalationAmount && month!==0) {
            for(let i=0;i<count;i++) {
							amount +=  escalationAmount;
						}
          } else if(percentage && month!==0) {
						for(let i=0;i<count;i++) {
							amount +=  (amount*percentage)/100
						}
					}
				}
			
				return amount;
			}


			let getStartDaysofMonth = (endDate) => {
				endDate = Moment(endDate);
				const startOfMonth = Moment(endDate).startOf('month');
				const daysRemaining = endDate.diff(startOfMonth, 'days') + 1;
				
				if (endDate.date() === 1) {
				  return 0; // Start date is the 1st day of the month, so no days remaining
				} else {
				  return daysRemaining;
				}
			}

			let getRemainingDaysofMonth = (startDate) => {

				startDate = Moment(startDate);
				const endOfMonth = Moment(startDate).endOf('month');
				const daysRemaining = endOfMonth.diff(startDate, 'days') + 1;
				
				if (startDate.date() === 1) {
				  return 0; // Start date is the 1st day of the month, so no days remaining
				} else {
				  return daysRemaining;
				}
			}			

		  if(lineItems.length){
        let predict =0;
        lineItems = lineItems.map(item=>{
          let nPrice;
          if(item && item.contractEscalations && item.contractEscalations.length){
            let amount=item.price;
            let currentDate=billNew;
            for(let i=0;i<item.contractEscalations.length;i++){
                let ele=item.contractEscalations[i];
                if(currentDate>=Moment(ele.start).valueOf()){
                    amount+=(amount*ele.percentage)/100;
                }
                else{
                    break;
                }
            }
            nPrice=amount;
          }
          else{
            let amendCount =0
            if(month%item.escalationFrequencyMultiplier===0) {
              amendCount = (month)/item.escalationFrequencyMultiplier;
            } else {
              amendCount = month/item.escalationFrequencyMultiplier > 0 ? Math.floor(month/item.escalationFrequencyMultiplier) : 0;
            }
            nPrice = getNewAmount(getRemainingDaysofMonth(contractData.startDate), item.price,amendCount,item.escalation,item.escalationAmount||0);
          }
          let price =nPrice;
          let base = nPrice;
          let grossBase = nPrice - (nPrice * (discount/100));

          let tax = grossBase * (item.taxSlab / 100);
          let total = grossBase + tax;
          predict = predict+total;
          return {
            ...item,
            tax,
            total,
            base,
            price,
            grossBase
          }
			})
			setInputData({
				...inputData,
				lineItems,
				predictedAmount:predict
			})
      checkInvoiceDateInContract();
		}
			
		} else if(contractId && inputData.billDate) {
      checkInvoiceDateInContract();
    }
	},[contractId,inputData.billDate])


	useEffect(()=>{
		let lineItems = inputData.lineItems;
		if(lineItems.length){
			lineItems = lineItems.map(item=>{
				let grossBase = item.base - (item.base * (discount/100));
				let tax = grossBase * (item.taxSlab / 100);
				let total = grossBase + tax;
				return {
					...item,
					grossBase,
					tax,
					total
				}
			})
			setInputData({
				...inputData,
				lineItems
			})
		}
	},[discount])

	let QTTY = useRef(), PRICE = useRef(), MATERIAL = useRef(),EDITQTTY=useRef(),EDITPRICE=useRef(),CESS=useRef(),DUEDATE=useRef(),ROUNDOFF=useRef(),NAME=useRef(), TCS = useRef();

  useEffect(() => {
    if (edit) {
      getDetailsEdit()
    }
  }, [edit, props.poId])

  const getDetailsEdit = async () => {
    EDITQTTY.current.value = edit.qtty;
    await getFormDate()
    FORM1.current.loadForm(`invoice::item::${props.categoryId}`);
    if (!props.poId) {
      EDITPRICE.current.value = edit.price;
      setEditGst({
        value: edit.gst,
        label: edit.gst
      })
    }
  }

  const getFormDate = async () => {
    return await new Promise((resolve, reject) => {
      get(`/forms/invoice::item::${props.categoryId}`, (e, r) => {
        if (r) {
          if (r.forms.length > 0) {
            let forms = {};
            r.forms.forEach((item) => {
              if (edit.forms && edit.forms[item.id]) {
                forms[item.fieldId] = edit.forms[item.id];
              }
            })
            if (Object.keys(forms).length > 0) {
              edit.forms = forms
            };
          }
          resolve()
        } else {
          resolve()
        }
      })
    })
  }

	useEffect(() => {
		paymentTerms.fetchVendorTerms(vendor.id);
		get(`vendor/config/${props.categoryId}/services`, (e, r) => {
			if (r) {
				setLoading(false);
				setServices(r.items.map(i => {
					return {
						value: i.id,
						label: i.name,
						cess:i.cess
					}
				}))
				if (r.items.length > 0) {
					setSingleService({
						label: r.items[0].name,
						value: r.items[0].id,
						cess:r.cess
					})
				}

			}
		})

	}, [])

  useEffect(()=>{
		if(modal) {
		  FORM1.current.loadForm(`invoice::item::${props.categoryId}`)
		}

	},[modal,props.categoryId])

  const toggleAsn = (val) =>{
    if(!(asnRequired)) {
      setEnableAsn(val)
    }
  }

  useEffect(()=>{
    INIT();
  },[])

  const INIT = () => {
    get(`settings/exceptions/vendor`, (e,r)=>{
        if(r){
          let obj={};
          setOcrV2(r.ocrV2)
          setOCRConfig({ocrMisMatchHardStopConfig:r.ocrMisMatchHardStopConfig,ocrMisMatchSoftStopConfig:r.ocrMisMatchSoftStopConfig})
          Object.entries(r.contractTypeWiseAmountEdit).forEach(([key, value]) => {
            obj['key']=key;
            obj['value']=value;
          })
          setContractTypeWiseAmountEdit(obj);
          setInvoiceRoundOfAmountLimit(r.invoiceRoundOfAmountLimit);
        }
    })
  }

  useEffect(()=>{
    if(props.imsPrefill){
      setInputData(prev=>{
        return {...prev,billNumber:props.imsPrefill.originalInvoiceNumber}
      })
      if(BILLNUMBER.current){
        BILLNUMBER.current.value = props.imsPrefill? props.imsPrefill.originalInvoiceNumber:undefined;
      }
    }
  },[props.imsPrefill,BILLNUMBER])

  const onCreateAsn = (asn) => {

    let data = saveData.data
    let OriginalEditableData = saveData.OriginalEditableData;

    if(props.grnId) {
      data.grnId = props.grnId;
    }
    // Additional charges
    if (vendorAdditionalCharges && ADDITIONAL_CHARGES.current && ADDITIONAL_CHARGES.current.getValue().length > 0) {
      data.additionalCharges = ADDITIONAL_CHARGES.current.getValue().map((item) => {
        delete item.charges;
        return item;
      })
    }

    let payload={
        invoiceData:{...data},
        ...ocrV2?{
          'ocrDataV2Data':ocrDetails.originalOcr,
          'editableData':{...OriginalEditableData,originalLrOcrData:LRCOPYDATA,originalEwayBillOcrData:ewayBillDATA}
        }:
        {'ocrData':ocrDetails.originalOcr},
        ...(enableAsn)?{asnData:{
          ...asn,
          officeId: props.data.billingTo.id,
          attachments: saveData.data.attachment.map((item, index) => ({
            "title": `File ${index + 1}`,
            "path": item
          }))
        }}:{}
    }
    
    setBtnLoading(true);
    post(`vendor/procurement/asn/create/v2/${props.poId}`, payload, (e, r) => {
      if (r) {
        showToast({
          type: "success",
          message: "Invoice submitted",
        });
        props.onClose(false);
        props.init();
        if(props && props.fetchASN){
          props.fetchASN();
        }
        setAsnModal(undefined)
        setBtnLoading(false);
      } 
      else if (e) {
        setBtnLoading(false);
        handleError(e)
      }
    })
  }

  const onSettingOcrDetails = (val) => {
    setOcrDetails(val);
    if(val.invoice) BILLNUMBER.current.value = val.invoice;
    if(val.date) BILLDATE.current.setValue(Moment(val.date, 'DD-MM-YYYY').toDate());
   
    setInputData(prev => {
      return(
        {
          ...prev,
          ...val.invoice ? {billNumber: val.invoice}:{},
          ...val.date ? {billDate: val.date
            ? Moment(val.date,'DD-MM-YYYY').format("YYYY-MM-DD")
            : ""}:{},
          ...(ocrLineItem && (val.ocrLineItem && val.ocrLineItem.length > 0) && !props.poId)?
          {lineItems:val.ocrLineItem} : {}
        }
      )
    });

    // if (val.invoice) {
    //   inv = val.invoice;
    //   INVOICE_NO.current.value = val.invoice;
    // }
    // if (val.date) {
    //   date = Moment(val.date, "DD/MM/YYYY").format("YYYY-MM-DD");
    //   BILLDATE.current.value = Moment(val.date, 'DD/MM/YYYY').format("YYYY-MM-DD");
    // }
    // setInputData(prev=>{
    //   return(
    //     {
    //       ...prev,
    //       billNumber: inv,
    //       billDate: date,
    //       irn: irn ? irn : "",
    //       placeOfSupply: placeOfSupply ? placeOfSupply : "",
    //     }
    //   )
    // })
    setConfimation();
  }

  const onSettingIrnDetails = (val) => {
    setInputData(prev => {
      return(
        {
          ...prev,
          ...(ocrLineItem && (val.ocrLineItem && val.ocrLineItem.length > 0) && !props.poId)?
          {lineItems:val.ocrLineItem} : {}
        }
      )
    });
    setShowIrnData(undefined);
  }


	if (loading) {
		return <SidePane column={<Spinner/>} button={false} onClose={() => props.onClose(false)}>
			<Spinner/>
		</SidePane>
	}


	let base = 0, cgst = 0, sgst = 0, igst = 0, total = 0, tax = 0;


	inputData.lineItems.forEach(item => {
		base = base + parseFloat(item.base);
		total = total + parseFloat(item.total);
		tax = tax + parseFloat(item.tax);
	})


	
	if (vendor && vendor.gstin && vendor.gstin.length > 2 && inputData.billingTo && inputData.billingTo.gstin && inputData.billingTo.gstin.length > 2) {
		if (vendor.gstin.slice(0, 2) === inputData.billingTo.gstin.slice(0, 2)) {
			cgst = tax / 2;
			sgst = tax / 2;
		} else {
			igst = tax;
		}
	} else {
		cgst = tax / 2;
		sgst = tax / 2;
	}

	let duplicateCheck = (type,data) => {
		if(restrictDuplicateInvoiceSubmission) {
			post(`vendor/invoicing/duplicateCheck`,{
				"vendor_id": inputData.vendor.id,
				"bill_no": data.billNumber,
				"bill_date": data.billDate
			},(e,r)=>{
				if(r) {
					if(r.duplicates.length) {
						setDuplicateInvoice(r.duplicates)
						setDuplicate(true)
	
					} else {
						if(type==='nonpo') {
                if(props.irnData){
                  let irnTotal = 0;
                  let tempItemList = [...props.irnData.signedInvoiceData?props.irnData.signedInvoiceData.ItemList:[]];
                    irnTotal = tempItemList.reduce((acc,item)=> acc + item.TotItemVal , 0);
                  if(irnTotal !== total){
                    setShoWIrnMismatchModal({data,mismatch:{type:"total",invoice:numberWithCommas(total),irn:numberWithCommas(irnTotal)}});
                    return;
                  }
              }
							nonPoInvoice(data);
						} else if(type==='po') {
              let OriginalEditableData = {};
              if((tenant === "bajajelec" || tenant === "TRDT") && ocrLineItem){
                bajaOCRMismatchHandler({data})
                return;
              }else if(ocrLineItem){
                let mismatchData = ocrMismatchStandard(config,ocrDetails,inputData,total,base,props.lineItems,matchingOcrItemData,props.poId);
                if(mismatchData.hardStop || mismatchData.softStop){
                  setShoWMismatchModal({mismatchData,data})
                  return;
                }
                OriginalEditableData = getFormattedEditableData(mismatchData.misMatchData,mismatchData.lineitemOCRPOMismatch);
              }
              if(vendorAsn && enableAsn){
                setBtnLoading(false)
                setSaveData({data,OriginalEditableData})
                setAsnModal(true)
              } else {
						  	poInvoice(data,OriginalEditableData);
              }
						} else if(type==='contract') {
							contractInvoice(data);
						}
					}
				}
			})
		} else {
			if(type==='nonpo') {
        if(props.irnData){
          let irnTotal = 0;
          let tempItemList = [...props.irnData.signedInvoiceData?props.irnData.signedInvoiceData.ItemList:[]];
          irnTotal = tempItemList.reduce((acc,item)=> acc + item.TotItemVal , 0);
          if(irnTotal !== total){
            setShoWIrnMismatchModal({data,mismatch:{type:"total",invoice:numberWithCommas(total),irn:numberWithCommas(irnTotal)}});
            return;
          }
        }
				nonPoInvoice(data);
			} else if(type==='po') {
        let OriginalEditableData = {};
        if((tenant === "bajajelec" || tenant === "TRDT") && ocrLineItem){
          bajaOCRMismatchHandler({data})
          return;
        }else if (ocrLineItem) {
          let mismatchData = ocrMismatchStandard(config, ocrDetails, inputData, total, base, props.lineItems, matchingOcrItemData, props.poId);
          if (mismatchData.hardStop || mismatchData.softStop) {
            setShoWMismatchModal({ mismatchData, data })
            return;
          }
          OriginalEditableData = getFormattedEditableData(mismatchData.misMatchData, mismatchData.lineitemOCRPOMismatch);
        }
        if(vendorAsn && enableAsn){
          setBtnLoading(false)
          setSaveData({data,OriginalEditableData})
          setAsnModal(true)
        } else {
          poInvoice(data,OriginalEditableData);
        }
			} else if(type==='contract') {
				contractInvoice(data);
			}
		}
	}

	let nonPoInvoice = (data) => {
    setBtnLoading(true)
    if (vendorAdditionalCharges && ADDITIONAL_CHARGES.current && ADDITIONAL_CHARGES.current.getValue().length > 0) {
        data.additionalCharges = ADDITIONAL_CHARGES.current.getValue().map((item) => {
        delete item.charges;
        return item;
      })
    }
		post(`vendor/submit/invoice`, {data}, (e, r) => {
			if (r) {
				showToast({
					type: 'success',
					message: 'Invoice uploaded successfull'
				})
				props.onClose(false)
				props.init();
			} else {
				handleError(e)
			}
      setBtnLoading(false)
		})
	}

	let poInvoice = (data,OriginalEditableData) => {
    if(props.grnId) {
      data.grnId = props.grnId;
    }
    if (vendorAdditionalCharges && ADDITIONAL_CHARGES.current && ADDITIONAL_CHARGES.current.getValue().length > 0) {
      data.additionalCharges = ADDITIONAL_CHARGES.current.getValue().map((item) => {
        delete item.charges;
        return item;
      })
    }

    setBtnLoading(true)
		post(`vendor/submit/invoice/po.v2`, {data,...ocrV2?{'ocrDataV2Data':ocrDetails.originalOcr,'editableData':{...OriginalEditableData,originalLrOcrData:LRCOPYDATA,originalEwayBillOcrData:ewayBillDATA}}:{'ocrData':ocrDetails.originalOcr}}, (e, r) => {
			if (r) {
				showToast({
					type: 'success',
					message: 'Invoice uploaded successfull'
				})
				props.onClose(false);
				props.init();
			} else {
				handleError(e)
			}
      setBtnLoading(false)
		})
	}


	let contractInvoice = (data) => {

  
		post(`vendor/submit/invoice/contract`, {data}, (e, r) => {
			if (r) {
				showToast({
					type: 'success',
					message: 'Invoice uploaded successfull'
				})
				props.onClose(false);
				props.init();
			} else {
				handleError(e)
			}
		})

	}

	return (
    <div>
      <SidePane
        button={false}
        onClose={() => props.onClose(false)}
        column={
          <div>
            <Invoice discount={discount} vendor={vendor} data={inputData} budgetingV2={budgetingV2} updateLineItem={(index)=>{
              let tempLineItem = [...inputData.lineItems];
              tempLineItem[index].autoBudgeting = !tempLineItem[index].autoBudgeting;
              setInputData((prev)=>{
                return(
                  {
                    ...prev,
                    tempLineItem
                  }
                )
              })
            }}/>
          </div>
        }
      >
        <div
          className="FadeRight-Effect"
          style={{ fontSize: "12px", width: "100%" }}
        >
          <div
            style={{
              fontWeight: 600,
              fontSize: "18px",
              lineHeight: "1.3",
              marginBottom: 30,
            }}
          >
            Submit Invoice
          </div>
          <div id="Invoice_Format" className="FadeRight-Effect">
          <div
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                margin: "30px 0px 15px 0px",
              }}
            >
              Attachment
            </div>
            <div
              style={{
                display: "flex",
                marginLeft: "-15px",
                flexWrap: "wrap",
                maxHeight: "250px",
                overflowY: "scroll",
              }}
            >
            <ReadOnly>
              <FileUpload
                onUpload={({ file }) => {
                  if (file && (file.endsWith('.jpg') || file.endsWith('.jpeg') || file.endsWith('.pdf') || file.endsWith('.png'))){
                    if(files.length>0){
                      setFiles((prev) => [...prev, file]);
                      return;
                    }
                    if (files.length === 0 && invoiceOcr) {
                      setFiles((prev) => [...prev, file]);
                      let payload = {url:file}
                      vertexAiInvoiceOcr && (payload.multiLineInvoice = true)
                      setOcrRunning(true);
                      post(OCRURL, payload, (e, r) => {
                        if (r) {
                          setDetails(r);
                          setOcrDetails(r);
                          setConfimation(true);
                        }else{
                          showToast({
                            type: 'errror',
                            message: 'Error in fetching OCR details'
                          })
                          setDetails(undefined);
                        }
                        setOcrRunning(false);
                      });
                    } else {
                      setFiles((prev) => [...prev, file]);
                    }
                  }else{
                    setFiles((prev) => [...prev, file]);
                  }
                }}
              />
            </ReadOnly>
              {files.length > 0 ? (
                files.map((item, index) => {
                  return (
                    <PhotoTile
                      onDelete={() => {
                        let items = [...files];
                        items = items.filter((val) => val !== item);
                        setFiles(items);
                      }}
                      key={`Photo-${index}`}
                      link={item}
                    />
                  );
                })
              ) : (
                <></>
              )}
            </div>
            {
                vendorAsn && !(props && props.data && props.data.disableAsn) && props.poId  ?
                  <div style={{
                    marginTop: 20
                  }}>
                    <ToggleSwitch status={enableAsn} callback={toggleAsn} label='Create ASN' />
                  </div>
                : null
            }
            <div style={{marginBottom:'10px'}}><FloatInputButton  title='Category' disabled='true' default={props.category} /></div>
            {props.poId || props.contractId ? (
              <div />
            ) : (
              <div>
                <SearchOffice2
                  title="Bill Address**"
                  office={inputData.billingTo}
                  onSelect={(billingTo) => {
                    setInputData({
                      ...inputData,
                      billingTo: {
                        ...billingTo,
                        name: billingTo.label,
                      },
                    });
                    setFilterData({
                      officeId:billingTo.value
                  })
                  }}
                  p2p={true}
                  entityId={(props && props.entityId)?props.entityId:undefined}
                />
								<div style={{display: 'flex',gap: '10px',marginBottom:'10px',alignItems:'center'}}>Shipping address same as billing address <input type='checkbox' onChange={handleShippingAddress} checked={shippingAdd}></input> </div>
                {!shippingAdd && <SearchOffice2
                  office={inputData.shippingTo}
                  title="Ship Address**"
                  onSelect={(shippingTo) => {
                    setInputData({
                      ...inputData,
                      shippingTo: {
                        ...shippingTo,
                        name: shippingTo.label,
                      },
                    });
                  }}
                  p2p={true}
                  entityId={(props && props.entityId)?props.entityId:undefined}
                />}
              </div>
            )}
            <div style={{ display: "flex" }}>
              <FunctionalInput
                type="text"
                title="Bill Number**"
                ref={BILLNUMBER}
                defaultValue={props.imsPrefill? props.imsPrefill.originalInvoiceNumber:props.gst2bPrefill? props.gst2bPrefill.invoiceNumber:undefined}
                onChange={(e) => {
                  let billNumber = e.target.value;
                  setInputData({
                    ...inputData,
                    billNumber,
                  });
                }}
              />
            </div>
            <div style={{ display: "flex",}}>
              <DatePickerCustom
                ref={BILLDATE}
                label={"Bill Date**"}
                formate={dateFormate}
                onChange={(date) => {
					          let billDate = Moment(date).format("YYYY-MM-DD");                   

					           if(billDate){
					            let days = paymentTerms.getTerm(props.categoryId);

					            if (days) {
					                let dueDate = Moment(Moment(billDate, "YYYY-MM-DD").valueOf()).add(days, "days").format("YYYY-MM-DD");
					                  DUEDATE.current.setValue(dueDate);
					                  setInputData({
						                  ...inputData,
						                  due: dueDate,
                              billDate
					                   });
					              } else {
                          setInputData({
                            ...inputData,
                            billDate
                        });
                        }
					                 
				             }}}
              />
              <div style={{ marginLeft: 12 ,width:'100%'}}>
			        <DatePickerCustom
                ref={DUEDATE}
                value={inputData.due}
                label={"Due Date"}
                formate={dateFormate}
                onChange={(date) => {
					          let due = Moment(date).format("YYYY-MM-DD");
					          if(due){
					              setInputData({
				                  ...inputData,
					                due,
					              });
				              }}}
                  />
              </div>
            </div>
            <p
              style={{
                color: "var(--text-color)",
                fontSize: 13,
                fontWeight: 400,
                marginTop: 12,
              }}
            >
              Line Items**
            </p>
            <div>
              <table
                style={{
                  border: "1px solid var(--border-color)",
                  width: "100%",
                  borderCollapse: "collapse",
                  marginTop: "20px",
                }}
              >
                <tr
                  style={{
                    background: "var(--tile-color)",
                    textAlign: "left",
                    borderBottom: "1px solid var(--border-color)",
                  }}
                >
                  <th style={{ padding: "10px" }}>Name</th>
                  <th style={{ padding: "10px" }}>Qty</th>
                  <th style={{ padding: "10px" }}>Price</th>
                  <th style={{ padding: "10px" }}>Tax %</th>
                  {costAllocation ? (
                    <th style={{ padding: "10px" }}>Cost Allocation</th>
                  ) : null}
                  <th style={{ padding: "10px" }}>Actions</th>
                </tr>
                {inputData.lineItems && inputData.lineItems.length ? (
                  inputData.lineItems.map((row, index) => {
                    return (
                      <tr>
                        <td style={{ padding: "10px" }}>
                          {row.name}
                          <br />
                          HSN: {row.hsn}
                        </td>
                        <td style={{ padding: "10px" }}>{row.qtty}</td>
                        <td style={{ padding: "10px" }}>
                          {row.price ? row.price.toFixed(2) : row.price}
                        </td>
                        <td style={{ padding: "10px" }}>{row.gst}</td>
                        {costAllocation ? (
                          <td
                            style={{
                              padding: "10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <p
                              style={{
                                backgroundColor: "var(--green-color)",
                                color: "#fff",
                                height: 20,
                                width: 20,
                                borderRadius: "50%",
                                fontWeight: "bold",
                                cursor: "pointer",
                                marginTop: 10,
                                fontSize: 16,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              onClick={() => {
                                setLineItemCostAllocation(index + 1);
                              }}
                            >
                              +
                            </p>
                            {row.costAllocations ? (
                              <p
                                style={{
                                  color: "var(--primary-color)",
                                  fontSize: 14,
                                  marginLeft: 5,
                                  marginTop: 12,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setViewItemAllocation(index + 1);
                                }}
                              >
                                View
                              </p>
                            ) : null}
                          </td>
                        ) : null}

                        <td style={{ padding: "10px" }}>
                          <div
                            style={{
                              color: "#6C5DD3",
                              fontSize: 10,
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setEdit(row);
                            }}
                          >
                           {(contractType && contractType==="RENTAL")?(contractTypeWiseAmountEdit && contractTypeWiseAmountEdit.key!=="RENTAL")?"EDIT":(contractTypeWiseAmountEdit && contractTypeWiseAmountEdit.key==="RENTAL" && contractTypeWiseAmountEdit.value===true)?"EDIT":"":"EDIT"}
                          </div>
                          <div
                            style={{
                              flex: 1,
                              color: "#dc3545",
                              fontSize: 10,
                              fontWeight: "bold",
                              cursor: "pointer",
                              marginTop:6,
                            }}
                            onClick={() => {
                              let items = [...inputData.lineItems];
                              let deletedItem = items[index];
                              items = items.filter(
                                (val) => val.key !== row.key
                              );
                              setUnmatchedOcrItemData(prev => [...prev, deletedItem]);
                              setInputData({
                                ...inputData,
                                lineItems: items,
                              });
                            }}
                          >
                            {(contractType && contractType==="RENTAL")?(contractTypeWiseAmountEdit && contractTypeWiseAmountEdit.key!=="RENTAL")?"DELETE":(contractTypeWiseAmountEdit && contractTypeWiseAmountEdit.key==="RENTAL" && contractTypeWiseAmountEdit.value===true)?"DELETE":"":"DELETE"}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5} style={{ padding: "10px" }}>
                      No Items added
                    </td>
                  </tr>
                )}
              </table>
            </div>
            {props.poId && ocrLineItem ?
              <p style={{
                marginTop: 12,
                color: "var(--button-color)",
                fontSize: 13,
                cursor: 'pointer',
                fontWeight: 600
              }} onClick={() => {
                setAddUmatchedLineItem(true);
              }}>
                + Add Line Item
              </p>
              : !props.poId ?
                <p style={{
                  marginTop: 12,
                  color: "var(--button-color)",
                  fontSize: 13,
                  cursor: 'pointer',
                  fontWeight: 600
                }} onClick={() => {
                  setModal(true)
                }}>
                  + Add Line Item
                </p> : null}
            {vendorAdditionalCharges ? (
              <AdditionalCharges ref={ADDITIONAL_CHARGES} />
            ) : null}
            <div style={{ display: "flex", marginTop: 5 }}>
              <div style={{ flex: 1 }}>
                <FunctionalKeyValue title="Base Amount" value={`INR ${base}`} />
              </div>
              <div style={{ marginLeft: 12, flex: 1 }}>
                <FunctionalKeyValue
                  title="iGST Amount"
                  value={`INR ${igst.toFixed(2)}`}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                <FunctionalKeyValue
                  title="CGST Amount"
                  value={`INR ${cgst.toFixed(3)}`}
                />
              </div>
              <div style={{ marginLeft: 12, flex: 1 }}>
                <FunctionalKeyValue
                  title="Sgst/Ugst Amount"
                  value={`INR ${sgst.toFixed(3)}`}
                />
              </div>
            </div>

            {invoiceDiscounting && <>
              <FunctionalInput
              blue="Discount will be applied before tax"
              style={{ marginTop: 12 }}
              onChange={(e) => {
                if (parseFloat(e.target.value)) {
                  let value = parseFloat(e.target.value);
                  if (value < 0) {
                    showToast({
                      type: "error",
                      message: "Value cannot be negative",
                    });
                    DISCOUNTEDITMAMOUNT.current.value = 0;
                    DISCOUNTAMT.current.value = 0;
                    return;
                  }
                  if (value > 90) {
                    let discountamount = (parseFloat(base) / 100) * parseFloat(inputData.discount)
                    e.target.value = inputData.discount
                      ? inputData.discount
                      : "";
                      DISCOUNTAMT.current.value = discountamount;
                      setDiscount(0);
                    return;
                  }
                  let discountAmt = parseFloat(base*value)/100;
                  DISCOUNTAMT.current.value = discountAmt;
                  setDiscount(parseFloat(e.target.value));
                } else {
                  let value = parseFloat(e.target.value);
                  if (!value) {
                    setDiscount(0);
                    e.target.value = value;
                    DISCOUNTAMT.current.value = value;
                  } else {
                    let discountamount = (parseFloat(base) / 100) * parseFloat(inputData.discount)
                    e.target.value = inputData.discount
                      ? inputData.discount
                      : "";
                    DISCOUNTAMT.current.value = discountamount;
                  }
                }
              }}
              title="Discount Percent"
              type="number"
              ref={(ref) => (DISCOUNTEDITMAMOUNT.current = ref)}
            />

            <FunctionalInput
              blue="Discount will be applied before tax"
              style={{ marginTop: 12 }}
              onChange={(e) => {
                if (parseFloat(e.target.value)) {
                  let value = parseFloat(e.target.value);
                  if (value < 0) {
                    showToast({
                      type: "error",
                      message: "Value cannot be negative",
                    });
                    DISCOUNTEDITMAMOUNT.current.value = 0;
                    DISCOUNTAMT.current.value = 0;
                    return;
                  }
                  if(total<=0){
                    showToast({
                      type: "error",
                      message: "please add line item",
                    });
                    DISCOUNTEDITMAMOUNT.current.value = 0;
                    DISCOUNTAMT.current.value = 0;
                    return;
                  }
                  let discountperc = (value*100)/parseFloat(base);
                  if (discountperc > 90) {
                    let discountamount = (parseFloat(base) / 100) * parseFloat(inputData.discount)
                    e.target.value = discountamount
                      ? discountamount
                      : "";
                    DISCOUNTEDITMAMOUNT.current.value = inputData.discount
                    ? numberWithCommas(inputData.discount)
                    : "";
                    setDiscount(0);
                    return;
                  }
                  DISCOUNTEDITMAMOUNT.current.value = numberWithCommas(discountperc);
                  setDiscount(parseFloat(discountperc));
                } else {
                  let value = parseFloat(e.target.value);
                  if (!value) {
                    setDiscount(0);
                    DISCOUNTEDITMAMOUNT.current.value = 0;
                    e.target.value = value;
                  } else {
                    let discountamount = (parseFloat(base) / 100) * parseFloat(inputData.discount)
                    DISCOUNTEDITMAMOUNT.current.value = inputData.discount
                      ? numberWithCommas(inputData.discount)
                      : "";
                    e.target.value = discountamount
                    ? discountamount
                    : "";
                  }
                }
              }}
              title="Discount Amount"
              type="number"
              ref={(ref) => (DISCOUNTAMT.current = ref)}
            />
            </>}
            <div style={{marginTop:"5px"}}>
            <FunctionalInput
                ref={TCS}
                title="TCS(Tax Collected at Source)"
                type="number"
              />
            </div>
            {roundOff ? (
              <FunctionalInput
                ref={ROUNDOFF}
                title="Round Off Amount"
                type="number"
                onChange={(val) => {
                  let value = val.target.value
                    if (value &&  Number(value) > Number(invoiceRoundOfAmountLimit)) {
                      showToast({
                      type:'error',
                      message:`max ${invoiceRoundOfAmountLimit} round off applicable`
                      })
                      ROUNDOFF.current.value = Number(invoiceRoundOfAmountLimit)
                    }
                    if (value &&  Number(value) < Number(invoiceRoundOfAmountLimit)*-1) {
                      showToast({
                      type:'error',
                      message:`roundOff cannot be lesser then -${invoiceRoundOfAmountLimit}`
                      })
                      ROUNDOFF.current.value = Number(invoiceRoundOfAmountLimit)*-1
                    }
                }}
              />
            ) : null}
            <FunctionalKeyValue
              title="Total Amount"
              value={`INR ${parseFloat(
                parseFloat(total) -
                  (parseFloat(total) / 100) * parseFloat(inputData.discount)
              ).toFixed(2)}`}
            />
            {contractId ? (
              <FunctionalKeyValue
                disabled={true}
                title="Predicted Amount"
                value={`INR ${
                  inputData.predictedAmount
                    ? parseFloat(inputData.predictedAmount).toFixed(2)
                    : inputData.predictedAmount
                }`}
              />
            ) : null}

            <Form handler={(ref) => (FORM.current = ref)} filterData={filterData} onChange={handleCustomFormOCRBajaj} customFormOcr={customFormOcr}/>
            <CostAllocation
              ref={COSTALLOCATION}
              totalAmount={costAllocationOnBaseAmount?base:total}
              prevItems={[...inputData.lineItems]}
              costAllocationType={inputData.costAllocationType}
            />
          {btnLoading ?
					   <div
             className="btn btn-portlet"
             style={{ maxWidth: "100%", marginTop: 20 }}>
              <StateButton />
          </div>
           :
            <div
              className="btn btn-portlet"
              style={{ maxWidth: "100%", marginTop: 20 }}
              onClick={() => {
                if(fetchingLineitems || ocrRunning || LRCOPYOCR || EwayBillOCR){
                  showToast({
                    type: 'error',
                    message: 'OCR is running, Please wait'
                  })
                  return;
                }
                let {
                  billDate,
                  due,
                  billNumber,
                  shippingTo,
                  billingTo,
                  lineItems,
                } = inputData;
                let { vendor } = props;

                if (lineItems.length === 0) {
                  showToast({
                    type: "error",
                    message: "Please select atleast 1 line item",
                  });
                  return;
                }
                if (!props.poId && !props.categoryId) {
                  if (!vendor) {
                    showToast({
                      type: "error",
                      message: "Please select the vendor",
                    });
                    return;
                  }
                  if (!shippingTo) {
                    showToast({
                      type: "error",
                      message: "Please select the ship to address",
                    });
                    return;
                  }

                  if (!billingTo) {
                    showToast({
                      type: "error",
                      message: "Please select the bill to address ",
                    });
                    return;
                  }
                }
                if (!billDate) {
                  showToast({
                    type: "error",
                    message: "Please enter the bill date",
                  });
                  return;
                }

                if (!billNumber) {
                  showToast({
                    type: "error",
                    message: "Please enter the bill Number",
                  });
                  return;
                }

                if (billDate) {
                  billDate = Moment(billDate, "YYYY-MM-DD");
                  billDate = Moment(billDate).valueOf();
                }

                if (due) {
                  due = Moment(due, "YYYY-MM-DD");
                  due = Moment(due).valueOf();
                }

                if (billDate > due) {
                  showToast({
                    type: "error",
                    message: "Due date cannot be older than bill date",
                  });
                  return;
                }

                if(!files.length){
                  showToast({
                    type: 'error',
                    message: "Attachment is required"
                  })
                  return;
                }
                let tcs = +(TCS.current.value);
                if (props.poId) {
                  let data = {
                    billDate,
                    due,
                    billNumber,
                    attachment: files,
                    lineItems,
                    currency: "INR",
                    poId: +props.poId,
                    discount,
                    categoryId: props.categoryId,
                    tcs
                  };
                  if (props.superId) {
                    data.superId = props.superId;
                  }

                  if (roundOff) {
                    let round = ROUNDOFF.current.value;
                    if(+round > Number(invoiceRoundOfAmountLimit) || +round < Number(invoiceRoundOfAmountLimit)*-1){
                      showToast({
                          type:'error',
                          message:`Round off can not be greater than ${invoiceRoundOfAmountLimit} or less than -${invoiceRoundOfAmountLimit}`
                      })
                      return;
                  }
                    data.roundOff = +round;
                  }
                  let emptyQtty = lineItems.find((e) =>  e.qtty === 0);
                  if(emptyQtty){
                     showToast({
                      type: "error",
                      message: `Please add qtty for ${emptyQtty.name}`,
                    });
                     return;
                  }
                  FORM.current.getKeyValue((cb) => {
                    data.forms = cb;
                    let costAllocation = COSTALLOCATION.current.getValue();

                    if (!costAllocation) {
                      showToast({
                        type: "error",
                        message: "Cost Allocation is required",
                      });
                      return;
                    }

                    data = {
                      ...data,
                      ...costAllocation,
                    };
                    duplicateCheck("po", data);
                  });
                } else if (props.contractId) {

                  let billNew = Moment(billDate)

                  if(props.contractId && props.rentFreePeriod && billNew.isBetween(props.rentFreePeriod.startDate,props.rentFreePeriod.endDate,null,[])) {
                    showToast({
                     type:'error',
                     message:'Invoice submission is not allowed due date fall in rent free period.'
                    })
                    return;
                 }

                  let data = {
                    billDate,
                    due,
                    billNumber,
                    attachment: files,
                    lineItems,
                    currency: "INR",
                    contractId: +props.contractId,
                    discount,
                    categoryId: props.categoryId,
                    predicted: inputData.predictedAmount,
                    vendor: {
                      id: inputData.vendor.id,
                    },
                    tcs
                  };
                  if (props.superId) {
                    data.superId = props.superId;
                  }

                  if (roundOff) {
                    let round = ROUNDOFF.current.value;
                    if(+round > Number(invoiceRoundOfAmountLimit) || +round < Number(invoiceRoundOfAmountLimit)*-1){
                      showToast({
                          type:'error',
                          message:`Round off can not be greater than ${invoiceRoundOfAmountLimit} or less than -${invoiceRoundOfAmountLimit}`
                      })
                      return;
                  }
                    data.roundOff = +round;
                  }

                  FORM.current.getKeyValue((cb) => {
                    data.forms = cb;

                    let costAllocation = COSTALLOCATION.current.getValue();

                    if (!costAllocation) {
                      showToast({
                        type: "error",
                        message: "Cost Allocation is required",
                      });
                      return;
                    }

                    data = {
                      ...data,
                      ...costAllocation,
                    };

                    duplicateCheck("contract", data);
                  });
                } else {
                
                  if (!billingTo) {
                    showToast({
                      type: "error",
                      message: "Please select the bill to address ",
                    });
                    return;
                  }
                  
                  if (!shippingTo) {
                    showToast({
                      type: "error",
                      message: "Please select the ship to address",
                    });
                    return;
                  }

                  let data = {
                    billDate,
                    due,
                    billNumber,
                    billingTo,
                    shippingTo,
                    vendor,
                    attachment: files,
                    lineItems,
                    discount,
                    addressId: vendor.key,
                    categoryId: props.categoryId,
                    tcs
                  };

                  if (props.superId) {
                    data.superId = props.superId;
                  }

                  if (roundOff) {
                    let round = ROUNDOFF.current.value;
                    if(+round > Number(invoiceRoundOfAmountLimit) || +round < Number(invoiceRoundOfAmountLimit)*-1){
                      showToast({
                          type:'error',
                          message:`Round off can not be greater than ${invoiceRoundOfAmountLimit} or less than -${invoiceRoundOfAmountLimit}`
                      })
                      return;
                  }
                    data.roundOff = +round;
                  }

                  FORM.current.getKeyValue((cb) => {
                    data.forms = cb;
                    let costAllocation = COSTALLOCATION.current.getValue();

                    if (!costAllocation) {
                      showToast({
                        type: "error",
                        message: "Cost Allocation is required",
                      });
                      return;
                    }

                    data = {
                      ...data,
                      ...costAllocation,
                    };
                    duplicateCheck("nonpo", data);
                  });
                }
              }}
            >
              Upload
            </div>
         }
          </div>
        </div>
      </SidePane>

      {modal ? (
        <Modal
          show={true}
          des="Add line item details to add one"
          title={"Add Line Item"}
          style={{
            save: {
              background: "var(--primary-color)",
              border: "2px solid var(--primary-color)",
              padding: "8px 15px",
              width: "100%",
              textAlign: "center",
            },
          }}
          close={() => {
            setModal(false);
          }}
          onSave={() => {
            let product = NAME.current.getValue();
            let hsn = MATERIAL.current.getValue();
            let qtty = +QTTY.current.value;
            let price = +PRICE.current.value;
            let taxSlab = +gst.value;
            let cessValue = 0;
            if (singleService.cess) {
              cessValue = CESS.current.value ? +CESS.current.value : 0;
            }
            if (!product || !product.title) {
              showToast({
                type: "error",
                message: "Please enter Product name",
              });
              return;
            }
            if (!qtty) {
              showToast({
                type: "error",
                message: "Please enter Quantity",
              });
              return;
            }
            if (qtty < 0) {
              showToast({
                type: "error",
                message: "Please enter the valid quantity",
              });
              return;
            }
            if (!price) {
              showToast({
                type: "error",
                message: "Please enter the price",
              });
              return;
            }
            if (price <= 0) {
              showToast({
                type: "error",
                message: "Please enter the valid price",
              });
              return;
            }
            if (!hsn) {
              showToast({
                type: "error",
                message: "Please enter Material Code",
              });
              return;
            }

            let discountSlab = discount;
            if (!discountSlab) discountSlab = 0;
            let base = price * qtty;
            let grossBase = base - base * (discountSlab / 100);
            let tax = grossBase * (taxSlab / 100);
            FORM1.current.getKeyValue((cb) => {
            let item = {
              name: product.title,
              qtty,
              price,
              hsn,
              base,
              grossBase,
              gst: taxSlab,
              taxSlab,
              tax,
              forms:cb,
              total: tax + grossBase,
              service: singleService,
              key: `${singleService.value}||${product.title}`,
              autoBudgeting: true
            };
            if(product.id){
              item['productId'] = product.id;
            }
            if (cessValue) {
              item.cessAmount = grossBase * (cessValue / 100);
              item.cessPercentage = cessValue;
              item.total = item.total + grossBase * (cessValue / 100);
            } else {
              item.cessAmount = 0;
              item.cessPercentage = 0;
            }

            inputData.lineItems.push(item);
            setInputData(inputData);
            setModal(false);
             })
          }}
          buttonName="Add Line Item"
        >
          <Select
            value={singleService}
            items={services}
            placeholder="Select Service"
            select={setSingleService}
          />
          <ProductSearch placeholder={"Name"} ref={NAME} services={searchProductByService && singleService && singleService.label} categoryId={props.categoryId} onSelect={product => {
            PRICE.current.value = product.amount;
            MATERIAL.current.setValue(product.hsn);
          }} />
          <SearchHsn numericalOnly={tenant==='TGRANL' || tenant==='TGRANAL'} ref={MATERIAL} />
          <FunctionalInput type="number" title="Qtty" ref={QTTY} />
          <FunctionalInput type="number" title="Price per Unit" ref={PRICE} />
          <SelectGST defaultValue={gst} placeholder="Select Gst" onSelect={setGst}/>
          {singleService.cess ? (
            <FunctionalInput
              onChange={(e) => {
                if (e.target.value > 99) {
                  CESS.current.value = 0;
                  showToast({
                    type: "error",
                    message: "Cess value is not more than 99",
                  });
                }
              }}
              type="number"
              ref={CESS}
              title="Enter Cess %"
              max="100"
            />
          ) : null}
           <Form 
            label="Invoice Line Item Form"
            desc="All field related to vendor form"
            handler={ref => {
             	FORM1.current=ref
        	   }
          } />
          </Modal>
        ) : (
          null
        )}

      {edit ? (
        <Modal
          show={true}
          des="Edit line item quantity"
          title={"Edit Line Item"}
          style={{
            save: {
              background: "var(--primary-color)",
              border: "2px solid var(--primary-color)",
              padding: "8px 15px",
              width: "100%",
              color: "white",
              curor: "pointer",
              textAlign: "center",
            },
          }}
          buttonName="Update"
          close={() => {
            setEdit(false);
          }}
          onSave={() => {
            let qtty = parseFloat(EDITQTTY.current.value).toFixed(3);
            let price = props.poId  ? edit.price: EDITPRICE.current.value;
            let taxN =  props.poId ? edit.taxSlab :editGst.value;

            if (!qtty) {
              showToast({
                type: "error",
                message: "Please enter the quantity",
              });
              return;
            }
            if(!Number(qtty) > 0){
              showToast({
                type:'error',
                message:"Please add Quantity"
              })
              return
            }
           if(props.poId){
            let orignalQtty = props.lineItems.find((e) => e.itemId === edit.itemId)
            if(Number(qtty) > Number(orignalQtty.qtty)) {
              showToast({
                type:'error',
                message:"Quantity should not be more then specified in the line item"
              })
              return
            }
           }
            if (!price) {
              showToast({
                type: "error",
                message: "Please enter the price per unit",
              });
              return;
            }

            let discountSlab = 0;
            let base = price * +qtty;
            let grossBase = base - base * (discountSlab / 100);
            let tax = grossBase * (taxN / 100);

            let item = {
              ...edit
             };
          FORM1.current.getKeyValue((cb) => {
            item = { ...edit };
            item.qtty = +qtty;
            item.base = base;
            item.taxSlab = taxN;
            item.gst = taxN;
            item.price = +price;
            item.grossBase = grossBase;
            item.tax = tax;
            item.total = tax + grossBase;
            item.forms = cb

      const updatedLineItems = inputData.lineItems.map((lineItem) => lineItem.key === item.key ? item : lineItem);
          setInputData({
            ...inputData,
            lineItems: updatedLineItems,
          });

            // let inputItem = [...inputData.lineItems];
            // inputItem = inputItem.filter((val) => val.key !== item.key);
            // inputItem.push(item);
            // setInputData({
            //   ...inputData,
            //   lineItems: inputItem,
            // });
            setEdit(false);
            })
          }}
        >
          <FunctionalInput
            ref={EDITQTTY}
            title="Enter Quantity"
            type="number"
            disabled={lineItemEditDisable}

          />
          {
            props.poId ?
            <>
            </>
            :
          <>
          <FunctionalInput 
            ref={EDITPRICE}
            title="Enter Price Per Unit" 
            disabled={lineItemEditDisable}
            type="number" />
          <SelectGST defaultValue={editGst} disabled={lineItemEditDisable} placeholder="Select Gst" onSelect={setEditGst}/>
          </>
          }
           <Form 
            label="Invoice Line Item Form"
            desc="All field related to vendor form"
            preFill={edit.forms}
            handler={ref => {
             	FORM1.current=ref
        	   }
          } />
        </Modal>
      ) : null}

      {duplicate ? (
        <Modal
          title="Duplicate Invoice"
          show={duplicate}
          close={() => {
            setDuplicate(false);
          }}
          hideButton={true}
        >
          <p style={{ fontSize: 14 }}>
            This Invoice is duplicate against following invoices .{" "}
            {restrictDuplicateInvoiceSubmission
              ? "Hence Invoice submission is not allowed"
              : ""}
          </p>
          {duplicateInvoice.map((val) => {
            return <p style={{ marginTop: 5, fontWeight: 600 }}>**{val}</p>;
          })}
        </Modal>
      ) : null}

      {lineItemCostAllocation ? (
        <LineItemCostAllocation
          lineItems={[...inputData.lineItems]}
          previousType={inputData.costAllocationType}
          index={lineItemCostAllocation}
          updateLineItems={(items, type) => {
            setInputData({
              ...inputData,
              lineItems: items,
              costAllocationType: type,
            });
          }}
          view={false}
          close={() => {
            setLineItemCostAllocation(false);
          }}
        />
      ) : null}

      {viewItemAllocation ? (
        <LineItemCostAllocation
          lineItems={[...inputData.lineItems]}
          previousType={inputData.costAllocationType}
          index={viewItemAllocation}
          updateLineItems={(items, type) => {
            setInputData({
              ...inputData,
              lineItems: items,
              costAllocationType: type,
            });
          }}
          view={true}
          close={() => {
            setViewItemAllocation(false);
          }}
        />
      ) : null}

      {
        ocrRunning &&
        <Modal hideButton={true} show={true}>
          <div className='flex column center pd3' style={{display:'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={"/assets/scan.gif"} alt='Scanning' style={{ maxWidth: 100 }} />
            <h1 className='mt2'>Scanning Receipt</h1>
          </div>
        </Modal>
      }

      {confirmation &&
        (
          (ocrLineItem) ?
            <LineItemConfirmation
               onClose={() => setConfimation()} 
               file={files[0]} 
               details={details}
               setOcrDetails={onSettingOcrDetails}
               services={services}
               poInvoice={props.poId}
            />
            :
            <ConfirmationModal
             file={files[0]} 
             onClose={() => setConfimation()} 
             details={details} 
             setOcrDetails={onSettingOcrDetails}
              />
        )
      }
      {showIrnData &&
          <IrnInvoiceData
               onClose={() => setShowIrnData()} 
               details={showIrnData}
               setOcrDetails={onSettingIrnDetails}
               services={services}
               poInvoice={props.poId}
            />
      }
      {
        addUmatchedLineItem &&
        <AddUnmatchedLineItems unmatchedOcrItemData={unmatchedOcrItemData} onClose={() => setAddUmatchedLineItem()}
          onSubmit={(selectedItemList) => {
            let selectedList = [];
            unmatchedOcrItemData.forEach(el => {
              if (selectedItemList.includes(el.itemId)) {
                selectedList.push(el);
              }
            })
            setUnmatchedOcrItemData(unmatchedOcrItemData.filter(el => !selectedItemList.includes(el.itemId)));
            setInputData(prev => {
              return ({
                ...prev,
                lineItems: [
                  ...prev.lineItems,
                  ...selectedList
                ]
              })
            })
            setAddUmatchedLineItem();
          }}
        />
      }
      {
        showBajajMismatchModal && <BajajMismatchModal tenant={tenant} mismatchData={showBajajMismatchModal.mismatch} onClose={() => { setShowBajajMismatchModal(); setBtnLoading(false)}} onSubmit={({editableOcrData,editableLrOcrData,editableEwayBillOcrData,editableIrnQrData,ocrMismatch}) => {  
          setShowBajajMismatchModal();      
          if (vendorAsn && enableAsn) {
            setBtnLoading(false)
            setSaveData({ data: showBajajMismatchModal.data, OriginalEditableData:{editableOcrData,editableLrOcrData,editableEwayBillOcrData,editableIrnQrData,ocrMismatch}})
            setAsnModal(true)
          } else {
            poInvoice(showBajajMismatchModal.data, {editableOcrData,editableLrOcrData,editableEwayBillOcrData,editableIrnQrData,ocrMismatch});
          }
        }} />
      }
      {
        showMismatchModal && 
        <MismatchModal onClose={()=>setShoWMismatchModal()} data={showMismatchModal.mismatchData}  onSubmit={(OriginalEditableData)=>{
          setShoWMismatchModal();
          if (vendorAsn && enableAsn) {
            setSaveData({ data: showMismatchModal.data, OriginalEditableData})
            setAsnModal(true)
          } else {
            poInvoice(showMismatchModal.data,OriginalEditableData);
          }
        }}/>
      }
       {
        showIrnMismatchModal &&
        <Modal title='Total Amount Mismatch' show={showIrnMismatchModal} buttonName={"Submit"} close={() => { setShoWIrnMismatchModal(false) }} onSave={() => {
          nonPoInvoice(showIrnMismatchModal.data);
          setShoWIrnMismatchModal(false);
        }}>
          <BorderTable 
          rows={[["Total Amount Mismatch",showIrnMismatchModal.mismatch.invoice,showIrnMismatchModal.mismatch.irn]]}
           headers={["Item", "Invoice Data", "IRN Data"]} />
        </Modal>
      }
      {asnModal ?
        <CreateAsn
          isBajajElec={(tenant === "bajajelec" || tenant === "TRDT")}
          ewayBillDATA={ewayBillDATA}
          LRCOPYDATA={LRCOPYDATA}
          asnForms={asnForms}
          loading={btnLoading}
          companyCode={tenant}
          onClose={() => { setAsnModal(undefined) }}
          inputData={inputData}
          onSave={onCreateAsn}
          categoryId={(props && props.categoryId)?props.categoryId:undefined}
        />
        :null }
    </div>
  );
}


export default SubmitInvoice;
