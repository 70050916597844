import React, { useEffect, useState } from 'react'
import './ocr.css'
import HeaderLevelData from './HeaderLevelData'
import Accordion from "../Accordion";
import NewTable from './NewTable.js'
import AttachmentPreview from '../AttachmentPreview/AttachmentPreview.js'
import { Modal } from '../fModal/Modal'
import { Select } from '../Class/Select';
import { showToast } from '../fToast/ToastCallback';

const OcrConfirmation = ({ details, file, setOcrDetails, onNext, onDiscard,services,poInvoice }) => {

    const [checks, setChecks] = useState({
        amount: true,
        cgst: true,
        igst: true,
        sgst: true,
        date: true,
        vendor: true,
        address: true,
        invoice: true,
        irn: true,
        serveService: true,
        placeSupply: true,
        defaultService: undefined,
        selectLineItem: true,
        ocrLineItem: details && details.ocrData && details.ocrData.invoiceItems && details.ocrData.invoiceItems.map(item => {
            let base = Number(item.price? item.price: 0) * Number(item.quantity?item.quantity : 0);
            let grossBase = base - (base * ((item.discount?item.discount : 0) / 100));
            let tax = Number(item.taxAmount?item.taxAmount : 0)
            // let cgst = item && item.cGstAmount? item.cGstAmount : 0
            // let sgst = item && item.sGstAmount? item.sGstAmount : 0
            // let igst = item && item.iGstAmount? item.iGstAmount : 0

            let taxSlab = 0;

            if(item.igstRate){
                taxSlab = item.igstRate;
            }
            else if(item.cGstRate){
                taxSlab = 2*item.cGstRate;
            }
            else if(item.sGstRate){
                taxSlab = 2*item.sGstRate;
            }
            else{
                taxSlab = (grossBase > 0) ? ((tax / grossBase) * 100) : 0;
            }

            return {
                selected: true,
                qtty: item.quantity,
                name: (item.title || item.name || item.desc),
                hsn: item.hsn,
                grossBase,
                base,
                price: Number(item.price),
                tax,
                gst: tax,
                taxSlab: taxSlab,
                total: (tax + grossBase),
                forms: {},
            }
        }),
    })


    const changeItemsService = () => {
        const service = checks.serveService && checks.defaultService
          ? { label: checks.defaultService && checks.defaultService.label && checks.defaultService.label, value: checks.defaultService && checks.defaultService.value && checks.defaultService.value }
          : undefined; 
      
        const updatedItems = checks.ocrLineItem.map(item => ({
          ...item,
          service
        }));
      
        setChecks(prev => ({
          ...prev,
          ocrLineItem: updatedItems
        }));
    }

    useEffect(() => {
        changeItemsService()
    },[checks.serveService,checks.defaultService])

    const handleServiceChangeForAll = (val) => {
        if (checks.serveService && services) {
            const selectedService = services.find(i => i.value === Number(val.value));
            if (selectedService) {
                setChecks(prev => ({
                    ...prev,
                    defaultService:selectedService
                }));
            }
        } else {
            setChecks(prev => ({
                ...prev,
                defaultService:undefined
            }));
        }
    };


    const discardHandler = () => {
        if(onDiscard){
            onDiscard();
            return;
        }
        setOcrDetails({});
    }

    let continueHandler = () => {
        if(onNext){
            onNext(checks.cgst,checks.igst,checks.sgst,checks.date,checks.vendor,checks.address,checks.invoice,checks.amount);
            return;
        }
        let addresses = details.address ? details.address : undefined;
        let dates = details.date ? details.date : undefined;
        let vendors = details.contact ? details.contact : undefined;
        let amounts = details.amount ? details.amount : undefined;
        let invoices = details.invoice ? details.invoice : undefined;
        let Igst = details.taxation.igst ? details.taxation.igst : 0;
        let Cgst = details.taxation.cgst ? details.taxation.cgst : 0;
        let Sgst = details.taxation.sgst ? details.taxation.sgst : 0;
        let totalTax = details.taxation.gst;
        let originalOcr = details.ocrData ? details.ocrData : {}
        let data = {};
        if (addresses && checks.address) {
            data["address"] = addresses
        }
        if (dates && checks.date) {
            data["date"] = dates;
        }

        if (vendors && checks.vendor) {
            data["vendor"] = vendors;
        }

        if (amounts && checks.amount) {
            data["amount"] = amounts;
        }
        if (invoices && checks.invoice ) {
            data["invoice"] = invoices
        }
        if (Igst && checks.igst) {
            data['igst'] = Igst;
        }
        if (Cgst && checks.cgst) {
            data['cgst'] = Cgst
        }
        if (Sgst && checks.sgst) {
            data['sgst'] = Sgst;
        }
        if (totalTax) {
            data['totalTax'] = totalTax;
        }

        if (!poInvoice && checks.ocrLineItem && checks.ocrLineItem.length > 0) {
            const selectedItems = checks.ocrLineItem.filter(item => item.selected) || [];

            // Check if any selected items are missing the service
            const itemWithoutService = selectedItems.find(item => 
                !item.service || 
                (item.service.label === undefined && item.service.value === undefined)
            );
        
            if (itemWithoutService) {
                showToast({
                    type:'error',
                    message:`Service Not Found: ${itemWithoutService.name || itemWithoutService.desc}`
                })
                return
            }
            data['ocrLineItem'] = selectedItems;
        }

        setOcrDetails({
            ...data,
            originalOcr

        });
    }



    return (
        <Modal
        modalStyle={{
                minWidth: '80%'
            }}
            show={details}
            hideButton={true}
            title="Ocr Fetch Details"
            des="Review the details of ocr to continue"
            style={{
                save: {
                    height: 45,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }
            }}
        >
            <div className='container'>
                <div className='content-data'>
                    <div className='header-data'>
                        <Accordion title={'Ocr Details'} uniqueKey={'Ocr Details'} defaultExpanded={true}>
                            <HeaderLevelData details={details} checks={checks} setChecks={setChecks} />
                        </Accordion>
                    </div>
                    <hr />
                    <div className='lineitem-data'>
                        <Accordion title={'Invoice Line Items'} uniqueKey={'Invoice Line Items'} defaultExpanded={true}>
                        {!poInvoice ? (
                            <div className="flex align-center">
                                <input 
                                    type="checkbox"
                                    onChange={() => {
                                        setChecks(prev => ({ 
                                            ...prev,
                                            serveService: !checks.serveService 
                                        }))
                                    }}
                                    checked={checks.serveService}
                                />
                                <div 
                                    className="pd1" 
                                    style={{
                                        fontSize: 12,
                                        color: 'var(--inactive-color)',
                                        marginLeft: '1rem'
                                    }}
                                >
                                    All Line items fall under same service:
                                </div>
                                <div style={{ marginLeft: '1rem', minWidth: '140px'}}>
                                    <Select 
                                        noLabel={true}
                                        value={checks.defaultService || undefined}
                                        items={services}
                                        placeholder="Service"
                                        select={handleServiceChangeForAll}
                                    />
                                </div>
                            </div>
                        ) : null}
                        <NewTable 
                            checks={checks}
                            setChecks={setChecks}
                            poInvoice={poInvoice}
                            services={services}
                        />
                        </Accordion>
                    </div>
                    <footer className='flex align-center' style={{display:'flex',alignItems:'center'}}>
                        <div role='button' onClick={discardHandler} className="btn btn-portlet"  style={{ background: '#ff6464' }}>Discard OCR</div>
                        <div role='button' onClick={continueHandler} className="btn btn-portlet" >Continue</div>
                    </footer>
                </div>

                <div className='content-img'>
                    <AttachmentPreview files={[file]} />
                </div>
            </div>


        </Modal>
    )
}

export default OcrConfirmation