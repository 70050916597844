import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/database'
import {get} from "../Model/Network/Config/Axios";
import {fetchConfig} from "./ConfigController";
import {luna} from "../Model/Network/Api/config";

class Firebase {

	firebaseConfig = {
		apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
		authDomain: "fintrip-47824.firebaseapp.com",
		databaseURL: "https://fintrip-47824.firebaseio.com",
		projectId: "fintrip-47824",
		storageBucket: "fintrip-47824.appspot.com",
		messagingSenderId: "462305025824",
		appId: "1:462305025824:web:ff319c042246e46e6ac145",
		measurementId: "G-CEMRSLECTY"
	}
	authenticated = false;
	cb = undefined;
	lunaCb = [];
	databaseCb = [];
	trackerDbs = [];

	init() {
		firebase.initializeApp(this.firebaseConfig);
	}


	addCallback(key, listener) {
		this.lunaCb.push({
			listener,
			key
		})
		if (this._data) {
			listener(this._data)
		}
	}

	fetchDb(cb) {
		if (this.database) {
			cb(this.database)
		} else {
			this.databaseCb.push(cb);
		}
	}


	fetchTrackerDb(cb) {
		if (this.tracker) {
			cb(this.tracker)
		} else {
			this.trackerDbs.push(cb);
		}
	}

	removeCallback(key) {
		this.lunaCb = this.lunaCb.filter(value => value.key !== key);
	}

	signout() {
		firebase.auth().signOut();
		this.cb = undefined;
	}

	initializeApp() {
		let self = this;
		if (!self.authenticated) {
			self.authenticated = true;
			self.cb = function (user) {
				if (user) {
					fetchConfig.fetchConfig(cb => {
						let tenant = cb.tenant;
						let url = `${luna}/${tenant}/active`;
						let database = firebase.database();
						self.database = database;
						self.databaseCb.forEach(cb => {
							cb(database)
						})
						self.databaseCb = [];
						database.ref(url).on('value', function (snapshot) {
							if (snapshot) {
								self._data = snapshot.val();
								self.lunaCb.forEach(value => {
									value.listener(self._data)
								})
							}
						}, console.log)
						this.tracker = firebase.app().database("https://fintrip.firebaseio.com");
						self.trackerDbs.forEach(tracker => {
							tracker(this.tracker)
						})
						self.trackerDbs = [];
					})
				} else {
					get('/luna', (e, r) => {
						if (r) {
							firebase.auth().signInWithCustomToken(r.token).then(succes => {
								user = firebase.auth().currentUser;
							}).catch(console.log)
						}
					})
				}
			}
			firebase.auth().onAuthStateChanged(function (user) {
				if (self.cb != null) {
					self.cb(user)
				}
			});
		}
	}

}

const FirebaseUtils = new Firebase();

export {
	FirebaseUtils
}
