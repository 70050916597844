import React, { useRef, useState } from 'react';
import Moment from 'moment';
import { useEffect } from "react";
import axios, { deleteApi, get, post } from "../../../Model/Network/Config/Axios";
import { ReadOnly } from '../fCard/ButtonOutline/ReadOnly';
import { showToast } from '../fToast/ToastCallback';
import Portal from '../Portal';
import attachment from './attachment.png';
import { Modal } from '../fModal/Modal';
import TabSwtich from '../fTabSwitch';
import ButtonOutline from '../fCard/ButtonOutline';
import { FunctionalInput } from '../fInputs';
import { SearchableSelect3 } from '../Class/Select/SearchableSelec3';
import { Select } from '../Class/Select';
import { PDFExport } from '@progress/kendo-react-pdf';
import ReactDOM from "react-dom"
import PhotoTile from '../fPhoto';

const Index = (props) => {

    let {id} = props;
    let MESSAGE = useRef(),MESSAGEEND=useRef(),file_ref=useRef(),DESC=useRef(),PDF = useRef();
    let [rooms,setRooms] = useState([]);
	const [modal,setModal] = useState(undefined);
	const [stateLoading,setStateLoading]  = useState(false);
	const [getTab, setTab] = useState(1)
	const [addParticipant,setAddParticipant] = useState(false);
	const [employees,setEmployees] = useState([]);
	const [buyers, setBuyers] = useState([]);
	const [participants, setParticipants] = useState([]);
	const [allParticipants, setAllParticipants] = useState([]);
	const intervalRef=useRef(null);
	const tabs=[
		{name:'Chat',active:getTab === 1, onClick:()=>setTab(1)},
		{name:'Participants',active:getTab === 2, onClick:()=>setTab(2)},
	]
	const [selectedParticipant, setSelectedPariticipant]=useState({
		label:"Add Participant",
		value:"EMPLOYEE"
	});

	const fetchAllParticipants=()=>{
        get(`workspace/seekClarification/roomParticipants/${id}`,(e,r)=>{
            if(r){
                setAllParticipants(r.participants)
            }
        })
    } 
	let scrollToBottom = () => {
		if(MESSAGEEND.current )MESSAGEEND.current.scrollIntoView({behavior: "smooth"});
	}
    useEffect(()=>{
		
		if(id){
			fetchAllParticipants()
        }
    },[id])

    let INIT = () => {
        get(`workspace/seekClarification/getRoomMessages/${id}`,(e,r)=>{
            if(r) {
                setRooms(r.messages.reverse());
            }
        })
    }
	useEffect(() => {
        INIT();
        if (!intervalRef.current) {
          intervalRef.current = setInterval(INIT, 5000);
        }
		setTimeout(scrollToBottom, 200);
        return () => {
            clearInterval(intervalRef.current);
            intervalRef.current = null; 
      }
}, [id])

    let handleKeyPress = (e) => {
		if (e.key === 'Enter') {
            let message = MESSAGE.current.value;
            if(!message) {
                showToast({
                    type:'error',
                    message:"Please enter the message"
                })
                return;
            }
            post(`workspace/seekClarification/sendMessage/${id}`,{message,file:''},(e,r)=>{
                if(r) {
                    INIT(id);
                    MESSAGE.current.value=''
					scrollToBottom();
                }else{
					showToast({
						type: 'error',
						message: e.data.message
					})
				}
            })
		}
	}

   


    let mapItem = (item,index) => {
        let s = {};
        let container = {};
        let textContainer = {};
        let { owner } = item.sender
        if (owner) {
            container = {
                marginLeft: 'auto'
            }
            s = {
                backgroundColor: '#f8e4cb',
                marginLeft: 'auto',
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
                borderTopRightRadius: 8
            }
            textContainer = {
                textAlign: 'right'
            }
        } else {
            s = {
                backgroundColor: "#f2f2f2",
                left: 0,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
                borderBottomRightRadius: 8,
                textAlign: 'right',
                marginLeft: 10
            }
        }

        return (
            <div key={item.id} style={{ width: '40%', marginBottom: 5, marginRight: 10, ...container, ...s, padding: 8 }}>
                {
                    item.file ?
                        <PhotoTile link={item.file} index={0} />
                        : null
                }
                <p key={index} style={{
                    fontSize: 13,
                    letterSpacing: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    textAlign:'left',
                    overflowWrap:'anywhere'
                }}>
                    {item.msg}
                </p>
                <p style={{ fontSize: 8, marginTop: 3, ...textContainer }}>{Moment(item.createdAt).format('DD-MM-YYYY hh:mm:ss')} {owner ? "" : item.sender.name}</p>
            </div>
        )

    }

	let _onChange = () => {
		let file = file_ref.files[0];
		const fileSize = file_ref.files[0].size / 1024 / 1024; // in MiB

		if (fileSize > 50) {
			showToast({
				type: 'error',
				message: 'File size not be greater than 50 mb'
			})
		} else {
			if (file) {
				const formData = new FormData();
				formData.append('file', file);
				setStateLoading(true);
	
				axios({
					method: 'post',
					url: 'https://bifrost.eka.io/file',
					data: formData,
					headers: {
						clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
						clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
					}
				})
					.then(res => {

						post(`workspace/seekClarification/sendMessage/${id}`,{message:file.name,file:res.data.url},(e,r)=>{
							if(r) {
								INIT(id);
								MESSAGE.current.value=''
								setStateLoading(false);
								setModal(false);
							}else{
								showToast({
									type: 'error',
									message: e.data.message
								})
							}
						})
					})
					.catch((e)=>{
						showToast({
							type: 'error',
							message: e.data.message
						})
					})
			}
		}
	}


	let exportChathandler = ()=>{
		let chats = <PDFExport title="Chats"  fileName="chats.pdf" ref={r => PDF = r}>
			<div>
				{rooms.map(mapItem)}
				<div style={{ float: "left", clear: "both" }} ref={ref => {
					MESSAGEEND.current = ref;
				}} />
			</div>
		</PDFExport>
		const container = document.getElementById("HIDDEN");
        ReactDOM.render(chats,container,()=>{PDF.save()});
	}


  return(
	<>
    <Portal>
         <div style={{
			position:'fixed',
			zIndex:1110,
			top:0,
			bottom:0,
			left:0,
			backgroundColor:"rgba(1,1,1,.1)"
		}}>
            <div style={{
				position:'fixed',
				zIndex:1111,
				boxShadow:"10px 0 20px 0 rgba(123,147,180,.2)",
				...props.right?{right:0}:{left:0},
				top:0,
				bottom:0,
				width:700,
				backgroundColor:'var(--white-color)'
			}}>

                <div style={{
					position:'relative'
				}}> 
					<div style={{
						width:700,
						top:0,
						height:60,
						...props.right?{right:0}:{left:0},
						zIndex:112,
						background:"var(--white-color)",
						display:'flex',
						borderBottom:'1px solid var(--border-color)',
					}}>
						<img alt="Close Button" onClick={()=>{
							props.close();
							clearInterval(intervalRef.current);
						}} src={require('./close.png')} style={{
							margin:22,
							cursor:'pointer',
							width:16,
							height:16
						}}/>
						<div style={{flex:2}}>
							<p style={{
								marginTop:14,
								fontWeight:600,
								fontSize:14,
								height:18,
								overflowY:'hidden'
							}}>{props.title}</p>
							<p style={{
								fontWeight:400,
								fontSize:10,
								color:'var(--light-color)'
							}}>{props.des}</p>
						</div>
						{getTab === 1 && <ReadOnly><div style={{flex:1}}>
							<p onClick={()=>{
								exportChathandler();
							}} style={{
								margin:18,
								color:"var(--primary-color)",
								fontWeight:700,
								cursor:'pointer',

							}}>
								Export Chat
							</p>
						</div></ReadOnly>}
						{props.status==='CLOSED'?null:<ReadOnly><div style={{flex:1}}>
							<p onClick={()=>{
								props.onClose(props.id)
							}} style={{
								margin:18,
								color:"var(--primary-color)",
								fontWeight:700,
								cursor:'pointer',

							}}>
								Mark Closed
							</p>
						</div></ReadOnly>}
					</div>
					<TabSwtich tabs={tabs} /> 
					{
						getTab === 1 &&
						<>

							<div style={{ marginTop: 20 }} >
								<div id="HIDDEN" style={{ position: "absolute", left: "-9999px",width:'700px' }}>
									{/* This div will hold dynamically added chats for export*/}
								</div>
								<div style={{height:'90vh',padding:10,paddingTop:70,paddingBottom:150,overflowY:'auto'}}>
									{rooms.map(mapItem)}
									<div style={{ float: "left", clear: "both" }} ref={ref => {
										MESSAGEEND.current = ref;
									}} />
								</div>
							</div>
							{props.status==="CLOSED"?null:<div className="sendMessage" style={{
								height: 70,
								display: 'flex',
								padding: 12,
								backgroundColor: "var(--white-color)",
								borderTop: '1px solid var(--border-color)',
								position:'fixed',
								...props.right?{right:0}:{left:0},
								bottom:0,
								width:700
							}}>
								<input style={{
									flex: 1,
									border: '1px solid var(--border-color)',
									borderRadius: 6,
									width: '90%',
									height: 45,
									padding: "8px 14px",
									fontSize: 15,
									color: "var(--text-color)"
								}} ref={MESSAGE} onKeyPress={handleKeyPress}
									placeholder="Enter your messsage here" type="text"/>

							<div style={{
								height:45,
								marginLeft:10,
								backgroundColor: '#dedede',
								display:'flex',
								alignItems:'center',
								justifyContent:'center',
								width:50,
								marginTop:0,
								borderRadius:4,
								border:'1px solid var(--border-color)'
							}} onClick={()=>{
								setModal(true)
							}}>
								<img src={attachment} alt="" style={{
									height:30,
									cursor:'pointer',
									borderRadius:4,
									padding:5
								}} />
							</div>	 

							</div>
							}
						</>
					}

					{
						getTab === 2 &&
						<div style={{padding:'15px'}} >
						
							<ReadOnly>
								<ButtonOutline
									style={{margin:'15px 5px'}}
									title="Add Participant"
									onClick={()=>setAddParticipant(true)}
								/>
							</ReadOnly>

							{
								allParticipants.map((item)=>{
									return (
										<div style={{display:'flex',justifyContent:'space-between',margin:'15px 0'}} >

											<p>{item.participantName}</p>
											{!item.owner && <p style={{width:'fit-content',color:'red',fontSize:'14',fontWeight:600,cursor:'pointer'}}
												onClick={()=>{
													deleteApi(`workspace/seekClarification/removeParticipants/${item.participantId}`,(e,r)=>{
														if(r){
															showToast({
																type:'success',
																message:'Participant Removed'
															})
															fetchAllParticipants(); 
														}else{

														}
													})
												}}
											>Remove</p>}
										</div>
									)
								})
							}
						</div>
					}
                </div>
                
            </div>

			<Modal
				zIndex={1115}
                show={modal}
                title="Add Attachment"
                desc="Please select the attachment you want to select"
                close={() => {
                    setModal(false);
                }}
                style={{
                    save: {
                        height: 45,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 600,
                       
                    }
                }}
                stateLoading={stateLoading}
                buttonName="Upload Attachment"
                onSave={_onChange}
            >
                <div style={{
                    border: '1px solid var(--border-color)',
                    padding: '10px 20px',
                    marginBottom: 20,
                    marginTop: 20,
                    borderRadius: 4
                }}>

                    <input
                        type="file"
                        onChange={() => { }}
                        ref={ref => file_ref = ref}
                        accept=".jpg,.jpeg,.pdf,.png,.xlsx,.csv"

                    />
                </div>
            </Modal>
           

        </div>
    </Portal>

	{
		addParticipant &&  
		<Modal
			zIndex={1000000}
			show={addParticipant}
			title='Add Participant'
			desc='Add Pariticpant to this chat room'
			close={()=>{
				setAddParticipant(false)
			}}
			buttonName='Add Participant'
			onSave={async()=>{
				let description=DESC.current.value;
				if(!participants.length){
					showToast({
						type:'error',
						message:'Please Select User'
					})
					return ;
				}
		
				if(!description){
					showToast({
						type:'error',
						message:'Please Enter Description To Continue'
					})
					return ;
				}
				
				for(const user of participants){
		
					await new Promise((res)=>{
		
						post(`workspace/seekClarification/addParticipants/${id}`,{
							"userId": user.userId,
							"description": description,
							"userType": user.type,
							"userRole": user.type
						},(e,r)=>{
							
							res("");
						})
					})
				}
				setAddParticipant(false)
				fetchAllParticipants();
			}}
		>

			<Select
				placeholder={selectedParticipant.label}
				value={selectedParticipant}
				items={[
					{
						label:"Add Participant",
						value:"EMPLOYEE"
					},
					{
						label:"Add Buyer",
						value:"ADMIN"
					}
				]}
				select={(val)=>{
					setSelectedPariticipant(val);
				}}
			/>

			{
				selectedParticipant && selectedParticipant.value==="EMPLOYEE" && 
				<>
					<label>{"Select Employee"}</label> 
					<SearchableSelect3 
						label="Select Employee"
						select={(val)=>{
							setParticipants([...participants,val]);
						}} 
						placeholder="Search User from Organization " 
						items={employees} 
						onChange={(val)=>{
							get(`search/v1/employees?q=${val}`,(e,r)=>{
								if(r) {
									setEmployees(r.employees.map(item=>({
										type:'EMPLOYEE',
										userId:item.id,
										value:item.name,
										des:'EMPLOYEE',
										label:item.name
					
									})))
								}
							})
						}}
						/>
				</>
			}

			{
				selectedParticipant && selectedParticipant.value==="ADMIN" && 
				<>
					
					<label>{"Select Buyer"}</label> 
					<SearchableSelect3 
		
						label="Select Buyer"
						select={(val)=>{ 
							setParticipants([...participants, val]);
						}} 
						placeholder="Search Buyers" 
						items={buyers} 
						onChange={(val)=>{
							get(`search/access?q=${val}`,(e,r)=>{
								if(r) {
									setBuyers(r.accesses.map(item=>({
										type:'ADMIN',
										userId:item.id,
										value:item.name,
										des:'ADMIN',
										label:item.name,
									})))
								}
							})
						}}
						/>
				</>
			}

			<FunctionalInput ref={DESC} title="Enter the description" />
			{
				participants.map(item=>{
					return (
						<div style={{display:'flex',justifyContent:'space-between',marginBottom:'5px'}} >
							<p>{item.value} </p>
							<p style={{fontSize:12,color:'red',cursor:'pointer'}}
								onClick={()=>{
									setParticipants([...participants.filter(user=>user.userId!==item.userId)])
								}}
							> Remove </p>
						</div>
					)
				})
			}
		</Modal>
		}
	</>
    )
}

Index.defaultProps = {
    closed:false
}



export default Index;