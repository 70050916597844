import React from "react";
import { numberWithCommas } from "../../../../../Utils/NumberToComma";

export function getRandomColor() {
    const colors = ['rgb(143 178 248)', 'rgb(218 195 253)', 'rgb(135 235 212)', 'rgb(233 197 107)', 'rgb(250 163 128)'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
}

function TableContainer({ name, color }) {
    return (
        <div className="text-center flex" style={{ border: '1px solid #d0d0d0', padding: 3, borderRadius: 4 }}>
            <div style={{
                height: '23px',
                width: '3px',
                backgroundColor: color ? color : undefined,
                marginLeft: '1px',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',

            }} />
            <p className="pd" style={{ marginLeft: 4 }} >{name}</p>
        </div>
    )
}

export default ({ checks }) => {
    let items = checks && checks.invoiceItems ? [...checks.invoiceItems] : []
    return (
        <div>
            <div
                className="heading"
                style={{
                    marginTop: 6,
                    marginBottom: 8,
                    display: 'inline-block',
                    fontWeight: 600,
                    fontSize: 20,
                    padding: '6px 12px',
                }}
            >
                Ocr LineItem
            </div>
            <table className='' style={{ tableLayout: 'auto', width: '100%' }} >
                <thead>
                    <tr style={{}}>
                        {['Details', 'Price', 'Base', 'GrossAmount', 'Cess', 'Qty', 'Amount',].map((item, index) => {
                            return (
                                <th style={{ border: 'none', height: 'auto',background:'#fff' }} key={index}>
                                    <TableContainer name={item} color={getRandomColor()} />
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody style={{margin:0}}>
                    {items && items.map((item, index) => {
                        const backgroundColor = (index / 2 !== 0 )? '#f5f5f5':'#fff'
                        return (
                            <tr key={item.id}>
                                <td style={{paddingTop:'1rem',paddingBottom:'1rem',backgroundColor,margin:0}}>{item.name || item.desc}</td>
                                <td style={{paddingTop:'1rem',paddingBottom:'1rem',backgroundColor,margin:0}}>{numberWithCommas(parseFloat(item && item.price ? item.price : 0).toFixed(3)) || 0}</td>
                                <td style={{paddingTop:'1rem',paddingBottom:'1rem',backgroundColor}}>{numberWithCommas(parseFloat(item && item.base ? item.base : 0).toFixed(2)) || 0}</td>
                                <td style={{paddingTop:'1rem',paddingBottom:'1rem',backgroundColor}}>{numberWithCommas(parseFloat(item && item.grossBase ? item.grossBase : 0).toFixed(2)) || 0}</td>
                                <td style={{paddingTop:'1rem',paddingBottom:'1rem',backgroundColor}}>{numberWithCommas(item && item.cessAmount ? item.cessAmount.toFixed(2) : 0)}</td>
                                <td style={{paddingTop:'1rem',paddingBottom:'1rem',backgroundColor}}>{parseFloat(item && item.qtty ? item.qtty : 0).toFixed(3)}</td>
                                <td style={{paddingTop:'1rem',paddingBottom:'1rem',backgroundColor}}>{numberWithCommas(item && item.total ? item.total.toFixed(2) : 0)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}