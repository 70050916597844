import React, {useState, forwardRef, useImperativeHandle, useRef} from 'react';
import Props from 'prop-types';
import { get } from '../../../../../Model/Network/Config/Axios';


const SearchHsn = forwardRef(({  style ,numericalOnly}, ref) => {
    const [suggestion, setSuggestion] = useState([]);
    const [value, setProductName] = useState();

    let VALUE=useRef();

    const onChange = (e) => { 
		setProductName(e.target.value);
        if (e.target.value) {
            get(`/vendor/product/hsnMapping/searchHsn`, (e, r) => {
                if (r) {
                    setSuggestion(r.hsnList)
                }
            }, {
                q: e.target.value,
            })
        } else {
            setSuggestion([]);
        }
	}   


    const suggestionList = (item, index) => {
        return (
            <div key={index}
                onClick={() => {
                    setSuggestion([]);
                    setProductName(item.hsn);
                    VALUE.current.value =item.hsn;
                }}
                className="flex flex-v-center hover"
                style={{
                    height: 45,
                    paddingLeft: 20,
                    cursor: 'pointer',
                    color: 'var(--input-color)',
                }}>
                <div>
                    <p style={{
                        fontSize: 12,
                    }}>{item.hsn}</p>
                    {item.description &&
                    <p style={{
                        fontSize: 12,
                    }}>Description: {item.description}</p>
                    }   
                </div>
            </div>
        )
    }


    const setValueFromOutside = (newValue) => {
        setProductName(newValue);
        VALUE.current.value = newValue;
      }
    
      useImperativeHandle(ref, () => ({
        getValue: () => {
          return value;
        },
        setValue: setValueFromOutside,
      }));


    return (
        <div style={{
            marginBottom: 20,
            ...style
        }}>
            <p className="input-label">
                Search Material Code (HSN/SAC)
            </p>
            <div style={{
                position: 'relative'
            }}>
                <div className="flex flex-h-center flex-v-center" style={{
                    borderRadius: 8,
                    height: 50,
                    marginTop: 10,
                    cursor: 'pointer',
                    border:'1px solid var(--border-color)'
                }}>
                    <input
                        type={numericalOnly ? 'number' : 'text'}
                        style={{
                            width: '98%',
                            height: '90%',
                            backgroundColor: '#fff',
                            color: 'var(--input-color)',
                            fontSize: 16,
                            padding: '10px 12px',
                            border: 'none'
                        }}
                        ref={VALUE}
                        onChange={onChange}
                        placeholder={`Search HSN`}
                    />
                </div>
                {
                    suggestion.length > 0 ?
                        <div style={{
                            position: 'absolute',
                            height: 210,
                            overflowY: 'auto',
                            width: '100%',
                            top: 53,
                            paddingTop: 10,
                            paddingBottom: 10,
                            boxShadow: '0px 0px 5px 1px var(--input-backgroundColor)',
                            zIndex: 10,
                            border:'1px solid var(--border-color)',
                            backgroundColor:'#fff'

                        }}>
                            {suggestion.map(suggestionList)}
                        </div> :
                        null
                }
            </div>
        </div>
    )
})


SearchHsn.propsTypes = {
    type: Props.string
}

SearchHsn.defaultProps = {
    type: 'text',
    placeholder: 'Enter the Text',
}


export default SearchHsn ;
