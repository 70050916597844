import React from "react";
import Props from "prop-types";
import Close from "./close.png";
import {StateButton} from "../fStateButton";
import {Portal} from "../Portal";

function Modal(props){
		return (
			<Portal>
				<div className="modal fade show" style={props.show ? { display: "block", background: 'rgba(0,0,0,.4)',zIndex:props.zIndex } : {}}>
					<div className="modal-dialog" role="document" style={{ ...props.modalStyle }} >
						<div className="modal-content">
							<div>
								<h5 className="p-portlet-title" id="exampleModalLabel">
									{props.title}
								</h5>
								{
									props.des ?
										<p className="p-portlet-des">
											{
												props.des
											}
										</p> : null
								}
								<img alt="close" style={{ width: 16, height: 16, marginTop: 10, cursor: 'pointer' }}
									 src={Close} className="modal-close" onClick={props.close} />
							</div>
							<div className="modal-body" style={{ ...props.modalBody }}>
								{props.children}
							</div>
							<div className={props.hideFooter ? "" : 'modal-footer'}>
								{!props.hideButton ? <div style={{
									background: "var(--primary-color)",
									width: "100%",
									border: "2px solid var(--primary-color)",
									padding: '12px 15px',
									textAlign: 'center',
									...props.style.save
								}}
									onClick={props.stateLoading ? null : props.onSave}
									className="save">
									{props.stateLoading ?
										<div style={{display:'flex',height:'inherit',justifyContent:'center',alignItems:'center'}}> <StateButton  /> </div >
										: props.buttonName}
								</div> : null}
							</div>
						</div>
					</div>
				</div>
			</Portal>
		)
}

Modal.propTypes = {
	onSave: Props.func.isRequired,
	title: Props.string.isRequired,
	buttonName: Props.string,
	show: Props.bool,
	close: Props.func.isRequired,
	style: Props.object,
	hideButton: Props.bool,
	hideFooter: Props.bool
}


Modal.defaultProps = {
	buttonName: "Save Changes",
	hideButton: false,
	style: {
		close: {},
		save: {}
	},
	hideFooter: false
}

export {
	Modal
};
