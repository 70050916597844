import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useEndPoint } from "../../../Hooks/useEndPoint";
import SearchInput from "../../SearchInput/SearchInput"


const Index = forwardRef(({label,placeholder,masterType,filterData},ref) => {

    let {api,renderResult,searchKey,getKey,queryKey} = useEndPoint(masterType);  // Fetching endpoint for platfrom specific
    const [value,setValue] = useState(undefined);

    useImperativeHandle(ref,()=>({
        getValue:()=>{
            return value?value[getKey]:undefined;
        },
        setValue:(value=undefined)=>{
            setValue(value);
        }
    }))

    let payload = undefined;
    if(masterType==='DEPARTMENT'&& filterData && filterData['officeId']) {
        payload = {
            officeId:filterData['officeId']
        }
    }
    return(
          <SearchInput 
             queryKey={queryKey}
             label={label} 
             placeholder={placeholder}
             api={api}
             onSelect={setValue}
             renderResult={renderResult}
             className="mb2"
             searchKey={searchKey}
             payload={payload}
            />
    )
})

export default Index;