import React from 'react'
import './ocr.css'
import KeyValue from './KeyValue'

const HeaderLevelData = ({ details, checks, setChecks }) => {

    const handleClick = (e) => {
        e.stopPropagation()
        let key = e.target.name;
        if(!checks.hasOwnProperty(key)) return;
        setChecks((prev) => ({ ...prev, [key]: e.target.checked }))
    }
    return (
        <div className='headerleveldata' onClick={handleClick}>
            <div className='row'>
                <div className='column'>
                    <KeyValue name="invoice" title="Invoice Number" value={details.invoice} checked={checks.invoice} />
                </div>
                <div className='column'>
                    <KeyValue name="amount" title="Total Amount" value={details.amount ? details.amount : 0} checked={checks.amount} />
                </div>
            </div>
            {details && details.taxation && details.taxation.sameState &&
                <div className="row">
                    <div className="column">
                        <KeyValue
                            checked={checks.cgst}
                            title={"CGST"}
                            name="cgst"
                            value={details.taxation.cgst ? details.taxation.cgst : 0}
                        />
                    </div>
                    <div className="column">
                        <KeyValue
                            checked={checks.sgst}
                            title={"SGST"}
                            name="sgst"
                            value={details.taxation.sgst ? details.taxation.sgst : 0}
                        />
                    </div>
                </div>
            }
            <div className="row">
                {!(details && details.taxation &&  details.taxation.sameState) &&
                    <div className="column">
                        <KeyValue
                            checked={checks.igst}
                            title={"IGST"}
                            name="igst"
                            value={details.taxation.igst ? details.taxation.igst : 0}
                        />
                    </div>}
                <div className="column">
                    <KeyValue
                        checked={checks.date}
                        title={"Invoice Date"}
                        name="date"
                        value={details.date ? details.date : 0}
                    />
                </div>
            </div>
            {details.contact &&
                <>
                    <div className="flex align-center">
                        <input name='vendor' className='mr1' type='checkbox' checked={checks.vendor} />
                        <label>Vendor Details</label>
                    </div>
                    <div className='row'>
                        <div className='column'>
                            <KeyValue
                                title={"Vendor Name"}
                                value={details.contact ? details.contact.title : 'NA'}
                            />
                        </div>
                        <div className='column'>
                            <KeyValue
                                title={"Vendor Gstin"}
                                value={details.contact.gstin}
                            />
                        </div>
                    </div>
                </>
            }
            {details.address &&
                <div>
                    <div className="flex align-center">
                        <input name='address' className='mr1' type='checkbox' checked={checks.address} />
                        <label>Billing Details</label>
                    </div>
                    <div className='row'>
                        <div className='column'>
                            <KeyValue
                                title={"Address"}
                                value={details.address ? details.address.name : 0}
                            />
                        </div>
                        <div className='column'>
                            <KeyValue
                                title={"Billing Gstin"}
                                value={details.address.gstin}
                            />
                        </div>
                    </div>
                </div>
            }
            <div className="row">
                {details && details.sellerGstin &&
                    <div className="column">
                        <KeyValue
                            title={"Seller Gstin"}
                            value={details.sellerGstin || ''}
                        />
                    </div>}
                {details && details.buyerGstin &&
                    <div className="column">
                        <KeyValue
                            title={"Buyer Gstin"}
                            value={details.buyerGstin || ''}
                        />
                    </div>}
            </div>
            {details.ocrData && details.ocrData.irn &&
                <div className='row'>
                    <div className='column'>
                        <KeyValue name={'irn'} title={'irn'} checked={checks.irn} value={details.ocrData.irn}/>
                    </div>
            </div>}
            {details.ocrData && details.ocrData.placeOfSupply &&
                <div className='row'>
                    <div className='column'>
                        <KeyValue name={'placeSupply'} title={'Place of Supply'} checked={checks.placeSupply} value={details.ocrData.placeOfSupply}/>
                    </div>
            </div>}
        </div>
    )
}

export default HeaderLevelData