import { post } from "../../Model/Network/Config/Axios";
import {FirebaseUtils} from "../../Utils/Firebase";
import _ from "lodash";

const Home = [
    {
        cname: 'home',
        value: 'Home',
        ukey: '2',
        path: '/app/home',
        info: 'Home Page',
        link: "/app/home",
        minfo: 'Home',
        filter: data => {
            return true
        }
    },
    {
        cname: 'organisation',
        value: 'Organisation',
        ukey: '2',
        path: '/app/organisation',
        info: 'Manage company hierarchy',
        link: "/app/organisation/offices",
        minfo: 'Hierarchy',
        filter: data => {
            return true
        }
    },
    {
        cname: 'policies',
        badge: 'policies',
        value: 'Control Room',
        ukey: '9',
        path: '/app/tower',
        link: "/app/tower",
        info: 'Manage company programmes',
        role: ["management.read"],
        color: "#1C2D41",
        module: "PROGRAMMES",
        isSuper: true,
        filter: data => {
            return data.main;
        }
    },
    {
        cname: 'approvals',
        value: 'Approvals',
        ukey: '9',
        path: '/app/approvals',
        link: "/app/approvals",
        module:'Approval',
        otherConfigCheck:true,
        info: 'View all approvals',
        otherConfig:false,
        role: ["finance.read"],
        filter: data => {
            return data.main;
        }
    },
    {
        cname: 'projects',
        value: 'Budget',
        ukey: '9',
        path: '/app/budget',
        link: "/app/budget",
        module:'BUDGETS',
        info: 'View all bugdet',
        role: ["management.read"],
        filter: data => {
            return data.main;
        }
    }
]


const Main = [
    {
        cname: "expenses",
        value: "Expenses",
        ukey: '18',
        module:'Expenses',
        path: '/app/expenses',
        otherConfigCheck:true,
        link: '/app/expenses',
        info: "All Expense history of your company",
        role: ["finance.read"],
        filter: data => {
            return data.main || data.finance;
        }
    },
    {
        cname: 'advance',
        value: 'Advances',
        ukey: '15',
        path: '/app/advances/petty',
        link: "/app/advances/petty",
        info: 'Petty Cash Management',
        role: ["finance.write"],
        badge: 'petty',
        color: "#FF7C51",
        module: "ADVANCES",
        filter: data => {
            return data.main || data.finance;
        }
    },
];


const Workspace = [
    {
        cname: 'townhall',
        value: 'Town Hall',
        ukey: 100,
        path: '/app/workspace/townhall',
        link: '/app/workspace/townhall',
        info: 'View Townhall',
        beta: true,
        module: 'TOWNHALL',
        filter: data => {
            return data.main
        }
    },
    {
        cname: 'fieldAssist',
        value: 'Field Assist',
        ukey: 100,
        path: '/app/workspace/assist',
        link: '/app/workspace/assist/plotter',
        info: 'View Field',
        beta: true,
        module: 'FIELDASSIST',
        filter: data => {
            return data.main
        }
    }, 
    {
        cname: 'support',
        value: 'Seek Clarification',
        ukey: '15',
        path: '/app/workspace/seekclarification',
        link: "/app/workspace/seekclarification",
        info: 'Seek Clarification',
        filter: data => {
            return data.main
        }
    },
    
    {
        cname: 'support',
        value: "Employee Support",
        ukey: 100,
        path: '/app/workspace/support',
        link: '/app/workspace/support',
        info: "View Filters",
        module: "CUSTOMSUPPORT",
        filter: data => {
            return data.main
        }
    }, {
        cname: 'support',
        value: "Vendor Support",
        ukey: 100,
        path: '/app/workspace/vendor/support',
        link: '/app/workspace/vendor/support',
        info: "View Filters",
        module: "CUSTOMSUPPORT",
        filter: data => {
            return data.main
        }
    },
    {
        cname: 'flow',
        value: "Workflow Builder",
        ukey: 100,
        path: '/app/workspace/app',
        link: '/app/workspace/app',
        info: "View Filters",
        module: "CUSTOMAPP",
        filter: data => {
            return data.main
        }
    }, {
        cname: 'form',
        value: "Custom Forms",
        ukey: 100,
        path: '/app/workspace/forms',
        link: '/app/workspace/forms',
        info: "View Filters",
        module: "CUSTOMSURVEY",
        filter: data => {
            return data.main
        }
    },
    {
        cname: 'scanner',
        value: 'Masters',
        ukey: 110,
        path: '/app/workspace/table',
        link: '/app/workspace/table',
        info: 'Custom Table',
        module: 'CUSTOMTABLE',
        filter: data => {
            return data.main
        }
    },
    {
        cname: 'scanner',
        value: 'Scanner Rooms',
        ukey: '15',
        path: '/app/vendor/scanners',
        link: "/app/vendor/scanners",
        info: 'Manage your Account Settings',
        module: "VENDOR",
        filter: data => {
            return data.main
        }
    },
    {
        cname: 'marketplace',
        value: 'Marketplace',
        ukey: '15',
        path: '/app/apps',
        link: "/app/apps",
        info: 'App Marketplace',
        isSuper: true,
        filter: data => {
            return data.main
        }
    },
   
]


const Others = [
    {
        cname: 'repository',
        value: 'Repository',
        ukey: '17',
        path: '/app/others/repository',
        link: "/app/others/repository",
        info: 'Export expense report',
        role: ["finance.read"],
        filter: data => {
            return data.main
        }
    },
    {
        cname: 'export',
        value: 'Export Wizard',
        ukey: '13',
        path: '/app/others/export',
        link: "/app/others/export/expense/export",
        info: 'Export expense report',
        role: ["finance.read"],
        filter: data => {
            return data.main
        }
    },
    {
        cname: 'reports',
        value: 'Reports',
        ukey: '14',
        path: '/app/others/reports',
        link: "/app/others/reports",
        info: 'Report',
        module: 'Reports',
        role: ["finance.read"],
        filter: data => {
            return data.main
        }
    },
    {
        cname: 'auditLogs',
        value: 'AuditLogs',
        ukey: '140',
        path: '/app/others/auditLogs',
        link: "/app/others/auditLogs",
        info: 'AuditLogs',
        pluginCheck:true,
        plugin:'auditLogging',
        role: ["finance.read"],
        filter: data => {
            return data.main
        }
    }
]


const SETTLEMENTS = [
    {
        cname: 'voucher',
        value: 'Expense Vouchers',
        ukey: '12',
        badge: 'voucher',
        color: "#212121",
        path: '/app/others/settlements/offline',
        link: "/app/others/settlements/offline",
        info: 'Manage your settlements',
        module: "SETTLEMENTS",
        filter: (data, config) => {
            let isVendor = _.includes(config.modules, "SETTLEMENTS");
            return (data.main || data.finance) && isVendor;
        }
    },
    {
        cname: 'payables',
        value: 'Account Payables',
        ukey: '12',
        badge: 'invoice',
        color: "#212121",
        path: '/app/others/settlements/invoicing',
        link: "/app/others/settlements/invoicing",
        info: 'Manage your settlements',
        module: "VENDOR SETTLEMENTS",
        filter: (data, config) => {
            let isVendor = _.includes(config.modules, "VENDOR");
            if (isVendor) {
                isVendor = _.includes(config.modules, "VENDOR SETTLEMENTS")
            }
            return (data.main || data.finance) && isVendor
        }
    },
    {
        cname: 'approvals',
        value: 'Settlement Approval',
        ukey: '1232-',
        badge: 'approvals',
        color: "#212121",
        path: '/app/others/settlements/settlement.approval',
        link: "/app/others/settlements/settlement.approval",
        info: 'Manage your settlements',
        module: "VENDOR SETTLEMENTS",
        filter: (data, config) => {
            let isVendor = _.includes(config.modules, "VENDOR");
            if (isVendor) {
                isVendor = config.plugins.settlementRequestFlow
            }
            return (data.main || data.finance) && isVendor
        }
    },
    {
        cname: 'travel',
        value: 'Travel Bookings',
        ukey: '12',
        color: "#212121",
        path: '/app/others/settlements/bookings',
        link: "/app/others/settlements/bookings",
        info: 'Manage your settlements',
        module: "SETTLEMENTS",
        filter: (data, config) => {
            let isVendor = _.includes(config.modules, "SETTLEMENTS");
            if (isVendor) {
                isVendor = _.includes(config.modules, "SELF BOOKINGS");
            }
            return (data.main || data.finance) && isVendor;
        }
    },
    {
        cname: 'settlement',
        value: 'Employee Settlements',
        ukey: '12',
        color: "#212121",
        path: '/app/others/settlements/employees',
        link: "/app/others/settlements/employees",
        info: 'Manage your settlements',
        module: "SETTLEMENTS",
        filter: (data, config) => {
            let isVendor = _.includes(config.modules, "SETTLEMENTS");
            return (data.main || data.finance) && isVendor;
        }
    },
    {
        cname: 'settlement',
        value: 'Vendor Settlements',
        ukey: '12',
        color: "#212121",
        path: '/app/others/settlements/vendor',
        link: "/app/others/settlements/vendor",
        info: 'Manage your settlements',
        module: "VENDOR SETTLEMENTS",
        filter: (data, config) => {
            let isVendor = _.includes(config.modules, "VENDOR");
            if (isVendor) {
                isVendor = _.includes(config.modules, "VENDOR SETTLEMENTS")
            }
            return (data.main || data.finance) && isVendor
        }
    }
]

const Payments = [

    {
        cname: 'payout',
        value: 'Payouts',
        ukey: '16',
        path: '/app/others/payouts',
        link: "/app/others/payouts/active",
        info: 'Payments using wallet',
        role: ["payouts.read"],
        badge: "payouts",
        module:'PAYOUT',
        color: "#00C853",
        filter:(data, config) => {
            return (data.main || data.finance)
        }
    },
    {
        cname: 'qr',
        value: 'Qr Payments',
        ukey: '16',
        path: '/app/others/qr',
        link: "/app/others/qr",
        info: 'Payments using wallet',
        color: "#00C853",
        module: "QR",
        filter: (data) => {
            return data.main || data.finance 
        }
    },
    {
        cname: 'Gst2BreconReport',
        value: 'GST 2B Report',
        ukey: '15',
        color: "#7551E9",
        path: '/app/vendor/Gst2BreconReport',
        link: "/app/vendor/Gst2BreconReport",
        info: 'Manage your Vendor Invoices',
        filter: (data, config) => {
            return config.plugins.gstinPayments
        }
    },
    {
        cname: 'Gst2BreconReport',
        value: 'GST 2A Report',
        ukey: '15',
        color: "#7551E9",
        path: '/app/vendor/Gst2AreconReport',
        link: "/app/vendor/Gst2AreconReport",
        info: 'Manage your Vendor Invoices',
        filter: (data, config) => {
            return config.plugins.gstinPayments
        }
    },
    {
        cname: 'Gst2BreconReport',
        value: 'E-Way Bill',
        ukey: '15',
        color: "#7551E9",
        path: '/app/vendor/eWayBill',
        link: "/app/vendor/eWayBill",
        info: 'Manage your E-Way BBill',
        filter: (data, config) => {
            return config.plugins.gstinPayments
        }
    },
    {
        cname: 'IMS',
        value: 'IMS',
        ukey: '15',
        color: "#7551E9",
        path: '/app/vendor/ims',
        link: "/app/vendor/ims",
        info: 'Manage your Vendor Invoices',
        filter: (data, config) => {
            return config.plugins.gstinPayments
        }
    },
    {
        cname: 'invoice',
        value: 'Tds Payments',
        ukey: '16',
        path: '/app/others/tds',
        link: "/app/others/tds",
        info: 'Payments using wallet',
        color: "#00C853",
        filter: (data, config) => {
            return config.plugins.tdsPayments
        }
    },
    {
        cname: 'invoice',
        value: 'Gst Payments',
        ukey: '16',
        path: '/app/others/gst',
        link: "/app/others/gst",
        info: 'Payments using wallet',
        color: "#00C853",
        filter: (data, config) => {
            return config.plugins.gstinPayments
        }
    },
    {
        cname: 'invoice',
        value: 'Invoice Discounting',
        ukey: '16',
        path: '/app/others/discounting',
        link: "/app/others/discounting",
        info: 'Payments using wallet',
        color: "#00C853",
        filter: (data, config) => {
            return config.plugins.invoiceDiscounting
        }
    },
    {
        cname: 'utility',
        value: 'Utility Payments',
        ukey: '15',
        path: '/app/utility',
        link: "/app/utility/dashboard",
        info: 'Manage your Account Settings',
        module: "UTILITYPAYMENTS",
        filter: data => {
            return data.main || data.finance
        }
    }
]

const Vendor = [
    {
        cname: 'vendor',
        value: 'Vendor Management',
        ukey: '2',
        otherConfigCheck:true,
        module:'Vendor Management',
        path: '/app/vendor/contacts',
        info: 'Manage company hierarchy',
        link: "/app/vendor/contacts",
        filter: data => {
            return data.main || data.procure
        }
    },
    {
        cname: 'advance',
        value: 'Vendor Advance',
        ukey: '2',
        path: '/app/vendor/advance',
        info: 'Manage vendor advance',
        module:'Vendor Advance',
        otherConfigCheck:true,
        link: "/app/vendor/advance",
        filter: data => {
            return data.main || data.procure
        }
    },
    {
        cname: 'invoice',
        value: 'Invoice Management',
        ukey: '15',
        color: "#7551E9",
        path: '/app/vendor/invoices',
        link: "/app/vendor/invoices",
        info: 'Manage your Vendor Invoices',
        module: "INVOICE",
        filter: data => {
            return data.main || data.procure
        }
    },
   
    {
        cname: 'expenses',
        value: 'Credit Note',
        ukey: '15',
        color: "#7551E9",
        pluginCheck:true,
        plugin:"creditNote",
        otherConfigCheck:true,
        module:'VENDOR',
        path: '/app/vendor/creditnote',
        link: "/app/vendor/creditnote",
        info: 'Manage your Credit Note',
        filter: data => {
            return data.main || data.procure
        }
    },
    {
        cname: 'expenses',
        value: 'Debit Note',
        ukey: '15',
        color: "#7551E9",
        pluginCheck:true,
        plugin:"debitNote",
        otherConfigCheck:true,
        module:'VENDOR',
        path: '/app/vendor/debitNote',
        link: "/app/vendor/debitNote",
        info: 'Manage your Debit Note',
        filter: data => {
            return data.main || data.procure
        }
    },
    {
        cname: 'expenses',
        value: 'Gate Inward Note',
        ukey: '15',
        color: "#7551E9",
        pluginCheck:true,
        plugin:"enableGin",
        otherConfigCheck:true,
        module:'VENDOR',
        path: '/app/vendor/gin',
        link: "/app/vendor/gin",
        info: 'Manage your gate inward note',
        filter: data => {
            return data.main || data.procure
        }
    },
    {
        cname: 'expenses',
        value: 'ASN',
        ukey: '15',
        color: "#7551E9",
        pluginCheck:true,
        plugin:"vendorAsn",
        otherConfigCheck:true,
        module:'VENDORPR',
        path: '/app/vendor/asn',
        link: "/app/vendor/asn",
        info: 'Manage your Advance shipping note',
        filter: data => {
            return data.main || data.procure
        }
    },
    {
        cname: 'procure',
        value: 'Procurement',
        ukey: '15',
        badge: 'procurement',
        color: "var(--primary-color)",
        key: "procurement",
        path: '/app/vendor/procurement',
        link: "/app/vendor/procurement/item",
        info: 'Manage your Account Settings',
        module: "VENDORPR",
        filter: data => {
            return data.main || data.procure
        },
    },
    {
        cname: 'stock',
        value: 'Product & Stocks',
        ukey: '15',
        path: '/app/vendor/products',
        link: "/app/vendor/products",
        info: 'Manage your Account Settings',
        module: "VENDOR",
        filter: data => {
            return data.main || data.procure
        }
    },
    {
        cname: 'stock',
        value: 'Stock Transfer',
        ukey: '15',
        path: '/app/vendor/transfer',
        link: "/app/vendor/transfer",
        info: 'Manage Your Transfer workflow',
        module: "VENDORPR",
        filter: data => {
            return data.main || data.procure
        }
    },
    {
        cname: 'invoice',
        value: 'Contract Management',
        ukey: '15',
        color: "#7551E9",
        path: '/app/vendor/contracts',
        link: "/app/vendor/contracts",
        info: 'Manage your Vendor Invoices',
        module: "CONTRACTS",
        filter: data => {
            return data.main || data.procure
        }
    },
    {
        cname: 'invoice',
        value: 'Payment Advice',
        ukey: '15',
        color: "#7551E9",
        pluginCheck:true,
        plugin:"paymentAdviceCopy",
        otherConfigCheck:true,
        module:'VENDOR',
        path: '/app/vendor/paymentAdvice',
        link: "/app/vendor/paymentAdvice",
        info: 'Manage your Payment advice copy',
        filter: data => {
            return data.main || data.procure
        }
    },
     {
        cname: 'invoice',
        value: 'Vendor Liabilities',
        ukey: 'vendorLiabilities',
        color: "#7551E9",
        pluginCheck:true,
        plugin:"paymentAdviceCopy",
        otherConfigCheck:true,
        module:'VENDOR',
        path: '/app/vendor/liabilities',
        link: "/app/vendor/liabilities",
        info: 'Manage your Payment vendor liabilities',
        filter: data => {
            return data.main || data.procure
        }
    },
]

const Account = [
    {
        cname: 'settings',
        value: 'Account Settings',
        ukey: '15',
        path: '/app/settings',
        link: "/app/settings/password/unblock",
        info: 'Manage your Account Settings',
        filter: data => {
            return true
        }
    },
    {
        cname: 'logout',
        value: 'Logout',
        ukey: '16',
        path: '/app/logout',
        info: 'Sign off Securely',
        onClick: () => {
            post(`auth/logOut`,{},(e,r)=>{
                if(r) {
                    window.localStorage.clear()
                    window.sessionStorage.clear();
                    FirebaseUtils.signout()
                    window.location.reload();
                }
            })


        },
        filter: data => {
            return true
        }
    }

]

export {Home, Main, Others, Payments, Account, Workspace, Vendor, SETTLEMENTS};
