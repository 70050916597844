import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { PDFExport } from "@progress/kendo-react-pdf";
import { useReactToPrint } from 'react-to-print';
import Moment from 'moment';
import { numberWithCommas } from '../../../../../../../Utils/NumberToComma';
import { PageLoader } from '../../../../../../Common/Components/PageLoader';
import inrInWords from '../../../../../../../Utils/INRToWords';

function Stelmec(props) {

  const { data, defaultBank } = props;
  const {vendor,billingTo} = data;
  const pdf = useRef(null);
  let pr_delivery_address;

  let totalBaseAmount = 0;
  let totalTax = 0;
  let cgst=0;
  let sgst=0;
  let igst=0;

  data.forms.forEach(item=>{
    if(item.id==='pr_delivery_address'){
      pr_delivery_address=item.value;
    }
  })

  const extractFromGSTIN=(key,gstin)=>{
    if(!gstin || gstin.length!==15) return ;
    if(key==='state'){
        return gstin.substring(0,2);
    }
    if(key==='pan'){
        return gstin.substring(2, 12); 
    }
  }

  const printForm = useReactToPrint({ 
    content: () => pdf.current,
    documentTitle: props.voucherId
})

  data.procured.forEach(val => {
    totalBaseAmount += val.base;
    totalTax += val.tax;
  });

    if (vendor && vendor.gstin && vendor.gstin.length > 2 && billingTo && billingTo.gstin && billingTo.gstin.length > 2) {
        if (vendor.gstin.slice(0, 2) === billingTo.gstin.slice(0, 2)) {
            cgst = totalTax / 2;
            sgst = totalTax / 2;
        } else {
            igst = totalTax;
        }
    } else {
        cgst = totalTax / 2;
        sgst = totalTax / 2;
    }

  if (!data || !defaultBank) return <PageLoader />;

  return (
    <div style={{ maxWidth: "900px", margin: "auto",textAlign:'left',padding:'20px 15px',fontSize:'12px' }}>
        <div style={{ textAlign: "right" }} >
            <p
                style={{
                    fontWeight: 600,
                    color: "var(--primary-color)",
                    cursor: "pointer",
                    marginBottom:'10px'
                }}
                onClick={() => { printForm() }}
            >
                Print
            </p>
        </div>
        <PDFExport  
            paperSize="A4"
            title="Order Document"
            padding="50"
            fileName={`Order-${data.orderId}.pdf`}
            ref={(r) => (pdf.current = r)}
        >
        <div style={{ padding: "20px 15px",margin:'15px 0' }}>
            <header>
                <table style={{ borderBottom: "1px solid black", lineHeight: "20px", width: "100%",textAlign:'left' }}>
                    <tr>
                        <td style={{ borderRight: "1px solid black", width: "50%", textAlign: "left" }}>
                            <img 
                                style={{ objectFit: "cover", objectPosition: "center", width: "200px", height: "150px" }} 
                                src="https://static.eka.io/v2/receipts/2025/02/625f89b7-c0a4-48d9-8da4-6beb63a0077a.jpeg" 
                                alt="Company Logo" 
                            />
                            <p style={{ fontWeight: 1000, fontSize: "20px", margin: 0 }}>Purchase Order</p>
                        </td>
                        <td style={{ paddingLeft: "10px",textAlign:'left' }}>
                            <strong>STELMEC LIMITED</strong><br />
                            <span>{data.billingTo.address}</span>,<br />
                            {/* <span>{data.billingTo.address2}</span><br /> */}
                            <strong> PAN No.</strong>: {extractFromGSTIN('pan',data.billingTo.gstin)}<br />
                            <strong> GSTIN</strong>: {data.billingTo.gstin}<br />
                            <strong> CIN No.</strong>: U31200MH2000PLC124565<br />
                            <strong> Website</strong>: <a href="https://www.stelmec.com/" rel="noopener noreferrer" target="_blank">https://www.stelmec.com/</a>
                        </td>
                    </tr>
                </table>
            </header> 
            <table style={{ lineHeight: "25px", width: "100%", marginBottom: "10px"}}>
                <tr>
                    <td style={{ width: "40%",textAlign:'left' }}>
                        <strong>Supplier Code :-</strong> {data.vendor.vendorId}<br />
                        <strong>{data.vendor.name}</strong><br />
                        <span>{data.vendor.address.address}</span><br />
                        <strong>State Code :</strong> {data.vendor.address.state}/{extractFromGSTIN('state',data.vendor.gstin)}<br />
                        <strong>GSTIN No :</strong> {data.vendor.gstin}<br />
                        <strong>Contact Person : { Object.keys(data.vendor.customTags).length ? data.vendor.customTags.contact_person_name : ""} </strong> <br />
                        <strong>Mobile No. : { Object.keys(data.vendor.customTags).length ? data.vendor.customTags.contact_person_mob : ""} </strong> <br />
                    </td>
                    <td style={{ width: "33%" ,textAlign:'left',verticalAlign:'text-top'}}>
                        <strong>Ship To Party </strong><br />
                        <span>{pr_delivery_address}</span><br />
                    </td>
                    <td style={{ verticalAlign: "text-top",textAlign:'left' }}>
                        <strong>P.O. No. : {data.orderId} </strong><br />
                        <strong>P.O. Date : {Moment(data.details.createdAt).format('DD-MM-YYYY')} </strong><br />
                        <strong>Ref No :</strong>{data.refNo} <br />
                        <strong>Payment Terms :</strong> {Object.keys(data.vendor.customTags).length ? data.vendor.customTags.payment_term : ""}<br />
                    </td>
                </tr>
            </table>
            <table border="1" cellSpacing="0" cellPadding="5" style={{ width: "100%" }}>
                <thead>
                    <tr>
                        <th>Sr No.</th>
                        <th>Item Code</th>
                        <th>HSN / SAC</th>
                        <th>Item Description</th>
                        <th>Delivery Date</th>
                        <th>Quantity</th>
                        <th>UOM</th>
                        <th>Unit Price INR</th>
                        <th>GST (%)</th>
                        <th>Total Amount INR</th>
                    </tr>
                </thead>
                <tbody>
                    {data.procured.map((item, index) => (
                        <tr key={index} style={{borderBottom:'dashed'}} >
                            <td>{index + 1}</td>
                            <td>{item.productCode}</td>
                            <td>{item.hsn}</td>
                            <td>{item.description}</td>
                            <td>{Moment(item.deliveryDate).format('DD-MM-YYYY')}</td>
                            <td>{item.qtty}</td>
                            <td>{item.unit}</td>
                            <td>{item.price}</td>
                            <td>{item.taxSlab} </td>
                            <td>{item.amount}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div style={{ justifyItems: "right", textAlign: "right", borderBottom: "1px solid black", width: "100%",fontSize:'13px' }}>
                <p style={{ width: "400px", borderBottom: "1px solid black", paddingBottom: "10px" }}>
                    <strong style={{ marginRight: "15px" }}>Total Amount Before Tax</strong>{numberWithCommas(totalBaseAmount)}<br />
                </p>
                <p style={{ width: "200px",textAlign:'right',margin:'15px 0' }}>
                    <strong style={{ marginRight: "15px" }}>CGST Tax Amount </strong>{numberWithCommas(cgst)}<br />
                    <strong style={{ marginRight: "15px" }}>SGST/UGST Tax Amount </strong>{numberWithCommas(sgst)}<br />
                    <strong style={{ marginRight: "15px" }}>IGST Tax Amount </strong>{numberWithCommas(igst)}<br />
                </p>
                <table style={{ width: "100%", borderTop: "1px solid black", paddingTop: "10px" }}>
                    <th style={{ textAlign: "left" }}>Amount In Words</th>
                    <th></th>
                    <th></th>
                    <th>Total Amount</th> 
                    <th>{numberWithCommas(totalBaseAmount+totalTax)}</th>
                    <tr>
                        <td style={{ textAlign: "left" }}>Rs. {inrInWords(data.procured.reduce((total, item) => total + item.amount, 0))}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
            <div style={{ fontSize: "9px", textAlign: "left", padding: "5px" }}>
                <p style={{fontWeight:800,fontSize:'16px',textAlign:'center',marginBottom:'15px'}} >Purchase Order Terms & Conditions</p>
                {data.conditions.map((item, index) => {
                    return (
                        <div style={{marginBottom:'10px'}} >
                        <div style={{fontSize:'14px',fontWeight:800}} >{item.title} : </div>
                        <div key={index} style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: 'var(--text-color)',
                        }}>
                            <div style={{
                                fontSize:13,
                            }} dangerouslySetInnerHTML={{ __html: `${item.content}` }} />
                        </div>
                        </div>
                  )
                })} 
            </div>
            <footer>
                <p>
                    <span style={{ marginRight: "300px" }}>
                        P.O.No. & Date : {data.poNo} - {data.poDate}
                    </span>
                    <span>
                        Print Date & Time : {data.printDate} - {data.printTime}
                    </span>
                    <span style={{ float: "right" }}>
                        {/* Page 1 of 4 */}
                    </span>
                </p>
                <strong style={{ float: "right" }}>This is system generated copy, signature is not required.</strong>
            </footer>
        </div>
        </PDFExport>
    </div>
  );
}

export default connect((state) => {
  return {
    config: state.auth.config,
  };
}, null)(Stelmec);