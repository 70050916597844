import React from 'react'
import {get} from "../../../../../Model/Network/Config/Axios";
import {SearchableSelect} from "../../../../../Components/Common/Class/Select/SearchableSelec2";
import {FloatInput} from '../../../../../Components/Common/Class/FloatLabelInput'

class SearchVendor extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			modal: undefined,
			allPOs: [],
			po:props.defaultValue
		}
	
	}

	

	componentDidUpdate(prevProps) {
		if(this.props.value !== prevProps.value && !this.props.modal) {
			this.setState({
				...this.state,
				po:this.props.value
			})

		}
	}



	render() {
		if(!this.props.modal){
			return  (
				<div style={{position:'relative'}}>
					<SearchableSelect disabled={this.props.disabled} label={this.state.select?<div style={{textOverflow:'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>{this.state.select.address}</div>:this.props.title} placeholder={this.props.title}
						value={this.state.po}
						select={item => {
							this.props.onSelect(item);
              this.setState({
                ...this.state,
                po: item,
              })
						}}
						onChange={val => {
								if(this.props.mtype === 'asn'){
									get(this.props.url, (e, r) => {
										this.setState({
											...this.state,
											allPOs: r.asnList.map(val => ({
												label: val.asnId,
												value: val.id,
												status:val.status
											}))
										})
									}, {
										q: val,
										...this.props.extraData?{...this.props.extraData}:{}
									})
								}
								if(this.props.mtype === 'po'){
									console.log("this.props.extraData",this.props.extraData);
									
									get(this.props.url, (e, r) => {
										this.setState({
											...this.state,
											allPOs: r.pos.map(val => ({
												label: val.poNo,
												value: val.id,
												status:val.status
											}))
										})
									}, {
										q: val,
										...this.props.extraData?{...this.props.extraData}:{}
									})
								}
								else if(this.props.mtype === 'tag'){
									get(this.props.url, (e, r) => {
										this.setState({
											...this.state,
											allPOs: r.tags.map(val => ({
												label: val.title,
												value: val.id,
												status:val.title
											}))
										})
									}, {
										q: val
									})
								}
								else if(this.props.mtype === 'poCondition'){
									get(this.props.url, (e, r) => {
										this.setState({
											...this.state,
											allPOs: r.poConditions.map(val => ({
												label: val.title,
												value: val.id,
												status:val.title,
												variable:val.variable
											}))
										})
									}, {
										q: val
									})
								}
						}}
						items={this.state.allPOs}
						style={{
							color: '#e6ebf3',
							height: 46,
							marginBottom: 12
						}}
					/>
				</div>
			)
		}
		return (
			<div>
               <div style={{cursor:'pointer'}}>
				   <FloatInput
					   type="text"
					   title="Search PO"
					   handleRef={ref=>this.vendor=ref}
					   disabled={true}
					   onClick={()=>{
						   this.setState({
							   ...this.state,
							   modal:true
						   })
					   }}
				   />
			   </div>
			</div>
		)
	}

}

SearchVendor.defaultProps = {
	disabled:false
}

export default SearchVendor
