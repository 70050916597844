// Calculate Levenshtein distance
const levenshteinDistance = (a, b) => {
    const matrix = [];

    // Increment along the first column of each row
    for (let i = 0; i <= b.length; i++) {
        matrix[i] = [i];
    }

    // Increment each column in the first row
    for (let j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }

    // Fill in the rest of the matrix
    for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1) === a.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j - 1] + 1, // substitution
                    matrix[i][j - 1] + 1, // insertion
                    matrix[i - 1][j] + 1 // deletion
                );
            }
        }
    }

    return matrix[b.length][a.length];
};

// Calculate similarity based on Levenshtein distance
const similarity = (a, b) => {
    const distance = levenshteinDistance(a, b);
    const maxLength = Math.max(a.length, b.length);
    return ((maxLength - distance) / maxLength) * 100;
};

// Check if two strings are similar enough (90% threshold)
const fuzzyMatch = (a='', b='', threshold = 90) => {
    const strA = String(a.toLowerCase());
    const strB = String(b.toLowerCase());
    if (!strA || !strB) return false;
    const score = similarity(strA, strB);
    console.log('::fuzzy match score::', score)
    return score >= threshold;
};

// const fuzzyMatch = (a = '', b = '') => {
//     const strA = String(a);
//     const strB = String(b);
//     if (!strA || !strB) return { text: '', percentage: 0 };
//     const score = similarity(strA, strB);
//     return { text: strB, percentage: score };
// };


export {fuzzyMatch}