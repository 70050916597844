import React from 'react';


class Index extends React.Component {


    render() {
        let { conditions,title } = this.props;
        return (
            <div style={{
                minHeight: 10,
                borderBottom: '1px solid var(--border-color)',
                padding:10
            }}>
                <p style={{
                    fontWeight: 800,
                    marginBottom: 10,
                    padding:'8px 10px'
                }}>{title}</p>

                {
                    conditions.length > 0 ?
                        conditions.map((item, index) => {
                            return (
                                <div key={index} style={{
                                    color: 'var(--text-color)',
                                    padding:'8px 10px'
                                }}>
                                    {(item && item.title)?<div style={{
                                        marginLeft: 10,
                                        fontSize:15,
                                    }}>{item.title}</div>:null}
                                    {item.content && <div style={{
                                        marginLeft: 20,
                                        fontSize:13,
                                        marginBottom:'5px'
                                    }} dangerouslySetInnerHTML={{ __html: `${item.content}` }} />}
                                </div>
                            )
                        })
                        :null
                               }

            </div>
        )
    }
}

Index.defaultProps = {
    title:`Terms and Conditions:`
}

export default Index;