import React, { useRef, useState,useEffect} from 'react'
import TabSwtich from "../../../../Common/fTabSwitch";
import {SearchableSelect} from "../../../../Common/Class/Select/SearchableSelec2";
import axios, {get, post} from "../../../../../Model/Network/Config/Axios";
import {FloatInputButton, FunctionalInput} from "../../../../Common/fInputs";
import {FileUpload} from "../../../../Common/fInputs/FunctionalFile";
import PhotoTile from "../../../../Common/fPhoto";
import Track from "../../../../Common/fTrack";
import Moment from "moment";
import {handleError, showToast} from "../../../../Common/fToast/ToastCallback";
import {SidePane} from "../../../../Common/fSidePane/RightPane";
import Invoice from "./fInvoice";
import _ from 'lodash'
import useDateFormat from '../../useDateFormat';
import { Form } from '../../../../Common/Class/Form';
import { NormalTable } from '../../../../Tables/NormalTable/NormalTable';
import { KeyValue } from '../../../../Common/Components/KeyValue';
import moment from 'moment';
import {Spinner} from '../../../../../Components/Common/fLoader'
import { DropdownModal } from '../../../../Common/fModal/DropdownModal';
import { Modal } from '../../../../Common/fModal/Modal';
import { ViewLedger } from '../../../fSettlements/fSettlements/fVendorLedger/VendorLedger/Helper/ViewLedger';
import { getBaseUrl } from '../../../../../Model/Network/Config/DecentralizeWrapper';
import { PageLoader } from '../../../../Common/Components/PageLoader';
import { SupportTicket } from '../../../../Common/SupportTicket';
import { ToggleSwitch2 } from '../../../../Common/fSwitch';
import { useSelector } from 'react-redux';
import { Form2 } from '../../../../Common/Class/Form2';


const tenantList = ["bajajelec","BAJAJELECT"];


export  default function India(props) {
	let { details, onReload, id,enableAddLineItem} = props;
	const [getTab, setTab] = useState(1);
	const [getVendor, setVendor] = useState('');
	const [getVendors, setVendors] = useState([]);
	const [tdsSlabs,setTdsSlabs] = useState([]);
	const [getBillOffice, setBillOffice] = useState('');
	const [getShipOffice, setShipOffice] = useState('');
	const [Settlements, setSettlements] = useState(undefined);
	const [getOffices, setOffices] = useState([]);
	const [partial,setPartial] = useState(false);
	const [recall,setRecall] = useState(false);
	const [advance,setAdvance] = useState(false);
	const [loading,setLoading]  = useState(false);
	const [decline,setDecline] = useState(false);
	const [recallPartial,setRecallPartial] = useState(false);
	let [ledger,setLedger] = useState(undefined);
    const [invoicePreview,setInvoicePreview] = useState(false)
	const {enableEditableInvoiceCurrencyConversionFinance,tdsAtLineItemInContract,tdsAtLineItemAutomation,tdsAtLineItem} = useSelector(state=>state.auth.config.plugins);
	const {tenant} = useSelector(state=>state.auth.config)
	const disableUpdate = tenantList.includes(tenant)
	const [categoryFormMap,setCategoryFormMap] = useState({});


     let dateFormate = useDateFormat("DD MMM YY")
	 let settlementFormate = useDateFormat("DD-MM-YYYY")

	 let {status} = details;
	 let REMARKS=useRef(),REMARKS1=useRef(),AMOUNT=useRef(),REMARK=useRef();
	 let FORM =useRef();
	 let CUSTOMFORM =useRef([]);

	 useEffect(() => {
		fetchSettlements(id)
	}, []);

	const  getVendorDetails = () =>{
		let vendorId = details.vendor.id
		get(`vendor/contacts/${vendorId}/details`,(e,r)=>{
            if(r){
				if(r && r.config && r.config.tdsMasterIds){
					setTdsSlabs(r.config.tdsMasterIds)
				}
            }
        })
	}

	useEffect(() =>{
		if(details && details.vendor && details.vendor.id){
			getVendorDetails()
		}
	},[])

	 useEffect(()=>{
		if(status==='APPROVAL') {
			FORM.current.loadForm(`admin::invoice::category::${details.invoice.categoryId}`);
			if(tenant==="BSETEST" || tenant==="BSE") getCategoryForm()
			CUSTOMFORM.current && CUSTOMFORM.current.forEach((ref) => {
				ref.loadForm(`finance::invoice::item::${details.invoice.categoryId}`)
			})
		}
	},[status])

	useEffect(()=>{
		if(getTab===1 && status==='APPROVAL') {
			FORM.current.loadForm(`admin::invoice::category::${details.invoice.categoryId}`);
			if(tenant==="BSETEST" || tenant==="BSE") getCategoryForm()
			CUSTOMFORM.current && CUSTOMFORM.current.forEach((ref) => {
				ref.loadForm(`finance::invoice::item::${details.invoice.categoryId}`)
			})
		}
	},[getTab])

	const getCategoryForm =()=>{
			get(`forms/admin::invoice::category::${details.invoice.categoryId}`,(e,r) =>{
				if(r){
					let forms = r.forms || []
					const accountingDate = forms.find(el => el.fieldId === 'accounting_date')
					setCategoryFormMap({[accountingDate.fieldId]: Moment(new Date()).format("DD/MM/YYYY")})
				}
			})
		}
	

	const handleFormRef = (ref, index) => {
		 CUSTOMFORM.current[index] = ref 
	  };


	const updateInvoice = (field, value) => {

		let key = '';
		switch (field) {
			case 'vendor':
				post(`/vendor/invoicing/${id}/vendor.change`, {vendor: getVendor.value}, (e, r) => {
					if (r) {
						setVendor('');
						onReload()
						showToast({
							type: 'success',
							message: 'Vendor Updated'
						})
					} else if (e) {
						handleError(e);
					}
				});
				return;
			case 'id':
				key = 'invoice';
				break;
			case 'bill':
				post(`/vendor/invoicing/${id}/change`, {
					key: 'billing',
					value: String(getBillOffice.value)
				}, (e, r) => {
					if (r) {
						onReload()
						setBillOffice('');
						showToast({
							type: 'success',
							message: 'Billing Address Updated'
						})
					} else if (e) {
						handleError(e);
					}
				});
				return;
			case 'ship':
				post(`/vendor/invoicing/${id}/change`, {
					key: 'address',
					value: String(getShipOffice.value)
				}, (e, r) => {
					if (r) {
						onReload()
						setShipOffice('');
						showToast({
							type: 'success',
							message: 'Shipping Address Updated'
						})
					} else if (e) {
						handleError(e);
					}
				});
				return;
			case 'base':
				key = 'base';
				break;
			case 'tax':
				key = 'tax';
				break;
			case 'currencyExchangeRate':
				key = 'currencyExchangeRate';
				break;
			default:
				return;
		}

		post(`/vendor/invoicing/${id}/change`, {'key': key, 'value': value}, (e, r) => {
			if (r) {
				onReload()
				showToast({
					type: 'success',
					message: 'Field Updated'
				})
			} else if (e) {
				handleError(e);
			}
		});
	}

	const uploadFile = (data) => {
		post(`/vendor/invoicing/${id}/attachment`, data, (e, r) => {
			if (r) {
				onReload()
				showToast({
					type: 'success',
					message: 'File uploaded'
				})
			} else if (e) {
				handleError(e);
			}
		});
	}

	const updateLineItemCustomForm = (key, value, itemId) => {
		post(`/vendor/invoicing/${id}/change`, { key, value, itemId }, (e, r) => {
			if (r) {
				onReload()
				showToast({
					type: 'success',
					message: 'Value Updated Sucessfully'
				})
			} else if (e) {
				handleError(e);
			}
		});
	}

	const renderTags = (item, index) => {
		return (
			<div key={`Tag-${index}`} style={{display: 'flex', alignItems: 'center'}}>
				<FloatInputButton style={{width: '100%'}} onSubmit={(val)=>{updateInsight(item[0], val)}} title={item[0]} placeholder={item[1]}/>
			</div>
		)
	}

	const updateInsight = (key, val)=>{
		post(`/vendor/invoicing/${id}/change`, {'key': key, 'value': val}, (e, r) => {
			if (r) {
				onReload()
				showToast({
					type: 'success',
					message: 'Insight Updated'
				})
			} else if (e) {
				handleError(e);
			}
		});
	}
	const approveHandler = async () => {
		let data = {};
		let flag = true;
		let count = 0;
		let internalCount = 0;
		CUSTOMFORM.current && CUSTOMFORM.current.forEach((ref) => {
			flag = false;
			ref.getKeyValue(val =>{
				if(Object.keys(val).length) {
					count++;
				}
				flag = true;
				
			});
			
		})

		if(!flag) {
			showToast({
				type:'error',
				message:"Please check line item custom first"
			})
			return
		}


		if(count>0) {
			
			details.invoice.items.forEach((i,index)=>{
				let formValue = {};
				CUSTOMFORM.current && CUSTOMFORM.current[index].getKeyValue(val=>{
					formValue = {...val}
				})
				post(`vendor/invoicing/${details.invoice.id}/finance.item.form`,{"itemId": i.id,"itemForms":{...formValue}},(e,r1)=>{
					if(r1) {
						internalCount++;
						if(internalCount===count) {
							FORM.current.getKeyValue(val =>{
								data.forms = {...data.forms, ...val};
								post(`/settlements/vendor/invoices/${details.invoice.id}/approve`, data, (e,r)=>{
									if(r){
										props.onReload();
										fetchSettlements(id)
										showToast({
											type: 'success',
											message: 'Approved'
										})
									}else{
										handleError(e)
									}
								})
							})
							
						}
					}
				})
			})
		}

		


		if(flag){
			if(count===0) {
				FORM.current.getKeyValue(val =>{
					data.forms = {...data.forms, ...val};
					post(`/settlements/vendor/invoices/${details.invoice.id}/approve`, data, (e,r)=>{
						if(r){
							props.onReload();
							fetchSettlements(id)
							showToast({
								type: 'success',
								message: 'Approved'
							})
						}else{
							handleError(e)
						}
					})
				});
			}
			
		} else {
			showToast({
				type:'error',
				message:"Please check line item custom first"
			})
		}
	}

	const fetchSettlements = (ID)=>{
		get(`settlements/settlements/invoice/${ID}/detailsV2`, (e,r)=>{
			if(r){
				setSettlements(r);
				setLoading(false);
			}else if(e){
				if(e.data.message==='Settlement not found'){
					setSettlements({settlements:[],settled:false})
				}else{
					handleError(e)
				}
				setLoading(false);
			}
		})
	}

	const renderFiles = (file, index) => {
		if(file.endsWith('.pdf')){
			return <iframe onClick={()=>window.open(file,'_blank')} key={index} src={file} title='Preview' className='pointer mt1' style={{border: 'none', width:'100%', height: '100vh'}}/>
		}else{
			return <img onClick={()=>window.open(file,'_blank')} src={file} alt='Attachment' className='w-100 mt1 pointer'/>
		}
	}


	if(loading) {
		return <PageLoader />
	}
	
	return (
		<SidePane button={false} onClose={() => props.onClose(false)}
				  column={
					<div style={{ width: '100%' }}>
					<div style={{ display: 'flex' }}>
						<ToggleSwitch2 style={{ justifyContent: 'flex-start' }} checked={invoicePreview} callback={setInvoicePreview} size="S" label="View Original Invoice" />
					</div>
					 {invoicePreview ?
						<div style={{ marginTop: 10 }}>
							{details && details.invoice && details.invoice.files && details.invoice.files.map(renderFiles)}
						</div>
						:
					  <Invoice
					  disableUpdate={disableUpdate}
					  tdsSlabs={tdsSlabs}
					  tdsAtLineItem={tdsAtLineItem}
					  tdsAtLineItemInContract={tdsAtLineItemInContract}
					  tdsAtLineItemAutomation={tdsAtLineItemAutomation}
					  onReload={onReload}
					  data={{
						  vendor: details.vendor.name,
						  enableAddLineItem: enableAddLineItem && (!details.po || !details.po.length) && !disableUpdate,
						  enableLineItemPlugin: enableAddLineItem,
						  email: details.vendor.email,
						  vendor_id: details.vendor.gstin,
						  date: details.invoice.date,
						  vendorDetails: details.vendor,
						  due: details.dueOn?details.dueOn:undefined,
						  Invoice_id: details.invoice.invoiceId,
						  bill_add: details.address,
						  ship_add: details.deliverTo,
						  asn: details.asn ? details.asn : null,
						  tds: (details && details.taxInformation && details.taxInformation.tds)?details.taxInformation.tds:0,
						  table: [
							...details.invoice.items.map((item => {
								return ({
									desc: item.name?item.name:item.service,
									price: item.price,
									qtty: item.qtty,
									amount: item.amount,
									hsn: item.hsn,
									service: item.service,
									attrs: item.attrs,
									serviceDesc:item.serviceDesc||'',
									serviceAttrs:item.serviceTags||{},
									id: item.id,
									notes: item.notes ? item.notes : undefined,
									productId: item.productId,
									total: item.amount,
									tax_amt: item.tax,
									unit: item.unit,
									forms: item.data,
									tds: (item.tdsAmount || item.tdsRate) && {
										tdsAmount: item.tdsAmount ? item.tdsAmount : "-",
										tdsCode: item.tdsCode,
										tdsRate: item.tdsRate,
										tdsDesc: item.tdsDesc,
									},
								})
							}))
							],
						  currency:details.taxInformation.currency,
						  tax: details.taxInformation.tax,
						  discount:details.taxInformation.discountAmount,
						  base: details.taxInformation.base,
						  payable_amt: (details && details.taxInformation && details.taxInformation.paidAmount)?details.taxInformation.paidAmount:0,
						  total:details.taxInformation.amount,
						  baseCurrencyAmount: details.invoice.baseCurrencyAmount,
						  settlement:Settlements?Settlements.settled:true,
						  allSettlement:Settlements?Settlements.settlements:[],
						  mSettlement:Settlements?Settlements:false,
						  status:details.status,
						  invoiceStatus: details.status,
						  systemInvoiceId: details.invoice.id,
						  categoryId: details.invoice.categoryId,
						  category: details.invoice.category,
						  currencyExchangeRate: (details && details.invoice && details.invoice.currencyExchangeRate)?details.invoice.currencyExchangeRate:"",
						  contractId: details.contractId,
						  vendorId: details.vendor.id,
						  bill_to: details.address,
						  singleLine: details.tags && details.tags['inv_type'],
					  }}/>
					}
					</div>
				  }>
			<div className='FadeRight-Effect' style={{fontSize: '12px', width: '100%'}}>
				<div style={{fontWeight: 600, fontSize: '18px', lineHeight: '1.3'}}>Invoice</div>
				<div style={{
					color: 'var(--text-color)',
					marginBottom: '35px',
					fontWeight: 600,
					fontSize: '24px',
					lineHeight: '1.3'
				}}>{details.invoice.counterId}</div>

				<TabSwtich tabs={[
					{
						name: 'Format', active: getTab === 1 , onClick: () => {
							setTab(1)
						}
					},
					{
						name: 'Insight', active: getTab === 2 , onClick: () => {
							setTab(2)
						}
					},

					{
						name: 'Timeline', active: getTab === 3 , onClick: () => {
							setTab(3)
						}
					},
					{
						name: 'Settlements', active: getTab === 4 , onClick: () => {
							setTab(4)
						}
					},
					{
						name: 'Ticket', active: getTab === 6 , onClick: () => {
							setTab(5)
						}
					},
				]}/>

				{getTab === 1 ?
					<div id="Invoice_Format" className='FadeRight-Effect'>
						{
							details.status==='POHOLD'?
							<div style={{fontSize: '12px',background: '#FDF0DD', borderRadius: '6px', marginTop: '20px', padding: '20px', width: '100%', display: 'flex', alignItems: 'center'}}>
								<svg style={{marginRight:10, fontSize: '16px'}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
									<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
									<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
								</svg>
								<span>This Invoice is on hold because <b>PO Approval is pending</b>.</span>
							</div>
							:<></>
						}
						{
							details.duplicates && details.duplicates.length ?
							<>
								<div style={{ fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 15px 0px' }}>Duplicate Voucher Id</div>
								{details.duplicates.map((item) => {
									return (
										<div style={{ marginTop: '5px' }}><span style={{ color: '#666', marginRight: '3px' }}>
											<svg style={{ fontSize: '14px', marginRight: '10px', verticalAlign: 'middle', color: '#fd7e14', marginBottom: '2px' }} className="bi bi-exclamation-triangle-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
												<path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
											</svg>
											{item}</span>
										</div>
									)
								})}
							</>
							: null
						}
						<div style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Details
						</div>
						<div style={{marginTop: '5px'}}><span
							style={{color: '#666', marginRight: '3px'}}>Invoice ID:</span>{details.invoice.invoiceId}
						</div>
						<div style={{marginTop: '5px'}}><span
							style={{color: '#666', marginRight: '3px'}}>Category:</span> {details.category}</div>
						<div style={{marginTop: '5px'}}><span style={{
							color: '#666',
							marginRight: '3px'
						}}>Amount:</span> {`${details.taxInformation.currency} ${details.taxInformation.amount}`}</div>
						{enableEditableInvoiceCurrencyConversionFinance && <div style={{marginTop: '5px'}}><span style={{
							color: '#666',
							marginRight: '3px'
						}}>Base Currency Amount:</span> {`INR ${details.invoice.baseCurrencyAmount}`}</div>}
						{
							_.includes(["COMPLETED","CANCELLED","FAILED","DECLINED"],details.status)?
								<>
									<div style={{
										fontSize: '14px',
										fontWeight: 'bold',
										margin: '30px 0px 15px 0px'
									}}>Information
									</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: '#666',
										marginRight: '3px'
									}}>Vendor Name:</span> {details.vendor.name}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: '#666',
										marginRight: '3px'
									}}>Invoice Date:</span> {details.invoice.date}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: '#666',
										marginRight: '3px'
									}}>Invoice ID:</span> {details.invoice.invoiceId}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: '#666',
										marginRight: '3px'
									}}>Email:</span> {details.vendor.email}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: '#666',
										marginRight: '3px'
									}}>Billing Address:</span> {details.address.address}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: '#666',
										marginRight: '3px'
									}}>Shipping Address:</span> {details.deliverTo.address}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: '#666',
										marginRight: '3px'
									}}>Tax:</span> {details.taxInformation.tax}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: '#666',
										marginRight: '3px'
									}}>Base Amount:</span> {details.taxInformation.base}</div>
									<div style={{marginTop: '5px'}}><span style={{
										color: '#666',
										marginRight: '3px'
									}}>Amount:</span> {details.taxInformation.amount}</div>
								</>
								: <>
									<div style={{
										fontSize: '14px',
										fontWeight: 'bold',
										margin: '30px 0px 15px 0px'
									}}>Update Invoice
									</div>
									<div style={{display: 'flex', width: '100%', alignItems: 'end'}}>
										<SearchableSelect
											label="Vendor Name"
											placeholder="Select vendor"
											value={getVendor}
											reset={() => {
												setVendor('');
											}}
											select={vendorModal => {
												setVendor(vendorModal)
											}}
											add={{
												name: "Add New vendor",
												link: "/app/vendor/contacts/new"
											}}
											onChange={val => {
												if (val.length > 1) {
													get(`/vendor/contacts/search?q=${val}`, (e, r) => {
														setVendors(r.contacts.map(item => ({
															value: item.id,
															label: item.title,
															des: item.email
														})))
													})
												} else {
													setVendors([])
												}
											}}
											items={getVendors}/>
										{
											getVendor === '' ? <></> :
												<div onClick={() => {
													updateInvoice('vendor')
												}} style={{
													height: '45px',
													width: '45px',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													fontSize: '16px',
													cursor: 'pointer',
													color: 'var(--white-color)',
													backgroundColor: 'var(--primary-color)',
													zIndex: '111',
													marginBottom: '8px',
													marginLeft: '-45px'
												}}>
													<svg className="bi bi-check-lg" xmlns="http://www.w3.org/2000/svg"
														 width="1em" height="1em" fill="currentColor"
														 viewBox="0 0 16 16">
														<path
															d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
													</svg>
												</div>
										}
									</div>


									<div style={{display: 'flex', width: '100%', alignItems: 'end'}}>
										<SearchableSelect
											label="Billing Address"
											placeholder="Select Office"
											value={getBillOffice}
											reset={() => {
												setBillOffice('');
											}}
											select={office => {
												setBillOffice(office)
											}}
											onChange={q => {
												if (q.length > 0) {
													let data = {q,p2p:"yes"};
													get(`/search/v1/offices`, (e, r) => {
														if (r) {
															setOffices(r.offices.map(item => ({
																label: item.name,
																value: item.id
															})))
														}
													}, data)
												} else {
													setOffices([])
												}
											}}
											items={getOffices}/>
										{
											getBillOffice === '' ? <></> :
												<div onClick={() => {
													updateInvoice('bill')
												}} style={{
													height: '45px',
													width: '45px',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													fontSize: '16px',
													cursor: 'pointer',
													color: 'var(--white-color)',
													backgroundColor: 'var(--primary-color)',
													zIndex: '111',
													marginBottom: '8px',
													marginLeft: '-45px'
												}}>
													<svg className="bi bi-check-lg" xmlns="http://www.w3.org/2000/svg"
														 width="1em" height="1em" fill="currentColor"
														 viewBox="0 0 16 16">
														<path
															d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
													</svg>
												</div>
										}
									</div>

									<div style={{display: 'flex', width: '100%', alignItems: 'end'}}>
										<SearchableSelect
											label="Shipping Address"
											placeholder="Select Office"
											value={getShipOffice}
											reset={() => {
												setShipOffice('');
											}}
											select={office => {
												setShipOffice(office)
											}}
											onChange={q => {
												if (q.length > 0) {
													let data = {q,p2p:"yes"};
													get(`/search/v1/offices`, (e, r) => {
														if (r) {
															setOffices(r.offices.map(item => ({
																label: item.name,
																value: item.id
															})))
														}
													}, data)
												} else {
													setOffices([])
												}
											}}
											items={getOffices}/>
										{
											getShipOffice === '' ? <></> :
												<div onClick={() => {
													updateInvoice('ship')
												}} style={{
													height: '45px',
													width: '45px',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													fontSize: '16px',
													cursor: 'pointer',
													color: 'var(--white-color)',
													backgroundColor: 'var(--primary-color)',
													zIndex: '111',
													marginBottom: '8px',
													marginLeft: '-45px'
												}}>
													<svg className="bi bi-check-lg" xmlns="http://www.w3.org/2000/svg"
														 width="1em" height="1em" fill="currentColor"
														 viewBox="0 0 16 16">
														<path
															d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
													</svg>
												</div>
										}
									</div>


									<FloatInputButton onSubmit={(val) => {
										updateInvoice('id', val)
									}} title='Invoice ID' placeholder={details.invoice.invoiceId}/>

									<div style={{display: 'flex'}}>
										<div style={{width: '50%'}}><FloatInputButton onSubmit={(val) => {
											updateInvoice('base', val)
										}} title='Base Amount ' placeholder={details.taxInformation.base}/>
										</div>
										<div style={{width: '50%',paddingLeft:15}}><FloatInputButton onSubmit={(val) => {
											updateInvoice('tax', val)
										}} title='Tax Amount ' placeholder={details.taxInformation.tax}/>
										</div>
									</div>
									{enableEditableInvoiceCurrencyConversionFinance && <FloatInputButton onSubmit={(val) => {
										updateInvoice('currencyExchangeRate', val)
									}} title='Exchange Rate' placeholder={details.invoice.currencyExchangeRate}/>}
								</>
						}
						<div
							style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 15px 0px'}}>Attachment
						</div>
						<div style={{
							display: 'flex',
							marginLeft: '-15px',
							flexWrap: 'wrap',
							maxHeight: '250px',
							overflowY: 'scroll'
						}}>

							<FileUpload onUpload={uploadFile}/>
							{
								details.invoice.files.length > 0 ?
									details.invoice.files.map((item, index) => {
										return (
											<PhotoTile key={`Photo-${index}`} link={item}/>
										)
									}) : <></>
							}
						</div>
						{ status === 'APPROVAL' &&
							details.invoice.items.map((line,index) => {
								return (
								<div key={index}>
									<Form2 initialValues={line.attrs} handler={ref=>handleFormRef(ref, index)} title={line.name} service={line.service} updateForm={(key,value)=>updateLineItemCustomForm(key,value,line.id)} />
								</div>)
							})
						}

						{
							status === 'APPROVAL' ?
							<div>
								<Form initialValues={categoryFormMap} handler={ref=>FORM.current=ref} />
							</div>
							:null
						}
						
						{
							status === 'APPROVAL' ?
							<div style={{left:'calc(100% - 650px)',right:0,bottom:0,background:'#fff',borderTop:'1px solid var(--border-color)',position:'fixed',height:55,width:'100%',display:'flex',alignItems:'center',paddingLeft:10,paddingRight:10}}>
								<div onClick={approveHandler}  className='btn btn-portlet-success' style={{background:"#E4EFE7",color:'#198754',width:'100%',maxWidth:'315px'}}>
								   Approve
								</div>
								<div onClick={()=>{
									setDecline(details.invoice.id)
								}} className='btn btn-portlet-success' style={{background:"#ffdad4",color:'#ba1b1b',width:'100%',maxWidth:'315px',marginLeft:10}}>
								   Decline
								</div>
					   		</div>
							:null
						}
					</div> : <></>
				}

				{getTab === 2 ?
					<>
						<div id="Invoice_Insight" className='FadeRight-Effect'>
							<div
								style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Insights
							</div>
							{
								details.tags ? Object.entries(details.tags).map(renderTags) : <></>
							}
						</div>
					</>
					: <></>
				}

				{getTab === 3 ?
					<div id="Invoice_Timeline" className='FadeRight-Effect'>
						<div style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Timeline
						</div>
						<Track stages={
							[...details.timelines.map((log) => {

								let DESC = <div><b>{log.name}</b><br/>
									<div style={{marginTop: '5px'}}>Type: {log.type}</div>
									<div style={{ marginTop: '5px' }}>Status: {log.status}</div>
									<div style={{marginTop: '5px'}}>Sent
										on: {Moment(log.time).format(`hh:mm a ${dateFormate}`)}
										<br/>{log.actionTime ? `Approved on: ${Moment(log.actionTime).format(` hh:mm a ${dateFormate} `)}` : ''}
									</div>
								</div>

								return (
									{
										title: log.email,
										desc: DESC,
										status: log.type === 'start' ? true : log.status ? log.status === 'COMPLETED' : false
									})
							})]
						}/>
					</div> : <></>
				}{
					getTab === 4 ?
					<div id =  "Settlements" className="FadeRight-Effect">
						{
							Settlements?
							<div style={{marginTop:30}}>
								<KeyValue title="LedgerId" value={Settlements.ledgerId} />
								<KeyValue title="Created At" value={Moment(Settlements.createdAt).format(settlementFormate)} />
								<KeyValue title="Currency" value={Settlements.currency} />


							</div>
							:null
						}
						{
						Settlements?
						Settlements.settlements.length?
						<div className='FadeRight-Effect'>
							<div style={{marginTop:30}}><span style={{color: '#666', marginRight: '3px'}}>Ledger ID: </span>{Settlements.ledgerId}</div>
							<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Amount: </span>{Settlements.currency} {Settlements.amount}</div>
							<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Created On: </span>{moment(Settlements.createdAt).format('HH:MM a DD-MM-YYYY')}</div>
							<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Remarks: </span>{Settlements.attrs.remarks}</div>
							<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Virtual Payout: </span>{Settlements.attrs['virtual.payout']}</div>
							<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Reference: </span>{Settlements.attrs.reference}</div>

							<div style={{fontSize: '14px', fontWeight: 'bold',margin: '30px 0px 10px 0px', marginBottom: '20px'}}>Settlements</div>
							{
								Settlements.settlements.length?
								Settlements.settlements.map((item, index)=>{
									return (
										<div style={{padding: '10px 20px', border: '1px solid var(--border-color)',position:'relative', borderRadius: '6px', marginTop: '15px'}}>
											<div key={`info-${index}`} style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Created On: </span>{moment(item.createdAt).format('HH:MM a DD-MM-YYYY')}</div>
											<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Amount: </span>{item.currency} {item.amount}</div>
											<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Ledger ID: </span>{item.ledgerId}</div>
											<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Type: </span>{item.type}</div>
											{
												item.type==='INVOICES' || item.type==='POADVANCE'?
													<>
													<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>TDS Amount: </span>{item.tdsAmount}</div>
													<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Paid Amount: </span>{item.paidAmount}</div>
													</>
													:<></>
											}
											<div style={{marginTop: '5px'}}><span style={{color: '#666', marginRight: '3px'}}>Transferred: </span>{item.transferred?'Yes':'No'}</div>

											<div style={{position:'absolute',cursor:'pointer',right:10,top:10,color:'var(--red-color)'}}>
												{item.type==='PARTIAL' && !item.transferred ?
												 <div onClick={()=>{setRecallPartial(item)}}>Recall</div>
												 :null
												}
											</div>
										</div>
									)
								})
								:<div style={{padding: '20px', border: '1px solid var(--border-color)', borderRadius: '6px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>No Information available</div>
							}
						</div>
						:<div style={{padding: '20px', border: '1px solid var(--border-color)',marginTop:20, borderRadius: '6px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>No Information available</div>
						:<Spinner/>
						}
						{
							Settlements ?
							<div className='FadeRight-Effect'>
								{
									!Settlements.settled ?
									<>
										<div style={{fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Partial Payment</div>
										<span style={{
											fontSize:14,
											fontWeight:600,
											cursor:'pointer',
											color:'var(--primary-color)',
										}} onClick={()=>{
											setPartial(true)
										}}>+ Add Partial Payment</span>
									</>
									:
									null

								}
							</div>
							: null
						}

						{
							details.status === 'APPROVAL' && details.invoice.advances.length>0 ?

							<>
								<div style={{fontSize: '14px',marginBottom:20, fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Select Advance to settled this invoice</div>
								<NormalTable
									headers={[
										{
											key: "code",
											name: "Details",
											weight: 3,
										},
										{
											key: "amount",
											name: "Amount",
											weight: 2,
										},

									]}
                    				data={details.invoice.advances.filter(val=>val.transferred)}
                   					mapItem={(item,index)=>{
										   let active = item.ledgerId === advance;
										   return(
											   <div key={index} className="border-bottom fintrip-department-item table-item">
												   	<div className="linear-layout horizontal">
														   <div style={{flex:3}}>
														   		<div style={{
																	   display:'flex',
																	   alignItems:'center'
																   }}>
																	<div onClick={()=>{
																		setAdvance(item.ledgerId);
																	}} style={{height:15,cursor:'pointer',width:15,border:'1px solid var(--border-color)',...active?{background:'var(--success-color)',borderColor:'var(--success-color)'}:{borderColor:'var(--border-color)'}}} />
																	<div style={{marginLeft:10}}>
																		<p onClick={(e)=>{
																			e.stopPropagation();
																			setLedger(item.ledgerId);
																		}} className="top" style={{fontSize:14,cursor:'pointer'}}>{item.ledgerId}</p>
																		<p className="bottom" style={{fontSize:12,marginTop:5}}>Type : {item.type}</p>
																	</div>
																</div>
														   </div>
														   <div style={{flex:2}}>
															   {item.currency} {item.amount}
															</div>
													</div>
												</div>
										   )
									   }}
                				/>
								{advance ?
								<p style={{
								color:'var(--white-color)',
								fontSize:14,
								background:'var(--primary-color)',
								padding:"8px 20px",
								borderRadius:2,
								cursor:'pointer',
								width:150,
								textAlign:'center',
								marginTop:20
							}} onClick={()=>{
								post(`settlements/settlements/${advance}/invoice/${id}/tagV2`,{},(e,r)=>{
									if(r) {
										props.onReload();
										fetchSettlements(id);
										showToast({
											type:'success',
											message:"Advance settled successfully against invoice"
										})
									} else {
										showToast({
											type:'error',
											message:e.data.message
										})
									}
								})
							}}>Settle Invoice </p>
								:null
								}
						   </>
						   :null
						}
							{
							Settlements && Settlements.advances && Settlements.advances.length ?
							<>
								<div style={{fontSize: '14px',marginBottom:20, fontWeight: 'bold', margin: '30px 0px 10px 0px'}}>Select Advance to settled this invoice</div>
								<NormalTable
									headers={[
										{
											key: "code",
											name: "Details",
											weight: 3,
										},
										{
											key: "amount",
											name: "Amount",
											weight: 2,
										},

									]}
                    				data={Settlements.advances.filter(val=>val.transferred)}
                   					mapItem={(item,index)=>{
										   let active = item.ledgerId === advance;
										   return(
											   <div key={index} className="border-bottom fintrip-department-item table-item">
												   	<div className="linear-layout horizontal">
														   <div style={{flex:3}}>
														   		<div style={{
																	   display:'flex',
																	   alignItems:'center'
																   }}>
																	<div onClick={()=>{
																		setAdvance(item.ledgerId);
																	}} style={{height:15,cursor:'pointer',width:15,border:'1px solid var(--border-color)',...active?{background:'var(--success-color)',borderColor:'var(--success-color)'}:{borderColor:'var(--border-color)'}}} />
																	<div style={{marginLeft:10}}>
																		<p onClick={(e)=>{
																			e.stopPropagation();
																			setLedger(item.ledgerId);
																		}} className="top" style={{fontSize:14,cursor:'pointer'}}>{item.ledgerId}</p>
																		<p className="bottom" style={{fontSize:12,marginTop:5}}>Type : {item.type}</p>
																	</div>
																</div>
														   </div>
														   <div style={{flex:2}}>
															   {item.currency} {item.amount}
															</div>
													</div>
												</div>
										   )
									   }}
                				/>
								{advance ?
								<p style={{
								color:'var(--white-color)',
								fontSize:14,
								background:'var(--primary-color)',
								padding:"8px 20px",
								borderRadius:2,
								cursor:'pointer',
								width:150,
								textAlign:'center',
								marginTop:20
							}} onClick={()=>{
								post(`settlements/settlements/${advance}/invoice/${id}/tag`,{},(e,r)=>{
									if(r) {
										props.onReload();
										fetchSettlements(id);
										showToast({
											type:'success',
											message:"Advance settled successfully against invoice"
										})
									} else {
										showToast({
											type:'error',
											message:e.data.message
										})
									}
								})
							}}>Settle Invoice </p>
								:null
								}
							</>
							:null
						}

						{
							details.status === 'COMPLETED' && Settlements && !Settlements.settled?
							(details.integration && _.size(details.integration) > 0 && details.integration['postingSynced'])?null:<div style={{marginTop:10}}>

							<p style={{
								color:'var(--white-color)',
								fontSize:14,
								background:'var(--red-color)',
								padding:"5px 12px",
								borderRadius:4,
								cursor:'pointer',
								position:'absolute',
								right:100,
								top:30
							}} onClick={()=>{setRecall(true);}}>
								Recall Invoice
							</p>
							</div>
							:
							null
						}


					</div>:
					null
				}
         	    {
					getTab===5 ?
					<div className='FadeRight-Effect'>
						<SupportTicket id={id} type="vendor-invoice" />
					</div>
					:null
				}
				<div style={{height: '50px'}}/>
			</div>
			<Modal
                title='Add Partial Payment'
                des="Fill up the details"
                close={()=>{
                    setPartial(false)
                }}
                show={partial}
                onSave={()=>{
                    let amount = +AMOUNT.current.value;
                    let remarks = REMARK.current.value;

                 
                    if(!amount) {
                        showToast({
                            type:'error',
                            message:'Please enter the amount'
                        })
                        return
                    }
                    if(!remarks) {
                        showToast({
                            type:'error',
                            message:"Please enter the remarks"
                        })
                        return;
                    }
				
                  
                    let data = {
                        amount,
                        remarks,
                        currency:details.invoice.currency,

                    }

					post(`settlements/settlements/invoice/${id}/partial`,{...data},(e,r)=>{
                        if(r) {
                            setPartial(false)
                            props.onReload()
							fetchSettlements(id)
                            showToast({
                                type:'success',
                                message:"Partial payment done successfully"
                            })
                        } else {
							handleError(e)
						}
                    })

                }}
            >
                <FunctionalInput type="text" title="Amount" ref={AMOUNT} />
				 <FunctionalInput type="text" title="Remarks" ref={REMARK} />

            </Modal>

			<Modal
                title='Recall Invoice'
                des="Enter a reason to recall it"
                close={()=>{
                    setRecall(false)
                }}
                show={recall}
                onSave={()=>{
                    let remarks = REMARKS1.current.value;

                    if(!remarks) {
                        showToast({
                            type:'error',
                            message:"Please enter the remarks"
                        })
                        return;
                    }


                    let data = {
                        remarks
                    }

					post(`settlements/vendor/invoices/${id}/recall`,{...data},(e,r)=>{
                        if(r) {
                            setRecall(false)
							fetchSettlements(id)
                            props.onReload()
							setTab(1)
                            showToast({
                                type:'success',
                                message:"Invoice Recall  successfully"
                            })
                        } else {
							handleError(e)
						}
                    })

                }}
            >
                <FunctionalInput type="text" title="Remarks" ref={REMARKS1} />

            </Modal>

			<Modal
                title='Recall Partial Payment'
                des="Enter a reason to recall it"
                close={()=>{
                    setRecallPartial(false)
                }}
                show={recallPartial}
                onSave={()=>{
                    let remarks = REMARKS.current.value;

                    if(!remarks) {
                        showToast({
                            type:'error',
                            message:"Please enter the remarks"
                        })
                        return;
                    }


                    let data = {
                        remarks,
                    }

					post(`settlements/settlements/${recallPartial.id}/cancel`,{...data},(e,r)=>{
                        if(r) {
                            setRecallPartial(false)
							fetchSettlements(id)
                            props.onReload()
							setTab(1)
                            showToast({
                                type:'success',
                                message:"Partial Payment Recall  successfully"
                            })
                        } else {
							handleError(e)
						}
                    })

                }}
            >
                <FunctionalInput type="text" title="Remarks" ref={REMARKS} />

            </Modal>

			{
				ledger ?
				<ViewLedger
					id={ledger}
					onClose={()=>{
						setLedger(false)
					}}
				/>
				:null
			}

			{
				decline && decline!== 0 ? <DropdownModal
					title="Decline Invoice"
					button="Decline"
					close={() => {
						setDecline(undefined)
					}}
					dropdown="vendor-decline"
					onNext={val => {
						axios.delete(`${getBaseUrl()}/settlements/vendor/invoices/${decline}?remarks=${val}`, {
							headers: {
								"content-type": "text/html"
							},
						}).then(s => {
							showToast({
								type: 'success',
								message: 'Invoice declined successfully'
							})
							setDecline(undefined)
							props.onReload();
						}).catch(handleError)
					}}
				/> : null}
		</SidePane>
	)

}
