import React from 'react'
import { Select } from '../Class/Select'

const SelectGST = ({defaultValue,onSelect,placeholder,disabled=false}) => {
    return (
        <Select
            value={defaultValue}
            items={[
                {
                    value: 0,
                    label: 0,
                },
                {
                    value: 3,
                    label: 3,
                },
                {
                    value: 5,
                    label: 5,
                },
                {
                    value: 9,
                    label: 9,
                },
                {
                    value: 12,
                    label: 12,
                },
                {
                    value: 18,
                    label: 18,
                },
                {
                    value: 28,
                    label: 28,
                },
            ]}
            disabled={disabled}
            placeholder={placeholder?placeholder:"Select Tax"}
            select={onSelect}
        />
    )
}

export default SelectGST