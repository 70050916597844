import React from "react";

export const OcrTableContainer = ({ details = {}, onClick }) => {
    const { headerRawData, invoiceItems, lineItemRawData, poNumbers, isMultiLineInvoice, success, originalOcr, ...rest } = details
    const entries = Object.entries(rest);
    const visibleEntries = entries.slice(0, 8)
    
    if (!Object.keys(rest).length > 0) return <></>
    return (
        <div style={containerStyle}>
            <table style={tableStyle}>
                <thead>
                    <tr>
                        <th style={tableHeaderStyle}>Key</th>
                        <th style={tableHeaderStyle}>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {visibleEntries.map(([key, value]) => (
                        <tr key={key} style={tableRowStyle}>
                            <td style={tableCellStyle}>{key}</td>
                            <td style={tableCellStyle}>{value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button
                onClick={() => onClick(details)}
                style={buttonStyle}
            >
                {"See All"}
            </button>
        </div>
    );
};

const containerStyle = {
    width: '100%',
    maxWidth: '600px',
    margin: 'auto',
    border: '1px solid #ddd',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: '#fff',
    marginBottom: 10
};

const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
};

const tableHeaderStyle = {
    backgroundColor: '#f5f5f5',
    padding: '12px',
    borderBottom: '2px solid #ddd',
    textAlign: 'left',
    fontWeight: 'bold',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
};

const tableRowStyle = {
    borderBottom: '1px solid #ddd',
};

const tableCellStyle = {
    padding: '10px',
    textAlign: 'left',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
};

const buttonStyle = {
    padding: '8px 12px',
    backgroundColor: 'rgb(75 75 75)',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '500',
    width: '100%',
    textAlign: 'center',
};