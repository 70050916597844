import moment from 'moment';
import React, {useState} from 'react';
import { numberWithCommas } from '../../../../../Utils/NumberToComma';
import './fInvoice.css'
import LineItemModal from '../../ViewInvoice/India/LineItemModal';
import { post } from '../../../../../Model/Network/Config/Axios';
import { handleError, showToast } from '../../../../Common/fToast/ToastCallback';

function Invoice(props){
    let {debitNote,total_amt,sgst,igst,total_tax,cgst,total_base,invoice_amount,roundOff,penalty,discountAmount,tds,status,categoryId,category,vendorID,tdsAtLineItem,isIndependentCreditNote,vendorAddress,vendorGstin} = props.data;

    const [showCustomFormId, setShowCustomFormId] = useState(false);
    const [edit, setEdit] = useState(undefined)

    const handleLineItemUpdate = (reqBody) => {
        if(reqBody.tds){
            reqBody = {...reqBody, ...reqBody.tds}
            delete reqBody.tds;
        }
        post( `vendor/vendor/creditNote/${props.CreditNoteId}/lineItem.update`,reqBody,(e,r)=>{
            if(r){
                props.reload();
                showToast({type:'success',message:'LineItem Updated SucessFully'})
                setEdit();
            }else{
                handleError(e);
            }
        })
    }

    const findLabelFromKey=(formData,key)=>{
        if(!formData.length)    return key;
        const obj=formData.find((item)=>item.id===key);
        if(!obj)    return key;
        return obj.key;
    }
    const rednerTable = (item, index)=>{
        return(
            <div key={`entry-${index}`} style={{display: 'flex',alignItems:'center', justifyContent: 'space-evenly', margin: '15px 0px'}}>
                <div style={{width: '160px'}}>{item.desc}<br/>Code: {item.hsn} <br /> Service : {item.service} 
                {item.tds && (
                    <div>
                        tdsAmount : {item.tdsAmount?item.tdsAmount.toFixed(2):''} <br></br>
                        tdsCode : {item.tdsCode} <br></br>
                        tdsRate : {item.tdsRate} <br></br>
                        tds Desc : {(item && item.tdsDesc) || '-'} <br></br>
                    </div>
                )}
                {(item && item.glCode)?
                    <div>
                        GL Code : {item.glCode} <br></br>
                    </div>
                :null}
                <br/>
                {item.forms && item.forms.length > 0 ? (
					<div>
				      <div 
					     style={{paddingTop:5,cursor: 'pointer'}}
					      onClick={() => setShowCustomFormId(showCustomFormId && showCustomFormId === item.id ? false : item.id)}>
						<h4>
						{showCustomFormId === item.id ? 'Hide': 'Show'}Custom form</h4>
				    	</div> <br />
					  {showCustomFormId === item.id &&
						item.forms.map(([key,value]) => (
						<div style={{ flexDirection: 'column' }} key={key}>
            	       	    <p style={{fontWeight:250,color:'grey'}}> {findLabelFromKey(item.formData,key)}: <span style={{fontWeight:500,color:'black'}}>{value}</span></p>
						 <br />
						</div>
						))}
					</div>
				    ) : null}
                <br></br></div>
                <div style={{width: '100px',}}> {item.price ?numberWithCommas(item.price.toFixed(2)):item.price}</div>
                <div style={{width: '100px',}}> {item.original_qtty.toFixed(2)}</div>
                {debitNote?<div style={{width: '100px',}}> {item.debitQty}</div>:null}
                <div style={{width: '100px',}}> {item.base_amt ?numberWithCommas(item.base_amt.toFixed(2)):item.base_amt}</div>
                <div style={{width: '100px'}}> {item.cessAmount ?numberWithCommas(item.cessAmount.toFixed(2)):0}</div>
                <div style={{width: '100px'}}> {item.tax_amt ?numberWithCommas(item.tax_amt.toFixed(2)):numberWithCommas(item.tax_amt)}</div>
                <div style={{width: '100px', textAlign: 'right'}}> {item.amount ?numberWithCommas(item.amount.toFixed(2)):numberWithCommas(item.amount)}</div>
                {isIndependentCreditNote && status === 'APPROVAL' && <div style={{ width: '100px', textAlign: 'right' }}>
                    <span style={{ padding: 5, color: 'var(--primary-color)', cursor: 'pointer' }}
                        onClick={() => setEdit(item)}>
                        EDIT
                    </span>
                </div>}
            </div>
        )
    }
    
    let partial = 0;

    props.data.allSettlement.forEach(val=>{
         if(val.transferred) {
            partial = partial+val.amount
         }
    })


 return(
        <div className='FadeRight-Effect' style={{width: '100%', fontSize: '12px'}}>
        <div style={{textAlign: 'center', fontSize: '22px', fontWeight: 'bold'}}>View Credit Note</div>
            {
              
                props.data.discount?
                    <p style={{
                        top:10,
                        left:0,
                        padding:'12px 30px',
                        position:'absolute',
                        background:"green",
                        color:'var(--white-color)',
                        fontSize:11
                    }}>
                        Discounted at {props.data.discount} %
                    </p>:undefined
            }
            {
              
              props.data.rcVendor?
                  <p style={{
                      top:40,
                      left:0,
                      padding:'12px 30px',
                      position:'absolute',
                      background:'var(--primary-color)',
                      color:'var(--white-color)',
                      fontSize:11
                  }}>
                      RCM Invoice
                  </p>:undefined
          }
        <div style={{border: '1px solid var(--border-color)', padding: '20px', borderRadius: '15px', marginTop: '30px'}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <b>Vendor</b><br/>
                    Name: {props.data.vendor}<br/>
                    TradeName: {props.data.tradeName||'NA'}<br/>
                    Vendor Code : {props.data.vendor_code} <br/>
                    Email: {props.data.email||"NA"}<br/>
                    GSTIN: {vendorGstin?vendorGstin:props.data.vendor_id||'NA'}<br/>
                    Address: {vendorAddress && vendorAddress.address} <br></br>
                    { vendorAddress && vendorAddress.state} {vendorAddress && vendorAddress.pincode} <br></br>
                </div>
                <div style={{textAlign: 'right'}}>
                Date: {moment(props.data.date).format('DD-MM-YYYY')}<br/>
                {props.data.due?<>Due Date: {moment(props.data.due).format('DD-MM-YYYY')}<br/></>:<></>}
                Credit Note Number : {props.data.bill_number}<br/>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
                <div style={{paddingRight: '20px'}}>
                    <b>Billing Address</b><br/>
                    {props.data.bill_to.name?<span>{props.data.bill_to.name}<br></br></span>:null}
                    {props.data.bill_to.title?<span>{props.data.bill_to.title}<br></br></span>:null}
                    {props.data.bill_to.registeredName?<span>{props.data.bill_to.registeredName}<br></br></span>:null}
                    {props.data.bill_add}<br/>
                    {props.data.bill_to.gstin?<span>{props.data.bill_to.gstin}<br></br></span>:null}

                </div>
                <div style={{textAlign: 'right', paddingLeft: '20px'}}>
                    <b>Shipping Address</b><br/>
                    {props.data.ship_to.name?<span>{props.data.ship_to.name}<br></br></span>:null}
                    {props.data.ship_to.title?<span>{props.data.ship_to.title}<br></br></span>:null}
                    {props.data.ship_to.registeredName?<span>{props.data.ship_to.registeredName}<br></br></span>:null}
                    {props.data.ship_add}<br></br>
                    {props.data.ship_to.gstin?<span>{props.data.ship_to.gstin}<br></br></span>:null}
                </div>
            </div>
        </div>

        <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: '30px', fontWeight: 'bold', paddingBottom: '10px', borderBottom: '1px solid var(--border-color)'}}>
            <div style={{width: '160px'}}>Desciption</div>
            <div style={{width: '100px',}}>Price/Per Unit</div>
            <div style={{width: '100px',}}>Qty</div>
            {debitNote?<div style={{width: '100px',}}>Debit Qty</div>:null}
            <div style={{width: '100px',}}>Base Amt</div>
            <div style={{width: '100px',}}>Cess Amt</div>
            <div style={{width: '100px'}}>Tax Amt</div>
            <div style={{width: '100px', textAlign: 'right'}}>Amount (INR)</div>
            {isIndependentCreditNote && <div style={{width: '100px', textAlign: 'right'}}>Action</div>}

        </div>

       {
           props.data.table.map(rednerTable)
       }
        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>SGST / UTGST: </div>
                <div> {numberWithCommas(sgst.toFixed(3))}</div>
            </div>
        </div>
        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>CGST: </div>
                <div> {numberWithCommas(cgst.toFixed(3))}</div>
            </div>
        </div>
        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>IGST: </div>
                <div> {igst ?numberWithCommas(igst.toFixed(2)):numberWithCommas(igst)}</div>
            </div>
        </div>

        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>Base Amt: </div>
                <div> {numberWithCommas(total_base.toFixed(2))}</div>
            </div>
        </div>
       {roundOff? 
        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>RoundOff: </div>
                <div> {numberWithCommas(roundOff.toFixed(2))}</div>
            </div>
        </div>:null
        }
         <div className='invoice-taxes'>
            <div className='wrap'>
                <div>Discount: </div>
                <div> {numberWithCommas(discountAmount.toFixed(2))}</div>
            </div>
        </div>
         <div className='invoice-taxes'>
            <div className='wrap'>
                <div>Penalty: </div>
                <div> {numberWithCommas(penalty.toFixed(2))}</div>
            </div>
        </div>
        <div className='invoice-taxes' style={{fontWeight: 'bold'}}>
            <div className='wrap'>
                <div>Tax Amt: </div>
                <div> {numberWithCommas(total_tax.toFixed(2))}</div>
            </div>
        </div>
        <div className='invoice-taxes' style={{fontWeight: 'bold'}}>
            <div className='wrap'>
                <div>TDS: </div>
                <div> {numberWithCommas(tds)}</div>
            </div>
        </div>
       

       
       

        <div style={{display: 'flex', padding: '10px 0px', fontWeight: 'bold', borderTop: '1px solid var(--border-color)', borderBottom: '1px solid var(--border-color)'}}>
            <div style={{flexGrow: 1}}>Total Credit Amount: </div>
            <div style={{width: '100px', textAlign: 'right'}}> {numberWithCommas((total_amt+penalty-tds).toFixed(2))}</div>
        </div>

        {invoice_amount && 
            <div style={{ display: 'flex', padding: '10px 0px', fontWeight: 'bold', borderTop: '1px solid var(--border-color)', borderBottom: '1px solid var(--border-color)' }}>
                <div style={{ flexGrow: 1 }}>Original Invoice Payable Amount: </div>
                <div style={{ width: '100px', textAlign: 'right' }}> {numberWithCommas((invoice_amount).toFixed(2))}</div>
            </div>
        }
        <div style={{ display: 'flex', padding: '20px 0px', fontWeight: 'bold'}}/>
         {
             edit &&
             <LineItemModal
                 edit={edit}
                 categoryId={categoryId}
                 onSave={handleLineItemUpdate}
                 tdsAtLineItem={tdsAtLineItem}
                 tdsAtLineItemInContract={props.tdsAtLineItemInContract}
                 vendorId={vendorID}
                 close={() => setEdit(undefined)}
                 category={category}
                 contractId={props.data.contractId}
                 isCreditNote={true}
                 tdsSlabs={(props && props.tdsSlabs)?props.tdsSlabs:undefined}
                 vendor={(props && props.vendor)?props.vendor:undefined}
             />
         }
       
    </div>
 )
}



export default Invoice;
