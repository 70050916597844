import React, {useState, forwardRef, useImperativeHandle} from 'react';
import Props from 'prop-types';
import { SearchOffice2 } from '../../../../../Common/fSubmitInvoice/common/SearchOffice';


const SearchOffice = forwardRef((_, ref) => {

    const [value, setValue] = useState('');


    useImperativeHandle(ref, () => ({
        getValue: () => {
            return value;
        }
    }));

    return (
        <>
            <SearchOffice2 disabled={_.disabled} office={value} title="Search Office" onSelect={setValue}  />
        </>
    )
})


SearchOffice.propsTypes = {
    type: Props.string
}

SearchOffice.defaultProps = {
    type: 'text',
    placeholder: 'Enter the Text',
}


export default SearchOffice ;
