import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FloatSelect } from '../../../../Common/Class/FloatLabelInput';
import { FunctionalInput } from '../../../../Common/fInputs';
import { Modal } from '../../../../Common/fModal/Modal';
import { showToast } from '../../../../Common/fToast/ToastCallback';
import SearchEntity from '../../Submit/MultiSubmitInvoice/CostAllocation/SearchEntity';
import SearchOffice from '../../Submit/MultiSubmitInvoice/CostAllocation/SearchOffice';
import SearchFilterLayer from '../../Submit/MultiSubmitInvoice/CostAllocation/SearchFilterLayer';
import { get } from '../../../../../Model/Network/Config/Axios';


const CostAllocation = forwardRef(({viewOnly=false,amount,reference,hide,gstin=undefined,category=undefined},ref) => {

    const [totalAmount, setTotalAmount] = useState();
    const [prefillData, setPrefillData] = useState([]);
    const cType = useSelector(state=>state.auth.config.costAllocationType);
    const [type,setType] = useState();
    const [edit,setEdit] = useState(false);
    const [items,setItems] = useState([]);
    const [nonEditableItems, setNonEditableItems] = useState([]);
    const [filterLayer, setFilterLayer] = useState(undefined);
    const [allocationData, setAllocationData] = useState();

    let AMOUNT = useRef(),PERCENTAGE=useRef(),LOCATION=useRef(),FILTER_LAYER= useRef();
    let {costAllocation} = useSelector(state=>state.auth.config.plugins);

    let INIT = () => {
        get(`vendor/costAllocation/getAllocations?referenceType=vendor-invoice&referenceId=${reference}`,(e,r)=>{
            if(r) {
                setPrefillData(r);
            }
        })
    }

    let fetchExistingData=()=>{
        get(`vendor/costAllocation/getAllocations`,(e,r)=>{
            if(r){
                setAllocationData(r);
            }
        },{
            referenceType:"credit-note",
            referenceId:`${viewOnly}`
        })
    }
    
    useEffect(()=>{ 
        
        if(prefillData && prefillData.data){
            setType({key:prefillData.data.baseType,value:prefillData.data.baseType});
            setItems([...prefillData.data.allocations.map((e,index)=>({...e,index}))])
            setNonEditableItems([...prefillData.data.allocations.map((e,index)=>({...e,index}))])
            setTotalAmount(prefillData.data.totalAllocatedAmount);
        }
    },[prefillData])

    useEffect(()=>{
        if(allocationData && allocationData.data){
            setType(allocationData.data.baseType);
            setItems([...allocationData.data.allocations])
            setTotalAmount(allocationData.data.totalAllocatedAmount);
        }
    },[allocationData])

    useEffect(()=>{
        if(amount && !isNaN(amount)){
            setTotalAmount(amount);
            const updatedItems=items.map((item)=>{
                return {...item,amount:(item.ratio*amount/100)};
            })
            setItems([...updatedItems]);
        }
    },[amount])

    useEffect(()=>{
        if(!viewOnly){
            INIT();
        }else{
            fetchExistingData();
        }
    },[]);


    useImperativeHandle(ref,()=>({
        getValue:()=>{

            let totalPercentage = items.reduce((acc, item) => acc + item.ratio, 0);
            let dummyPercentage = items.reduce((acc, item) => +parseFloat(acc + item.ratio).toFixed(2), 0);
    
 
            let tAmount = items.reduce((acc, item) => acc + item.amount, 0);
            let dummyAmount = 0
            items.forEach(i=>{
                let sItem = +(i.amount).toFixed(3);
                dummyAmount += sItem;
            })
            dummyAmount = +(dummyAmount).toFixed(3)


            tAmount = Number(tAmount.toFixed(3));
            setTotalAmount(Number(totalAmount.toFixed(3)));
            totalPercentage = Number(totalPercentage.toFixed(2));

            if(!costAllocation) {
                return true;
            }
            
            if(items.length===0) {
                return true;
            } 


            if(totalPercentage!==100.00) {
                showToast({
                    type:'error',
                    message:'Combine cost allocation must be 100%'
                })
                return false;
            }
            
            if (+Math.abs(dummyPercentage - 100).toFixed(2) === 0.01) {

                let isGreaterError = +Math.abs(dummyAmount - totalAmount).toFixed(2) === 0.01


                const adjustedItems = items.map((item,index) => {
                    if(index===0) {
                        return {
                            ...item,
                            amount: isGreaterError ?  +(item.amount-0.01).toFixed(2): +(item.amount).toFixed(2),
                            ratio: +(item.ratio-0.01).toFixed(2)
                        }
                    } else {
                        return {
                            ...item,
                            amount:+(item.amount).toFixed(2),
                            ratio:+(item.ratio).toFixed(2)
                        }
                    }}
            
                  );

                  return {
                    costAllocationBaseType: type.key,
                    costAllocationAmount: Number(parseFloat(totalAmount).toFixed(2)),
                    costAllocations: adjustedItems,
                  };
              }

              if (+Math.abs(dummyAmount - totalAmount).toFixed(2) === 0.01) {              

                const adjustedItems = items.map((item,index) => {
                    if(index===0) {
                        return {
                            ...item,
                            amount: +(item.amount-0.01).toFixed(2),
                            ratio: +(item.ratio).toFixed(2)
                        }
                    } else {
                        return {
                            ...item,
                            amount:+(item.amount).toFixed(2),
                            ratio:+(item.ratio).toFixed(2)
                        }
                    }}
                  );

                return {
                  costAllocationBaseType: type.key,
                  costAllocationAmount: Number(parseFloat(totalAmount).toFixed(3)),
                  costAllocations: adjustedItems,
                };
              }


              if(tAmount!==totalAmount) {
                showToast({
                    type:'error',
                    message:'Combine amount value must be equal to totalAmount of invoice'
                })
                return false;
            }


            return {
                    costAllocationBaseType:type.key,
                    costAllocationAmount:Number(parseFloat(totalAmount).toFixed(2)),
                    costAllocations:[...items.map(item=>({
                        ...item,
                        amount:+item.amount.toFixed(3),
                        ratio:+item.ratio.toFixed(2)
                    }))]
            }
        }
    }))

    if(!costAllocation || (!viewOnly && !items.length)) {
        return <></>
    }

    if(viewOnly && !allocationData){
        return <></>
    }

    return(
        <div style={{marginBottom:30,
            ...hide ?
            {
                display:'none'
            }
            :
            {}
        }}>
            <div style={{fontSize: '14px', fontWeight: 'bold', margin: '10px 0px'}}>Cost Allocation For Credit Note</div>   
            {
                items.length>0 ?
                <>
                    <div style={{height:40,background:'#dedede',border:'1px solid var(--border-color)',display:'flex',borderTopLeftRadius:4,borderTopRightRadius:4}}>
                    {
                     [
                        {name:'location',flex:1,style:{fontWeight:'bold',borderRight:'1px solid var(--border-color)'}},
                        {name:'Percentage',flex:1,style:{fontWeight:'bold',borderRight:'1px solid var(--border-color)'}},
                        {name:'Amount',flex:1,style:{fontWeight:'bold',borderRight:'1px solid var(--border-color)'}},
                        ...(!viewOnly ? [{name:'Action',flex:1,style:{fontWeight:'bold',borderRight:'1px solid var(--border-color)'}}] : []),
                     ]
                     .map((item,index)=>{
                        return(
                            <div key={index} style={{flex:item.flex,...item.style,height:40,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                {item.name}
                            </div>
                        )
                     })
                     } 
                   </div>
                   { 
                    items.map((item,index)=>{
                        return(
                            <div key={index} style={{height:40,border:'1px solid var(--border-color)',display:'flex',flex:1}}>
                                <div style={{height:40,display:'flex',borderRight:'1px solid var(--border-color)',justifyContent:'center',alignItems:'center',flex:1}}>{item.location}</div>
                                <div style={{height:40,display:'flex',borderRight:'1px solid var(--border-color)',justifyContent:'center',alignItems:'center',flex:1}}>{item.ratio?item.ratio.toFixed(2):item.ratio}</div>
                                <div style={{height:40,display:'flex',borderRight:'1px solid var(--border-color)',justifyContent:'center',alignItems:'center',flex:1}}>{item.amount?item.amount.toFixed(3):item.amount}</div>
                                {!viewOnly && <div style={{height:40,display:'flex',borderRight:'1px solid var(--border-color)',justifyContent:'center',alignItems:'center',flex:1}}> 
                                    <p style={{width:40,fontSize:'11px',height:'20px',padding:0,margin:0,alignContent:'center',borderRadius:'10px'}} onClick={()=>setEdit({...item,index})} className='btn btn-portlet btn-primary' >
                                        Edit 
                                    </p>
                                </div>}
                            </div>  
                        )
                    })
                   }
                </>
                :null
            }

            {
                edit ?
                <Modal
                    title="Edit Item"
                    show={true}
                    buttonName="Edit"
                    style={{
                        save:{
                            display:'flex',
                            height:40,
                            alignItems:'center',
                            justifyContent:'center',
                            borderRadius:4
                        }
                    }}
                    close={()=>{
                        setEdit(false);
                    }}
                    onSave={()=>{
                        let percentage = PERCENTAGE.current.value;
                        let amount = AMOUNT.current.value;

                        if(!percentage && !amount) {
                            showToast({
                                type:'error',
                                message:"Please enter the percentage or amount"
                            })
                            return;
                        }
            

                        const updatedItems=items.map((item,index)=>{
                            if(index===edit.index){
                                return {
                                    ...item,
                                    ratio:+parseFloat(percentage || amount / totalAmount * 100).toFixed(2),
                                    amount: +parseFloat(amount || totalAmount * percentage / 100).toFixed(3),
                                }
                            }
                            return item;
                        })

                        let sItems = [...updatedItems];
                        setItems(sItems);
                        setEdit(false);
                    }}
                >
                     <SearchFilterLayer 
                        disabled={true}
                        ref={FILTER_LAYER} 
                        onSelect={setFilterLayer}
                        filterLayer={filterLayer}
                     />
                    <FloatSelect
                        disabled={true}
                        title="Select Type"
                        blue="** All cost allocation define on the type you select on the first time."
                        items={[
                            {key:cType,value:cType},
                            {key:'office',value:'Office'},
                            {key:'dept',value:'Department'},
                            {key:'team',value:'Team'},
                        ]}
                        onChange={(value)=>{
                            setType({
                                key:value,
                                value
                            })
                        }}
                        value={type}
                    />

                    {
                        type.key==='office' ?
                        <SearchOffice disabled={true}  ref={LOCATION} /> :
                        type.key === cType ?
                        <SearchEntity  disabled={true} category={category} gstin={gstin} ref={LOCATION}  extra={filterLayer ? {data:filterLayer}:{}} />
                        :null
                    }

                    <div style={{display:'flex',flex:1}}>
                         <FunctionalInput
                            disabled={viewOnly}
                            type="number" 
                            title="Percentage" 
                            placeholder="Enter the percentage" 
                            ref={PERCENTAGE} 
                            defaultValue={edit.ratio}
                            style={{marginRight:20}}
                            onChange={(e)=>{
                                let value = +e.target.value;
                                 if(value>100) {
                                    PERCENTAGE.current.value = 0;
                                    showToast({
                                        type:'error',
                                        message:'Percentage value not more than 100%'
                                    })
                                    return;
                                 }
                                let currentAmount = (value*totalAmount)/100
                                AMOUNT.current.value = currentAmount;
                             }}
                         />
                          <FunctionalInput 
                            disabled={viewOnly}
                            defaultValue={edit.amount}
                            type="number" 
                            title="Amount"
                            placeholder="Enter the amount" 
                            ref={AMOUNT} 
                            onChange={(e)=>{
                                let value = +e.target.value;
                                if(value>nonEditableItems[edit.index].amount){
                                    showToast({
                                        type:'error',
                                        message:`Amount cannot be more than ${nonEditableItems[edit.index].amount}`
                                    })
                                    AMOUNT.current.value = edit.amount;
                                    PERCENTAGE.current.value = edit.ratio;
                                    return;
                                }
                                let currentPercentage = ((parseInt(value)*100)/(totalAmount));
                                PERCENTAGE.current.value = currentPercentage;
                            }}
                        /> 
                    </div>
                </Modal>
                :null
            }
        </div>
    )
})

CostAllocation.defaultProps = {
    prevItems:[],
    costAllocationType:undefined,
    hide:false
}


export {CostAllocation}