import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {useParams} from 'react-router-dom';
import { HalfModal } from '../../../../../Common/fModal/HalfModal';
import { PDFExport } from '@progress/kendo-react-pdf';
import PhotoTile from '../../../../../Common/fPhoto';
import Empty from '../../../../../Common/fCard/Empty';
import { get } from '../../../../../../Model/Network/Config/Axios';
import SubmitInvoice from '../../../../fCommon/Submit/MultiSubmitInvoice';
import Moment from 'moment'
import { numberWithCommas } from '../../../../../../Utils/NumberToComma';


const Index = ({renewalItem,setRenewal,setRenewalItem,viewGrn,close,renewalTracking,conditionalPo,productTaxMapping={},...props}) => {

    let PDF = useRef();
    const [condition,setCondition] = useState([]);
    const [total,setTotal] = useState(0);
    const [conditionSubmit,setConditionSubmit] = useState([]);
    const [grnSubmit,setGrnSubmit] = useState(false);
    const [items,setItems] = useState([]);
    const [itemsObject,setItemsObject] =useState({});
    const [tax,setTax] = useState(0);
    let {id} = useParams();

    useEffect(()=>{
        if(viewGrn) {
            let sItems = [];
            let sObject = {};
            let totalAmt = 0;
            let tax=0;
            viewGrn.items.forEach(i=>{
                console.log(i);
                sItems.push(i.id)
                sObject[i.id] = i;
                let taxSlab=0
                if(productTaxMapping[i.id]){
                    taxSlab=productTaxMapping[i.id];
                    tax+=((i.price*i.qtty)*(taxSlab/100))
                }
                totalAmt += (Number(i.qtty)*Number(i.price))
            })
            setTax(tax);
            setTotal(totalAmt)
            setItems(sItems)
            setItemsObject(sObject);
        }
    },[viewGrn])

    useEffect(()=>{
        if(conditionalPo) {
            get(`vendor/procurement/poCondition/getAllPoItemConditionGrn?offset=0`,(e,r)=>{
                if(r) {
                    setCondition(r.poConditionGrnList)
                }
            },{
                filter:`${viewGrn.id}@t0.main_grn_id`
            })
        }
    },[conditionalPo])

    let conditionMapping = () => {

        return(
            <div style={{
                marginTop:10
            }}>
                <div style={{
                    display:'flex',
                    alignItems:'center',
                    border:'1px solid var(--border-color)'
                }}>
                    {
                        [
                            {
                                name:'',
                                width:30
                            },
                            {
                                name: "Product Name",
                                flex: 1
                            },
                            {
                                name:'Vendor',
                                flex:2
                            },
                            {
                                name: "Quantity",
                                flex: 1
                            },
                            {
                                name: "Procured",
                                flex: 1
                            },
                            {
                                name:'Action',
                                flex:0.5
                            }
                           
                        ].map((item,i)=>{
                            return(
                                <div key={i} style={{
                                    borderLeft:'1px solid var(--border-color)',
                                    ...item.width ? {
                                        width: item.width
                                    } : {
                                            flex: item.flex
                                        }
                                }}>
                                    <p style={{
                                        padding: 8,
                                        fontSize: 13,
                                        color: "var(--text-color)"
                                    }}>
                                        {item.name}
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    condition.map((item,j)=>{
                        return(
                            <div key={j} style={{
                                display:'flex',
                                alignItems:'center',
                                border:'1px solid var(--border-color)',
                                height:45,
                                fontSize:14
                            }}> 
                                <div style={{
                                    width:30,
                                    height:45,
                                    borderRight:'1px solid var(--border-color)'
                                }} className='flex flex-v-center'>
                                    
                                </div>
                                <div style={{
                                    flex:1,
                                    borderRight:'1px solid var(--border-color)',
                                    height:45,

                                }} className='flex flex-v-center'>
                                    <p style={{paddingLeft:8}}>
                                        {item.title}
                                    </p>
                                </div>
                                <div style={{
                                    flex:2,
                                    borderRight:'1px solid var(--border-color)',
                                    height:45,

                                }} className='flex flex-v-center'>
                                    <p style={{paddingLeft:8}}>
                                        {item.vendorName}
                                     </p>
                                </div>

                                <div style={{
                                    flex:1,
                                    borderRight:'1px solid var(--border-color)',
                                    height:45,

                                }} className='flex flex-v-center'>
                                    <p style={{paddingLeft:8}}>
                                        {item.qty} /{item.units}
                                    </p>
                                </div>

                                <div style={{
                                    flex:1,
                                    borderRight:'1px solid var(--border-color)',
                                    height:45,

                                }} className='flex flex-v-center'>
                                    <p style={{
                                        paddingLeft:8
                                    }}>
                                        {item.approvedQty} /{item.units}
                                    </p>
                                </div>

                                <div style={{
                                    flex:0.5
                                }} onClick={()=>{
                                    setConditionSubmit([item])
                                }}>
                                    <p style={{
                                        paddingLeft:8,
                                        fontSize:14,
                                        fontWeight:'bold',
                                        color:'var(--primary-color)',
                                        cursor:'pointer'
                                    }}>Submit</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    
    return(
       <>
        <HalfModal
            title={viewGrn.reference}
            desc="All grn details"
            container={{
                 left: "30%"
            }}
             close={close}
            >
             <div style={{display:'flex',justifyContent:'flex-end'}}>
                  {
                    conditionalPo ?
                    <>
                    </>
                    :
                    <div onClick={()=>{
                        PDF.current.save();
                    }} className='btn btn-portlet' style={{width:140}}>Download Pdf</div>
                  }
                  
                    
                    {
                         renewalItem ?
                             <div className="btn btn-portlet" style={{width:140,marginLeft:10}} onClick={() => {
                                setRenewal(renewalItem);
                            }}>
                                 Renewal Item
                            </div>
                         : null
                    }
                  </div>
                        <PDFExport margin={20} fileName={`${viewGrn.id}.pdf`}  ref={PDF}>
                        <p> PO Number: {props.data.orderId}</p> <br />
                        <p> GRN ID: {props.data.grns[0].grnId||'-'}</p> <br />
                        <div style={{
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'space-between'
                        }}>
                            <div style={{fontSize:14, fontWeight:'bold'}}>All Items</div>
                            {
                                conditionalPo ?
                                <div style={{
                                    fontSize:14,
                                    color:'var(--primary-color)',
                                    cursor:'pointer'
                                }} onClick={()=>{
                                    setGrnSubmit(true);
                                }}>Submit</div>
                                :null

                            }
                           
                        </div>
                        <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    border: '1px solid var(--border-color)',
                                    marginTop:20
                                }}>
                                    {
                                        [
                                            {
                                                name: "Product Name",
                                                flex: 1
                                            },
                                            {
                                                name: "Delivery Address",
                                                flex: 1
                                            },
                                            {
                                                name: "Order Quantity",
                                                flex: 1
                                            },
                                            {
                                                name: "Price",
                                                flex: 1
                                            },
                                            {
                                                name: "Delivered Quantity",
                                                flex: 1
                                            },
                                            {
                                                name: "GRN Amount",
                                                flex: 1
                                            },
                                            {
                                                name: "Total",
                                                flex: 1
                                            },


                                        ].map((item, i) => {
                                            return (
                                                <div key={i} style={{
                                                    ...item.width ? {
                                                        width: item.width
                                                    } : {
                                                            flex: item.flex
                                                        }
                                                }}>
                                                    <p style={{
                                                        padding: 8,
                                                        fontSize: 13,
                                                        color: "var(--text-color)"
                                                    }}>
                                                        {item.name}
                                                    </p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>



                                {
                                    viewGrn.items.map((val,index)=>{
                                        let active = renewalItem && renewalItem.id === val.id;

                                        return(
                                            <div style ={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                border: '1px solid var(--border-color)',
                                                alignItems: 'center',
                                                minHeight: 45,
                                                fontSize: 14
                                            }} key={index}>
                                                <div style={{
                                                    flex:1,
                                                    padding:8,
                                                    fontWeight:600,
                                                    alignItems:'center'
                                                }}>
                                                    {
                                                        renewalTracking ?
                                                        <div onClick={()=>{
                                                            if(renewalItem.id===val.id) {
                                                                setRenewalItem(false);
                                                            } else {
                                                                setRenewalItem(val)
                                                            }
                                                        }} style={{width:18,height:18,marginRight:5,border:'1px solid var(--border-color)',...active ?{
                                                            background:'var(--primary-color)'
                                                        }:{}}}>
                        
                                                        </div>
                                                        :null
                               
                                                }
                                                     <div>{val.productName}</div>
                                                     <div>Item Ref No : {val.id}</div>
                                                   {
                                                     <>
                                                        {
                                                            Object.keys(val.attrs).map((item,index)=>{
                                                                return(
                                                                    <>

                                                                    <div style={{
                                                                        fontSize:11,
                                                                        marginTop:1,
                                                                        fontWeight:500
                                                                    }} key={index}>{item} : {val.attrs[item]}</div> <br></br>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                     </>
                                                   }
                                                </div>
                                                <div style ={{
                                                    flex:1,
                                                    padding:8
                                                }}>
                                                    {val.officeName ? val.officeName : ''}<br></br>
                                                    {val.address}
                                                </div>
                                                <div style ={{
                                                    flex:1,
                                                    padding:8
                                                }}>
                                                    {val.poQty}
                                                </div>
                                                <div style ={{
                                                    flex:1,
                                                    padding:8
                                                }}>
                                                    {val.price}
                                                </div>
                                                <div style ={{
                                                    flex:1,
                                                    padding:8
                                                }}>
                                                    {val.qtty}
                                                </div>
                                                <div style ={{
                                                    flex:1,
                                                    padding:8
                                                }}>
                                                    {(Number(val.qtty)*Number(val.price)).toFixed(2)}
                                                </div>
                                                <div style ={{
                                                    flex:1,
                                                    padding:8
                                                }}>
                                                    {(Number(val.qtty*val.price)*(1+(((productTaxMapping[val.id])?productTaxMapping[val.id]:0)/100))).toFixed(2)}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <hr/>
                                <div style={{display:'flex',justifyContent:"space-between"}}>
                                    <div style={{fontWeight:'bold'}}>
                                       GRN Amount 
                                    </div>
                                    <div style={{marginRight:'30'}}>
                                        {numberWithCommas(total)}
                                    </div>
                                </div>
                                <div style={{display:'flex',justifyContent:"space-between"}}>
                                    <div style={{fontWeight:'bold'}}>
                                       Tax
                                    </div>
                                    <div style={{marginRight:'30'}}>
                                        {numberWithCommas(tax.toFixed(2))}
                                    </div>
                                </div>
                                <div style={{display:'flex',justifyContent:"space-between"}}>
                                    <div style={{fontWeight:'bold'}}>
                                       Total GRN Amount 
                                    </div>
                                    <div style={{marginRight:'30'}}>
                                        {numberWithCommas(total+tax)}
                                    </div>
                                </div>
                                <hr/>

                                {
                                    condition.length>0 ?
                                    <>
                                      <p style={{fontSize:14,fontWeight:'600',marginTop:20}}>All Condition</p>
                                      {conditionMapping()}
                                    </>
                                    :null
                                }
                              <div style={{
                                    flex:1,
                                    borderRight:'1px solid var(--border-color)',
                                    height:45,

                                 }} className='flex flex-v-center'>
                                    <p style={{paddingLeft:8,fontWeight:'500',fontSize:13}}>
                                      Delivery Date:  <span>{Moment(viewGrn.date).format("DD-MM-YYYY")}</span>
                                    </p>
                                </div>
                                <p style={{paddingLeft:8,fontWeight:'500',fontSize:13}}>
                                    GRN Owner: {viewGrn.owner ? viewGrn.owner.ownerName : "-"}
                                </p>
                                <p style={{paddingLeft:8,fontWeight:'500',fontSize:13,marginTop:10}}>
                                    Remarks: {viewGrn.remarks}
                                </p>
                                <p style={{marginTop:20,fontWeight:'bold',fontSize:13}}>All Attachment</p>
                                <div style={{display:'flex',flexWrap:'wrap',marginTop:5}}>
                                    {
                                         viewGrn.documents && viewGrn.documents.map((item,index)=>{
                                            return(
                                                <PhotoTile key={index} link={item} />
                                            )
                                         })
                                     }
                                     {
                                        viewGrn.documents && viewGrn.documents.length===0 ?
                                            <Empty />
                                        :null
                                     }
                                </div>

                               
                               
                 </PDFExport>


           

        </HalfModal>
           {
             conditionSubmit.length>0 ?
             <SubmitInvoice
                    currency={props.data.vendor.currency}
                    data={props.data}
                    onClose={() => {
                        setConditionSubmit(false);
                    }}
                    init={()=>{
                        props.init();
                        close();
                    }}
                    conditionalSumbit={true}                    
                    vendor={{
                        id:conditionSubmit[0].vendorId,
                        name:conditionSubmit[0].vendorName
                    }}
                    category={props.data.category.name}
                    categoryId={props.data.category.id}
                    vendorId={conditionSubmit[0].vendorId}
                    lineItems={conditionSubmit.map(val=>{
                        let qtty = val.approvedQty;
                       
                        let discountSlab = 0;
                        let base = val.price * qtty;
                        let grossBase = base - (base * (discountSlab/100));
                        let tax = (grossBase * (val.tax / 100))
                        return(
                             {     
                            poItemConditionId: val.poConditionItemId,
                             itemId:val.id,
                             name:val.title,
                             qtty,
                             price:val.price,
                             hsn:val.hsn,
                             base:val.price*qtty,
                             grossBase,
                             gst:val.tax,
                             taxSlab:val.tax,
                             tax,
                             total:tax+grossBase,
                             service:{
                                     label:"Random vehicle",
                            },
                            key:`${val.title}||Random vehicle||${val.id}`
                         }
             )
             })}
         /> 
           : null
           }

           	{
				grnSubmit ?
					<SubmitInvoice
						currency={props.data.vendor.currency}
						data={props.data}
						onClose={() => {
							setGrnSubmit(false);
						}}
						init={()=>{
							props.init();
                            close();
						}}
						category={props.data.category.name}
						categoryId={props.data.category.id}
						poId={id}
                        grnId={viewGrn.id}
						vendorId={props.data.vendor.id}
						lineItems={props.data.procured.filter(i=>_.includes(items,i.id)).map(val=>{
							let qtty = +itemsObject[val.id].qtty;
							
							let discountSlab = 0;
                        	let base = val.price * qtty;
                        	let grossBase = base - (base * (discountSlab/100));
                        	let tax = (grossBase * (val.taxSlab / 100))
                       	 return(
                            {
                                itemId:val.id,
                                name:val.name,
                                qtty,
                                price:val.price,
                                hsn:val.hsn,
                                base:val.price*qtty,
                                grossBase,
                                gst:val.taxSlab,
                                taxSlab:val.taxSlab,
                                tax,
                                total:tax+grossBase,
								productId:val.productId,
                                service:{
                                    label:val.serviceName,

                                },
                                key:`${val.name}||${val.serviceName}||${val.id}`
                            }
                        )
						})}
					/> : null
			}

        </>
    )
}

export default Index;