import moment from "moment";
import { fuzzyMatch } from "../../../../../../Utils/fuzzyMatch";


// PO vs OCR Mismatch
const ZDOM_CATEGORY_ID = 2855;
export const OCRMismatchBajaj = (poData, poLineItems, matchingOcrItemData, ocrData, lrCopyData, eWayBillData, categoryID, tenant) => {

    let headerLevelMismatch = {}; 
    let ocrInvoiceQRMismatch = {}; 
    let ocrInvoiceLrCopyMismatch = {}; 
    let ocrInvoiceEwayBillMismatch = {}; 
    let ocrLREwayBillMismatch = {};
    let lineitemOCRPOMismatch = {}; //{title:{prodcode,sku,hsn,gstrate,gstamt,totalamt,price,qty}}
    const originalOcr = ocrData && ocrData.originalOcr? ocrData.originalOcr : {};

    const {billingTo:poBillingTo,shipTo:poShipTo,vendor:poVendor} = poData;
    const poVendorGstin = poVendor.address ? poVendor.address.gstin ? poVendor.address.gstin : poVendor.gstin : poVendor.gstin;

    const ocrVendor = {
        gstin: originalOcr.sellerGst,
        pin: originalOcr.sellerPinCode,
        name: originalOcr.merchantName,
        address: originalOcr.sellerAddress
    }
    const ocrBilTo = {
        gstin: originalOcr.buyerGst,
        name: originalOcr.buyerAddress,
        pin: originalOcr.buyerPinCode
    }
    const ocrShipTo = {
        gstin: originalOcr.shipToGstin,
        name: originalOcr.shipToAddress,
        pin: originalOcr.shipToPincode
    }
    let poBase = 0, poTotal = 0, poTax = 0;
    poLineItems.forEach(item => {
        poBase = poBase + parseFloat(item.base);
        poTotal = poTotal + parseFloat(item.total);
        poTax = poTax + parseFloat(item.tax);
    })

    let ocrBase =  originalOcr && originalOcr.baseAmount;
    let ocrTotal =  ocrData && ocrData.amount;
    let ocrTax = 0
    originalOcr && originalOcr.invoiceItems && originalOcr.invoiceItems.forEach(item => {
        // ocrBase = ocrBase + parseFloat(item.amount);
        // ocrTotal = ocrTotal + parseFloat(item.totalAmount);
        ocrTax = ocrTax + parseFloat(item.taxAmount);
    })

    //----------------po data vs ocr data------------------------

    //billTOGstin
    // if(!ocrBilTo.gstin || !poBillingTo.gstin || ocrBilTo.gstin !== poBillingTo.gstin){
    headerLevelMismatch["Buyer GSTIN"] = {};
    headerLevelMismatch["Buyer GSTIN"]["po"] = { key: "buyerGstin", value: poBillingTo.gstin };
    headerLevelMismatch["Buyer GSTIN"]["ocr"] = { key: 'buyerGst', value: ocrBilTo.gstin };
    headerLevelMismatch["Buyer GSTIN"]['mismatch'] = !ocrBilTo.gstin || !poBillingTo.gstin || ocrBilTo.gstin !== poBillingTo.gstin;
    // headerLevelMismatch.push(["Buyer GSTIN",poBillingTo.gstin,ocrBilTo.gstin])
    // }

    //billToPin
    // if(!poBillingTo.pin || !ocrBilTo.pin || poBillingTo.pin!==ocrBilTo.pin){
    headerLevelMismatch["Billing Address PIN"] = {};
    headerLevelMismatch["Billing Address PIN"]["po"] = { key: "buyerPinCode", value: poBillingTo && poBillingTo.pin };
    headerLevelMismatch["Billing Address PIN"]["ocr"] = { key: "buyerPinCode", value: ocrBilTo && ocrBilTo.pin };
    headerLevelMismatch["Billing Address PIN"]["mismatch"] = !poBillingTo.pin || !ocrBilTo.pin || poBillingTo.pin !== ocrBilTo.pin
    // headerLevelMismatch.push(["Billing Address PIN",poBillingTo?.pin,ocrBilTo?.pin])
    // }

    //shipToGstin
    // if(!ocrShipTo.gstin || !poShipTo.gstin || ocrShipTo.gstin !== poShipTo.gstin){
    headerLevelMismatch["Ship To GSTIN"] = {};
    headerLevelMismatch["Ship To GSTIN"]["po"] = { key: "shipToGstin", value: poShipTo.gstin };
    headerLevelMismatch["Ship To GSTIN"]["ocr"] = { key: "shipToGstin", value: ocrShipTo.gstin };
    headerLevelMismatch["Ship To GSTIN"]["mismatch"] = !ocrShipTo.gstin || !poShipTo.gstin || ocrShipTo.gstin !== poShipTo.gstin
    // headerLevelMismatch.push(["Ship To GSTIN",poShipTo.gstin,ocrShipTo.gstin])
    // }

    //shipToPin
    // if(!ocrShipTo.pin || !poShipTo.pin || ocrShipTo.pin !== poShipTo.pin){
    headerLevelMismatch["Ship To Pin"] = {};
    headerLevelMismatch["Ship To Pin"]["po"] = { key: "shipToPincode", value: poShipTo.pin };
    headerLevelMismatch["Ship To Pin"]["ocr"] = { key: "shipToPincode", value: ocrShipTo.pin };
    headerLevelMismatch["Ship To Pin"]["mismatch"] = !ocrShipTo.pin || !poShipTo.pin || ocrShipTo.pin !== poShipTo.pin
    // headerLevelMismatch.push(["Ship To Pin",poShipTo.pin,ocrShipTo.pin])
    // }

    //vendorGstin
    const ocrVendorGstin = ocrVendor && ocrVendor.gstin;
    // if(!ocrVendorGstin || !poVendorGstin || ocrVendorGstin !== poVendorGstin){
    headerLevelMismatch["Vendor Gstin"] = {};
    headerLevelMismatch["Vendor Gstin"]["po"] = { key: "sellerGst", value: poVendorGstin };
    headerLevelMismatch["Vendor Gstin"]["ocr"] = { key: "sellerGst", value: ocrVendorGstin };
    headerLevelMismatch["Vendor Gstin"]["mismatch"] = !ocrVendorGstin || !poVendorGstin || ocrVendorGstin !== poVendorGstin;
    // headerLevelMismatch.push(["Vendor Gstin",poVendorGstin,ocrVendorGstin])
    // }

    //totalAmount
    // if(poTotal < ocrTotal){
    headerLevelMismatch["Invoice Amount"] = {};
    headerLevelMismatch["Invoice Amount"]["po"] = { key: "amount", value: Number(poTotal).toFixed(2), type: 'number' };
    headerLevelMismatch["Invoice Amount"]["ocr"] = { key: "amount", value: Number(ocrTotal).toFixed(2), type: 'number' };
    headerLevelMismatch["Invoice Amount"]["mismatch"] = poTotal < ocrTotal;
    // headerLevelMismatch.push(["Invoice Amount",numberWithCommas(poTotal),numberWithCommas(ocrTotal)])
    // }

    //baseAmount
    headerLevelMismatch["Total Base Amount"] = {};
    headerLevelMismatch["Total Base Amount"]["po"] = { key: "baseAmount", value: Number(poBase).toFixed(2), type: 'number' };
    headerLevelMismatch["Total Base Amount"]["ocr"] = { key: "baseAmount", value: Number(ocrBase).toFixed(2), type: 'number' };
    headerLevelMismatch["Total Base Amount"]["mismatch"] = (poBase !== ocrBase);

    //gstAmount
    // if(poTax !== ocrTax){
    headerLevelMismatch["GST Amount"] = {};
    headerLevelMismatch["GST Amount"]["po"] = { key: "taxAmount", value: Number(poTax).toFixed(2), type: 'number' };
    headerLevelMismatch["GST Amount"]["ocr"] = { key: "taxAmount", value: Number(ocrTax).toFixed(2), type: 'number' };
    headerLevelMismatch["GST Amount"]["mismatch"] = poTax !== ocrTax;
    // headerLevelMismatch.push(["GST Amount",numberWithCommas(poTax),numberWithCommas(ocrTax)])
    // }

    //----------------ocr-invoice data vs ocr-qr data----------------

    const irnQrCodeData = originalOcr && originalOcr.irnQrCodeData ? originalOcr.irnQrCodeData : {};
    //invoiceNum
    // if(!originalOcr.invoice || !irnQrCodeData.docNumber || originalOcr.invoice !== irnQrCodeData.docNumber){
    ocrInvoiceQRMismatch["Invoice Number"] = {};
    ocrInvoiceQRMismatch["Invoice Number"]["ocr"] = { key: "invoice", value: originalOcr.invoice };
    ocrInvoiceQRMismatch["Invoice Number"]["qr"] = { key: "docNumber", value: irnQrCodeData.docNumber };
    ocrInvoiceQRMismatch["Invoice Number"]["mismatch"] = !originalOcr.invoice || !irnQrCodeData.docNumber || originalOcr.invoice !== irnQrCodeData.docNumber
    // ocrInvoiceQRMismatch.push(["Invoice Number",originalOcr.invoice ,irnQrCodeData.docNumber])
    // }
    //invoiceDate
    // if(!originalOcr.date || !irnQrCodeData.docDate || moment(originalOcr.date,"DD-MM-YYYY").valueOf() !== moment(irnQrCodeData.docDate,"DD/MM/YYYY").valueOf()){
    ocrInvoiceQRMismatch["Invoice Date"] = {};
    ocrInvoiceQRMismatch["Invoice Date"]["ocr"] = {
        key: "date",
        value: moment(originalOcr.date, "DD-MM-YYYY").format("YYYY-MM-DD")
    };;
    ocrInvoiceQRMismatch["Invoice Date"]["qr"] = {
        key: "docDate",
        value: moment(irnQrCodeData.docDate, "DD/MM/YYYY").format("YYYY-MM-DD")
    }
    ocrInvoiceQRMismatch["Invoice Date"]["mismatch"] = !originalOcr.date || !irnQrCodeData.docDate || moment(originalOcr.date, "DD-MM-YYYY").valueOf() !== moment(irnQrCodeData.docDate, "DD/MM/YYYY").valueOf();
    // ocrInvoiceQRMismatch.push(["Invoice Date",moment(originalOcr.date,"DD-MM-YYYY").format("YYYY-MM-DD") ,moment(irnQrCodeData.dockDate,"DD/MM/YYYY").format("YYYY-MM-DD")])
    // }
    //buyerGst
    // if(!originalOcr.buyerGst || !irnQrCodeData.buyerGstin || originalOcr.buyerGst !== irnQrCodeData.buyerGstin){
    ocrInvoiceQRMismatch["Buyer GSTIN"] = {};
    ocrInvoiceQRMismatch["Buyer GSTIN"]["ocr"] = { key: "buyerGstin", value: originalOcr.buyerGst };
    ocrInvoiceQRMismatch["Buyer GSTIN"]["qr"] = { key: "buyerGstin", value: irnQrCodeData.buyerGstin };
    ocrInvoiceQRMismatch["Buyer GSTIN"]["mismatch"] = !originalOcr.buyerGst || !irnQrCodeData.buyerGstin || originalOcr.buyerGst !== irnQrCodeData.buyerGstin
    // ocrInvoiceQRMismatch.push(["Buyer GSTIN",originalOcr.buyerGst ,irnQrCodeData.buyerGstin])
    // }
    //sellerGst
    // if(!originalOcr.sellerGst || !irnQrCodeData.sellerGstin || originalOcr.sellerGst !== irnQrCodeData.sellerGstin){
    ocrInvoiceQRMismatch["Vendor Gstin"] = {};
    ocrInvoiceQRMismatch["Vendor Gstin"]["ocr"] = { key: "sellerGst", value: originalOcr.sellerGst };
    ocrInvoiceQRMismatch["Vendor Gstin"]["qr"] = { key: "sellerGstin", value: irnQrCodeData.sellerGstin };
    ocrInvoiceQRMismatch["Vendor Gstin"]["mismatch"] = !originalOcr.sellerGst || !irnQrCodeData.sellerGstin || originalOcr.sellerGst !== irnQrCodeData.sellerGstin
    // ocrInvoiceQRMismatch.push(["Vendor Gstin",originalOcr.sellerGst ,irnQrCodeData.sellerGstin])
    // }
    //invoiceAmount
    // if(!originalOcr.amount || !irnQrCodeData.totalInvoiceAmount || originalOcr.amount !== irnQrCodeData.totalInvoiceAmount){
    ocrInvoiceQRMismatch["Invoice Amount"] = {};
    ocrInvoiceQRMismatch["Invoice Amount"]["ocr"] = { key: "amount", value: Number(originalOcr.amount).toFixed(2) , type: 'number' };
    ocrInvoiceQRMismatch["Invoice Amount"]["qr"] = { key: "invoiceAmount", value: Number(irnQrCodeData.totalInvoiceAmount).toFixed(2) };
    ocrInvoiceQRMismatch["Invoice Amount"]["mismatch"] = !originalOcr.amount || !irnQrCodeData.totalInvoiceAmount || originalOcr.amount !== irnQrCodeData.totalInvoiceAmount
    // ocrInvoiceQRMismatch.push(["Invoice Amount",originalOcr.amount ,irnQrCodeData.invoiceAmount])
    // }
    //irn
    // if(!originalOcr.irn || !irnQrCodeData.irn || (originalOcr.irn).replace(/-/g, '') !== (irnQrCodeData.irn).replace(/-/g, '')){
    ocrInvoiceQRMismatch["IRN"] = {};
    ocrInvoiceQRMismatch["IRN"]["ocr"] = { key: "irn", value: originalOcr.irn };
    ocrInvoiceQRMismatch["IRN"]["qr"] = { key: "irn", value: irnQrCodeData.irn };
    ocrInvoiceQRMismatch["IRN"]["mismatch"] = !originalOcr.irn || !irnQrCodeData.irn || (originalOcr.irn).replace(/-/g, '') !== (irnQrCodeData.irn).replace(/-/g, '');
    // ocrInvoiceQRMismatch.push(["IRN",originalOcr.irn ,irnQrCodeData.irn])
    // }


    if (categoryID === ZDOM_CATEGORY_ID || tenant === "TRDT") {

        //----------------ocr-invoice data vs ocr-lr copy data----------------

        //invoiceNumber
        // if(!originalOcr.invoice || !lrCopyData.invoiceNo || (originalOcr.invoice) !== (lrCopyData.invoiceNo)){
        ocrInvoiceLrCopyMismatch["Invoice Number"] = {};
        ocrInvoiceLrCopyMismatch["Invoice Number"]["ocr"] = { key: "invoice", value: originalOcr.invoice };
        ocrInvoiceLrCopyMismatch["Invoice Number"]["lr"] = { key: "invoiceNo", value: lrCopyData.invoiceNo };
        ocrInvoiceLrCopyMismatch["Invoice Number"]["mismatch"] = !originalOcr.invoice || !lrCopyData.invoiceNo || (originalOcr.invoice) !== (lrCopyData.invoiceNo);
        // ocrInvoiceLrCopyMismatch.push(["Invoice Number",originalOcr.invoice ,lrCopyData.invoiceNo])
        // }

        // invoiceDate
        {
            let ocrInvoiceDate = originalOcr.date ? moment(originalOcr.date, "DD-MM-YYYY").valueOf() : undefined;
            let ocrLRDate = lrCopyData.lrDate ? moment(lrCopyData.lrDate, "DD-MM-YY").valueOf() : undefined;

            // if(!ocrInvoiceDate || !ocrLRDate || (ocrLRDate<ocrInvoiceDate)){
            ocrInvoiceLrCopyMismatch["Invoice Date"] = {};
            ocrInvoiceLrCopyMismatch["Invoice Date"]["ocr"] = {
                key: "date",
                value: moment(originalOcr.date, "DD-MM-YYYY").format("YYYY-MM-DD")
            };

            ocrInvoiceLrCopyMismatch["Invoice Date"]["lr"] = {
                key: "lrDate",
                value: moment(lrCopyData.lrDate, "DD-MM-YY").format("YYYY-MM-DD")
            };
            ocrInvoiceLrCopyMismatch["Invoice Date"]["mismatch"] = !ocrInvoiceDate || !ocrLRDate || (ocrLRDate < ocrInvoiceDate);
            // ocrInvoiceLrCopyMismatch.push(["Invoice Date",moment(originalOcr.date,"DD-MM-YYYY").format("YYYY-MM-DD") ,moment(lrCopyData.lrDate,"DD-MM-YY").format("YYYY-MM-DD")])
            // }
        }

        //shipto
        // let lrCopyShipTo = lrCopyData.consignorAddress ? lrCopyData.consignorAddress : "";
        // let ocrShipAddress = ocrShipTo.name ? ocrShipTo.name : "";
        // if(!fuzzyMatch(ocrShipAddress,lrCopyShipTo,40)){
        ocrInvoiceLrCopyMismatch["Ship To Pin"] = {};
        ocrInvoiceLrCopyMismatch["Ship To Pin"]["ocr"] = { key: "shipToPincode", value: ocrShipTo.pin };
        ocrInvoiceLrCopyMismatch["Ship To Pin"]["lr"] = { key: "consignorPinCode", value: lrCopyData.consignorPinCode };
        ocrInvoiceLrCopyMismatch["Ship To Pin"]["mismatch"] = !ocrShipTo.pin || !lrCopyData.consignorPinCode || ocrShipTo.pin !== lrCopyData.consignorPinCode
        // ocrInvoiceLrCopyMismatch.push(["Shipping Address",poShipAddress,lrCopyShipTo])
        // }



        //----------------ocr-invoice data vs ocr-eway Bill data----------------


        //invoiceNumber
        // if(!originalOcr.invoice || !eWayBillData.invoiceNo || (originalOcr.invoice) !== (eWayBillData.invoiceNo)){
        ocrInvoiceEwayBillMismatch["Invoice Number"] = {};
        ocrInvoiceEwayBillMismatch["Invoice Number"]["ocr"] = { key: "invoice", value: originalOcr.invoice };
        ocrInvoiceEwayBillMismatch["Invoice Number"]["eway"] = { key: "invoiceNo", value: eWayBillData.invoiceNo };
        ocrInvoiceEwayBillMismatch["Invoice Number"]["mismatch"] = !originalOcr.invoice || !eWayBillData.invoiceNo || (originalOcr.invoice) !== (eWayBillData.invoiceNo);
        // ocrInvoiceEwayBillMismatch.push(["Invoice Number",originalOcr.invoice ,eWayBillData.invoiceNo])
        // }

        // invoiceDate
        {
            let ocrInvoiceDate = originalOcr.date ? moment(originalOcr.date, "DD-MM-YYYY").valueOf() : undefined;
            let ocrEwayBillDate = eWayBillData.billDate ? moment(eWayBillData.billDate, "DD-MM-YYYY").valueOf() : undefined;

            // if(!ocrInvoiceDate || !ocrEwayBillDate || (ocrEwayBillDate < ocrInvoiceDate )){
            ocrInvoiceEwayBillMismatch["Invoice Date"] = {};
            ocrInvoiceEwayBillMismatch["Invoice Date"]["ocr"] = {
                key: "date",
                value: moment(originalOcr.date, "DD-MM-YYYY").format("YYYY-MM-DD")
            };

            ocrInvoiceEwayBillMismatch["Invoice Date"]["eway"] = {
                key: "billDate",
                value: moment(eWayBillData.billDate, "DD-MM-YYYY").format("YYYY-MM-DD")
            };
            ocrInvoiceEwayBillMismatch["Invoice Date"]["mismatch"] = !ocrInvoiceDate || !ocrEwayBillDate || (ocrEwayBillDate < ocrInvoiceDate);
            // ocrInvoiceEwayBillMismatch.push(["Invoice Date",moment(originalOcr.date,"DD-MM-YYYY").format("YYYY-MM-DD") ,moment(eWayBillData.billDate,"DD-MM-YYYY").format("YYYY-MM-DD")])
            // }
        }

        //supplierGstin
        //vendorGstin
        let ewayBillTo = eWayBillData.from ? eWayBillData.from : {};
        let ewayBillVendorGstin = eWayBillData && eWayBillData.from && eWayBillData.from.gstin;
        // if(!ocrVendorGstin || !ewayBillVendorGstin || ocrVendorGstin!== ewayBillVendorGstin){
        ocrInvoiceEwayBillMismatch["Vendor Gstin"] = {};
        ocrInvoiceEwayBillMismatch["Vendor Gstin"]["ocr"] = { key: "sellerGst", value: ocrVendorGstin };
        ocrInvoiceEwayBillMismatch["Vendor Gstin"]["eway"] = { key: "fromGstin", value: ewayBillVendorGstin };
        ocrInvoiceEwayBillMismatch["Vendor Gstin"]["mismatch"] = !ocrVendorGstin || !ewayBillVendorGstin || ocrVendorGstin !== ewayBillVendorGstin;
        // ocrInvoiceEwayBillMismatch.push(["Vendor Gstin",ocrVendorGstin,ewayBillVendorGstin])
        // }

        //supplierPin
        // if(!ocrVendor.pin || !ewayBillTo.pincode || (ocrVendor.pin !== ewayBillTo.pincode)){
        ocrInvoiceEwayBillMismatch["Vendor Address PIN"] = {};
        ocrInvoiceEwayBillMismatch["Vendor Address PIN"]["ocr"] = { key: "sellerPinCode", value: ocrVendor && ocrVendor.pin };
        ocrInvoiceEwayBillMismatch["Vendor Address PIN"]["eway"] = { key: "fromPinCode", value: ewayBillTo && ewayBillTo.pincode };
        ocrInvoiceEwayBillMismatch["Vendor Address PIN"]["mismatch"] = !ocrVendor.pin || !ewayBillTo.pincode || (ocrVendor.pin !== ewayBillTo.pincode);
        // ocrInvoiceEwayBillMismatch.push(["Vendor Address PIN",ocrVendor?.pin,ewayBillTo?.pincode])
        // }

        //shipToGstin
        let eWayshipTo = eWayBillData.to ? eWayBillData.to : {}
        // if(!ocrShipTo.gstin || !eWayshipTo.gstin || (ocrShipTo.gstin !== eWayshipTo.gstin)){
        ocrInvoiceEwayBillMismatch["Ship To GSTIN"] = {};
        ocrInvoiceEwayBillMismatch["Ship To GSTIN"]["ocr"] = { key: "shipToGstin", value: ocrShipTo && ocrShipTo.gstin };
        ocrInvoiceEwayBillMismatch["Ship To GSTIN"]["eway"] = { key: "toGstin", value: eWayshipTo && eWayshipTo.gstin };
        ocrInvoiceEwayBillMismatch["Ship To GSTIN"]["mismatch"] = !ocrShipTo.gstin || !eWayshipTo.gstin || (ocrShipTo.gstin !== eWayshipTo.gstin)
        // ocrInvoiceEwayBillMismatch.push(["Ship To GSTIN",ocrShipTo?.gstin,eWayshipTo?.gstin])
        // }

        //shipToPin
        // if(!ocrShipTo.pin || !eWayshipTo.pincode || (ocrShipTo.pin !== eWayshipTo.pincode)){
        ocrInvoiceEwayBillMismatch["Ship To Pin"] = {};
        ocrInvoiceEwayBillMismatch["Ship To Pin"]["ocr"] = { key: "shipToPincode", value: ocrShipTo && ocrShipTo.pin };
        ocrInvoiceEwayBillMismatch["Ship To Pin"]["eway"] = { key: "toPinCode", value: eWayshipTo && eWayshipTo.pincode };
        ocrInvoiceEwayBillMismatch["Ship To Pin"]["mismatch"] = !ocrShipTo.pin || !eWayshipTo.pincode || (ocrShipTo.pin !== eWayshipTo.pincode)
        // ocrInvoiceEwayBillMismatch.push(["Ship To Pin",ocrShipTo?.pin,eWayshipTo?.pincode])
        // }

        //OCR-invocieAmount
        // if(!eWayBillData.taxableAmount || ocrBase!== eWayBillData.taxableAmount){
        ocrInvoiceEwayBillMismatch["Base Amount"] = {};
        ocrInvoiceEwayBillMismatch["Base Amount"]["ocr"] = { key: "baseAmount", value: ocrBase , type: 'number' };
        ocrInvoiceEwayBillMismatch["Base Amount"]["eway"] = { key: "taxableAmount", value: eWayBillData.taxableAmount ? eWayBillData.taxableAmount : 0, type: 'number' };
        ocrInvoiceEwayBillMismatch["Base Amount"]["mismatch"] = !eWayBillData.taxableAmount || ocrBase !== eWayBillData.taxableAmount;
        // ocrInvoiceEwayBillMismatch.push(["Base Amount",ocrBase,...[eWayBillData.taxableAmount?eWayBillData.taxableAmount:0]])
        // }

        //gstType
        let ocrInvoiceGstType = ocrData && ocrData.igst ? "IGST" : ocrData && ocrData.cgst ? "CGST SGST" : undefined;
        let eWayBillGstType = eWayBillData && eWayBillData.iGstAmount ? "IGST" : ocrData && ocrData.cGstAmount ? "CGST SGST" : undefined;

        // if(ocrInvoiceGstType !== eWayBillGstType){
        ocrInvoiceEwayBillMismatch["GST Type"] = {};
        ocrInvoiceEwayBillMismatch["GST Type"]["ocr"] = { key: "gstType", value: ocrInvoiceGstType };
        ocrInvoiceEwayBillMismatch["GST Type"]["eway"] = { key: "gstType", value: eWayBillGstType };
        ocrInvoiceEwayBillMismatch["GST Type"]["mismatch"] = ocrInvoiceGstType !== eWayBillGstType
        // ocrInvoiceEwayBillMismatch.push(["GST Type",ocrInvoiceGstType,eWayBillGstType])
        // }

        //----------------LR data vs ocr-eway Bill data----------------


        //transporterID
        // if(!eWayBillData.transporterId || !lrCopyData.transporterGstin || eWayBillData.transporterId !==lrCopyData.transporterGstin){
        ocrLREwayBillMismatch["Transporter GSTIN"] = {};
        ocrLREwayBillMismatch["Transporter GSTIN"]["eway"] = { key: "transporterId", value: eWayBillData.transporterId };
        ocrLREwayBillMismatch["Transporter GSTIN"]["lr"] = { key: "transporterGstin", value: lrCopyData.transporterGstin };
        ocrLREwayBillMismatch["Transporter GSTIN"]["mismatch"] = !eWayBillData.transporterId || !lrCopyData.transporterGstin || eWayBillData.transporterId !== lrCopyData.transporterGstin;
        // ocrLREwayBillMismatch.push(["Transporter GSTIN",eWayBillData.transporterId,lrCopyData.transporterGstin])
        // }

        //transporterName
        // if(!eWayBillData.transporterName || !lrCopyData.transporterName || !fuzzyMatch(eWayBillData.transporterName,lrCopyData.transporterName,60)){
        ocrLREwayBillMismatch["Transporter Name"] = {};
        ocrLREwayBillMismatch["Transporter Name"]["eway"] = { key: "transporterName", value: eWayBillData.transporterName };
        ocrLREwayBillMismatch["Transporter Name"]["lr"] = { key: "transporterName", value: lrCopyData.transporterName };
        ocrLREwayBillMismatch["Transporter Name"]["mismatch"] = !eWayBillData.transporterName || !lrCopyData.transporterName || !fuzzyMatch(eWayBillData.transporterName, lrCopyData.transporterName, 60);
        // ocrLREwayBillMismatch.push(["Transporter Name",eWayBillData.transporterName,lrCopyData.transporterName])
        // }

        //date
        let lrDate = lrCopyData.lrDate ? moment(lrCopyData.lrDate, "DD-MM-YY").valueOf() : undefined;
        let ewayBillDate = eWayBillData.documentDate ? moment(eWayBillData.documentDate, "DD-MM-YYYY").valueOf() : undefined;
        // if(!lrDate || !ewayBillDate || lrDate!==ewayBillDate){
        ocrLREwayBillMismatch["Date"] = {};
        ocrLREwayBillMismatch["Date"]["eway"] = {
            key: "documentDate",
            value: moment(eWayBillData.documentDate, "DD-MM-YYYY").format("YYYY-MM-DD")
        };

        ocrLREwayBillMismatch["Date"]["lr"] = {
            key: "lrDate",
            value: moment(lrCopyData.lrDate, "DD-MM-YY").format("YYYY-MM-DD")
        };
        ocrLREwayBillMismatch["Date"]["mismatch"] = !lrDate || !ewayBillDate || lrDate !== ewayBillDate;
        // ocrLREwayBillMismatch.push(["Date",moment(eWayBillData.documentDate,"DD-MM-YYYY").format("YYYY-MM-DD"),moment(lrCopyData.lrDate,"DD-MM-YY").format("YYYY-MM-DD")])
        // }

        //docNumber
        // if(!eWayBillData.documentNo || !lrCopyData.lrNo || eWayBillData.documentNo !== lrCopyData.lrNo){
        ocrLREwayBillMismatch["Doc Number"] = {};
        ocrLREwayBillMismatch["Doc Number"]["eway"] = { key: "documentNo", value: eWayBillData.documentNo };
        ocrLREwayBillMismatch["Doc Number"]["lr"] = { key: "lrNo", value: lrCopyData.lrNo };
        ocrLREwayBillMismatch["Doc Number"]["mismatch"] = !eWayBillData.documentNo || !lrCopyData.lrNo || eWayBillData.documentNo !== lrCopyData.lrNo;
        // ocrLREwayBillMismatch.push(["Doc Number",eWayBillData.documentNo,lrCopyData.lrNo])
        // }

        //vechicleNumber
        // if(!eWayBillData.vehicleNo || !lrCopyData.truckNo || eWayBillData.vehicleNo !== lrCopyData.truckNo){
        ocrLREwayBillMismatch["Vehicle Number"] = {};
        ocrLREwayBillMismatch["Vehicle Number"]["eway"] = { key: "vehicleNo", value: eWayBillData.vehicleNo };
        ocrLREwayBillMismatch["Vehicle Number"]["lr"] = { key: "truckNo", value: lrCopyData.truckNo };
        ocrLREwayBillMismatch["Vehicle Number"]["mismatch"] = !eWayBillData.vehicleNo || !lrCopyData.truckNo || eWayBillData.vehicleNo !== lrCopyData.truckNo;
        // ocrLREwayBillMismatch.push(["Vehicle Number",eWayBillData.vehicleNo,lrCopyData.truckNo])
        // }
    }

    //----------------ocr-lineitem vs ocr-lineitem----------------

    for (let item of poLineItems) {
        let key = `${item.itemId}-${item.name}-${item.hsn}-${item.description}}`
        if (!matchingOcrItemData[item.itemId]) {
            lineitemOCRPOMismatch[key] = undefined;
            continue;
        }
        let ocrItem = matchingOcrItemData[item.itemId];
        let mismatch = {};

        //productcode
        // if(!item.productCode || !ocrItem.productCode || item.productCode !== ocrItem.productCode){
        mismatch["Product Code"] = {
            po: { key: "productCode", value: item.productCode },
            ocr: { key: "productCode", value: ocrItem.productCode },
            mismatch: !item.productCode || !ocrItem.productCode || item.productCode !== ocrItem.productCode
        };
        // mismatch.push(["Product Code",item.productCode,ocrItem.productCode])
        // }
        //sku
        // if(!item.sku || !ocrItem.sku || item.sku !== ocrItem.sku){
        //     mismatch.push(["SKU",item.sku,ocrItem.sku])
        // }
        //hsn
        // if(!item.hsn || !ocrItem.hsn || item.hsn !== ocrItem.hsn){
        mismatch["HSN"] = {
            po: { key: "hsn", value: item.hsn },
            ocr: { key: "hsn", value: ocrItem.hsn },
            mismatch: !item.hsn || !ocrItem.hsn || item.hsn !== ocrItem.hsn
        };
        // mismatch.push(["HSN",item.hsn,ocrItem.hsn])
        // }
        //gstamount
        // if(item.tax !== ocrItem.taxAmount){
        mismatch["GST Amount"] = {
            po: { key: "tax", value: item.tax ? Number(item.tax).toFixed(2) : "" },
            ocr: { key: "taxAmount", value: ocrItem.taxAmount ? Number(ocrItem.taxAmount).toFixed(2) : "" },
            mismatch: item.tax !== ocrItem.taxAmount
        };

        // mismatch.push(["GST Amount",numberWithCommas(item.tax),numberWithCommas(ocrItem.taxAmount)])
        // }
        //price
        console.log('item.price', item.price)
        console.log('ocrItem.price', ocrItem.price)
        // if(item.price !== ocrItem.price){
        mismatch["Per Unit Price"] = {
            po: { key: "price", value: item.price ? Number(item.price).toFixed(2) : "" },
            ocr: { key: "price", value: ocrItem.price ? Number(ocrItem.price).toFixed(2) : "" },
            mismatch: item.price !== ocrItem.price
        };

        // mismatch.push(["Per Unit Price",numberWithCommas(item.price),numberWithCommas(ocrItem.price)])
        // }
        //quantity
        // if((item.qtty) < ocrItem.quantity){
        mismatch["Total Quantity"] = {
            po: { key: "qtty", value: item.qtty ? Number(item.qtty).toFixed(2) : "" },
            ocr: { key: "quantity", value: ocrItem.quantity ? Number(ocrItem.quantity).toFixed(2) : "" },
            mismatch: (item.qtty) < ocrItem.quantity
        };

        // mismatch.push(["Total Quantity",numberWithCommas(item.qtty),numberWithCommas(ocrItem.quantity)])
        // }
        //totalamount
        let totalOcrAmt = Number(ocrItem.price) * Number(ocrItem.quantity)
        // if(totalOcrAmt !== ocrItem.amount){
        mismatch["Total Amount OCR"] = {
            po: { key: "total", value: item.total ? Number(item.total).toFixed(2) : "" },
            ocr: { key: "amount", value: ocrItem.amount ? Number(ocrItem.amount).toFixed(2) : "" },
            mismatch: totalOcrAmt !== ocrItem.amount
        };

        // mismatch.push(["Total Amount OCR",'',numberWithCommas(item.total)])
        // }

        lineitemOCRPOMismatch[key] = mismatch
    }

    return { headerLevelMismatch, ocrInvoiceQRMismatch, lineitemOCRPOMismatch, ocrInvoiceLrCopyMismatch, ocrInvoiceEwayBillMismatch, ocrLREwayBillMismatch }
}

// const addressMismatch = (address1, address2, threshold = 60) => {
//     // Compare GSTIN values
//     if (address1 && address1.gstin !== address2 && address2.gstin) return true;

//     // Compare PIN code values if they exist
//     if (address1 && address1.pin && address2 && address2.pin && address1.pin !== address2.pin) return true;

//     return false;

//     // Fuzzy match on names
//     let poAddress = address1.name ? address1.name : "";
//     poAddress += (address1.address? address1.address : "");
//     return !fuzzyMatch(poAddress, address2?.name, threshold);
// }

export const ocrMismatchStandard = (config, ocrDetails, inputData, total, base, poLineItems, matchingOcrItemData, poId) => {
    let { ocrMisMatchHardStopConfig = {}, ocrMisMatchSoftStopConfig = {} } = config;
    let misMatchData = {};
    let lineitemOCRPOMismatch = {};
    let hardStop = false;
    let softStop = false;

    let { billNumber, billingTo, shippingTo, vendor: poVendor, discount } = inputData;
    const poVendorGstin = poVendor.address ? poVendor.address.gstin ? poVendor.address.gstin : poVendor.gstin : poVendor.gstin;
    const originalOcr = ocrDetails && ocrDetails.originalOcr ? ocrDetails.originalOcr : {};

    const ocrVendor = {
        gstin: originalOcr.sellerGst,
        pin: originalOcr.sellerPinCode,
        name: originalOcr.merchantName,
        address: originalOcr.sellerAddress,
        pan: originalOcr.sellerPan
    }
    const ocrBilTo = {
        gstin: originalOcr.buyerGst,
        name: originalOcr.buyerAddress,
        pin: originalOcr.buyerPinCode
    }
    const ocrShipTo = {
        gstin: originalOcr.shipToGstin,
        name: originalOcr.shipToAddress,
        pin: originalOcr.shipToPincode
    }

    let totalAmt = (parseFloat(total) - (parseFloat(total) / 100 * parseFloat(discount))).toFixed(2);

    //Total Amount
    if ((!originalOcr.amount || parseFloat(totalAmt).toFixed(2) !== parseFloat(originalOcr.amount).toFixed(2))) {
        misMatchData['Total Amount'] = {
            name: "invoiceAmount",
            ocr: originalOcr.amount ? Number(originalOcr.amount).toFixed(2) : originalOcr.amount,
            po: Number(totalAmt).toFixed(2)
        }
        hardStop = hardStop || ocrMisMatchHardStopConfig['invoiceAmount'];
        softStop = softStop || ocrMisMatchSoftStopConfig['invoiceAmount'];
    }
    //Vendor Gstin
    if ((!ocrVendor.gstin || poVendor.pin !== ocrVendor.gstin)) {
        misMatchData['Vendor Gstin'] = {
            name: "vendorGSTINM",
            ocr: (ocrVendor.gstin || ""),
            po: (poVendorGstin || "")
        }
        hardStop = hardStop || ocrMisMatchHardStopConfig['vendorGSTINM'];
        softStop = softStop || ocrMisMatchSoftStopConfig['vendorGSTINM'];
    }
    //Vendor Pan
    if ((!ocrVendor.pan || poVendorGstin !== ocrVendor.pan)) {
        misMatchData['Vendor Pan'] = {
            name: "vendorPan",
            ocr: (ocrVendor.pan || ""),
            po: (poVendor.pan || "")
        }
        hardStop = hardStop || ocrMisMatchHardStopConfig['vendorPan'];
        softStop = softStop || ocrMisMatchSoftStopConfig['vendorPan'];
    }
    //Vendor Pin
    if ((!ocrVendor.pin || poVendor.pin !== ocrVendor.pin)) {
        misMatchData['Vendor Pin'] = {
            name: "vendorPin",
            ocr: (ocrVendor.pin || ""),
            po: (poVendor.pin || "")
        }
        hardStop = hardStop || ocrMisMatchHardStopConfig['vendorPin'];
        softStop = softStop || ocrMisMatchSoftStopConfig['vendorPin'];
    }
    //invoiceDate
    let invoiceDate = moment(inputData.billDate, "DD-MM-YYYY").valueOf();
    let ocrDate = moment(originalOcr.date, "DD-MM-YYYY").valueOf();
    if ((!ocrVendor.pin || invoiceDate !== ocrDate)) {
        misMatchData['Invoice Date'] = {
            name: "invoiceDate",
            ocr: moment(originalOcr.date, "DD-MM-YYYY").format("DD-MM-YYYY"),
            po: moment(inputData.billDate, "DD-MM-YYYY").format("DD-MM-YYYY")
        }
        hardStop = hardStop || ocrMisMatchHardStopConfig['invoiceDate'];
        softStop = softStop || ocrMisMatchSoftStopConfig['invoiceDate'];
    }
    //Bill To Gstin
    if ((!ocrBilTo.gstin || billingTo.gstin !== ocrBilTo.gstin)) {
        misMatchData['Bill To Gstin'] = {
            name: "buyerGstin",
            ocr: (ocrBilTo.gstin || ""),
            po: (billingTo.gstin || "")
        }
        hardStop = hardStop || ocrMisMatchHardStopConfig['buyerGstin'];
        softStop = softStop || ocrMisMatchSoftStopConfig['buyerGstin'];
    }
    //Ship To Gstin
    if (!ocrShipTo.gstin || !shippingTo.gstin || ocrShipTo.gstin !== shippingTo.gstin) {
        misMatchData['Ship To Gstin'] = {
            name: "sellerGstin",
            ocr: (ocrShipTo.gstin || ""),
            po: (shippingTo.gstin || "")
        }
        hardStop = hardStop || ocrMisMatchHardStopConfig['sellerGstin'];
        softStop = softStop || ocrMisMatchSoftStopConfig['sellerGstin'];
    }
    //Bill No
    if ((!ocrDetails.invoice || billNumber !== ocrDetails.invoice)) {
        misMatchData['Bill No'] = {
            name: "billNo",
            ocr: (ocrDetails.invoice || ""),
            po: (billNumber || "")
        }
        hardStop = hardStop || ocrMisMatchHardStopConfig['billNo'];
        softStop = softStop || ocrMisMatchSoftStopConfig['billNo'];
    }
    //Total base amount
    if ((!originalOcr.baseAmount || parseFloat((base)).toFixed(2) !== parseFloat(originalOcr.baseAmount).toFixed(2))) {
        misMatchData['Total base amount'] = {
            name: "baseAmount",
            ocr: originalOcr.baseAmount ? Number(originalOcr.baseAmount).toFixed(2) : originalOcr.baseAmount,
            po: base ? Number(base).toFixed(2) : ""
        }
        hardStop = hardStop || ocrMisMatchHardStopConfig['baseAmount'];
        softStop = softStop || ocrMisMatchSoftStopConfig['baseAmount'];
    }
    //Po Number
    let poOcr = originalOcr.poNumbers && Array.isArray(originalOcr.poNumbers) ? originalOcr.poNumbers[0] : undefined;
    if ((!poOcr || poOcr !== poId)) {
        misMatchData['Po Number'] = {
            name: "poNo",
            ocr: (poOcr || ""),
            po: (poId || "")
        }
        hardStop = hardStop || ocrMisMatchHardStopConfig['poNo'];
        softStop = softStop || ocrMisMatchSoftStopConfig['poNo'];
    }
    //line items
    for (let item of poLineItems) {
        let key = `${item.itemId}-${item.name}-${item.hsn}-${item.description}}`
        if (!matchingOcrItemData[item.itemId]) {
            lineitemOCRPOMismatch[key] = undefined;
            continue;
        }
        let ocrItem = matchingOcrItemData[item.itemId];
        let mismatch = {};

        //productcode
        if (!item.productCode || !ocrItem.productCode || item.productCode !== ocrItem.productCode) {
            mismatch["Product Code"] = {
                po: { key: "productCode", value: (item.productCode || "") },
                ocr: { key: "productCode", value: (ocrItem.productCode || "") }
            };
        }
        if (!item.hsn || !ocrItem.hsn || item.hsn !== ocrItem.hsn) {
            mismatch["HSN"] = {
                po: { key: "hsn", value: (item.hsn || "") },
                ocr: { key: "hsn", value: (ocrItem.hsn || "") }
            };
        }
        //gstamount
        if (item.tax !== ocrItem.taxAmount) {
            mismatch["GST Amount"] = {
                po: { key: "tax", value: item.tax ? Number(item.tax).toFixed(2) : "" },
                ocr: { key: "taxAmount", value: ocrItem.taxAmount ? Number(ocrItem.taxAmount).toFixed(2) : "" },
            };
        }
        //price
        if (item.price !== ocrItem.price) {
            mismatch["Per Unit Price"] = {
                po: { key: "price", value: item.price ? Number(item.price).toFixed(2) : "" },
                ocr: { key: "price", value: ocrItem.price ? Number(ocrItem.price).toFixed(2) : "" },
            };
        }
        //quantity
        if ((item.qtty) !== ocrItem.quantity) {
            mismatch["Total Quantity"] = {
                po: { key: "qtty", value: item.qtty ? Number(item.qtty).toFixed(2) : "" },
                ocr: { key: "quantity", value: ocrItem.quantity ? Number(ocrItem.quantity).toFixed(2) : "" },
            };
        }
        //totalamount
        if (parseFloat((item.total)).toFixed(2) !== parseFloat((ocrItem.amount)).toFixed(2)) {
            mismatch["Total Amount OCR"] = {
                po: { key: "total", value: item.total ? Number(item.total).toFixed(2) : "" },
                ocr: { key: "amount", value: ocrItem.amount ? Number(ocrItem.amount).toFixed(2) : "" },
            };
        }
        lineitemOCRPOMismatch[key] = mismatch
    }
    hardStop = hardStop || ocrMisMatchHardStopConfig['lineItem'];
    softStop = softStop || ocrMisMatchSoftStopConfig['lineItem'];


    return { misMatchData, lineitemOCRPOMismatch, hardStop, softStop }
}

export const getFormattedEditableData = (misMatchData, lineitemOCRPOMismatch) => {
    let ocrMismatch = {};
    ocrMismatch['headerOcr'] = {};
    Object.keys(misMatchData).forEach(key => {
        let modifiedKey = key.split(' ').join('_');
        ocrMismatch['headerOcr'][modifiedKey] = { po: misMatchData[key].po, ocr: misMatchData[key].ocr };
    })
    ocrMismatch['invoiceLineItem'] = [];
    Object.keys(lineitemOCRPOMismatch).forEach(key => {
        if (!lineitemOCRPOMismatch[key] || Object.keys(lineitemOCRPOMismatch[key]).length === 0) return;
        let [, name, , desc] = key.split('-');
        let mismatchObj = {};
        Object.keys(lineitemOCRPOMismatch[key]).forEach(mismatchKey => {
            let modifiedKey = mismatchKey.split(' ').join('_');
            mismatchObj[modifiedKey] = { po: lineitemOCRPOMismatch[key][mismatchKey].po.value, ocr: lineitemOCRPOMismatch[key][mismatchKey].ocr.value };
        })
        mismatchObj['title'] = { po: name, ocr: name };
        mismatchObj['desc'] = { po: desc, ocr: desc };
        ocrMismatch['invoiceLineItem'].push(mismatchObj);
    })

    return { ocrMismatch }

}
