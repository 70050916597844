


const checkRCMStatusonLineItem = (invoices) => {
    let node =false
    invoices.forEach(item=>{
        if(item.rcItem) {
            node = true;
        }
    })
    return !node;
}


const checkRCMStatusonInvoiceLevel = (details) => {

    let { isRcm} = typeof details.content === 'string' ? JSON.parse(details.content) : details.content;
    return !isRcm
}


export {checkRCMStatusonInvoiceLevel, checkRCMStatusonLineItem}