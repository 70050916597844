import React from 'react';
import './index.css';
import PDF_ICON from './pdf.png';
import DOC_ICON from './doc.png';
import EXCEL_ICON from './excel.png';
import IMG from './img.png'
import EML from './eml.png';
import ZIP from './zip.png'

function PhotoTile(props) {
    let ICON = '';
    let FLAG = false;

    if(props.link){
        ICON = props.link
    }else{
        ICON = IMG;
        FLAG = true;
    }

    if(ICON.substring(ICON.length - 3).toLowerCase()==='pdf'){
        ICON = PDF_ICON;
    }else if(ICON.substring(ICON.length - 3).toLowerCase()==='doc'){
        ICON = DOC_ICON;
    } else if(ICON.substring(ICON.length - 3).toLowerCase()==='eml'){
        ICON = EML;
    }else if(ICON.substring(ICON.length - 3).toLowerCase()==='csv' || ICON.substring(ICON.length - 4).toLowerCase()==='xlsx'){
        ICON = EXCEL_ICON;
    }else if(ICON.substring(ICON.length-3).toLowerCase()==='zip'){
        ICON = ZIP;
    }

    return (
        <div className='Attachment-Thumb' style={{position: 'relative', ...props.style}}>
            {ICON!==ZIP ? <div className={FLAG?'':'Voucher-attachment'} onClick={(e)=>{e.stopPropagation(); if(props.viewOnly) {props.onClick()}  else {if(!FLAG){if(props.onClick){props.onClick()}else{window.open(props.link, '_blank')}}}}} style={{minWidth: '70px', maxWidth: '70px', height: '70px', margin: '7px', cursor: 'pointer', borderRadius: '15px', border: '1px solid var(--border-color)', backgroundColor: ' var(--off-white)', backgroundImage: `url('${ICON}')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}></div>:
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBlock: 20,
            minWidth: '70px',
            maxWidth: '70px',
            height: '70px',
            margin: '7px',
            cursor: 'pointer',
            borderRadius: '15px',
            border: '1px solid var(--border-color)',
            backgroundColor: 'var(--off-white)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
        }}>
            <div 
                className={FLAG ? '' : 'Voucher-attachment'} 
                onClick={(e) => {
                    e.stopPropagation(); 
                    if (props.viewOnly) {
                        props.onClick();
                    } else {
                        if (!FLAG) {
                            if (props.onClick) {
                                props.onClick();
                            } else {
                                window.open(props.link, '_blank');
                            }
                        }
                    }
                }}
                style={{
                    minWidth: '55px',
                    maxWidth: '55px',
                    height: '55px',
                    cursor: 'pointer',
                    borderRadius: '15px',
                    border: '1px solid var(--border-color)',
                    backgroundColor: 'var(--off-white)',
                    backgroundImage: `url('${ICON}')`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}
            ></div>
        </div>
        
    }
        {
            props.onDelete?
            <div className='delete' onClick={props.onDelete}>
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                </svg>
            </div>
            :<></>
        }
        </div>
    );
}

export default PhotoTile;
