import React, { useState } from 'react';
import { Items } from './Items';
import { SidePane } from '../../../../../Common/fSidePane/RightPane';


export const CreateBatch = ({ onClose, onSubmit, item, viewBatch = false }) => {
    const batches = item.grnBatchItems
    const [rows, setRows] = useState(batches ? batches.map((item) => ({ id: Date.now(), batch: item.batchNumber, qty: item.batchQtty })) : [{ id: 1, batch: "", qty: "" }]);

    return (
        <SidePane
            onClose={onClose}
            button={false}
        >
            <div className='FadeRight-Effect' style={{ fontSize: '12px', width: '100%' }}>
                <div style={{ fontWeight: 600, fontSize: '18px', lineHeight: '1.3' }} >Batch Details</div>
                <div style={{ color: 'var(--text-color)', marginBottom: '35px', fontWeight: 600, fontSize: '24px', lineHeight: '1.3' }}>Grn item batch</div>
                <Items
                    setRows={setRows}
                    viewOnly={viewBatch}
                    rows={rows}
                    item={item}
                    onSubmit={onSubmit}
                />
            </div>
        </SidePane>
    )
}