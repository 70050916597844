import React from 'react';
import {connect} from 'react-redux';
import {HalfModal} from '../../../../../Common/fModal/HalfModal';
import Template1 from './Template1';
import Suncity from './Suncity';
import Onemg from './1mg';
import Nykaa from './Nykaa/index.js'
import Rajasthan from './Rajasthan/index';
import Fino from './Fino';
import Epifi from './Epifi';
import CitymallN from './CitymallN';
import XpressBees from './XpressBees';
import Purplle from './Purplle';
import IIFL from './IIFL'
import Zippmat from './Zippmat';
import Rapido from './Rapido';
import Blissclub from './Blissclub';
import TigerAnalytics from './TigerAnalytics';
import Dtdc from './Dtdc';
import Udaan from './Udaan';
import NinjaCart from './NinjaCart'
import Stelmec from './Stelmec'
import SBFCPdf from './SBFC';
import DnvTest from './DnvTest';
import Edmee from './Edmee';

const  DATA = {
    SUNCTY : Suncity,
    TATA1MG : Onemg,
    NYKAA:Nykaa,
    RR:Rajasthan,
    FINO:Fino,
    EPIFI:Epifi,
    EPIFITECH:Epifi,
    ROYALS:Rajasthan,
    CTYML:CitymallN,
    XPRSBS:XpressBees,
    PRPLTEST:Purplle,
    Purplle:Purplle,
    TIIFL:IIFL, 
    TZPM: Zippmat,
    POCRAP: Rapido,
    ZIPMAT:Zippmat,
    BLISCLB: Blissclub,
    BLC: Blissclub,
    ZIPPMAT:Zippmat,
    TGRANL: TigerAnalytics,
    TGRANAL:TigerAnalytics,
    DTDC:Dtdc,
    DTDC2:Dtdc,
    UDAANTEST:Udaan,
    UDAAN:Udaan,
    NJRT:NinjaCart,
    NIJCART:NinjaCart,
    SBFC:SBFCPdf,
    SBF:SBFCPdf,
    STELMC:Stelmec,
    dnvtest:DnvTest,
    EDMEE:Edmee
}


const PoDocument  = (props) => {
    
    let COMPONENT =   DATA[props.tenant];
    if(!COMPONENT)
        COMPONENT = Template1;
    return (
            <HalfModal
                title="Purchase Order"
                desc="Purchase Order"
                container={{
                    left:`${props.tenant==="UDAANTEST" || props.tenant==="UDAAN"?"15%":"30%"}`
                }}
                close={() => {
                   props.close();
                }}
            >
               <COMPONENT {...props} tenant={(props && props.tenant)?props.tenant:""}/>
            </HalfModal>
        )
}



export default connect((state)=>{
    return {
        tenant:state.auth.config.tenant
    }
},null)(PoDocument);
