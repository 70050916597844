import React, { useCallback, useState } from 'react'
import Accordion from "../../../../../Common/Accordion"
import EditableBorderTable from "../../../../../Common/EditableBorderTable/EditableBorderTable"
import _ from 'lodash'
import { Modal } from '../../../../../Common/fModal/Modal'

const BajajMismatchModal = ({ onClose, onSubmit, mismatchData, tenant }) => {
    const [originalMismatchData,setOriginalMismatchData] = useState(mismatchData);

    const [editableOcrData, setEditableOcrData] = useState({});
    const [editableLrOcrData, setEditableLrOcrData] = useState({});
    const [editableEwayBillOcrData, setEditableEwayBillOcrData] = useState({});
    const [editableIrnQrData, setEditableQROcrData] = useState({});
    const [lineItemEditableData,setLineItemEditableData] = useState({});

    const { headerLevelMismatch, ocrInvoiceQRMismatch, lineitemOCRPOMismatch, ocrInvoiceLrCopyMismatch, ocrInvoiceEwayBillMismatch, ocrLREwayBillMismatch } = originalMismatchData;
    let isLineItemMismatch = false;
    Object.keys(lineitemOCRPOMismatch).forEach(el => {
        if (!lineitemOCRPOMismatch[el] || Object.keys(lineitemOCRPOMismatch[el]).length) isLineItemMismatch = true; 
    })
    let isHardStop = false;
    // let isHardStop = headerLevelMismatch.length || ocrInvoiceQRMismatch.length || ocrInvoiceLrCopyMismatch.length || ocrInvoiceEwayBillMismatch.length;
    // Object.keys(lineitemOCRPOMismatch).forEach(el => {
    //     if (lineitemOCRPOMismatch[el].length && lineitemOCRPOMismatch[el][0] != "NOT Found"){
    //         isHardStop = (isHardStop || true);
    //     }
    // })
    const handleEdit = (mismatchKey,item,value) => {
        setOriginalMismatchData((prev) => {
            const updatedMismatchData = { ...prev };
            const categories = [
                "headerLevelMismatch",
                "ocrInvoiceQRMismatch",
                "ocrInvoiceLrCopyMismatch",
                "ocrInvoiceEwayBillMismatch",
                "ocrLREwayBillMismatch",
            ];
    
            categories.forEach((category) => {
                if (
                    updatedMismatchData[category] && updatedMismatchData[category].hasOwnProperty(mismatchKey) &&
                    updatedMismatchData[category][mismatchKey] && updatedMismatchData[category][mismatchKey].hasOwnProperty(item)
                ) {
                    updatedMismatchData[category] = {
                        ...updatedMismatchData[category],
                        [mismatchKey]: {
                            ...updatedMismatchData[category][mismatchKey],
                            [item]: {...updatedMismatchData[category][mismatchKey][item],value:value},
                        },
                    };
                }
            });
            return updatedMismatchData;
        });
    }

    const debouncedHandleEdit = useCallback(_.debounce(handleEdit,300),[]);

    // const editableOcrData = {}, editableLrOcrData = {}, editableEwayBillOcrData = {}, editableQROcrData = {};
    const submitHandler = () => { 
        const ocrMismatch = {};
        ocrMismatch['poOcrData'] = {};
        // let error = null 
        Object.keys(headerLevelMismatch).forEach(key => {
            if(!headerLevelMismatch[key]["mismatch"]) return;
            let modifiedKey = key.split(' ').join('_');
            let {ocr={},po={}} = headerLevelMismatch[key];
            // if (!po.value && !ocr.value) {
            //     error = key;
            // }
            ocrMismatch['poOcrData'][modifiedKey] = {ocr:ocr.value,po:po.value};
        })
        // if (error) {
        //     showToast({
        //         type: 'error',
        //         message: `${error} is required` 
        //     });
        //     return;
        // }

        ocrMismatch['invoiceQrData'] = {};
        Object.keys(ocrInvoiceQRMismatch).forEach(key => {
            if(!ocrInvoiceQRMismatch[key]["mismatch"]) return;
            let modifiedKey = key.split(' ').join('_');
            let {ocr={},qr={}} = ocrInvoiceQRMismatch[key];
            ocrMismatch['invoiceQrData'][modifiedKey] = {ocr:ocr.value,qr:qr.value};
        })

        ocrMismatch['invoiceLrData'] = {};
        Object.keys(ocrInvoiceLrCopyMismatch).forEach(key => {
            if(!ocrInvoiceLrCopyMismatch[key]["mismatch"]) return;
            let modifiedKey = key.split(' ').join('_');
            let {ocr={},lr={}} = ocrInvoiceLrCopyMismatch[key];
            ocrMismatch['invoiceLrData'][modifiedKey] = {ocr:ocr.value,lr:lr.value};
        })

        ocrMismatch['invoiceEwayData'] = {};
        Object.keys(ocrInvoiceEwayBillMismatch).forEach(key => {
            if(!ocrInvoiceEwayBillMismatch[key]["mismatch"]) return;
            let modifiedKey = key.split(' ').join('_');
            let {ocr={},eway={}} = ocrInvoiceEwayBillMismatch[key];
            ocrMismatch['invoiceEwayData'][modifiedKey] = {ocr: ocr.value,eway: eway.value};
        })

        ocrMismatch['lrEwayData'] = {};
        Object.keys(ocrLREwayBillMismatch).forEach(key => {
            if(!ocrLREwayBillMismatch[key]["mismatch"]) return;
            let modifiedKey = key.split(' ').join('_');
            let {lr={},eway={}} = ocrLREwayBillMismatch[key];
            ocrMismatch['lrEwayData'][modifiedKey] = {lr:lr.value,eway:eway.value};
        })
        ocrMismatch['inoviceLineItem'] = [];
        Object.keys(lineitemOCRPOMismatch).forEach(key => {
            if(!lineitemOCRPOMismatch[key]) return;
            let [,name,,desc] = key.split('-');
            let mismatchObj = {};
            Object.keys(lineitemOCRPOMismatch[key]).forEach(mismatchKey => {
                if(!lineitemOCRPOMismatch[key][mismatchKey]["mismatch"]) return;
                let modifiedKey = mismatchKey.split(' ').join('_');
                mismatchObj[modifiedKey] = {po:lineitemOCRPOMismatch[key][mismatchKey].po.value,ocr:lineitemOCRPOMismatch[key][mismatchKey].ocr.value};
            })
            mismatchObj['title'] = { po: name, ocr: name };
            mismatchObj['desc'] = { po: desc, ocr: desc };
            ocrMismatch['inoviceLineItem'].push(mismatchObj);
        })
        editableOcrData.invoiceItems = [];
        Object.keys(lineItemEditableData).forEach(key => {
            let [,name,,desc] = key.split('-');
            editableOcrData.invoiceItems.push({
                ...lineItemEditableData[key],
                title: name,
                desc
            })
        })
        onSubmit({editableOcrData,editableLrOcrData,editableEwayBillOcrData,editableIrnQrData,ocrMismatch});
    }


    return (
        <Modal show={true} title='OCR Mismatch' des='' close={onClose} hideButton={isHardStop} buttonName={isHardStop?undefined:"Submit Invoice"} onSave={isHardStop?undefined:submitHandler} width={'70vw'} contentStyle={{ maxHeight: 700 }} modalStyle={{maxWidth:"60vw"}}>
            {headerLevelMismatch && !!Object.keys(headerLevelMismatch).length &&
                <Accordion defaultExpanded={true} title={'OCR-Invoice VS PO'} uniqueKey="OCR VS PO'">
                    <EditableBorderTable className="bg-white" rows={
                        Object.keys(headerLevelMismatch).filter(el => (tenant === "TRDT" || (tenant === "bajajelec" && headerLevelMismatch[el]["mismatch"]))).map((mismatchKey) => {
                            let {po:poData,ocr:ocrData} = headerLevelMismatch[mismatchKey];
                            // const value = poData && poData.value;
                            // const invalidInput = {
                            //     border: '1px solid red',
                            //     backgroundColor: '#ffe6e6',
                            //     outline: 'none'
                            // }
                            return(
                                [{
                                    data: mismatchKey
                                }, 
                                {
                                    data: poData && poData.value
                                }, 
                                {
                                    data: ocrData && ocrData.value,
                                    editable: headerLevelMismatch[mismatchKey]["mismatch"],
                                    // showError:true,
                                    placeHolder: mismatchKey,
                                    style: {width:'75%'},
                                    type: (mismatchKey).toLowerCase().includes('date')? 'date':'text',
                                    onChange: (e) => {
                                        let val = e.target.value;
                                        if(ocrData.type === 'number') val = Number(val);
                                        setEditableOcrData(prev => ({
                                            ...prev,
                                            ...(ocrData && ocrData.key ? { [ocrData.key]: val } : {})
                                        }))
                                        debouncedHandleEdit(mismatchKey,"ocr",val);
                                        // editableOcrData[mismatchKey] = val;
                                    }
                                }]
                            )
                        })} headers={["Type", "PO", "OCR-Invoice"]} />
                </Accordion>
            }

            {ocrInvoiceQRMismatch && !!Object.keys(ocrInvoiceQRMismatch).length &&
                <Accordion defaultExpanded={true} title={'OCR-Invoice VS QR'} uniqueKey="OCR VS QR'">
                    <EditableBorderTable className="bg-white" rows={
                        Object.keys(ocrInvoiceQRMismatch).filter(el => (tenant === "TRDT" || (tenant === "bajajelec" && ocrInvoiceQRMismatch[el]["mismatch"]))).map((mismatchKey) => {
                            let {qr:qrData,ocr:ocrData} = ocrInvoiceQRMismatch[mismatchKey];
                            return(
                                [{
                                    data: mismatchKey
                                }, 
                                {
                                    data: ocrData && ocrData.value,
                                    editable: ocrInvoiceQRMismatch[mismatchKey]["mismatch"],
                                    placeHolder: mismatchKey,
                                    style: {width:'75%'},
                                    type: (mismatchKey).toLowerCase().includes('date')? 'date':'text',
                                    onChange: (e) => {
                                        let val = e.target.value;
                                        if(ocrData.type === 'number') val = Number(val);
                                        setEditableOcrData(prev=>({
                                            ...prev,
                                            ...(ocrData && ocrData.key ? { [ocrData.key]: val } : {})
                                        }))
                                        debouncedHandleEdit(mismatchKey,"ocr",val);
                                        // editableOcrData[mismatchKey] = val;
                                    }
                                }, 
                                {
                                    data: qrData && qrData.value,
                                    editable: ocrInvoiceQRMismatch[mismatchKey]["mismatch"],
                                    placeHolder: mismatchKey,
                                    style: {width:'75%'},
                                    type: (mismatchKey).toLowerCase().includes('date')? 'date':'text',
                                    onChange: (e) => {
                                        let val = e.target.value;
                                        if(qrData.type === 'number') val = Number(val);
                                        setEditableQROcrData((prev)=>({
                                            ...prev,
                                            ...(qrData && qrData.key ? { [qrData.key]: val } : {})
                                        }))
                                        debouncedHandleEdit(mismatchKey,"qr",val);
                                        // editableQROcrData[mismatchKey] = val;
                                    }
                                }]
                            )
                        })} headers={["Type", "OCR-Invoice", "QR"]} />
                </Accordion>
            }

            {ocrInvoiceLrCopyMismatch && !!Object.keys(ocrInvoiceLrCopyMismatch).length &&
                <Accordion defaultExpanded={true} title={'OCR-Invoice VS LR-Copy'} uniqueKey="OCR VS LR-Copy'">
                    <EditableBorderTable className="bg-white" rows={
                        Object.keys(ocrInvoiceLrCopyMismatch).filter(el => (tenant === "TRDT" || (tenant === "bajajelec" && ocrInvoiceLrCopyMismatch[el]["mismatch"]))).map((mismatchKey) => {
                            if(tenant === "bajajelec" && !ocrInvoiceLrCopyMismatch[mismatchKey]["mismatch"]) return null;
                            let {lr:lrData,ocr:ocrData} = ocrInvoiceLrCopyMismatch[mismatchKey];
                            return(
                                [{
                                    data: mismatchKey
                                }, 
                                {
                                    data: ocrData && ocrData.value,
                                    editable: ocrInvoiceLrCopyMismatch[mismatchKey]["mismatch"],
                                    placeHolder: mismatchKey,
                                    style: {width:'75%'},
                                    type: (mismatchKey).toLowerCase().includes('date')? 'date':'text',
                                    onChange: (e) => {
                                        let val = e.target.value;
                                        if(ocrData.type === 'number') val = Number(val);
                                        setEditableOcrData(prev=>({
                                            ...prev,
                                            ...(ocrData && ocrData.key ? { [ocrData.key]: val } : {})
                                        }))
                                        debouncedHandleEdit(mismatchKey,"ocr",val);
                                        // editableOcrData[mismatchKey] = val;
                                    }
                                }, 
                                {
                                    data: lrData && lrData.value,
                                    editable: ocrInvoiceLrCopyMismatch[mismatchKey]["mismatch"],
                                    placeHolder: mismatchKey,
                                    style: {width:'75%'},
                                    type: (mismatchKey).toLowerCase().includes('date')? 'date':'text',
                                    onChange: (e) => {
                                        let val = e.target.value;
                                        if(lrData.type === 'number') val = Number(val);
                                        let key = mismatchKey;
                                        if(mismatchKey === "Invoice Date") key = "Date"
                                        setEditableLrOcrData((prev)=>({
                                            ...prev,
                                            ...(lrData && lrData.key ? { [lrData.key]: val } : {})
                                        }))
                                        debouncedHandleEdit(key,"lr",val);
                                        // editableLrOcrData[key] = val;
                                    }
                                }]
                            )
                        })} headers={["Type", "OCR-Invoice", "LR Copy"]} />
                </Accordion>
            }

            {ocrInvoiceEwayBillMismatch && !!Object.keys(ocrInvoiceEwayBillMismatch).length &&
                <Accordion defaultExpanded={true} title={'OCR-Invoice VS E-Way Bill'} uniqueKey="OCR VS E-Way Bill'">
                    <EditableBorderTable className="bg-white" rows={
                        Object.keys(ocrInvoiceEwayBillMismatch).filter(el => (tenant === "TRDT" || (tenant === "bajajelec" && ocrInvoiceEwayBillMismatch[el]["mismatch"]))).map((mismatchKey) => {
                            let {eway:ewayData,ocr:ocrData} = ocrInvoiceEwayBillMismatch[mismatchKey];
                            return(
                                [{
                                    data: mismatchKey
                                }, 
                                {
                                    data: ocrData && ocrData.value,
                                    editable: ocrInvoiceEwayBillMismatch[mismatchKey]["mismatch"],
                                    placeHolder: mismatchKey,
                                    style: {width:'75%'},
                                    type: (mismatchKey).toLowerCase().includes('date')? 'date':'text',
                                    onChange: (e) => {
                                        let val = e.target.value;
                                        if(ocrData.type === 'number') val = Number(val);
                                        setEditableOcrData(prev=>({
                                            ...prev,
                                            ...(ocrData && ocrData.key ? { [ocrData.key]: val } : {})
                                        }))
                                        debouncedHandleEdit(mismatchKey,"ocr",val);
                                        // editableOcrData[mismatchKey] = val;
                                    }
                                }, 
                                {
                                    data: ewayData && ewayData.value,
                                    editable: ocrInvoiceEwayBillMismatch[mismatchKey]["mismatch"],
                                    placeHolder: mismatchKey,
                                    style: {width:'75%'},
                                    type: (mismatchKey).toLowerCase().includes('date')? 'date':'text',
                                    onChange: (e) => {
                                        let val = e.target.value;
                                        if(ewayData.type === 'number') val = Number(val);
                                        let key = mismatchKey;
                                        if(mismatchKey === "Invoice Date") key = "Date"
                                        setEditableEwayBillOcrData((prev)=>({
                                            ...prev,
                                            ...(ewayData && ewayData.key ? { [ewayData.key]: val } : {})
                                        }))
                                        debouncedHandleEdit(key,"eway",val);
                                        // editableEwayBillOcrData[key] = val;
                                    }
                                }]
                            )
                        })} headers={["Type", "OCR-Invoice", "E-way Bill"]} />
                </Accordion>
            }

            {ocrLREwayBillMismatch && !!Object.keys(ocrLREwayBillMismatch).length &&
                <Accordion defaultExpanded={true} title={'LR-Copy VS E-Way Bill'} uniqueKey="LR-Copy VS E-Way Bill'">
                    <EditableBorderTable className="bg-white" rows={
                        Object.keys(ocrLREwayBillMismatch).filter(el => (tenant === "TRDT" || (tenant === "bajajelec" && ocrLREwayBillMismatch[el]["mismatch"]))).map((mismatchKey) => {
                            let {eway:ewayData,lr:lrData} = ocrLREwayBillMismatch[mismatchKey];
                            return (
                                [{
                                    data: mismatchKey
                                },
                                {
                                    data: lrData && lrData.value,
                                    editable: ocrLREwayBillMismatch[mismatchKey]["mismatch"],
                                    placeHolder: mismatchKey,
                                    style: {width:'75%'},
                                    type: (mismatchKey).toLowerCase().includes('date')? 'date':'text',
                                    onChange: (e) => {
                                        let val = e.target.value;
                                        if(lrData.type === 'number') val = Number(val);
                                        let key = mismatchKey;
                                        if(mismatchKey === "Invoice Date") key = "Date"
                                        setEditableLrOcrData((prev)=>({
                                            ...prev,
                                            ...(lrData && lrData.key ? { [lrData.key]: val } : {})
                                        }))
                                        debouncedHandleEdit(key,"lr",val);
                                        // editableLrOcrData[key] = val;
                                    }
                                },
                                {
                                    data: ewayData && ewayData.value,
                                    editable: ocrLREwayBillMismatch[mismatchKey]["mismatch"],
                                    placeHolder: mismatchKey,
                                    style: {width:'75%'},
                                    type: (mismatchKey).toLowerCase().includes('date')? 'date':'text',
                                    onChange: (e) => {
                                        let val = e.target.value;
                                        if(ewayData.type === 'number') val = Number(val);
                                        let key = mismatchKey;
                                        if(mismatchKey === "Invoice Date") key = "Date"
                                        setEditableEwayBillOcrData((prev)=>({
                                            ...prev,
                                            ...(ewayData && ewayData.key ? { [ewayData.key]: val } : {})
                                        }))
                                        debouncedHandleEdit(key,"eway",val);
                                        // editableEwayBillOcrData[key] = val;
                                    }
                                },]
                            )
                        })} headers={["Type", "LR-Copy", "E-way Bill"]} />
                </Accordion>
            }

            {isLineItemMismatch &&
                Object.keys(lineitemOCRPOMismatch).map((el) => {
                    let notFound = false;
                    if(!lineitemOCRPOMismatch[el]) notFound = true;
                    else if(Object.keys(lineitemOCRPOMismatch[el]).length === 0) return <></>;

                    return (
                        <Accordion uniqueKey={el} title={el.split('-').slice(1).join(',')}>
                            {notFound ?
                                <p>Line item not found in OCR</p> :
                                <EditableBorderTable className="bg-white" rows={
                                    Object.keys(lineitemOCRPOMismatch[el]).filter(item => (tenant === "TRDT" || (tenant === "bajajelec" && lineitemOCRPOMismatch[el][item]["mismatch"]))).map((mismatchKey) => {
                                        let {po:poData,ocr:ocrData} = lineitemOCRPOMismatch[el][mismatchKey];

                                        return(
                                            [{
                                                data: mismatchKey
                                            }, 
                                            {
                                                data: poData && poData.value
                                            },
                                            {
                                                data: ocrData && ocrData.value,
                                                editable: lineitemOCRPOMismatch[el][mismatchKey]["mismatch"],
                                                placeHolder: mismatchKey,
                                                style: {width:'75%'},
                                                onChange: (e) => {
                                                    let val = e.target.value;
                                                    let lineItem = lineItemEditableData && lineItemEditableData[el]? lineItemEditableData[el] : {};
                                                    if(ocrData && ocrData.key) lineItem[ocrData.key] = val;
                                                    setLineItemEditableData((prev)=>({
                                                        ...prev,
                                                        [el]: lineItem
                                                    })) 
                                                }
                                            }]
                                        )
                                    })} headers={["Type", "PO", "OCR-Invoice"]} />
                            }
                        </Accordion>
                    )
                })
            }
        </Modal>
    )
}

export default BajajMismatchModal