import React, { useState } from "react";
import { useEffect, useRef } from "react";
import { Modal } from "../../../../Common/fModal/Modal";
import { showToast } from "../../../../Common/fToast/ToastCallback";
import { Select } from "../../../../Common/Class/Select";
import ProductSearch from "../../Submit/ProductSearch";
import SearchHsn from "../../Submit/SearchHsn";
import { FunctionalInput } from "../../../../Common/fInputs";
import { Form } from "../../../../Common/Class/Form";
import { useVendorPaymentTerm } from "../../../../HooksUtility/Vendor/getPaymentTerm";
import { get } from "../../../../../Model/Network/Config/Axios";

const AddLineItem = ({categoryId,onClose,vendorId,onSubmit,singleLine,bill_to}) =>{

	let QTTY = useRef(), PRICE = useRef(), MATERIAL = useRef(),NAME=useRef();
    const FORM1 = useRef();
    const FORM = useRef();
    let isSingleLine = singleLine?singleLine==='single':false;
    const [singleService, setSingleService] = useState({});
	const [services, setServices] = useState([]);
    const [discount,] = useState(0);
	let paymentTerms = useVendorPaymentTerm();

	useEffect(() => {
		paymentTerms.fetchVendorTerms(vendorId);
		get(`vendor/config/${categoryId}/services`, (e, r) => {
			if (r) {
				setServices(r.items.map(i => {
					return {
						value: i.id,
						label: i.name,
						cess:i.cess
					}
				}))
				if (r.items.length > 0) {
					setSingleService({
						label: r.items[0].name,
						value: r.items[0].id,
						cess:r.cess
					})
				}

			}
		})
	}, [])
  
    useEffect(()=>{
      if(isSingleLine && singleService.value) FORM.current.loadForm(`vendor::${singleService.value}`)
    },[singleService])

    useEffect(()=>{
		if(!isSingleLine && categoryId) {
		  FORM1.current.loadForm(`invoice::item::${categoryId}`)
		}
	},[categoryId])

  let masterKeyValue = {};
  if(bill_to) {
    masterKeyValue.bill_to=bill_to
  }
  
return(
    <div>
         <Modal
          show={true}
          des="Add line item details to add one"
          title={"Add Line Item"}
          style={{
            save: {
              background: "var(--primary-color)",
              border: "2px solid var(--primary-color)",
              padding: "8px 15px",
              width: "100%",
              textAlign: "center",
            },
          }}
          close={onClose}
          onSave={() => {
            let product = NAME.current.getValue();
            let hsn = MATERIAL.current.getValue();
            let qtty = +QTTY.current.value;
            let price = +PRICE.current.value;

            if (!product || !product.title) {
              showToast({
                type: "error",
                message: "Please enter Product name",
              });
              return;
            }
            if (!qtty) {
              showToast({
                type: "error",
                message: "Please enter Quantity",
              });
              return;
            }
            if (qtty < 0) {
              showToast({
                type: "error",
                message: "Please enter the valid quantity",
              });
              return;
            }
            if (!hsn) {
              showToast({
                type: "error",
                message: "Please enter Material Code",
              });
              return;
            }

            let discountSlab = discount;
            if (!discountSlab) discountSlab = 0;
            let base = price * qtty;
            let grossBase = base - base * (discountSlab / 100);

              FORM1.current.getKeyValue((cb) => {
                let item = {
                  name:product.title,
                  qtty,
                  price,
                  hsn,
                  base,
                  grossBase,
                  forms:cb,
                  total:grossBase,
                  service: singleService,
                  key: `${singleService.value}||${product.title}`,
                };
                if(product.id){
                  item['productId'] = product.id;
                }
                 onSubmit(item)
               })
          }}
          buttonName="Add Line Item"
        >
          <Select
            value={singleService}
            items={services}
            placeholder="Select Service"
            select={setSingleService}
          />
          <ProductSearch placeholder={"Name"} ref={NAME} categoryId={categoryId} />
          <SearchHsn ref={MATERIAL} />
          <FunctionalInput type="number" title="Qtty" ref={QTTY} onChange={(e)=>{
            let value = e.target.value;
            if(value<0) {
                showToast({
                  type: "error",
                  message: "Please enter the valid Qtty",
                });
                QTTY.current.value= 0;
            }
          }} />
          <FunctionalInput type="number" title="Price per unit" ref={PRICE} onChange={(e)=>{
            let value = e.target.value;
            if(value<0) {
                showToast({
                  type: "error",
                  message: "Please enter the valid price",
                });
                PRICE.current.value= 0;
            }
          }} />
      {!isSingleLine ?
        <Form
          label="Invoice Line Item Form"
          desc="All field related to vendor form"
          masterKeyValue={masterKeyValue}
          handler={ref => {
            FORM1.current = ref
          }
          } /> : <Form
          label="Invoice Service Form"
          desc="All field related to vendor form"
          masterKeyValue={masterKeyValue}

          handler={ref => {
            FORM.current = ref
          }
          } />}
          </Modal>
    </div>
)

}

export default AddLineItem