import React, { useEffect, useRef, useState } from "react";
import { FunctionalInput } from "../../../../Common/fInputs";
import { Modal } from "../../../../Common/fModal/Modal";
import { Select } from "../../../../Common/Class/Select";
import { get } from "../../../../../Model/Network/Config/Axios";
import ProductSearch from "../../Submit/ProductSearch";
import _ from 'lodash';
import TdsLineItem from "./TdsLineItem";
const LineItemModal = ({ close, edit, onSave, categoryId, category,  disableUpdate, searchProductByService = false,vendorId,vendor,tdsSlabs,tdsAtLineItemAutomation=false,contractId,tdsAtLineItemInContract=false,tdsAtLineItem=false }) => {

  let categoryList = ["BCCD CRM", "BCCD Non - CRM"]
  const QTY = useRef(),
    MATERIAL = useRef(),
    UNIT = useRef(),
    PRICE = useRef(),
    NAME = useRef(),
    GLCODE = useRef();

  const [, setProduct] = useState(undefined);
  const [updated, setUpdated] = useState({
    content: {}
  });
  const [singleService, setSingleService] = useState({});
  const [services, setServices] = useState([]);

  useEffect(() => {
    if (edit) {
      setProduct({ hsn: edit.hsn, name: edit.desc, label: edit.desc })
      QTY.current.value = edit.qtty || 0
      PRICE.current.value = edit.price
      MATERIAL.current.value = edit.hsn || ''
      UNIT.current.value = edit.unit || ''
      
      if (edit.service) {
        setSingleService({
          label: edit.service,
          value: null,
        })
        NAME.current.setValue({ title: edit.desc, id: edit.productId })
      }
    }
  }, [edit])

  useEffect(() => {
    if (categoryId) {
      get(`vendor/config/${categoryId}/services`, (e, r) => {
        if (r) {
          setServices(r.items.map(i => {
            return {
              value: i.id,
              label: i.name,
              cess: i.cess
            }
          }))
          if (r.items.length > 0) {
            setSingleService({
              label: r.items[0].name,
              value: r.items[0].id,
              cess: r.cess
            })
          }

        }
      })
    }
  }, [categoryId])

  const updateFunction = (key, value) => {
    let updateObject = { ...updated };
    updateObject["content"][key] = value;
    setUpdated(updateObject);
  }


  return (
    <Modal
      show={true}
      des="Edit line item quantity"
      title={"Edit Line Item"}
      style={{
        save: {
          background: "var(--primary-color)",
          border: "2px solid var(--primary-color)",
          padding: "8px 15px",
          width: "100%",
          color: "white",
          curor: "pointer",
          textAlign: "center",
        },
      }}
      buttonName="Update"
      close={close}
      onSave={() => {
        let obj = {
          itemId: edit.id,
          ...updated["content"]
        }
        if (Object.keys(updated["content"]).length) {
          onSave(obj)
        }
      }}
    >
      <Select
        disabled={disableUpdate}
        value={singleService}
        items={services}
        placeholder="Select Service"
        select={(service) => {
          updateFunction("serviceId", service.value || null)
          setSingleService(service)
        }}
      />
      <ProductSearch
        disabled={disableUpdate}
        services={searchProductByService && singleService && singleService.label}
        placeholder={"Name"}
        ref={NAME}
        categoryId={categoryId}
        onChangeText={(item) => {
          item && item.id && updateFunction("productId", item.id)
          item && item.title && updateFunction("name", item.title)
          if (item && item.glCode) {
            updateFunction("gl", item.glCode)
            GLCODE.current.value = item.glCode
          }
        }}
      />
      <FunctionalInput
        disabled={disableUpdate}
        type="text"
        title="Material Code (HSN/SAC)"
        onChange={(e) => {
          updateFunction("hsn", e.target.value)
        }}
        ref={MATERIAL}
      />
      <FunctionalInput
        disabled={disableUpdate}
        type="text"
        title="Unit"
        onChange={(e) => {
          updateFunction("unit", e.target.value)
        }}
        ref={UNIT}
      />
      <FunctionalInput
        disabled={_.includes(categoryList, category) || disableUpdate}
        ref={QTY}
        title="Enter Quantity"
        onChange={(e) => {
          updateFunction("qty", Number(e.target.value))
        }}
        type="number"
      />
      <FunctionalInput
        disabled={disableUpdate}
        ref={PRICE}
        title="Enter Price"
        type="number"
        onChange={(e) => {
          updateFunction("price", Number(e.target.value))
        }}
      />
      {/* currently tds at line item is not working in case of foreign currency, needs to be handled from BE first */}
      {((contractId && tdsAtLineItemInContract))||(tdsAtLineItemAutomation?(!contractId):(tdsAtLineItem && !contractId))?<div style={{ width: '100%', marginTop: 5 }}>
              <TdsLineItem 
                 disabled={disableUpdate}
                 vendorId={vendorId}
                 onSelect={(obj) =>  updateFunction("tds",obj)}
                 value={edit.tds ? edit.tds:null}
                 tdsSlabs={(tdsSlabs)?tdsSlabs:undefined}
                 vendor={vendor}
              />
            </div>:null
      }
    </Modal>
  );
};

export default LineItemModal;
