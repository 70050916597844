import React, { useEffect, useState } from 'react'
import './ocr.css'
import HeaderLevelData from './HeaderLevelData'
import Accordion from "../Accordion";
import NewTable from './NewTable.js'
import { Modal } from '../fModal/Modal'
import { Select } from '../Class/Select';
import { showToast } from '../fToast/ToastCallback';

const IrnInvoiceData = ({ details, setOcrDetails, onDiscard,services,poInvoice ,onClose}) => {

   const  items =  (details && details.signedInvoiceData && details.signedInvoiceData.ItemList)||[]
    const [checks, setChecks] = useState({
        amount: true,
        cgst: true,
        igst: true,
        sgst: true,
        date: true,
        vendor: true,
        address: true,
        invoice: true,
        irn: true,
        serveService: true,
        placeSupply: true,
        defaultService: undefined,
        selectLineItem: true,
        ocrLineItem: items && items.map(item => {
            let base = Number(item.UnitPrice? item.UnitPrice: 0) * Number(item.Qty?item.Qty : 0);
            let grossBase = base - (base * ((item.discount?item.discount : 0) / 100));
            let tax = Number(item.taxAmount?item.taxAmount : 0)
            let cgst = item && item.cgstAmt? item.cgstAmt : 0
            let sgst = item && item.sgstAmt? item.sgstAmt : 0
            let igst = item && item.IgstAmt? item.IgstAmt : 0

            let taxSlab = item.GstRt||0
              tax = Number(cgst||0)+Number(sgst||0)+Number(igst||0)

            return {
                selected: true,
                qtty: item.Qty,
                name: (item.title || item.name || item.desc||item.SlNo),
                hsn: item.HsnCd||item.hsn||'',
                grossBase,
                base,
                price: Number(item.UnitPrice||0),
                tax,
                gst: taxSlab,
                taxSlab: taxSlab,
                total: (tax + grossBase),
                forms: {},
            }
        }),
    })

    const changeItemsService = () => {
        const service = checks.serveService && checks.defaultService
          ? { label: checks.defaultService && checks.defaultService.label && checks.defaultService.label, value: checks.defaultService && checks.defaultService.value && checks.defaultService.value }
          : undefined; 
      
        const updatedItems = checks.ocrLineItem.map(item => ({
          ...item,
          service
        }));
      
        setChecks(prev => ({
          ...prev,
          ocrLineItem: updatedItems
        }));
    }

    useEffect(() => {
        changeItemsService()
    },[checks.serveService,checks.defaultService])

    const handleServiceChangeForAll = (val) => {
        if (checks.serveService && services) {
            const selectedService = services.find(i => i.value === Number(val.value));
            if (selectedService) {
                setChecks(prev => ({
                    ...prev,
                    defaultService:selectedService
                }));
            }
        } else {
            setChecks(prev => ({
                ...prev,
                defaultService:undefined
            }));
        }
    };


    const discardHandler = () => {
        if(onDiscard){
            onDiscard();
            return;
        }
        setOcrDetails({});
    }

    let continueHandler = () => {
        let data = {};
        if (!poInvoice && checks.ocrLineItem && checks.ocrLineItem.length > 0) {
            const selectedItems = checks.ocrLineItem.filter(item => item.selected) || [];

            // Check if any selected items are missing the service
            const itemWithoutService = selectedItems.find(item => 
                !item.service || 
                (item.service.label === undefined && item.service.value === undefined)
            );
        
            if (itemWithoutService) {
                showToast({
                    type:'error',
                    message:`Service Not Found: ${itemWithoutService.name || itemWithoutService.desc}`
                })
                return
            }
            data['ocrLineItem'] = selectedItems;
        }

        setOcrDetails({
            ...data
            

        });
    }

    return (
        <Modal
             modalStyle={{
                minWidth: '80%'
            }}
            close={onClose}
            show={details}
            hideButton={true}
            title="IRN Details"
            des="Review the details of IRN to continue"
            style={{
                save: {
                    height: 45,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }
            }}
        >
            <div className='container'>
                <div className='content-data'>
                    <div className='header-data'>
                        <Accordion title={'IRN Details'} uniqueKey={'IRN Details'} defaultExpanded={true}>
                            <HeaderLevelData details={details} checks={checks} setChecks={setChecks} />
                        </Accordion>
                    </div>
                    <hr />
                    <div className='lineitem-data'>
                        <Accordion title={'Invoice Line Items'} uniqueKey={'Invoice Line Items'} defaultExpanded={true}>
                        {!poInvoice ? (
                            <div className="flex align-center">
                                <input 
                                    type="checkbox"
                                    onChange={() => {
                                        setChecks(prev => ({ 
                                            ...prev,
                                            serveService: !checks.serveService 
                                        }))
                                    }}
                                    checked={checks.serveService}
                                />
                                <div 
                                    className="pd1" 
                                    style={{
                                        fontSize: 12,
                                        color: 'var(--inactive-color)',
                                        marginLeft: '1rem'
                                    }}
                                >
                                    All Line items fall under same service:
                                </div>
                                <div style={{ marginLeft: '1rem', minWidth: '140px'}}>
                                    <Select 
                                        noLabel={true}
                                        value={checks.defaultService || undefined}
                                        items={services}
                                        placeholder="Service"
                                        select={handleServiceChangeForAll}
                                    />
                                </div>
                            </div>
                        ) : null}
                        <NewTable 
                            checks={checks}
                            setChecks={setChecks}
                            poInvoice={poInvoice}
                            services={services}
                        />
                        </Accordion>
                    </div>
                    <footer className='flex align-center' style={{display:'flex',alignItems:'center'}}>
                        <div role='button' onClick={discardHandler} className="btn btn-portlet"  style={{ background: '#ff6464' }}>Discard</div>
                        <div role='button' onClick={continueHandler} className="btn btn-portlet" >Continue</div>
                    </footer>
                </div>
            </div>


        </Modal>
    )
}

export default IrnInvoiceData