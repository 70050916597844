import React from 'react';
import './ocr.css';

const HeaderLevelData = ({ details = {}}) => {
    const { headerRawData, invoiceItems, lineItemRawData, poNumbers, isMultiLineInvoice,success,originalOcr, ...rest } = details;
    return (
        <div className="headerleveldata">
        <div className="chip-container" style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
            {Object.entries(rest).map(([key, value]) => {
                const isAddress = key.toLowerCase().includes('address') && value.split(' ').length > 5; //  if key contains "address" and more then 5 words
                return (
                    <div 
                        key={key} 
                        style={{
                            flex: isAddress ? 'calc(40% - 8px)' : 'calc(20% - 8px)', // Address takes 2 cols, others 1
                            minWidth: isAddress ? '40%' : '20%',
                            background: 'rgb(248 248 246)',
                            padding: '8px 12px',
                            borderRadius: '16px',
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: '60px',
                        }}
                    >
                        <span style={{ fontSize: '12px', color: '#666' }}>{key}</span>
                        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{value}</span>
                    </div>
                );
            })}
        </div>
    </div>
    );
};

export default HeaderLevelData;