import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';

const index = ({title,children,defaultExpanded,uniqueKey, backgroundColor = 'rgb(241 241 241)'}) => {
  return (
    <Accordion  defaultExpanded={defaultExpanded} style={{backgroundColor,overflowX:'scroll'}} key={uniqueKey}>
        <AccordionSummary  id={`panel${uniqueKey}-header`} expandIcon={<i className='fa fa-angle-down'/>} >
                <Typography component="span">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{width:'100%',display:'block'}}>
            <Typography>
                {children}
            </Typography>
        </AccordionDetails>
    </Accordion>
  )
}

export default index