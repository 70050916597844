import React from 'react'

const KeyValue = ({onCheck,name,title,value,checked}) => {
  return (
    <div className='keyValue'>
        <label>{title}</label>
        {name && <input type="checkbox" onChange={onCheck} checked={checked} name={name} />}
        {value && <div>{value}</div>}
    </div>
  )
}

export default KeyValue