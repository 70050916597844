import React from "react";
import { numberWithCommas } from "../../../Utils/NumberToComma";
import { Select } from "../Class/Select";
import { showToast } from "../fToast/ToastCallback";

export function getRandomColor() {
    const colors = ['rgb(143 178 248)', 'rgb(218 195 253)', 'rgb(135 235 212)', 'rgb(233 197 107)', 'rgb(250 163 128)'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
}

function TableContainer({ name, color }) {
    return (
        <div className="text-center flex" style={{ border: '1px solid #d0d0d0', padding: 3, borderRadius: 4 }}>
            <div style={{
                height: '23px',
                width: '3px',
                backgroundColor: color ? color : undefined,
                marginLeft: '1px',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',

            }} />
            <p className="pd0" >{name}</p>
        </div>
    )
}

export default ({ checks,setChecks,poInvoice,services }) => {
    let items = checks && checks.ocrLineItem ? [...checks.ocrLineItem] : []

    const updateLineItem = (index, updatedItem) => {
        const updatedItems = [...checks.ocrLineItem];
        updatedItems[index] = updatedItem;
        setChecks(prev => {   
            return {
                ...prev, 
                ocrLineItem: updatedItems
            };
        });
    };


    return (
        <table className='' style={{ tableLayout: 'auto',border:'1px solid rgb(208, 208, 208)' }} >
            <thead>
                <tr style={{}}>
                        {[...(!poInvoice)?["#"]:[],'Details',...(!poInvoice)?['Service']:[],'Price', 'Base', 'GrossAmount', 'Cess', 'Qty', 'Amount',].map((item, index) => {
                            return (
                                <th style={{ border: 'none', height: 'auto' }} key={index}>
                                    <TableContainer name={item} />
                                </th>
                            )
                        })}
                </tr>

            </thead>
            <tbody style={{}}>
                {items && items.map((item, index) => {
                    return (
                        <tr key={item.id} style={{}}>
                           {!poInvoice? <td>
                                    <input type='checkbox' onChange={()=>{
                                        if(item.selected){
                                            setChecks(prev => ({
                                                ...prev,
                                                selectLineItem: false,
                                            }))
                                        }
                                        let updatedItem = { 
                                            ...item, 
                                            selected: !item.selected
                                        }
                                        updateLineItem(index, updatedItem);
                                        }} checked={item.selected}>
                                        
                                    </input>
                                </td>:null}
                                <td >{item.name || item.desc}</td>
                                {!poInvoice?<td style={{ minWidth: '140px', }}>
                                        <Select
                                        noLabel={true} 
                                        value={item.service||undefined} 
                                        items={services}
                                         placeholder="Service" 
                                        select={(val)=>{
                                            if(checks.serveService){
                                                showToast(({
                                                    type:'error',
                                                    message:"All line items fall under same service is turned on"
                                                }))
                                                return
                                            }
                                            const selectedService = services.find(i => i.value === Number(val.value));
                                                if(selectedService && selectedService.value) {
                                                    let updated = {
                                                        ...item,
                                                        service: {
                                                        label: selectedService.label,
                                                        value: selectedService.value,
                                                    }
                                                    }
                                                    updateLineItem(index, updated);
                                                }
                                        }} />
                                </td>:null}
                            <td>{numberWithCommas(parseFloat(item && item.price ? item.price : 0).toFixed(3)) || 0}</td>
                            <td>{numberWithCommas(parseFloat(item && item.base ? item.base : 0).toFixed(2)) || 0}</td>
                            <td>{numberWithCommas(parseFloat(item && item.grossBase ? item.grossBase : 0).toFixed(2)) || 0}</td>
                            <td>{numberWithCommas(item && item.cessAmount ? item.cessAmount.toFixed(2) : 0)}</td>
                            <td>{parseFloat(item && item.qtty ? item.qtty : 0).toFixed(3)}</td>
                            <td>{numberWithCommas(item && item.total ? item.total.toFixed(2) : 0)}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}