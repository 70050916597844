import React, { useRef, useState } from "react"
import { showToast } from "../../../../../Common/fToast/ToastCallback";
import { FunctionalInput } from "../../../../../Common/fInputs";
import { Modal } from "../../../../../Common/fModal/Modal";

const EwayVerifiy = ({ onClose, gstin, ewayNum, onVerify }) => {
    const Pass = useRef()
    const Username = useRef()
    const [loading, setLoading] = useState(false)

    const verify = () => {
        let username = Username.current.value;
        let password = Pass.current.value;
        if (!username) {
            showToast({
                type: 'error',
                message: 'Please enter username'
            })
            return
        }
        if (!password) {
            showToast({
                type: 'error',
                message: 'Please enter password'
            })
            return
        }
        setLoading(true)
        // get(`/workspace/ewaybill/${workspace}/fetchDetails`, (e, r) => {
        //     if (r) {
        //         onVerify((r && r.data) ? r.data : undefined)
        //     } else {
        //         handleError(e)
        //     }
        //     setLoading(false)
        // }, {
        //     username,
        //     gstin,
        //     password,
        //     ewb_no: ewayNum
        // })
    }


    return (
        <Modal
            show={true}
            des="Fill details to verify  e-way bill no"
            title={"E-Way Bill Verification"}
            buttonName="Verify"
            stateLoading={loading}
            close={onClose}
            onSave={verify}>
            <FunctionalInput ref={Username} type="text" title="Username" />
            <FunctionalInput ref={Pass} type="text" title="Password" />
        </Modal>
    )
}

export default EwayVerifiy