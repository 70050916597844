import React from 'react';
import { Modal } from '../../../../Common/fModal/Modal';
import HeaderLevelData from './HeaderLevelData';
import NewTable from './NewTable';
import './ocr.css'
export const ViewOcrMapping = ({ data = {}, onClose }) => {
    return (
        <Modal
            modalStyle={{
                minWidth: '95%',
                maxHeight: '100vh',
                overflow: 'hidden'
            }}
            show={true}
            close={onClose}
            hideButton={true}
            modalBody={{
                padding: 3,
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '78vh'
            }}
            title="Ocr Details"
            des="Review the details of ocr"
            style={{
                save: {
                    display: 'flex',
                    maxHeight: '80vh',
                    minHeight: '80vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                }
            }}
        >
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{
                    width: '100%',
                    maxHeight: '80vh',
                    minHeight: '80vh',
                    overflowY: 'auto'
                }}>
                    <div className='container' >
                        <div className='content-data'>
                            <div className='header-data' >
                                <HeaderLevelData details={data} />
                            </div>
                            <hr />
                            <div className='lineitem-data' style={{
                                borderTop: '7px solid #e3d2d2',
                                borderLeft: '2px solid #e3d2d2',
                                borderRight: '2px solid #e3d2d2',
                                borderBottom: '2px solid #e3d2d2',
                                marginBottom: 20,
                                borderTopLeftRadius: 10, borderTopRightRadius: 10, padding: 10, marginTop: 20
                            }}>
                                <NewTable checks={data} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Modal>
    )
}