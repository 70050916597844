import React from "react";



const useEndPoint = (masterType="EMPLOYEE") => {
    const TYPE = {
        "OFFICE":{
            api:"vendor/address",            // api implemented plus tested
            searchKey:'name',
            renderResult:(item)=>{
                let {name,address,gstin="-"} = item;
                return(
                    <>
                      <span>Name : {name}</span> <br></br>
                      <span>Gstin : {gstin}</span> <br></br>
                      <span>Address : {address}</span>
                    </>
                )
            },
            getKey:'name'
        },
        "DEPARTMENT":{
            api:`search/searchDepartment`,
            searchKey:'name',
            queryKey: 'q',
            getKey:'name',
            renderResult:(item)=>{
                let {name,office} = item;
                return(
                    <>
                      <span>Name : {name}</span> <br></br>
                      <span>Office : {office}</span> <br></br>
                    </>
                )
            }
        },
        "PRODUCT":{
            api:"vendor/product/getProducts",
            searchKey:'title',
            getKey:'title',
            renderResult:(item)=>{
                let {title="-",glCode="-",service="-",hsn="-",currency="-"} = item;
                return(
                    <>
                      <span>Name : {title}</span> <br></br>
                      <span>GLCODE : {glCode}</span> <br></br>
                      <span>Service : {service}, HSN : {hsn} , Currency: {currency}</span>
                    </>
                )
            }
        },
        "VC_SERVICE":{
            api:"vendor/address",
            searchKey:'name',
            renderResult:(item)=>{
                let {name,address,gstin="-"} = item;
                return(
                    <>
                      <span>Name : {name}</span> <br></br>
                      <span>Gstin : {gstin}</span> <br></br>
                      <span>Address : {address}</span>
                    </>
                )
            }
        },
        "VC_CATEGORY":{
            api:"vendor/address",
            searchKey:'name',
            renderResult:(item)=>{
                let {name,address,gstin="-"} = item;
                return(
                    <>
                      <span>Name : {name}</span> <br></br>
                      <span>Gstin : {gstin}</span> <br></br>
                      <span>Address : {address}</span>
                    </>
                )
            }
        },
        "SERVICE":{
            api:"vendor/address",
            searchKey:'name',
            renderResult:(item)=>{
                let {name,address,gstin="-"} = item;
                return(
                    <>
                      <span>Name : {name}</span> <br></br>
                      <span>Gstin : {gstin}</span> <br></br>
                      <span>Address : {address}</span>
                    </>
                )
            }
        },
        "CATEGORY":{
            api:"vendor/address",
            searchKey:'name',
            renderResult:(item)=>{
                let {name,address,gstin="-"} = item;
                return(
                    <>
                      <span>Name : {name}</span> <br></br>
                      <span>Gstin : {gstin}</span> <br></br>
                      <span>Address : {address}</span>
                    </>
                )
            },
            getKey:'name'

        },
        "VENDOR":{
            api:"vendor/search",             // api implemented
            searchKey:'title',
            renderResult:(item)=>{
                let {title,registeredId,gstin="-"} = item;
                return(
                    <>
                      <span>Name : {title}</span> <br></br>
                      <span>Gstin : {gstin}</span> <br></br>
                      <span>Code : {registeredId}</span>
                    </>
                )
            },
            getKey:'title'

        },
        "EMPLOYEE":{
            api:`workspace/support/employeeSearch`,             // api implemented
            searchKey:'name',
            queryKey: 'query',
            renderResult:(item)=>{
                let {name,email="-",code="-",team,department,office} = item;
                return(
                    <>
                      <span>Name : {name}</span> <br></br>
                      <span>Email : {email}, Code : {code}</span> <br></br>
                      <span>Team : {team}, Department : {department}, Office : {office}</span> <br></br>
                    </>
                )
            },
            getKey:'email'

        },
        "TEAM":{
            api:"vendor/address",
            searchKey:'name',
            renderResult:(item)=>{
                let {name,address,gstin="-"} = item;
                return(
                    <>
                      <span>Name : {name}</span> <br></br>
                      <span>Gstin : {gstin}</span> <br></br>
                      <span>Address : {address}</span>
                    </>
                )
            }
        },
        
    }


    return TYPE[masterType]
}


export {useEndPoint}