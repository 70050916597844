import React from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

function AttachmentPreview({files}) {

    const renderFiles = (file, index) => {
        if(file.endsWith('.pdf')){
            return <iframe sandbox key={index} onClick={()=>window.open(file,'_blank')}  src={file} title='Preview' className='shadow-sm mt1 pointer'
            style={{
                position:'absolute',
                right:0,
                overflow:'visible',
                width:'100%',
                left:0,
                border: 'none', 
                height: '100vh'
             }}
             />
        }else{
            return <div>
                <div className='flex justify-center mb1'>
                <h5>Hover on the image to enlarge</h5>
                </div>
                <InnerImageZoom 
                    src={file} 
                    zoomSrc={file} 
                    zoomType="hover" // hover or click
                    zoomPreload={true} // Preload zoom image for faster zoom-in
                    zoomScale={1.5} // Zoom scale factor
                />
            </div>
        }
    }

    return (
        <>
        <h5>{files.length} Attachment</h5>
        <div className='flex column w-100'>
            {files.map(renderFiles)}
        </div>
        </>
    );
}

export default AttachmentPreview;
