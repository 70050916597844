import React from "react";
import {Link} from "react-router-dom";
import Props from "prop-types";

class SearchableSelect extends React.Component {

	constructor(props) {
		super(props)
		this.showMenu = this.showMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
		this.state = {
			shown: false
		}
	}

	showMenu(event) {
		if (this.props.disabled) {
			return;
		}
		if(this.props.activity) {
			this.props.activity();
		}
		event.preventDefault();
		if (this.props.reset)
			this.props.reset()
		this.setState({ shown: true, items: [] }, () => {
			document.addEventListener('click', this.closeMenu);
		});
	}


	closeMenu(event) {
		if (!event) {
			this.setState({ shown: false }, () => {
				document.removeEventListener('click', this.closeMenu);
			});
			return
		}
		if (this.dropdownMenu && !this.dropdownMenu.contains(event.target)) {
			this.setState({ shown: false }, () => {
				document.removeEventListener('click', this.closeMenu);
			});

		}
	}

	onClick = (click) => {
		this.setState({ shown: false }, () => {
			document.removeEventListener('click', this.closeMenu);
		});
		click()
	}

	onChange = () => {
		let value = this.input.value;
		if (this.props.onChange) {
			this.props.onChange(value)
		}
	}

	select = item => {
		this.closeMenu()
		this.props.select(item)
	}
	
	render() {
		console.log(this.props.disabled);
		return (
			<div ref={ref => this.dropdownMenu = ref} className="selectContainer2" style={{width: '100%'}}>
				{
					this.props.label ?
						<label className="floating-top-label">
							{this.props.label}
				        </label>
						:  this.props.name ?
						<label className="floating-top-label">
							{this.props.name}
				        </label>
						: null}
				<div onClick={this.showMenu} className={`floating-label ${this.props.value ? 'valid' : ''}`} style={this.props.style ? { ...this.props.style } : {}}>
					<p style={{ textAlign: 'left' }}>{this.props.value ? this.props.value.label : this.props.placeholder}</p>
				</div>
				{
					this.state.shown ?
						<div className="menu" style={{ zIndex: 111, ...this.props.menuStyle?this.props.menuStyle:{} }}>
							<div className="select-input-outer">
								<div className="select-input-wrapper">
									<img src={require('./search.png')} alt="search" />
									<input ref={ref => this.input = ref} onChange={this.onChange} type="text"
										placeholder={this.props.placeholder} />
								</div>
							</div>
							<div className="item-container">
								{this.props.add ? 
									<Link to={this.props.add.link}>
										<div className="add-button item">
											<img src={require('./plus.png')} alt="plus" />
											<p style={{fontSize: '12px', display: 'flex', alignItems: 'center'}}>{this.props.add.name}</p>
										</div>
									</Link> 
									: null
								}
								{this.props.button ? 
									<div onClick={()=>{this.props.button.click()}}>
										<div className="add-button item">
											<img src={require('./plus.png')} alt="plus" />
											<p style={{fontSize: '12px', display: 'flex', alignItems: 'center'}}>{this.props.button.name}</p>
										</div>
									</div> 
									: null
								}
								{
									this.props.items.map((item,index) => <div key={index}
										onClick={this.select.bind(this, item)}
										className="item" style={{fontSize:'12px'}}>
										<p  style={{fontSize:'12px'}}>{item.label}</p>
										{item.des ? <p  className="des">{item.des}</p> : null}
										{item.address ? <p  className="des">Address: {item.address}</p> : null}
										{item.des1 ? <p  className="des">{item.des1}</p> : null}
										{item.msme ? <p  className="des">MSME/Udyam VENDOR : {item.msme}</p> : null}
										{item.tax ? <p  className="des">{item.tax}%</p> : null}
										{item.gstin ? <p  className="des">GSTIN : {item.gstin}</p> : null}
										{item.unit ? <p  className="des">unit : {item.unit}</p> : null}
										{item.hsn ? <p className="des">Hsn No. : {item.hsn}</p> : null}
										{item.otherRate ? <p  className="des">Other Rate : {item.otherRate}</p> : null}
										{item.individualHufRate ? <p className="des">Individual Huf Rate : {item.individualHufRate}</p> : null}
									</div>)
								}
							</div>
						</div> :
						null
				}
			</div>
		)
	}

}

SearchableSelect.propTypes = {
	value: Props.object,
	add: Props.object,
	placeholder: Props.string.isRequired,
	onChange: Props.func,
	items: Props.array,
	select: Props.func.isRequired
}

SearchableSelect.defaultProps = {
	items: []
}

export {
	SearchableSelect
}
