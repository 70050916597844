import React from 'react';
import { connect } from 'react-redux';
import { PageLoader } from '../../../../../../Common/Components/PageLoader';
import moment from 'moment';
import inrInWords from '../../../../../../../Utils/INRToWords';

function Edmee({ data, defaultBank }) {
  if (!data || !defaultBank) return <PageLoader />;

  let totalBaseAmount = 0;
//   let totalTax = 0;

  data.procured.forEach(val => {
    totalBaseAmount += val.base;
    // totalTax += val.tax;
  })

  const start=data && data.forms && data.forms.length ? data.forms.find(el=>el.id==='service_from') : {value:''};
  const end=data && data.forms && data.forms.length ? data.forms.find(el=>el.id==='service_to') : {value:''};
  const internalReference=data && data.forms && data.forms.length ? data.forms.find(el=>el.id==='internal_reference') : {value:''};
  const narration=data && data.forms && data.forms.length ? data.forms.find(el=>el.id==='narration') : {value:''};

  const listOfApprovers=data.timelines && data.timelines.length ? data.timelines.slice(1).filter(val => val && val.owner && val.owner.ownerType==='EMPLOYEE').map(el=>el.name) : [];
  const uniqueApprovers=new Set(listOfApprovers);

  return (
    <div style={{ fontFamily: "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif", fontSize: "12px", maxWidth: "900px", margin: "auto" }}>
      <header>
        <img style={{ width: "100%", height: "40%" }} src="https://static.eka.io/v2/receipts/2025/03/e818e06c-e8cd-474e-8588-3815a1a17256.png" alt="" />
      </header>
      <div style={{ marginLeft: "4%", marginRight: "2%" }}>
        <div style={{ border: "1px solid black" }}>
          <table style={{ borderBottom: "1px solid black", fontSize: "15px", width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "75%", textAlign: "left" }}>
                  <p>Purchase Order No: {data.orderId}</p>
                </td> 
                <td>
                  Date: {moment(data.details ?  data.details.createdAt : '').format('DD-MM-YYYY')}
                </td>
              </tr>
            </tbody>
          </table>
          <table style={{ lineHeight: "25px", width: "100%", borderBottom: "1px solid black" }}>
            <tbody>
              <tr>
                <td style={{ width: "30%", borderRight: "1px solid black", verticalAlign: "top", textAlign: "left" }}>
                  <p style={{ fontWeight: 600 }}>To,</p>
                  <p style={{ fontWeight: 600 }}>{data.vendor.name}</p>
                  <p>{data.vendor.address.address}</p>
                  <p>{data.vendor.address.city}, {data.vendor.address.pincode} </p>
                </td>
                <td style={{ width: "30%", verticalAlign: "top", textAlign: "left", borderRight: "1px solid black" }}>
                  <p style={{ fontWeight: 600 }}>Delivery Address</p>
                  <p style={{ fontWeight: 600 }}>{data.procured[0].officeRegisteredName}</p>
                  <p>{data.procured[0].officeAddress}</p>
                </td>
                <td style={{ width: "40%", verticalAlign: "top" }}>
                  <p>AAN: ABIBL/IT/24/25/014</p>
                  <p>Dated: {moment(data.details ?  data.details.createdAt : '').format('DD-MM-YYYY')}</p>
                </td>
              </tr>
            </tbody>
          </table>
          <table cellSpacing="0" cellPadding="5" style={{ width: "100%", borderCollapse: "separate", borderBottom: "1px solid black",borderTop:'1px solid black' }}>
            <thead>
              <tr>
                <th style={{ width: "10%", borderRight: "1px solid black", textAlign: "left", padding: "5px" }}>Sr No.</th>
                <th style={{ width: "50%", borderRight: "1px solid black", textAlign: "left", padding: "5px" }}>Description</th>
                <th style={{ width: "10%", borderRight: "1px solid black", textAlign: "left", padding: "5px" }}>Qty</th>
                <th style={{ width: "10%", borderRight: "1px solid black", textAlign: "left", padding: "5px" }}>Unit</th>
                <th style={{ width: "10%", borderRight: "1px solid black", textAlign: "left", padding: "5px" }}>Rate (Rs.) Per Unit</th>
                <th style={{ width: "10%", borderRight: "1px solid black", textAlign: "left", padding: "5px" }}>Amt (Rs.) Basic</th>
              </tr>
            </thead>
            <tbody>
              {data.procured.map((item, index) => (
                <tr key={index}>
                  <td style={{ borderRight: "1px solid black", padding: "5px" }}>{index+1})</td>
                  <td style={{ borderRight: "1px solid black", padding: "5px" }}>
                    <p style={{margin:'1px 0',fontSize:13}} >{item.description}</p>
                    <p style={{margin:'1px 0',fontSize:12}}  >Notes : {item.notes} </p>
                    <p style={{margin:'2px 0'}} >Period - {start.value} to {end.value}</p>
                  </td>
                  <td style={{ borderRight: "1px solid black", padding: "5px" }}>{item.qtty}</td>
                  <td style={{ borderRight: "1px solid black", padding: "5px" }}>{item.unit}</td>
                  <td style={{ borderRight: "1px solid black", padding: "5px" }}>{item.price}</td>
                  <td style={{ borderRight: "1px solid black", padding: "5px" }}>{item.price*item.qtty}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <table style={{ lineHeight: "25px", width: "100%", borderTop: "1px solid black" }}>
            <tbody>
              <tr>
                <td style={{ width: "30%", borderRight: "1px solid black", verticalAlign: "top", textAlign: "left" }}>
                  <p style={{ fontWeight: 600 }}>Billing Address:</p>
                  <p>{data.billingTo.registered_name}</p>
                  <p>{data.billingTo.address}</p>
                </td>
                <td style={{ width: "60%", verticalAlign: "top", textAlign: "left"}}>
                  <p style={{ fontWeight: 600 }}>Terms And Conditions</p>
                  {data.conditons && data.conditons.map((term, index) => (
                    <p key={index}>{term}</p>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
          <table style={{ lineHeight: "25px", width: "100%", borderTop: "1px solid black" }}>
            <tbody>
              <tr>
                <td style={{ width: "20%", fontWeight: 600 }}>TOTAL RS.</td>
                <td style={{ width: "10%", fontWeight: 600 }}>{totalBaseAmount} </td>
              </tr>
            </tbody>
          </table>
          <table style={{ lineHeight: "25px", width: "100%", borderTop: "1px solid black", height: "15vh" }}>
            <tr>
                <td style={{ width: "60%" }}>
                  <p>
                    Figure In Words {inrInWords(totalBaseAmount)} 
                  </p>
                  <div style={{display:'flex',marginTop:'15px'}} >
                    {
                      uniqueApprovers ? 
                      
                      Array.from(uniqueApprovers).map((item, index) => (
                        <div key={index} style={{flex:1}}> 
                            {item}
                        </div>
                      ))

                      : null
                    }
                  </div>
                </td>
              <td style={{ width: "20%" }}>
                <p style={{fontSize:'14px',fontWeight:600}} >Internal Reference</p>
                <p>
                  {internalReference.value}
                </p>
              </td>
              <td style={{ width: "20%" }}>
                <p style={{fontSize:'14px',fontWeight:600}} >Narration</p>
                <p>
                  {narration.value}
                </p>
              </td>
            </tr>
          </table> 
        </div>
        <div style={{ textAlign: "right", marginTop: "2vh" }}>
          <p style={{ fontWeight: 600, fontSize: "15px", marginBottom: "2px" }}>
            Edme Insurance Brokers Ltd.
          </p>
          <p style={{ fontWeight: 600, fontSize: "15px", marginBottom: "2px" }}>
            (Formerly Aditya Birla Insurance Brokers Ltd.)
          </p>
          <p style={{ marginBottom: "2px" }}>
            Corporate Office: VIOS Tower, 6th Floor, off Eastern Express Highway,
          </p>
          <p style={{ marginBottom: "2px" }}>
            Sewri - Chembur Road, Mumbai 400 037, Maharashtra, India
          </p>
          <p style={{ marginBottom: "2px" }}>
            T: +91 22 4356 8585 | E: care@edmeinsurance.com | W: www.edmeinsurance.com
          </p>
          <p style={{ marginBottom: "2px" }}>
            Registered Office: 2nd Floor, Privillion, East Wing, Sarkhej – Gandhinagar
            Highway, Vikram Nagar, Bodakdev, Ahmedabab, Gujarat-380054
          </p>
          <p style={{ fontWeight: 600, marginBottom: "2px" }}>
            CIN: U99999GJ2001PLC062239 | IRDAI Registration Number: 146 | License
            Validity: 9th April, 2027 | Broker Category: Composite Broker
          </p>
          <p style={{ marginBottom: "2px" }}>
            ISO 9001 Quality Management Certificate certified by Intertek
            Certification Ltd. Under certificate number 0145476
          </p>
          <p style={{ marginBottom: "2px" }}>
            ISO 27001 Information Security Management Certificate certified by BSI
            under certificate number IS738839
          </p>
        </div>
      </div>
    </div>
  );
}

export default connect((state) => ({ config: state.auth.config }))(Edmee);