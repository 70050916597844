import React from 'react';
import Moment from 'moment';
import {connect} from 'react-redux';
import {PDFExport} from "@progress/kendo-react-pdf";
import watermark from './watermark.png';

class Udaan extends React.Component {
    render() {
        let list = this.props.data;
        const poPdfDownloadAfterApproval=(this.props.poPdfDownloadAfterApproval?this.props.poPdfDownloadAfterApproval:undefined);
        let vendorDefaultBankDetails = (this.props.defaultBank?this.props.defaultBank:undefined);
        let Ponumber=list.orderId;
        let Company_name="",Company_email="",Company_mobile="",entityCode="",effective_start_date="",po_validity_tenure="",breach_period="",breach_termination_period="",invoice_type="",payment_terms="";
        list.forms.forEach((item)=>{
            if(item.id==="enter_name") Company_name=item.value
            else if(item.id==="enter_email") Company_email=item.value
            else if(item.id==="enter_contact") Company_mobile=item.value
            else if(item.id==="effective_start_date") effective_start_date=item.value
            else if(item.id==="po_validity_tenure") po_validity_tenure=item.value
            else if(item.id==="breach_period") breach_period=item.value
            else if(item.id==="breach_termination_period") breach_termination_period=item.value
            else if(item.id==="invoice_type") invoice_type=item.value
            else if(item.id==="payment_terms" || item.id===" payment_terms") payment_terms=item.value
        })
        entityCode=(list && list.billingTo && list.billingTo.entityName)?list.billingTo.entityName:""
        let shipToGst="",shipToLocationName="",shipToLocation="";
        if(list && list.procured && list.procured.length){
            if(list.procured[0] && list.procured[0].officeGstin){
                shipToGst=list.procured[0].officeGstin
            }
            if(list.procured[0] && list.procured[0].officeRegisteredName){
                shipToLocationName=list.procured[0].officeRegisteredName
            }
            if(list.procured[0] && list.procured[0].officeAddress){
                shipToLocation=list.procured[0].officeAddress
            }
        }
        let entity=entityCode;
        let modifiedPoNo = Ponumber;
        let {conditions} = this.props;

        let entityMapping={
            "GRNY" : "GRANARY WHOLESALE PRIVATE LIMITED",
            "GTPL" : "GRANTRAIL WHOLESALE PRIVATE LIMITED",
            "HCPL" : "HIVELOOP CAPITAL PRIVATE LIMITED",
            "HIPL" : "HIVELOOP INTERNET PRIVATE LIMITED",
            "HLPL" : "HIVELOOP E-COMMERCE PRIVATE LIMITED (FORMERLY KNOWN AS HIVELOOP LOGISTICS PRIVATE LIMITED)",
            "HTPL" : "HIVELOOP TECHNOLOGY PRIVATE LIMITED",
            "IBPL" : "INDUSAGE TECHAPP PRIVATE LIMITED",
            "KTPL" : "KWICKBOX TECHNOLOGY PRIVATE LIMITED",
            "LWPL" : "LEADMARK WHOLESALE PRIVATE LIMITED",
            "NAPL" : "NEWLOOP APPS PRIVATE LIMITED",
            "NLPL" : "NEWLOOP LOGISTICS PRIVATE LIMITED",
            "REPL" : "RAPIDEDGE CASH AND CARRY PRIVATE LIMITED",
            "STPL" : "STACKTRAIL CASH AND CARRY PRIVATE LIMITED",
            "SWPL" : "STAPEL WHOLESALE PRIVATE LIMITED",
            "CCPL" : "CLICKFIX CASH AND CARRY PRIVATE LIMITED",
            "DTPL" : "DIGITRAIL TECHNOLOGY PRIVATE LIMITED",
            "KWPL" : "KWICKEDGE TECHNOLOGY PRIVATE LIMITED",
            "HEPL" : "HIVELOOP E-COMMERCE PRIVATE LIMITED (FORMERLY KNOWN AS HIVELOOP LOGISTICS PRIVATE LIMITED)"
        }

        let total = 0,qty=0,base=0,cgst=0,sgst=0,igst=0,tax=0;

        list.procured.forEach(val=>{
            total +=val.amount;
            qty +=val.qtty
            base += val.base;
            tax += val.tax
        })
        let gstFlag=false;

        if (list.vendor && list.vendor.gstin && list.vendor.gstin.length > 2 && list.billingTo && list.billingTo.gstin && list.billingTo.gstin.length > 2) {
            if (list.vendor.gstin.slice(0, 2) === list.billingTo.gstin.slice(0, 2)) {
                cgst = tax / 2;
                sgst = tax / 2;
            } else {
                igst = tax;
                gstFlag=true;
            }
        } else {
            cgst = tax / 2;
            sgst = tax / 2;
        }

        let mapCondition = (condition) => {
            return (
                <tr key={condition.title}>
                    <div style={{'font-weight':'bold'}}>{condition.title}</div>
                    <div style={{'font-weight':'bold'}}>{condition.description}</div>
                    <div style={{fontSize:'11px'}}
                        dangerouslySetInnerHTML={{
                        __html: condition.content,
                        }}
                    />
                </tr>
            );
        }

        return (
            <div>
                {(poPdfDownloadAfterApproval ?  (!(list.status==='DRAFT' || list.status==='PENDING' || list.status==='VENDOR_ACK')):true)?
                <div>
                    <p
                        style={{
                        fontWeight: 600,
                        color: "var(--primary-color)",
                        cursor: "pointer",
                        }}
                        onClick={() => {
                        this.pdf.save();
                        }}
                    >
                        Download Now
                    </p>
                </div>:null}
                <PDFExport
                    title="Order Document"
                    padding="40"
                    fileName={`Order-${list.orderId}.pdf`}
                    ref={(r) => (this.pdf = r)}
                    >
                    <div style={list.status==="DRAFT"?{backgroundImage: `url(${watermark})`,backgroundRepeat: 'no-repeat',backgroundPosition: '150px 0'}:{}}>
                        <div style={{"max-width" : "1200px", "font-weight": "600", "min-width":"1166px"}}>
                            <div style={{"height":"150px", "text-align": "center"}}>
                                <p style={{"font-size": "x-large", "margin-bottom": "0px", "font-weight": "bold"}}>{"Purchase Order"}</p>
                                <p style={{"font-weight": "bold"}}>{(entity)?entityMapping[entity]:""}</p>
                                <p style={{"font-size": "11px", "margin-bottom": "0px"}}>{`Billing Address:- ${list.billingTo.address}`} </p>
                                <p style={{"font-size": "11px"}}>{`GSTIN- ${list.billingTo.gstin}`}</p>
                            </div>
                        </div>
                        <div style={{"margin-bottom": "11px"}}>
                            <table style={{"max-width": "1200px", "border":"none", "min-width":"1166px"}} cellpadding="0" cellspacing="0" width="1200px">
                                <tbody>
                                    <tr>
                                        <td rowspan="1" colspan="1" style={{"border": "none", "font-size":"11px", "text-align":"left"}}>
                                            <div><span style={{"font-weight": "bold"}}>PO No.</span> {modifiedPoNo}</div>
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"border": "none", "font-size":"11px", "text-align":"left","width":"700px"}}>
                                            <div><span style={{"font-weight": "bold"}}>Version No.</span> </div>
                                        </td>
                                        <td rowspan="1" colspan="6" style={{"border": "none", "font-size":"11px", "text-align":"left"}}>
                                            <div><span style={{"font-weight": "bold"}}>PO Date: {Moment(list.details.createdAt).format("DD/MM/YYYY")}</span> </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="1" colspan="8" style={{"border": "none", "font-size":"11px", "text-align":"left"}}>
                                            <div><span style={{"font-weight": "bold"}}>PO Type</span> {list.category.name}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table style={{"max-width": "1200px", "table-layout": "fixed", "border": "#000 1px solid", "min-width":"1166px"}} cellpadding="0" cellspacing="0" width="1200px">
                                <tbody>
                                    <tr>
                                        <td rowspan="1" colspan="4" style={{"border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            <div>Company Information</div>
                                        </td>
                                        <td rowspan="1" colspan="4" style={{"border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            <div>Service Provider Information</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" style={{"border": "#808080 1px solid", "font-size":"11px"}} >
                                            <table style={{"border":"none"}}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{"border":"none", "font-weight": "bold", "text-align":"left"}} colspan="1">Location Name</td>
                                                        <td style={{"border":"none", "max-width": "100%", "word-break": "break-all", "text-align":"left"}} colspan="3">{shipToLocationName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"border":"none", "font-weight": "bold", "text-align":"left"}} colspan="1">Location</td>
                                                        <td style={{"border":"none", "max-width": "100%", "word-break": "break-all", "text-align":"left"}} colspan="3">{shipToLocation}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"border":"none", "font-weight": "bold", "text-align":"left"}} colspan="1">GSTIN</td>
                                                        <td style={{"border":"none", "max-width": "100%", "word-break": "break-all", "text-align":"left"}} colspan="3">{shipToGst}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"border":"none", "font-weight": "bold", "text-align":"left"}} colspan="1">Name</td>
                                                        <td style={{"border":"none", "max-width": "100%", "word-break": "break-all", "text-align":"left"}} colspan="3">{Company_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"border":"none", "font-weight": "bold", "text-align":"left"}} colspan="1">Contact Number</td>
                                                        <td style={{"border":"none", "max-width": "100%", "word-break": "break-all", "text-align":"left"}} colspan="3">{Company_mobile}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"border":"none", "font-weight": "bold", "text-align":"left"}} colspan="1">Email</td>
                                                        <td style={{"border":"none", "max-width": "100%", "word-break": "break-all", "text-align":"left"}} colspan="3">{Company_email}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td colspan="4" style={{"border": "#808080 1px solid", "font-size":"11px"}}>
                                            <table style={{"border":"none"}}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{"border":"none", "font-weight": "bold", "text-align":"left"}} colspan="1">Name</td>
                                                        <td style={{"border":"none", "max-width": "100%", "word-break": "break-all", "text-align":"left"}} colspan="3">{list.vendor.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"border":"none", "font-weight": "bold", "text-align":"left"}} colspan="1">Address</td>
                                                        <td style={{"border":"none", "max-width": "100%", "word-break": "break-all", "text-align":"left"}} colspan="3">{list.vendor.address.address}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"border":"none", "font-weight": "bold", "text-align":"left"}} colspan="1">GSTIN</td>
                                                        <td style={{"border":"none", "max-width": "100%", "word-break": "break-all", "text-align":"left"}} colspan="1">{list.vendor.gstin}</td>
                                                        <td style={{"border":"none", "font-weight": "bold", "text-align":"left"}} colspan="1">PAN</td>
                                                        <td style={{"border":"none", "max-width": "100%", "word-break": "break-all", "text-align":"left"}} colspan="1">{list.vendor.pan}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"border":"none", "font-weight": "bold", "text-align":"left", "max-width": "100%",'white-space': 'nowrap'}} colspan="1">Bank A/C No.</td>
                                                        <td style={{"border":"none", "max-width": "100%", "word-break": "break-all", "text-align":"left"}} colspan="1">{vendorDefaultBankDetails?vendorDefaultBankDetails.account:""}</td>
                                                        <td style={{"border":"none", "font-weight": "bold", "text-align":"left"}} colspan="1">Contact No.</td>
                                                        <td style={{"border":"none", "max-width": "100%", "word-break": "break-all", "text-align":"left"}} colspan="1">{list.vendor.mobile}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"border":"none", "font-weight": "bold", "text-align":"left"}} colspan="1">Bank Name</td>
                                                        <td style={{"border":"none", "max-width": "100%", "word-break": "break-all", "text-align":"left"}} colspan="3">{vendorDefaultBankDetails?(vendorDefaultBankDetails.bankName?vendorDefaultBankDetails.bankName:vendorDefaultBankDetails.name):""}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"border":"none", "font-weight": "bold", "text-align":"left"}} colspan="1">EmailID</td>
                                                        <td style={{"border":"none", "max-width": "100%", "word-break": "break-all", "text-align":"left"}} colspan="3">{list.vendor.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"border":"none", "font-weight": "bold", "text-align":"left"}} colspan="1">IFSC</td>
                                                        <td style={{"border":"none", "max-width": "100%", "word-break": "break-all", "text-align":"left"}} colspan="3">{vendorDefaultBankDetails?vendorDefaultBankDetails.ifsc:""}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{"margin-top": "30px"}}>
                            <table style={{"max-width": "1200px", "table-layout": "fixed", "border": "#000 1px solid"}} cellpadding="0" cellspacing="0" width="1200px">
                                <tbody>
                                    <tr>
                                        <td rowspan="1" colspan="1" style={{"width":"30px", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            SL No
                                        </td>
                                        <td rowspan="1" colspan="2" style={{"width":"90px", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            Product Description
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"width":"90px","border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            Product Ref
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"width":"90px", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            HSN /SAC
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"width":"45px", "word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            GST Rate(%)
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"width":"70px", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            Qty
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"width":"60px", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            UOM
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"width":"90px", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            Unit Price
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"width":"70px", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            Currency
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"width":"100px", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            Amt Ex of GST
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"width":"80px", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            CGST
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"width":"80px", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            SGST
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"width":"80px", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            IGST
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"width":"100px", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            Amount
                                        </td>
                                    </tr>
                                    {
                                        list.procured.map((item, index) => {
                                            let igst=0,cgst=0,sgst=0
                                            if (gstFlag) {
                                                igst = item.tax;
                                            } else {
                                                cgst = item.tax / 2;
                                                sgst = item.tax / 2;
                                            }
                                            return <tr style={{"border": "solid 01px #000"}}>
                                                <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center"}}>
                                                    {index+1}
                                                </td>
                                                <td rowspan="1" colspan="2" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center"}}>
                                                    {item.notes}
                                                </td>
                                                <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center"}}>
                                                    {item.productCode}
                                                </td>
                                                <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center"}}>
                                                    {item.hsn}
                                                </td>
                                                <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center"}}>
                                                    {item.taxSlab} %
                                                </td>
                                                <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center"}}>
                                                    {item.qtty}
                                                </td>
                                                <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center"}}>
                                                    {item.unit}
                                                </td>
                                                <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center"}}>
                                                    {item.price}
                                                </td>
                                                <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center"}}>
                                                    {list.currency}
                                                </td>
                                                <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center"}}>
                                                    {item.base.toFixed(2)}
                                                </td>
                                                <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center"}}>
                                                    {cgst.toFixed(2)}
                                                </td>
                                                <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center"}}>
                                                    {sgst.toFixed(2)}
                                                </td>
                                                <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center"}}>
                                                    {igst.toFixed(2)}
                                                </td>
                                                <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center"}}>
                                                    {item.amount.toFixed(2)}
                                                </td>
                                            </tr>
                                        })
                                    }
                                    <tr>
                                        <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center"}}>
                                        </td>
                                        <td rowspan="1" colspan="2" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            Total
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            {qty}
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            {base.toFixed(2)}
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            {cgst.toFixed(2)}
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            {sgst.toFixed(2)}
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            {igst.toFixed(2)}
                                        </td>
                                        <td rowspan="1" colspan="1" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "text-align": "center", "font-weight": "bold"}}>
                                            {total.toFixed(2)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="1" colspan="15" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "border-bottom": "none","border-top": "none", "text-align":"left"}}>
                                            <div><span style={{"font-weight": "bold"}}>Payment Terms : </span>{payment_terms}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="1" colspan="15" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "border-bottom": "none","border-top": "none", "text-align":"left"}}>
                                            <div><span style={{"font-weight": "bold"}}>Term : </span>{`This Order shall be effective from ${effective_start_date} for a period of ${po_validity_tenure} months. `}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="1" colspan="15" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "border-bottom": "none", "border-top": "none","text-align":"left"}}>
                                            <div><span style={{"font-weight": "bold"}}>Termination : </span>{`Either party may terminate the Order in the event the other party has materially breached or defaulted in the performance of any of its obligations hereunder, and if such breach or default capable of being cured is not cured within ${breach_period} days after receiving the written notice by the non-breaching party to the non-breaching party. Any termination shall become effective at the end of such ${breach_termination_period} day period unless the breaching party has cured any such breach or default prior to the expiration of such period to the satisfaction of non-breaching party.`} </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="1" colspan="15" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "border-bottom": "none","border-top": "none", "text-align":"left"}}>
                                            <div><span style={{"font-weight": "bold"}}>Invoice : </span>{`Service Provider shall raise a ${invoice_type} invoice towards the Company on the PO Start Date as mentioned on the face Order, or on the last day of each month (as applicable), which shall be treated due for  payment by the Company within thirty (30) days from the end of such invoiced month or from the receipt of such invoice whichever is later, provided the deliverables made by the Service Provider have been duly approved by the Company.`}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="1" colspan="15" style={{"word-break": "break-all", "border": "1px solid #000", "font-size":"11px", "border-bottom": "none", "text-align":"left"}}>
                                            <div><span style={{"font-weight": "bold"}}>This is system generated PO hence signature is not required.</span></div>
                                        </td>
                                    </tr> 
                                    <tr style={{"border-top": "#000 1px solid", "font-size": "11px"}}>
                                        <td colspan="15" style={{"border": "#000 1px solid", "border-right": "#000 1px solid"}} >
                                            {
                                                conditions.map(mapCondition)
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                
                </PDFExport>
            </div>
        );
    }
}

export default connect(state => {
    return {
        config: state.auth.config
    }
}, null)(Udaan);
