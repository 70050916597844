import React from 'react';
import { NormalTable } from '../../../../../Tables/NormalTable/NormalTable';

export const Items = ({ setRows, rows, item, viewOnly }) => {

  if (viewOnly) {
    return (
      <div className="p-4">
        <NormalTable
          data={rows}
          mapItem={(item, index) => {
            return (
              <div key={index} className="border-bottom fintrip-department-item table-item">
                <div className="linear-layout horizontal">
                  <div style={{ flex: 1 }}>
                    {item.batch}
                  </div>
                  <div style={{ flex: 1 }}>
                    {item.qty}
                  </div>
                </div>
              </div>
            )
          }}
          headers={[
            { key: 'Batch', name: 'Batch' },
            { key: 'Qty', name: 'Qty' },
          ]}
        />
      </div>
    )
  }
}