import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { Card } from '../../../../Common/fCards';
import { ViewInvoice } from '../../../fCommon/ViewInvoice';
import Empty from '../../../../Common/fCard/Empty';
import { numberWithCommas } from '../../../../../Utils/NumberToComma';
import { get } from '../../../../../Model/Network/Config/Axios';
import SubmitInvoice from "../../../fCommon/Submit";
import { useParams } from 'react-router-dom';
import { Title as Header } from "../../../../Header/TopHeader";
import _ from 'lodash'
import { ReadOnly } from '../../../../Common/fCard/ButtonOutline/ReadOnly';
import { ViewCreditNote } from '../../../fCommon/ViewCreditNote';
import { ViewDebitNote } from '../../../fCommon/ViewDebitNote';
import { CreditNoteInvoice } from '../../../fCommon/CreateCreditNote';
import { DebitNoteInvoice } from '../../../fCommon/CreateDebitNote';




const Invoices = ({ status, data, fetchASN }) => {

    const [getInvoice, setInvoice] = useState(undefined);
    const [getInvoiceID, setInvoiceID] = useState(undefined);
    const [invoiceItem, setInvoiceItem] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [submitCreditNote, setSubmitCreditNote] = useState(undefined);
    const [creditId, setCreditId] = useState(undefined);
    const [debitId, setDebitId] = useState(undefined);
    const [submitDebitNote, setSubmitDebitNote] = useState(undefined);

    let { id } = useParams();


    useEffect(() => {

        INIT();

    }, [])



    let INIT = () => {
        let data = {};
        get(`vendor/invoicing/invoices?po_id=${id}`, (e, r) => {
            if (r) {
                setInvoiceItem(r.invoices);
            }
        }, data)
    }

    let mapCard = (item, index) => {
        return (
            <Card key={`Invoice-${index}`}
                attachment={item.file}
                flag={item.status === 'COMPLETED' && item.settlement && !item.settlement.settled ? { value: "Settlement Pending", color: '#000099' } : item.status === 'COMPLETED' ? { value: item.status, color: 'var(--green-color)' } : item.status === 'APPROVAL' ? { value: item.status, color: '#6f42c1' } : { value: item.status }}
                headings={[`${item.currency} ${numberWithCommas(item.currencyAmount)}`, item.owner.ownerName]}
                details={[{ label: 'Date', value: Moment(item.createdAt).format(" hh:mm a DD MMM YY") }, { label: 'Invoice ID', value: item.invoiceId }, { label: 'Category', value: item.category }, { label: 'Service', value: item.service }]}
                onClick={() => { setInvoice(item.id); setInvoiceID(item.id) }}>
                {_.size(item.integrationData) > 0 && item.integrationData['postingSynced'] && (
                    <div style={{
                        padding: '4px 5px', marginBottom: '5px', borderRadius: '5px', position: 'relative', marginLeft: '23%', fontSize: '11px', color: '#fff', background: 'rgb(78 185 135)'
                    }}>Posting Synced successfully</div>
                )}
            </Card>
        )
    }


    return (
        <div>
            <div style={{ padding: "10px 20px" }}>
                <Header container={true} id="Invoices" title="All invoices"
                    des="All the invoices  are visible here">
                    <div style={{
                        position: 'absolute',
                        right: 0,
                        top: 30
                    }}>
                        {status === 'INVOICE' || status === 'GRN' ? <ReadOnly>
                            <button style={{
                                border: 'var(--primary-color)',
                                background: 'var(--primary-color)',
                                padding: '8px 20px',
                                color: "var(--white-color)",
                                borderRadius: 4,
                                cursor: 'pointer'
                            }} onClick={() => {
                                setSubmit(true);
                            }}>
                                Submit Invoice
                            </button>
                        </ReadOnly>
                            : null
                        }
                    </div>
                </Header>
            </div>
            {invoiceItem.length > 0 ?
                <div style={{ marginLeft: 20, display: 'flex', flexWrap: 'wrap' }}>
                    {invoiceItem.map(mapCard)}
                </div> :
                <div style={{ marginLeft: 20 }}>
                    <Empty />
                </div>
            }
            {
                getInvoice ?
                    <ViewInvoice
                        submitDebitNote={(val) => { setSubmitDebitNote(val); setInvoice(undefined) }}
                        setCreditId={(val) => { setCreditId(val) }}
                        setDebitId={(val) => { setDebitId(val) }}
                        submitCreditNote={(val) => { setSubmitCreditNote(val); setInvoice(undefined) }}
                        id={getInvoice}
                        invoiceId={getInvoiceID}
                        onClose={() => { setInvoice(undefined) }}
                    /> : <></>
            }
            {
                creditId &&
                <ViewCreditNote id={creditId} onClose={() => { setCreditId(undefined); }}
                    init={() => {
                        INIT();
                        setCreditId(undefined);
                    }} />
            }
            {
                debitId &&
                <ViewDebitNote id={debitId} onClose={() => { setDebitId(undefined); }} />
            }
            {
                submitCreditNote &&
                <CreditNoteInvoice id={submitCreditNote.value} invoiceId={submitCreditNote.label}
                    onClose={() => {
                        setSubmitCreditNote(undefined);
                    }}
                    init={() => {
                        INIT();
                        setSubmitCreditNote(undefined);
                    }} />
            }
            {
                submitDebitNote &&
                <DebitNoteInvoice id={submitDebitNote.value} invoiceId={submitDebitNote.label}
                    onClose={() => {
                        setSubmitDebitNote(undefined);
                    }}
                    init={() => {
                        INIT();
                        setSubmitDebitNote(undefined);
                    }} />
            }

            {
                submit ?
                    <SubmitInvoice
                        currency={data.vendor.currency}
                        data={data}
                        onClose={() => {
                            setSubmit(false);
                        }}
                        init={() => {
                            INIT();
                        }}
                        fetchASN={fetchASN}
                        category={data.category.name}
                        categoryId={data.category.id}
                        poId={id}
                        vendorId={data.vendor.id}
                        lineItems={data.procured.filter(i => i.qtty !== i.approved && !i.shortClosure).map(val => {
                            let qtty = val.qtty;
                            if ((val.qtty - val.approved) > 0) {
                                qtty = val.qtty - val.approved
                            }
                            let discountSlab = 0;
                            let base = val.price * qtty;
                            let grossBase = base - (base * (discountSlab / 100));
                            let tax = (grossBase * (val.taxSlab / 100))
                            return (
                                {
                                    itemId: val.id,
                                    name: val.name,
                                    budgetStatus: val.budgetStatus,
                                    budgetId: val.budgetId,
                                    budgetAmount: val.budgetAmount,
                                    qtty:qtty.toFixed(2),
                                    price: val.price,
                                    hsn: val.hsn,
                                    base: val.price * qtty,
                                    grossBase,
                                    gst: val.taxSlab,
                                    taxSlab: val.taxSlab,
                                    tax,
                                    ...(val.attrs && val.attrs.autoBudgeting) ? { autoBudgeting: (val.attrs.autoBudgeting === "yes") ? true : false } : {},
                                    total: tax + grossBase,
                                    productId: val.productId,
                                    service: {
                                        label: val.serviceName,

                                    },
                                    productCode: val.productCode,
                                    description: val.description,
                                    key: `${val.name}||${val.serviceName}||${val.id}`
                                }
                            )
                        })}
                    /> : null
            }

        </div>
    )
}

export default Invoices
