import React, { useEffect, useRef, useState } from "react"
import EwayVerifiy from "./EwayVerifiy";
import CheckIcon from './check.png'
import Moment from "moment";
import moment from "moment";
import { showToast } from "../../../../../Common/fToast/ToastCallback";
import { Modal } from "../../../../../Common/fModal/Modal";
import { FunctionalInput } from "../../../../../Common/fInputs";
import { Form } from "../../../../../Common/Class/Form";

const CreateAsn = ({ onClose, onSave, asnForms, inputData, companyCode,loading, ewayBillDATA, LRCOPYDATA, isBajajElec, categoryId=undefined }) => {
    const DELIVERYDATE = useRef();
    const CHALLAN = useRef();
    const VECHICAL = useRef();
    const CUSTOMFORM = useRef();
    const E_WAY_BILL = useRef();
    const E_WAY_VALID = useRef();
    const TIME = useRef();
    // const [showVerification, setShowVerification] = useState(undefined)
    const [verify, setVerify] = useState(undefined)
    const [verified, setVerified] = useState(undefined)
    const [prefill,setPrefill] = useState();


    useEffect(()=>{
        CUSTOMFORM.current.loadForm(`vendor::asn::${categoryId}`)
    },[])

    useEffect(()=>{
        if(ewayBillDATA && LRCOPYDATA && isBajajElec){
            VECHICAL.current.value     = (ewayBillDATA && ewayBillDATA.vehicleNo)?ewayBillDATA.vehicleNo:"";
            E_WAY_BILL.current.value   = (ewayBillDATA && ewayBillDATA.documentNo)?ewayBillDATA.documentNo:"";
            E_WAY_VALID.current.value  = (ewayBillDATA && ewayBillDATA.validUptoDate)? Moment(ewayBillDATA.validUptoDate,"DD-MM-YYYY").format("YYYY-MM-DD") : undefined;
            if(asnForms) prefillCustomFormBajajELectric(asnForms)
        }   
    },[LRCOPYDATA,ewayBillDATA,isBajajElec,asnForms])

    const prefillCustomFormBajajELectric = (asnForms) => {
        let customFormIds ={
            "eway_bill_number" : (ewayBillDATA && ewayBillDATA.documentNo)?ewayBillDATA.documentNo:"",
            "eway_date"        : (ewayBillDATA && ewayBillDATA.validUptoDate)? Moment(ewayBillDATA.validUptoDate,"DD-MM-YYYY").format("YYYY-MM-DD") : undefined,
            "lr_no"            : (LRCOPYDATA && LRCOPYDATA.lrNo)?LRCOPYDATA.lrNo:"",
            "lr_date"          : (LRCOPYDATA && LRCOPYDATA.lrDate)? Moment(LRCOPYDATA.lrDate,"DD-MM-YYYY").format("YYYY-MM-DD") : undefined
        }
        let obj = {};
        Object.keys(customFormIds).forEach(el => {
            let field = asnForms.find(form => form.fieldId === el);
            if(field){
                obj[field.id] = customFormIds[el];
            }
        })
        setPrefill(obj);
    }

    const onSubmit = () => {
        let codeValidate = companyCode === 'TRDT'
        let deliveryDate = DELIVERYDATE.current.value;
        let challan = CHALLAN.current.value;
        let vehicle = VECHICAL.current.value;
        let eWayBillNo = E_WAY_BILL.current.value;
        let eWayBillValidUpto = E_WAY_VALID.current.value;
        let time = TIME.current.value;
        if(eWayBillValidUpto && (moment(eWayBillValidUpto)).isBefore(moment(), 'day')){
            showToast({
                type: 'error',
                message: 'E-way valid upto date has been passed!'
            })
            return;
        }
        if (!deliveryDate) {
            showToast({
                type: 'error',
                message: 'Please enter the delivery date'
            })
            return;
        }
        if (codeValidate && !eWayBillNo) {
            showToast({
                type: 'error',
                message: 'Please enter e-Way Bill No'
            })
            return;
        }
        if (codeValidate && !eWayBillValidUpto) {
            showToast({
                type: 'error',
                message: 'Please select e-way valid upto'
            })
            return;
        }
        
        if(eWayBillValidUpto){
            eWayBillValidUpto = time ? Moment(`${eWayBillValidUpto} ${time}`,"YYYY-MM-DD HH:mm").valueOf():  Moment(eWayBillValidUpto, 'YYYY-MM-DD HH:mm').valueOf()
        }
        // if (eWayBillNo && !verified) {
        //     showToast({
        //         type: 'error',
        //         message: 'eWayBillNo not verified'
        //     })
        //     return;
        // }

        CUSTOMFORM.current.getKeyValue(cb => {
            let data = {
                deliveryDate: Moment(deliveryDate, 'YYYY-MM-DD').valueOf(),
                forms: cb,
                challanNo: challan,
                vehicleNo: vehicle,
                eWayBillNo,
            }
            if (eWayBillValidUpto) {
                data.eWayBillValidUpto = eWayBillValidUpto
            }
            onSave(data)
        })
    }

    const onChangeBillNo = (e) => {
        let val = e.target.value
        if (val.length === 15) {
            // setShowVerification(val)
        }
        if (val.length > 15) {
            E_WAY_BILL.current.value = val.slice(0, 15)
        }
        setVerified(undefined)
    }

    return (
      <>
        <Modal
          show={true}
          stateLoading={loading}
          des="Fill details to create ASN"
          title={"Create ASN"}
          close={onClose}
          onSave={onSubmit}
          buttonName={"Submit Invoice"}
        >
          <FunctionalInput
            ref={DELIVERYDATE}
            type="date"
            title="Select Delivery Date"
          />
          <FunctionalInput ref={CHALLAN} type="text" title="Challan Number" />
          <FunctionalInput ref={VECHICAL} type="text" title="Vehicle number" />
          <FunctionalInput
            ref={E_WAY_BILL}
            type="text"
            title="E-Way Bill No"
            onChange={onChangeBillNo}
          />
          {/* {showVerification && (
            <p
              style={{
                marginTop: 12,
                color: "var(--primary-color)",
                fontSize: 13,
                cursor: "pointer",
                fontWeight: 600,
              }}
              onClick={() => {
                setVerify(true);
              }}
            >
              Verify E-Way Bill No
            </p>
          )} */}
          {verified && (
            <>
              <div
                className="input-label"
                style={{
                  border: "1px solid var(--border-color)",
                  borderRadius: 6,
                  padding: 8,
                }}
              >
                <div
                  className="pd5"
                  style={{
                    display: "flex",
                    background: `white`,
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <img
                    src={CheckIcon}
                    className="mt1"
                    alt="Icon"
                    style={{ width: 22, height: 22, borderRadius: 20 }}
                  />
                  <p
                    style={{
                      marginLeft: 10,
                      textAlign: "center",
                      paddingTop: 2,
                      fontSize: 11,
                    }}
                    className="input-label"
                  >
                    e-way Bill Number Verified
                  </p>
                </div>
                <div>
                  Transporter Name :
                  {verified && verified.transporterName
                    ? verified.transporterName
                    : "-"}
                </div>
                <div>
                  Extended Times :
                  {verified && verified.extendedTimes
                    ? verified.extendedTimes
                    : "-"}
                </div>
                <div>
                  Status :{verified && verified.status ? verified.status : "-"}
                </div>
              </div>
            </>
          )}
          <div style={{ display: "flex", gap: 10 }}>
            <FunctionalInput
              ref={E_WAY_VALID}
              min={moment().format("YYYY-MM-DD")}
              type="date"
              title="E-Way Bill Valid Upto"
            />
            <FunctionalInput type="time" ref={TIME} title="Time" />
          </div>
          <Form preFill={prefill} handler={ref => CUSTOMFORM.current = ref} />
        </Modal>
        {verify && (
          <EwayVerifiy
            ewayNum={E_WAY_BILL.current.value}
            gstin={inputData.vendor.gstin || "-"}
            onVerify={(res) => {
              if (res) {
                setVerified(res && res.data ? res.data : undefined);
                setVerify(false);
              }
            }}
            onClose={() => setVerify(false)}
          />
        )}
      </>
    );
}

export default CreateAsn