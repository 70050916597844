import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { get,post } from '../../../../../Model/Network/Config/Axios';
import { SearchableSelect } from '../../../../Common/Class/Select/SearchableSelec2';
import { FunctionalInput } from '../../../../Common/fInputs';
import { Modal } from '../../../../Common/fModal/Modal';
import { handleError, showToast } from '../../../../Common/fToast/ToastCallback';
import { LineHeader } from '../../../../Header/LineHeader';
import { useSelector } from 'react-redux';



const Tds = (props) => {


    const [tds,setTds] = useState([]);
    const [tdsMode,setTdsMode] = useState('percentage');
    const [changeTds,setChangeTds] = useState(false);
    const [allTds,setAllTds] = useState([]);
    const [selectedTds,setSelectedTds] = useState(false);
    const Amount = useRef(),CODE=useRef();
	const {onlyGlobalTdsMaster} = useSelector((state) => state.auth.config.plugins);
    let  id= +props.id;
	let {vendorId,vendor} =props

	useEffect(() => {
		if (onlyGlobalTdsMaster) {
			get(`vendor/config/tds/allTdsMaster`, (e, r) => {
				if (r) {
					const individualHufTypes = vendor && vendor.pan && ["P", "H", "B"].includes(vendor.pan.charAt(3));			
					setAllTds(r.tds.map(val => ({
						id: val.id,
						label: val.section,
						des: val.description,
						tax: individualHufTypes ? val.individualHufRate:  val.otherRate
					})))
				}
			})
		} else {
			get(`vendor/contacts/${vendorId}/details`, (e1, r1) => {
				if (r1) {
					get(`/vendor/config/tds/all`, (e, r) => {
						if (r) {
							if (r1.config.tdsIds.length) {
								setTds(r.tds.filter(item => _.includes(r1.config.tdsIds, item.id)).map(val => ({
									id: val.id,
									label: val.code,
									des: val.description,
									tax: val.tax
								})))
							} else {
								setTds(r.tds.map(val => ({
									id: val.id,
									label: val.code,
									des: val.description,
									tax: val.tax
								})))
							}

						}
					})
				}
			})
		}

	}, [])

    return(
        <>      
                <div style={{marginTop:0}}>
                    <p style={{marginBottom:10}}>Tds</p>
                    <div style ={{
                        height:40,
                        background:'var(--off-white)',
                        border:'1px solid var(--border-color)',
                        width:'100%',
                        padding:10,
                        fontSize:12,
                        color:'var(--text-color)'
                    }} onClick={()=>{
						if(props.disabled) return;
                        setChangeTds(true);
                    }}>
                        {props.value?props.value.toFixed(2):props.value}
                    </div>
                </div>
                {
					changeTds ?
						<Modal
							onSave={() => {
								if (tdsMode === 'percentage') {
									if (!selectedTds) {
										showToast({
											type: 'error',
											value: 'Please enter some value'
										})
										return;
									}
									post(`/vendor/invoicing/${id}/change`, {
										key: 'tds',
										value: `${selectedTds.id}`,
										billDate:props.billDate
									}, (e, r) => {
										if (r) {
											props.init();
										} else {
											handleError(e)
										}
									})
                                    setChangeTds(false);
									showToast({
										type: 'success',
										message: "Field update successfully"
									})
								} else {
									let amount = Amount.current.value;
									let code = CODE.current.value;
									if (!amount) {
										showToast({
											type: 'error',
											message: "Please enter the amount"
										})
										return;
									}
									if(!code) {
										showToast({
											type: 'error',
											message: "Please enter the remarks"
										})
										return;
									}
									post(`/vendor/invoicing/${id}/change`, {
										key: 'tds.fixed',
										value: amount,
										code
									}, (e, r) => {
										if (r) {
											props.init();

										} else {
											handleError(e)
										}
									})
                                    setChangeTds(false);
									showToast({
										type: 'success',
										message: "Field update successfully"
									})
								}
							}}
							style={{
								save: {
									padding: '12px 15px',
									width: '100%',
									textAlign: 'center',

								}
							}}
							show={true}
							title="Change Tds"
							des="Change Tds"
							close={() => {
                                setChangeTds(false);
							}}
							buttonName="Change Tds "
						>
							<LineHeader
								items={
									[
										{
											name: 'Select Percentage',
											active: tdsMode === 'percentage',
											click: () => {
                                                setTdsMode('percentage');
											}
										},
										{
											name: 'Select Amount',
											active: tdsMode === 'amount',
											click: () => {
                                                setTdsMode('amount');
											}
										},

									]
								}
							/>
							<div style={{
								marginTop: 20
							}}/>
							{
								tdsMode === 'percentage' ?
									<SearchableSelect
										disabled={props.disabled}
										label="Select Tds"
										placeholder="Select Tds"
										onChange={val => {
											if (val.length > 0) {
												let allItems = tds.filter(item => {
													if (val.toLowerCase().includes(item.label)) {
														return true
													}
													if (val.toLowerCase().includes(item.desc)) {
														return true;
													}
													if (val.toLowerCase().includes(item.tax)) {
														return true
													}
													return false;
												})

                                                setAllTds(allItems);
											} else {
                                                setTds(tds);
											}
										}}
										select={selectedTds => {
                                            setSelectedTds(selectedTds);
										}}
										value={selectedTds}
										items={allTds.length > 0 ? allTds : tds}
									/>
									: tdsMode === 'amount' ?
									<>
									<FunctionalInput disabled={props.disabled} type="number" title="Enter Amount"
												ref={Amount}/>
									<FunctionalInput disabled={props.disabled} type="text" title="Remarks"
												ref={CODE}/>
									</>
									:
									null

							}
						</Modal>
						: null
				}

        </>
    )
}

export default Tds;