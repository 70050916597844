import React, { useEffect, useRef, useState } from 'react';
import Moment from 'moment';
import _ from 'lodash';
import {useParams} from 'react-router-dom';
import { post,get } from '../../../../../Model/Network/Config/Axios';
import { showToast,handleError } from '../../../../Common/fToast/ToastCallback';
import { Modal } from '../../../../Common/fModal/Modal';
import { FunctionalInput } from '../../../../Common/fInputs';
import { HalfModal } from '../../../../Common/fModal/HalfModal';
import {Title as Header} from "../../../../Header/TopHeader";
import PhotoTile from '../../../../Common/fPhoto';
import { Select } from '../../../../Common/Class/Select';
import { useSelector } from 'react-redux';
import { FloatSelect } from '../../../../Common/Class/FloatLabelInput';
import GrnFill from './GRNFILL/GrnFill';
import ViewGrn from './GRNFILL/ViewGrn';
import { MultipleAttachmentUploaderModal } from '../../../../Common/Class/FloatLabelInput/MultipleFileAlone';


const Index = (props) => {

    let invoiceList = props.data.invoices


    let {id} = useParams();
    const [modal,setModal] = useState(false);
    const [short,setShort] = useState(false);
    const [selectedItems,setSelectedItems] = useState([]);
    const [attachment,setAttachment] = useState([]);
    const [grns,setGrns] =useState([]);
    const [complete,setComplete ] = useState(false);
    const [invoices,setInvoices] = useState([])
    const [selectedInvoice,setSelectedInvoice] = useState(undefined);
    const [stateLoading] = useState(false);
    const [viewGrn,setViewGrn] = useState(undefined);
    const [grnKey] = useState('invoice');
	const {renewalTracking,conditionalPo,blockGrnCreation} = useSelector(state=>state.auth.config.plugins);
    const [renewalItem] = useState(false);
    const [renewal,setRenewal] = useState(false);
    const [renewalGrnId,setRenewalGrnId]= useState(undefined);
    const [renewalTracker,setRenewalTracker] = useState([]);
    const [selectedRenewalTracker,setSelectedRenewalTracker] = useState([]);
    const [fill,setFill] = useState(false);
    const [completeLoading,setCompleteLoading] = useState(false);
    let Qtty = useRef(),GrnNumber=useRef(),Dates=useRef(),REMARKS=useRef();
    let TYPESELECT =useRef(),ENDDATE=useRef(),LAGDAY=useRef(),SERIALNUMBER=useRef();
    const [productTaxMapping,setProductTaxMapping] = useState({});

    useEffect(()=>{
        if(props && props.data && props.data.procured && props.data.procured.length){
            let obj={};
            props.data.procured.forEach((item)=>{
            obj[(item && item.id)?item.id:""]=(item && item.taxSlab)?item.taxSlab:0;
            })
            setProductTaxMapping(obj);
        }
    },[])
    


    useEffect(()=>{
        init();
    },[])

    useEffect(()=>{
        setInvoices(invoiceList.filter(val=>val.status==='POHOLD').map(val=>({label:`${val.invoiceId}/${val.billNumber}`,value:val.id})))
    },[invoiceList])



    useEffect(()=>{
        if(renewalGrnId && renewalTracking) {
            let filter = [];
            filter.push(`0@deleted`);
            filter.push(`${id}@new_po_id`);
            get(`vendor/stock/renewalTracking/getRenewalTrackers`,(e,r)=>{
                if(r) {
                   setRenewalTracker(r.trackers);
                }
            },{
                filter
            })
        }
    },[renewalGrnId,renewalTracking])


    let getId = () => {
        return props.id;
    }

   let init = () => {
        get(`vendor/po/requests/${getId()}/grn`, (e, r) => {
            if (r) {
                setGrns(r.grns);
            }
        })
    }

    let getGrnDetails =  (item) => {
        get(`vendor/po/requests/${getId()}/grn/${item.id}/details`, (e, r) => {
            if (r) { 
                const updatedItems = r.items.map((i) => {
                    const data = props.data.procured.find((e) => e.id === i.id);
                    const updatedItem = { ...i };
                      updatedItem.poQty = (data && data.qtty) || 0;
                      updatedItem.address = (data && data.officeAddress) || i.address ||  '-';
                      updatedItem.officeName =(data && data.officeName) || 0;
                      updatedItem.price = (data && data.price) || 0;
                      return updatedItem;
                  });
                  setViewGrn({...r,items:updatedItems}, item);
            }
        })
    }
    
  let selected = (item, index) => {
        return (
            <div key={index} style={{
                display: 'flex',
                flexDirection: 'row',
                border: '1px solid var(--border-color)',
            }}>
                <div style={{
                    padding: 8,
                    flexDirection: 'row',
                    display: 'flex',
                    flex: 3
                }}>
                    <div style={{

                    }}>
                        <p style={{
                            fontSize: 13,
                            color: "var(--text-color)"
                        }}>
                            {item.name}
                        </p>
                        <p style={{
                            fontSize: 11,
                            color: "var(--light-color)"
                        }}>
                            {item.vendor}
                        </p>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flex: 1,
                    fontSize: 11,
                    color: "var(--text-color)",
                    alignItems: 'center'
                }}>
                    {
                        Moment(item.prDate).format("DD-MM-YYYY")
                    }
                </div>
                <div style={{
                    flex: 1,
                    display: 'flex',
                    fontSize: 13,
                    color: "var(--text-color)",
                    alignItems: 'center'
                }}>
                    {
                        item.qtty
                    } {item.unit}
                </div>
                <div style={{
                    flex: 1,
                    display: 'flex',
                    fontSize: 13,
                    color: "var(--text-color)",
                    alignItems: 'center'
                }}>
                    {
                        item.required
                    }
                </div>

                {
                    item.qtty === item.approved ?
                        <div style={{
                            flex: 1,
                            color: 'var(--primary-color)',
                            fontWeight: 600,
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 13,
                            cursor: 'pointer'
                        }}>

                        </div>
                        :
                        <div style={{
                            flex: 1,
                            color: 'var(--red-color)',
                            fontWeight: 600,
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 13,
                            cursor: 'pointer'

                        }} onClick={() => {
                            let mSelectedItems = [...selectedItems];
                            mSelectedItems = mSelectedItems.filter(val => val.id !== item.id);
                            setSelectedItems(mSelectedItems);
                        }}>
                            Delete
                        </div>
                }
            </div>

        )
    }

   let doneGrn = () => {
        return (
            <div style={{
                marginBottom: 20
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    border: '1px solid var(--border-color)',
                }}>
                    {
                        [
                            {
                                name: "Reference No",
                                flex: 1
                            },
                            {
                                name: "Date",
                                flex: 1
                            },
                           
                            {
                                name:'',
                                width:100
                            }

                        ].map((item, i) => {
                            return (
                                <div key={i} style={{
                                    ...item.width ? {
                                        width: item.width
                                    } : {
                                            flex: item.flex
                                        }
                                }}>
                                    <p style={{
                                        padding: 8,
                                        fontSize: 13,
                                        color: "var(--text-color)"
                                    }}>
                                        {item.name}
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>

                {grns.map((item, index) => {
                    return (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            border: '1px solid var(--border-color)',
                            alignItems: 'center',
                            height: 45,
                            fontSize: 14
                        }} key={index}>
                            <div style={{
                                paddingLeft: 10,
                                display: 'flex',
                                alignItems: 'center',
                                flex: 1
                            }}>
                                {item.reference ? item.reference : item.id}
                            </div>
                            <div style={{
                                flex: 1
                            }}>
                                {Moment(item.date).format('DD-MM-YYYY')}
                            </div>
                           
                            <div  style ={{
                                width:100,
                                textAlign:'center',
                                color:'var(--primary-color)',
                                fontWeight:600,
                                cursor:'pointer'
                            }} onClick={()=>{
                                getGrnDetails(item);
                            }}>
                                View
                            </div>


                        </div>
                    )
                })}
            </div>
        )
    }

        let { data } = props;
        let { procured } = data;
        return (
            <div style={{
                position:'relative'
            }}>
                <div style={{padding: '0px 20px'}}>
				    <Header container={true} id="Grn" title="Grn Items" des="All grn items are visible here" />
		
				</div>
                <div className='input-container' style={{position:'relative'}}>
                {
                    selectedItems.length > 0 ?
                        <div className="btn btn-portlet" style={{width:180,position:"absolute",right:20,top:10}} onClick={() => {
                            setComplete(true);
                        }}>
                            Complete Grn
                        </div>
                        : null
                }
                <div>

                    {
                         selectedItems.length > 0 ?
                            <div style={{
                                marginBottom: 20
                            }}>
                                <p style={{fontSize:11,color:"var(--text-color)",marginBottom:12}}>Grn Selected</p>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    border: '1px solid var(--border-color)',
                                }}>
                                    {
                                        [
                                            {
                                                name: "Details",
                                                flex: 3
                                            },
                                            {
                                                name: "Date",
                                                flex: 1
                                            },
                                            {
                                                name: "Quantity/unit",
                                                flex: 1
                                            },
                                            {
                                                name: "Required",
                                                flex: 1
                                            },
                                            {
                                                name: "Action",
                                                flex: 1
                                            }
                                        ].map((item, i) => {
                                            return (
                                                <div key={i} style={{
                                                    ...item.width ? {
                                                        width: item.width
                                                    } : {
                                                            flex: item.flex
                                                        }
                                                }}>
                                                    <p style={{
                                                        padding: 8,
                                                        fontSize: 13,
                                                        color: "var(--text-color)"
                                                    }}>
                                                        {item.name}
                                                    </p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {selectedItems.map(selected)}
                            </div>
                            : null
                    }
                    <div style={{
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'space-between'
                    }}>
                         <p style={{fontSize:11,color:"var(--text-color)",marginBottom:12}}>
                                Pending Items
                         </p>
                         {
                            conditionalPo?
                            <div onClick={()=>{
                                setFill(true);
                            }} style={{
                                fontSize:14,
                                color:'var(--primary-color)',
                                fontWeight:600,
                                marginBottom:5,
                                cursor:'pointer'
                            }}>
                                Create GRN
                            </div>
                            :null

                         }
                        
                    </div>
                    
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        border: '1px solid var(--border-color)',
                    }}>
                        {
                            [
                                
                                {
                                    name: "Details",
                                    flex: 2
                                },
                                {
                                    name: "Date",
                                    flex: 1
                                },
                                {
                                    name: "Quantity/unit",
                                    flex: 1
                                },
                                {
                                    name: "Delivered",
                                    flex: 1
                                },
                                {
                                    name: "Action",
                                    flex: 0.5
                                }
                            ].map((item, i) => {
                                return (
                                    <div key={i} style={{
                                        ...item.width ? {
                                            width: item.width
                                        } : {
                                                flex: item.flex
                                            }
                                    }}>
                                        <p style={{
                                            padding: 8,
                                            fontSize: 13,
                                            color: "var(--text-color)"
                                        }}>
                                            {item.name}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        procured.filter(val => val.qtty !== val.approved).length === 0 ?
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: 12,
                                fontSize:11,
                                border: '1px solid var(--border-color)'
                            }}>
                                Nothing to procure
                            </div> : undefined
                    }

                    {
                        procured.filter(val => val.qtty !== val.approved).map((item, i) => {
                            return (
                                <div key={i} style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    border: '1px solid var(--border-color)',
                                }}>
                                    
                                    <div style={{
                                        padding: 8,
                                        flexDirection: 'row',
                                        display: 'flex',
                                        flex: 2
                                    }}>
                                        <div style={{

                                        }}>
                                            <p style={{
                                                fontSize: 13,
                                                color: "var(--text-color)"
                                            }}>
                                                {item.name}
                                            </p>
                                            <p style={{
                                                fontSize: 11,
                                                color: "var(--text-color)",
                                                fontWeight:'bold'
                                            }}>
                                               Item Ref no: {item.id}
                                            </p>
                                            <p style={{
                                                fontSize: 11,
                                                color: "var(--light-color)"
                                            }}>
                                                {item.vendor}
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flex: 1,
                                        fontSize: 11,
                                        color: "var(--text-color)",
                                        alignItems: 'center'
                                    }}>
                                        {
                                            Moment(item.prDate).format("DD-MM-YYYY")
                                        }
                                    </div>
                                    <div style={{
                                        flex: 1,
                                        display: 'flex',
                                        fontSize: 11,
                                        color: "var(--text-color)",
                                        alignItems: 'center'
                                    }}>
                                        {
                                            item.qtty
                                        } {item.unit}
                                    </div>
                                    <div style={{
                                        flex: 1,
                                        display: 'flex',
                                        fontSize: 11,
                                        color: "var(--text-color)",
                                        alignItems: 'center'
                                    }}>
                                        {
                                            item.approved
                                        }
                                    </div>
                                    {
                                        item.qtty === item.approved || conditionalPo || blockGrnCreation?
                                            <div style={{
                                                flex: 0.5,
                                                color: 'var(--primary-color)',
                                                fontWeight: 600,
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontSize: 11,
                                                cursor: 'pointer'
                                            }}/>
                                            :
                                            <div style={{
                                                flex: 0.5,
                                                background: 'var(--primary-color)',
                                                color: '#fff',
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontSize: 11,
                                                cursor: 'pointer',
                                                padding: '5px',
                                                borderRadius: '5px'


                                            }} onClick={() => {
                                                setModal(item);
                                                if((item.qtty-item.approved)>0) {
                                                    Qtty.current.value = (item.qtty-item.approved).toFixed(2);
                                                }
                                            }}>
                                                Grn
                                            </div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{marginTop:20}}>
                    <p style={{fontSize:11,color:"var(--text-color)",marginBottom:12}}>
                        Past Grns
                    </p>
                    {
                        grns.length > 0 ?
                           doneGrn()
                            : <p style={{
                                padding:'12px 20px',
                                border:'1px solid var(--border-color)',
                            fontSize:13
                            }}>
                                No Past Grn Found
                            </p>
                    }
                </div>
                </div>

                <Modal
                    show={modal}
                    title="Grn Item"
                    des="Select the number of item whom you want to grn"
                    close={() => {
                        setModal(false);
                    }}
                    style={
                        {
                            save: {
                                width: '100%',
                                fontSize: 17,
                                height: 44,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                ...stateLoading ? {
                                    background: 'var(--white-color)'
                                } : null
                            }
                        }
                    }
                    buttonName="Select Item"
                    onSave={() => {
                        let selectedItem = selectedItems;
                        selectedItem = selectedItem.filter(val => val.id !== modal.id);
                        let item = {...modal};
                        item.required = +Qtty.current.value;
                        selectedItem.push(item);
                        setSelectedItems(selectedItem);
                        setModal(false);
                    }}
                >
                    <FunctionalInput type="text" ref={Qtty} title="Quantity" />
                </Modal>


                <Modal
                    show={complete}
                    title="Complete Grn"
                    des="Complete the grn for selected item"
                    stateLoading={completeLoading}
                    close={() => {
                        setComplete(false);
                    }}
                    style={
                        {
                            save: {
                                width: '100%',
                                fontSize: 17,
                                height: 44,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                ...stateLoading ? {
                                    background: 'var(--white-color)'
                                } : null
                            }
                        }
                    }
                    buttonName="Complete Grn"
                    onSave={() => {
                        let reference = GrnNumber.current.value;
                       
                        if (!Dates.current.value) {
                            showToast({
                                type: 'error',
                                message: 'Please select the date'
                            })
                            return
                        }
                       
                        let date = Moment(Dates.current.value, 'YYYY-MM-DD').format('DD-MM-YYYY')
                        let items = selectedItems.map(val => ({
                            qtty: val.required,
                            id: val.id
                        }))
                        let fileNames = {};
                        attachment.forEach(i=>{
                            fileNames[i.url] = i.name
                        })
                        let data = {
                            reference,
                            date,
                            items,
                            files:attachment.map(i=>i.url),
                            fileNames
                        }

                        if(grnKey === 'invoice') {
                            if(!selectedInvoice) {
                                showToast({
                                    type:'error',
                                    message:"Please select the invoice first"
                                })
                                return
                            }
                            data.invoice = selectedInvoice.value;
                            setCompleteLoading(true)
                            post(`vendor/po/requests/${selectedInvoice.value}/invoiceGrnV2`, { data }, (e, r) => {
                                if (r) {
                                    if(renewalTracking) {
                                        setRenewalGrnId(r.grnId);
                                    }
                                    showToast({
                                        type: 'success',
                                        message: 'Grn done successfully'
                                    })
                                    setSelectedItems([]);
                                    setComplete(false);
                                    setSelectedInvoice(undefined)
                                    init();
                                    props.init();
                                }
                                if (e) {
                                    handleError(e);
    
                                }
                                setCompleteLoading(false)
                            })

                        }}
                        
                    }

                        
                    
                >
                   
                {

                    grnKey === 'invoice'?
                    <Select items={invoices} placeholder="Select Invoice" value={selectedInvoice} select={val=>{
                        setSelectedInvoice(val)
                    }}/>
                   : null 
                   }
                    <FunctionalInput type="text" ref={GrnNumber} title="Grn number(optional)" />
                    <FunctionalInput type="date" ref={Dates} title="Date" />
                    <p style={{fontSize:14,fontWeight:60,marginTop:5,marginBottom:5}}>Delivery Proof : </p>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <MultipleAttachmentUploaderModal onUpload={(items)=>{
                            let allFiles = [...attachment];
                            allFiles.push(...items);
                            setAttachment(allFiles)
                        }} />
                      
                        {
                            attachment.length>0 ?
                            attachment.map((val,index)=>{
                                return(
                                    <PhotoTile key={index} link={val.url}/>
                                )
                            })
                            : null
                        }
                    
                    </div>
                    
                </Modal>

                {
                    viewGrn ?
                    <ViewGrn 
                        {...props}
                        setRenewal={()=>{
                            setRenewal(renewalItem)
                        }}
                        conditionalPo={conditionalPo}
                        renewalTracking={renewalTracking}
                        renewalItem={renewalItem}
                        viewGrn={viewGrn}
                        init={()=>{
                            props.init();
                        }}
                        close={()=>{
                            setViewGrn(false)
                        }}
                        productTaxMapping={productTaxMapping}
                        />
                    :null
                }

                
                {
                short?
                    <Modal buttonName="Create" show={short}
                           title="Confirmation Dialog"
                           des="Do you want to create purchase order from this items"
                           style={
                               {
                                   save: {
                                       width: '100%',
                                       fontSize: 17,
                                       height: 44,
                                       display: 'flex',
                                       justifyContent: 'center',
                                       alignItems: 'center',
                                   }
                               }
                           }
                           onSave={() => {

                              let remarks = REMARKS.current.value;
                              if(!remarks) {
                                  showToast({
                                      type:'error',
                                      message:"Please enter the remarks"
                                  })
                                  return
                              }
                               post(`vendor/po/requests/${getId()}/short`, {
                                   remarks
                               }, (e, r) => {
                                   if (r) {
                                      
									   showToast({
										   type:'success',
										   message:"Short Closure done successfully"
									   })
                                       REMARKS.current.value ='';
                                       setShort(false);
                                       
                                   } else {
                                       handleError(e)
                                   }
                               })
                           }}
                           close={() => {
                               setShort(false)
                           }}>
                       <FunctionalInput type="text" title="Remarks" ref={REMARKS} />
                    </Modal>:undefined
            }

            {
                renewal ?
                <Modal
                    title="Renewal Item"
                    show={true}
                    close={()=>{
                        setRenewal(false);
                    }}
                    buttonName="Update"
                    des="Enter the details of renewal item"
                    onSave={()=>{

                        let type = TYPESELECT.current.value();
                        let date = ENDDATE.current.value;
                        let alertLagDays = LAGDAY.current.value;
                        let serialNumber = SERIALNUMBER.current.value;

                        if(!type) {
                            showToast({
                                type:'error',
                                message:"Please select the type"
                            })
                            return;
                        }

                        if(!date) {
                            showToast({
                                type:'error',
                                message:"Please select the end date"
                            })
                            return;
                        }

                        if(!alertLagDays) {
                            showToast({
                                type:'error',
                                message:"Please select the lag days"
                            })
                            return;
                        }

                        if(!serialNumber) {
                            showToast({
                                type:'error',
                                message:"Please enter the serail number"
                            })
                            return;
                        }

                        let data = {
                            type,
                            date:Moment(date,'YYYY-MM-DD').valueOf(),
                            alertLagDays:+alertLagDays,
                            serialNos:[serialNumber],
                            qty:+renewal.qtty,
                            productId:+renewal.id,
                            grnId:+viewGrn.id

                        }

                        post(`vendor/stock/renewalTracking/AddRenewalTracker`,data,(e,r)=>{
                            if(r) {
                                showToast({
                                    type:'success',
                                    message:"Renewal Tracker added successfully"
                                })
                                setRenewal(false);
                            } else {
                                showToast({
                                    type:'error',
                                    message:e.data.message
                                })
                            }
                        })
                    }}
                >
                    <FloatSelect handleRef={ref=>TYPESELECT.current=ref}  items={[{key:'WARRANTY',value:"WARRANTY"},{key:'SUBSCRIPTION',value:'SUBSCRIPTION'}]} />
                    <FunctionalInput ref={LAGDAY} type="number" title="Lag Day" />
                    <FunctionalInput ref={ENDDATE} type="date" title="End Date" />
                    <FunctionalInput ref={SERIALNUMBER} type="text" title="Serial Number" />

                 
                </Modal>
                :null
            }

            {
                fill ?
                <GrnFill 
                    {...props}
                    close={()=>{
                        setFill(false);
                        init()
                        props.init && props.init()
                    }}
                />
                :null
            }

            {
                renewalGrnId ?

                <HalfModal
                    title="Disable Renewal Tracker"
                    close={()=>{
                        setRenewalGrnId(false);
                    }}
                    show={true}
                >
                     <p style={{fontSize:15,fontWeight:500,marginBottom:30}}>Select the items which you want to disable</p>
                     {
                        renewalTracker.map((item,index)=>{
                            let active = _.includes(selectedRenewalTracker,item.id);
                            return(
                                <div key={index} style={{display:'flex',alignItems:'center',borderBottom:'1px solid var(--border-color)',paddingBottom:12}}>
                                    <div style={{
                                        border:'2px solid var(--border-color)',
                                        height:22,
                                        width:22,
                                        borderRadius:4,
                                        ...active ?
                                        {
                                            background:"var(--primary-color)",
                                            border:'2px solid var(--border-color)'
                                        }:
                                        {

                                        }
                                    }} onClick={()=>{
                                        let items = [...selectedRenewalTracker];
                                        if(_.includes(items,item.id)) {
                                            items = items.filter(i=>i!==item.id);
                                        } else {
                                            items.push(item.id);
                                        }
                                        setSelectedRenewalTracker(items);
                                    }} />

                                    <div style={{marginLeft:20}}>
                                        <span style={{fontSize:14}}>{item.stock.product.title}</span> <br></br>
                                        <span style={{fontSize:12,fontWeight:'bold'}}>{item.stock.product.productId}</span>
                                    </div>
                                </div>
                            )
                        })
                     }

                     <div onClick={()=>{
                        if(!selectedRenewalTracker.length) {
                            showToast({
                                type:'error',
                                message:"Please select atleast one item"
                            })
                            return;
                        }

                        let count = 0;
                        selectedRenewalTracker.forEach(item=>{
                            post(`vendor/stock/renewalTracking/deActivate/${item}`,{grnId:renewalGrnId},(e,r)=>{
                                if(r) {
                                    count++;
                                    if(count===selectedRenewalTracker.length) {
                                        showToast({
                                            type:'success',
                                            message:"All items disabled successfully."
                                        })
                                        setRenewalGrnId(false);
                                    }
                                    
                                }
                            })
                        })

                        

                     }} className='btn btn-portlet' style={{marginTop:40}}>
                        Disable Tracker
                     </div>
                </HalfModal>

                :null

            }

            </div >
        )

}

export default Index;
