import React, { useEffect, useState } from 'react'
import Accordion from "../../../../../Common/Accordion";
import { Modal } from '../../../../../Common/fModal/Modal';
import EditableBorderTable from "../../../../../Common/EditableBorderTable/EditableBorderTable"
import { getFormattedEditableData } from './OCRMismatch';
import { get } from '../../../../../../Model/Network/Config/Axios';

const MismatchModal = ({ data = {}, onClose, onSubmit, }) => {
    const { misMatchData, lineitemOCRPOMismatch, hardStop } = data;
    const [config,setOCRConfig] = useState({ocrMisMatchHardStopConfig:{},ocrMisMatchSoftStopConfig:{}});

    useEffect(() => {
        INIT();
    }, [])

    const INIT = () => {
        get(`settings/exceptions/vendor`, (e, r) => {
            if (r) {
                setOCRConfig({ ocrMisMatchHardStopConfig: r.ocrMisMatchHardStopConfig, ocrMisMatchSoftStopConfig: r.ocrMisMatchSoftStopConfig });
            }
        })
    }

    let isLineItemMismatch = false;
    Object.keys(lineitemOCRPOMismatch).forEach(el => {
        if (!lineitemOCRPOMismatch[el] || Object.keys(lineitemOCRPOMismatch[el]).length) isLineItemMismatch = true;
    })

    const submitHandler = () => {
        let OriginalEditableData = getFormattedEditableData(misMatchData, lineitemOCRPOMismatch);
        onSubmit(OriginalEditableData)
    }
    return (
        <Modal show={true} title='OCR Mismatch' des='' close={onClose} hideButton={hardStop} buttonName={hardStop ? undefined : "Submit Invoice"} onSave={hardStop ? undefined : submitHandler} width={'70vw'} contentStyle={{ maxHeight: 700 }} modalStyle={{ maxWidth: "60vw" }}>
            {misMatchData && !!Object.keys(misMatchData).length &&
                <Accordion defaultExpanded={true} title={'OCR-Invoice VS PO'} uniqueKey="OCR VS PO'">
                    <EditableBorderTable className="bg-white" rows={
                        Object.keys(misMatchData).filter((item) => (config.ocrMisMatchHardStopConfig[misMatchData[item].name] || config.ocrMisMatchSoftStopConfig[misMatchData[item].name])).map((mismatchKey) => {
                            let { po: poData, ocr: ocrData } = misMatchData[mismatchKey];
                            return (
                                [{
                                    data: mismatchKey
                                },
                                {
                                    data: poData
                                },
                                {
                                    data: ocrData
                                }]
                            )
                        })} headers={["Type", "PO", "OCR-Invoice"]} />
                </Accordion>
            }

            {isLineItemMismatch && (config.ocrMisMatchHardStopConfig['lineItem'] || config.ocrMisMatchSoftStopConfig['lineItem']) &&
                Object.keys(lineitemOCRPOMismatch).map((el) => {
                    let notFound = false;
                    if (!lineitemOCRPOMismatch[el]) notFound = true;
                    else if (Object.keys(lineitemOCRPOMismatch[el]).length === 0) return <></>;

                    return (
                        <Accordion uniqueKey={el} title={el.split('-').slice(1).join(',')}>
                            {notFound ?
                                <p>Line item not found in OCR</p> :
                                <EditableBorderTable className="bg-white" rows={
                                    Object.keys(lineitemOCRPOMismatch[el]).map((mismatchKey) => {
                                        let { po: poData, ocr: ocrData } = lineitemOCRPOMismatch[el][mismatchKey];

                                        return (
                                            [{
                                                data: mismatchKey
                                            },
                                            {
                                                data: poData && poData.value
                                            },
                                            {
                                                data: ocrData && ocrData.value,
                                            }]
                                        )
                                    })} headers={["Type", "PO", "OCR-Invoice"]} />
                            }
                        </Accordion>
                    )
                })
            }
        </Modal>
    )
}

export default MismatchModal